import React, { useState } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  HStack,
  IconButton,
  Spacer,
  useColorMode,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Button,
  useToast,
} from "@chakra-ui/react";
import { FaTrash, FaClock } from "react-icons/fa";
import { CloseIcon, AddIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import GridIcon from "../../assets/icons/dot_grid_icon.svg";
import axiosInstance from "../../helpers/axiosInstance"; // Import your axios instance
import Translate from "../../components/LanguageTranslation/Translate";

const AllActivitiesList = ({
  activities,
  setActivities,
  onActivityClick,
  tripId,
  location,
  setView,
}) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [hoveredItem, setHoveredItem] = useState(null);

  // Function to update activities on the backend
  const handleUpdateActivities = async (updatedActivities) => {
    try {
      await axiosInstance.put(`/bucket-list/${tripId}/update/activities`, {
        viator_api_response: [
          {
            location: location, // Replace this with dynamic location if needed
            viator_response: updatedActivities,
          },
        ],
      });
      // console.log("Activities updated successfully.");
    } catch (error) {
      console.error("Error updating activities:", error);
      throw error;
    }
  };

  // Function to handle drag end event
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(activities);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setActivities(items); // Update state with reordered activities
    handleUpdateActivities(items); // Update activities on the backend
  };

  // Function to handle deleting an activity
  const handleDeleteActivity = async (activityToDelete) => {
    const updatedActivities = activities.filter(
      (activity) => activity !== activityToDelete
    );

    try {
      await handleUpdateActivities(updatedActivities); // Update activities on the backend
      setActivities(updatedActivities); // Update state after successful backend update
    } catch (error) {
      console.error("Failed to delete activity on the backend:", error);
      // You can add error handling here, e.g., show a notification to the user
    }
  };

  const handleCloseDetail = () => {
    setView(null);
  };

  const [currentStep, setCurrentStep] = useState(0);
  const [title, setTitle] = useState("");
  const [attractionStreetAddress, setAttractionStreetAddress] = useState("");
  const [attractionCity, setAttractionCity] = useState("");

  const handleNextStep = async () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1); // Update the step immediately
      return;
    }

    const newActivity = {
      sortOrder: activities.length,
      title,
      attractionStreetAddress,
      attractionCity,
      webURL: "",
      pageUrlName: "",
      primaryDestinationUrlName: "",
      publishedDate: "",
      attractionLatitude: null,
      attractionLongitude: null,
      attractionState: "",
      destinationId: null,
      seoId: null,
      productCount: 0,
      thumbnailHiResURL: "",
      photoCount: 0,
      primaryDestinationId: null,
      rating: 0,
      primaryDestinationName: "",
      thumbnailURL: "",
    };

    const updatedActivities = [...activities, newActivity];

    // Update the frontend state immediately for better UX
    setActivities(updatedActivities);
    setTitle("");
    setAttractionStreetAddress("");
    setAttractionCity("");
    setCurrentStep(0);

    try {
      // Make the API call to save the new activity list to the backend
      await axiosInstance.put(`/bucket-list/${tripId}/update/activities`, {
        viator_api_response: [
          {
            location: location,
            viator_response: updatedActivities,
          },
        ],
      });

      toast({
        title: "New activity added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error adding new activity:", error);
      toast({
        title: "Error adding new activity",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
      w="100%"
      h="100vh"
      overflowY="hidden"
    >
      <HStack justifyContent="space-between" bg="white">
        <Box
          flex="1"
          display="flex"
          justifyContent="left"
          p={4}
          pt={2}
          pb={2}
          pl={4}
          maxH="8vh"
          borderBottom="1px"
          borderLeft="1px"
          borderColor="blackAlpha.200"
        >
          <Text
            fontSize="md"
            fontWeight="bold"
            mt={1}
            pb={2}
            color="blackAlpha.700"
          >
            <Translate text="Update Places" />
          </Text>
          <Spacer />
          <IconButton
            icon={<CloseIcon boxSize={2.5} />}
            onClick={handleCloseDetail}
            colorScheme="gray"
            size="sm"
            isRound={true}
            position="relative"
            zIndex="tooltip"
            bg="white"
            boxShadow="md"
          />
        </Box>
      </HStack>
      <Box h="89vh">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="activities">
            {(provided) => (
              <VStack
                spacing={4}
                h="100%"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
                bg="white"
                align="stretch"
                p={4}
                m={2}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {activities.map((activity, index) => (
                  <Draggable
                    key={`activity-${index}`}
                    draggableId={`activity-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <Box
                        position="relative"
                        bg="white"
                        borderRadius="lg"
                        boxShadow="md"
                        border="1px solid"
                        borderColor="gray.200"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        onMouseEnter={() => setHoveredItem(index)}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        {/* Numbering circle */}
                        <Box
                          position="absolute"
                          top="-10px"
                          left="-10px"
                          bg="black"
                          color="white"
                          borderRadius="full"
                          width="24px"
                          height="24px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          fontSize="12px"
                          fontWeight="bold"
                        >
                          {index + 1}
                        </Box>
                        <Box
                          {...provided.dragHandleProps}
                          bg="gray.100"
                          alignContent="center"
                          visibility={
                            hoveredItem === index ? "visible" : "hidden"
                          }
                          h="100%"
                        >
                          <Image
                            src={GridIcon}
                            alt="Drag Handle"
                            boxSize="4"
                            opacity={hoveredItem === index ? 1 : 0.5}
                          />
                        </Box>

                        <VStack ml={1} w="full" p={4} pl={0} h="100%">
                          <HStack align="center" spacing={4} w="100%">
                            <VStack
                              align="flex-start"
                              spacing={1}
                              flex="1"
                              h="100%"
                            >
                              <Text fontSize="18px" fontWeight="bold" mb={0}>
                                {activity.title}
                              </Text>
                              <Text fontSize="14px" color="gray.600" mb={0}>
                                {activity.primaryDestinationName}
                              </Text>
                              <HStack spacing={1}>
                                <FaClock color="gray.500" />
                                <Text
                                  fontSize="sm"
                                  color="gray.500"
                                  ml={1}
                                  mb={0}
                                >
                                  {activity.rating} <Translate text="Rating" />
                                </Text>
                                <Text fontSize="sm" color="gray.500" mb={0}>
                                  •
                                </Text>
                                <Text fontSize="sm" color="gray.500" mb={0}>
                                  {activity.attractionCity}
                                </Text>
                              </HStack>
                            </VStack>
                            {activity.thumbnailHiResURL && (
                            <Image
                              src={activity.thumbnailHiResURL}
                              alt={activity.primaryDestinationName}
                              boxSize="120px"
                              borderRadius="lg"
                              objectFit="cover"
                            />
                            )}
                          </HStack>
                          <HStack spacing={1} w="100%">
                            {activity.webURL && (
                            <Button
                              as="a"
                              href={activity.webURL}
                              target="_blank"
                              rel="noopener noreferrer"
                              size="sm"
                              bg="#286328"
                              _hover="none"
                              _active="none"
                              textColor="white"
                              // width="full"
                              mr={8}
                            >
                              <Translate text="Book" />
                            </Button>
                            )}
                            <Spacer />
                            <IconButton
                              icon={<FaTrash />}
                              aria-label="Delete activity"
                              borderRadius="full"
                              size="xs"
                              visibility={
                                hoveredItem === index ? "visible" : "hidden"
                              }
                              onClick={() => handleDeleteActivity(activity)}
                            />
                          </HStack>
                        </VStack>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <InputGroup>
                  {currentStep === 0 && (
                    <Input
                      placeholder="Enter Activity Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 1 && (
                    <Input
                      placeholder="Enter Street Address"
                      value={attractionStreetAddress}
                      onChange={(e) =>
                        setAttractionStreetAddress(e.target.value)
                      }
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 2 && (
                    <Input
                      placeholder="Enter City"
                      value={attractionCity}
                      onChange={(e) => setAttractionCity(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  <InputRightElement>
                    <IconButton
                      onClick={handleNextStep}
                      bg="black"
                      color="white"
                      _hover="none"
                      size="sm"
                      icon={
                        currentStep < 2 ? <ArrowForwardIcon /> : <AddIcon />
                      }
                      aria-label={
                        currentStep < 2 ? "Next Step" : "Add Activity"
                      }
                    />
                  </InputRightElement>
                </InputGroup>
              </VStack>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};

export default AllActivitiesList;
