import React, { useState, useRef } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  Heading,
  IconButton,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  HStack,
} from "@chakra-ui/react";
import { CloseIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import Activities from "./Activities"; // Ensure the correct import
import Translate from "../../components/LanguageTranslation/Translate";

const ItineraryDetails = ({ tripPlan, onClose, lastUpdated }) => {
  // console.log("tripPlan", tripPlan);
  const scrollContainerRef = useRef(null);
  const tabListRef = useRef(null);

  const tabListscrollLeft = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollBy({
        left: -300, // Adjust this value to control the scroll distance
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const tabListscrollRight = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollBy({
        left: 300, // Adjust this value to control the scroll distance
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const [selectedDayIndex, setSelectedDayIndex] = useState(0);
  const [scrollIndex, setScrollIndex] = useState(0);

  // Check if tripPlan exists and has an itinerary
  if (!tripPlan || !tripPlan.itinerary || tripPlan.itinerary.length === 0) {
    return null; // Render nothing if there's no itinerary
  }

  const itinerary = tripPlan.itinerary;

  // Access viatorResponse directly from tripPlan
  const viatorData = tripPlan.viatorResponse || [];

  // Flatten all viator_responses into a single array of activities
  const allActivities = viatorData.reduce((acc, current) => {
    return acc.concat(current.viator_response);
  }, []);

  // Get the first day's first activity image with a fallback
  const firstDayImage =
    itinerary[0]?.activity_detail_list?.[0]?.thumbnailHiResURL ||
    "https://via.placeholder.com/300x200.png?text=No+Image";

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300, // Adjust this value to control the scroll distance
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300, // Adjust this value to control the scroll distance
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  return (
    <Box
      right="0"
      top="0"
      h="100vh"
      w="100%"
      p="5"
      pt={2}
      bg="white"
      zIndex="modal"
      overflowY="auto"
      borderLeft="2px solid"
      borderColor="gray.200"
    >
      <IconButton
        icon={<CloseIcon boxSize={2.5} />}
        onClick={onClose}
        colorScheme="gray"
        size="sm"
        isRound={true}
        position="relative"
        zIndex="tooltip"
        bg="white"
        boxShadow="md"
      />
      <VStack spacing={6} align="stretch" pt="2">
        <Box position="relative" w="100%" borderRadius="xl" overflow="hidden">
          <Image
            src={firstDayImage}
            fallbackSrc="https://via.placeholder.com/300x200.png?text=No+Image"
            alt={itinerary[0]?.location || "No Description Available"}
            objectFit="cover"
            w="100%"
            h="300px"
          />
          <Box
            position="absolute"
            bottom="0"
            left="0"
            w="100%"
            color="white"
            p={4}
            textAlign="left"
          >
            <Text
              mb={0}
              fontSize="2xl"
              fontWeight="bold"
              width="90%"
              noOfLines={2}
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {tripPlan.trip_name}
            </Text>
            <Text fontSize="sm" mb={0}>
              <Translate text="Last updated:" />{" "}
              {lastUpdated ? lastUpdated.split(" ")[0] : "N/A"}
            </Text>
          </Box>
        </Box>
        <Tabs
          variant="unstyled"
          onChange={(index) => setSelectedDayIndex(index)}
          index={selectedDayIndex}
        >
          <Box position="relative" w="100%">
            <IconButton
              aria-label="Scroll Left"
              icon={<ChevronLeftIcon />}
              onClick={tabListscrollLeft}
              isRound={true}
              size="xs"
              position="absolute"
              top="40%"
              left="0px"
              transform="translateY(-50%)"
              backgroundColor="white"
              boxShadow="base"
              zIndex="overlay"
            />
            <TabList
              ref={tabListRef}
              overflowX="auto"
              position="relative"
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              {itinerary.map((day, index) => (
                <Tab
                  key={index}
                  _selected={{ bg: "white", border: "2px solid black" }}
                  _hover={{ bg: "none" }}
                  p={2}
                  pl={3}
                  pr={3}
                  border="2px solid"
                  borderColor="gray.200"
                  borderRadius="xl"
                  mr={3}
                  mb={4}
                  align="left"
                  minW="15%"
                  maxW="20%"
                  textAlign="center"
                >
                  <VStack alignItems="flex-start" justifyContent="flex-start">
                    <Heading
                      fontSize="md"
                      fontWeight="bold"
                      mb={0}
                      fontFamily="Arial"
                    >
                      <Translate text="Day" /> {index + 1}
                    </Heading>
                    <Text
                      fontSize="xs"
                      color="gray.500"
                      mb={0}
                      fontFamily="Arial"
                    >
                      {day?.location || "No Location Available"}
                    </Text>
                  </VStack>
                </Tab>
              ))}
            </TabList>
            {/* Right Scroll Button */}
            <IconButton
              aria-label="Scroll Right"
              icon={<ChevronRightIcon />}
              onClick={tabListscrollRight}
              isRound={true}
              size="xs"
              position="absolute"
              top="40%"
              right="0px"
              transform="translateY(-50%)"
              backgroundColor="white"
              boxShadow="base"
              zIndex="overlay"
            />
          </Box>
          <TabPanels>
            {itinerary.map((day, index) => (
              <TabPanel
                key={index}
                p={5}
                border="2px solid"
                borderColor="gray.200"
                borderRadius="xl"
              >
                <Heading fontSize="18px" fontFamily="Arial" mb={0}>
                  {day.current_day_name}
                </Heading>
                <VStack
                  mt={5}
                  spacing={2}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    fontFamily="Arial"
                    mb={0}
                  >
                    <Translate text="Morning:" />
                  </Text>
                  <Text fontSize="sm" fontFamily="Arial" mb={0}>
                    {day.plan.morning || "No plan available"}
                  </Text>
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    fontFamily="Arial"
                    mb={0}
                  ></Text>
                  <Text fontSize="sm" fontFamily="Arial" mb={0}>
                    {day.plan.afternoon || "No plan available"}
                  </Text>
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    fontFamily="Arial"
                    mb={0}
                  >
                    <Translate text="Evening:" />
                  </Text>
                  <Text fontSize="sm" fontFamily="Arial" mb={0}>
                    {day.plan.evening || "No plan available"}
                  </Text>
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    fontFamily="Arial"
                    mb={0}
                  >
                    <Translate text="Bedtime:" />
                  </Text>
                  <Text fontSize="sm" fontFamily="Arial" mb={0}>
                    {day.plan.bedtime || "No plan available"}
                  </Text>
                </VStack>
                <VStack mt={5}>
                  <Activities data={day.activity_detail_list || []} />
                </VStack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
        <Heading fontSize="18px" fontFamily="Arial" mt={4} mb={0}>
          <Translate text="Top" /> {viatorData[0]?.location || "No Location"}{" "}
          <Translate text="Travel Images" />
        </Heading>

        {/* HStack for Images outside the VStack */}
        <Box position="relative" w="100%" h="55vh" overflow="hidden">
          <HStack
            spacing={4}
            overflowX="auto"
            maxW="100%" // Limit the width of the HStack to the container width
            ref={scrollContainerRef} // Attach the ref to the HStack
            sx={{
              "&::-webkit-scrollbar": {
                display: "none", // Hide the scrollbar for a cleaner look
              },
              msOverflowStyle: "none", // Hide scrollbar for IE and Edge
              scrollbarWidth: "none", // Hide scrollbar for Firefox
            }}
            p={2}
          >
            {allActivities.map((item, idx) => (
              <Box
                key={idx}
                flexShrink={0}
                width="calc(100% / 2.5)" // Show 3 images at a time, adjust to your preference
                maxW="calc(100% / 2.5)"
                height="53vh"
              >
                <Image
                  src={
                    item.thumbnailHiResURL || "https://via.placeholder.com/150"
                  }
                  alt={item.title || "No Title"}
                  objectFit="cover"
                  width="100%" // Make sure each image takes full width of its container
                  height="100%" // Make sure each image takes full height of its container
                  borderRadius="xl"
                />
              </Box>
            ))}
          </HStack>

          {/* Scroll buttons */}
          <IconButton
            aria-label="Scroll Left"
            icon={<ChevronLeftIcon />}
            onClick={scrollLeft} // Trigger the scrollLeft function
            isRound={true}
            size="xs"
            position="absolute"
            top="50%"
            left="10px"
            transform="translateY(-50%)"
            backgroundColor="white"
            boxShadow="base"
            zIndex="overlay"
          />

          <IconButton
            aria-label="Scroll Right"
            icon={<ChevronRightIcon />}
            onClick={scrollRight} // Trigger the scrollRight function
            isRound={true}
            size="xs"
            position="absolute"
            top="50%"
            right="10px"
            transform="translateY(-50%)"
            backgroundColor="white"
            boxShadow="base"
            zIndex="overlay"
          />
        </Box>
      </VStack>
    </Box>
  );
};

export default ItineraryDetails;
