import React, { useState, useEffect } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import {
  Box,
  Text,
  Image,
  VStack,
  HStack,
  IconButton,
  Flex,
  Button,
  Spacer,
  useToast,
  Input,
  Textarea,
  CloseButton,
} from "@chakra-ui/react";
import { useParams, useHistory } from "react-router-dom";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import Translate from "../../../components/LanguageTranslation/Translate";

const PostDetail = () => {
  const history = useHistory();
  const { postId } = useParams(); // Get the post ID from the URL
  const [post, setPost] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedPost, setUpdatedPost] = useState({
    trip_name: "",
    trip_options: "",
    trip_title: "",
    trip_post_text: "",
    trip_images: [],
  });
  const [newImages, setNewImages] = useState([]);
  const [originalTripImages, setOriginalTripImages] = useState([]); // New state to store filenames
  const toast = useToast();

  const constructImageUrl = (filename) => {
    if (!filename) return null;

    // Replace backslashes with forward slashes and remove the leading "./"
    const cleanPath = filename.replace(/\\/g, "/").replace(/^\.\//, "");

    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";
    const url = `${baseUrl}/${cleanPath}`;
    return url;
  };

  const fetchPost = async (postId) => {
    try {
      const response = await axiosInstance.get(`/posts/${postId}`);
      if (response.data.success) {
        const postData = response.data.data;

        // Store original filenames before constructing URLs
        setOriginalTripImages(postData.trip_images);

        // Construct full image URLs for displaying in the UI
        const imageUrls = postData.trip_images.map((filename) =>
          constructImageUrl(filename)
        );

        setPost({ ...postData, trip_images: imageUrls });
        setUpdatedPost({
          trip_name: postData.trip_name,
          trip_options: postData.trip_options,
          trip_title: postData.trip_title,
          trip_post_text: postData.trip_post_text,
          trip_images: imageUrls, // Use URLs for displaying in the editor
        });
      } else {
        console.error("Failed to fetch post:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching post:", error);
    }
  };

  // useEffect to call the fetchPost function when postId changes
  useEffect(() => {
    fetchPost(postId);
  }, [postId]);

  useEffect(() => {
    // Check if the post text exceeds 3 lines and show the "Show More" link if it does
    const textElement = document.getElementById("post-text");
    if (textElement && textElement.scrollHeight > textElement.clientHeight) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }, [post]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < post.trip_images.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const toggleTextExpansion = () => {
    setIsTextExpanded(!isTextExpanded);
  };

  const handleDelete = async () => {
    try {
      const response = await axiosInstance.delete(`/posts/${postId}`);
      if (response.data.success) {
        toast({
          title: "Post deleted.",
          description: "The post has been deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.push("/travel_tracker"); // Redirect to the main page after deletion
      } else {
        toast({
          title: "Error",
          description: "Failed to delete the post.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while deleting the post.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
  
      // Append text fields
      formData.append("trip_name", updatedPost.trip_name);
      formData.append("trip_options", updatedPost.trip_options);
      formData.append("trip_title", updatedPost.trip_title);
      formData.append("trip_post_text", updatedPost.trip_post_text);
  
      // Identify deleted images by comparing the original images and the updated post images
      const deletedUrls = originalTripImages
        .filter(
          (filename) =>
            !updatedPost.trip_images.includes(constructImageUrl(filename))
        )
        .map((filename) =>
          filename.replace(`${process.env.REACT_APP_BASE_URL}/`, "")
        ); // Strip base URL
  
      // Prepare the list of existing image filenames (without the base URL)
      const tripImagesList = updatedPost.trip_images.map((url) =>
        url.replace(`${process.env.REACT_APP_BASE_URL || "http://localhost:5000"}/`, "")
      );
  
      // Send all existing URLs (without the base URL) as a single field
      formData.append("existing_urls", JSON.stringify(tripImagesList));
  
      // Add new image files to the formData
      newImages.forEach((image) => {
        formData.append("trip_images", image);
      });
  
      // Always send deleted_urls, even if it's an empty array
      formData.append("deleted_urls", JSON.stringify(deletedUrls));
  
      // Send the updated payload
      const response = await axiosInstance.put(`/posts/${postId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.data.success) {
        setPost(response.data.data);
        toast({
          title: "Post updated.",
          description: "The post has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsEditing(false);
        setNewImages([]); // Clear new images after update
        fetchPost(postId); // Refetch the updated post
      } else {
        toast({
          title: "Error",
          description: "Failed to update the post.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while updating the post.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  

  const handleImageRemove = (index) => {
    setUpdatedPost((prev) => ({
      ...prev,
      trip_images: prev.trip_images.filter((_, i) => i !== index),
    }));
  };

  const handleNewImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setNewImages((prev) => [...prev, ...files]);
  };

  if (!post) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box w="full" h="100vh">
      {/* Title, Date, and Post Actions */}
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        pb={1}
        h="8vh"
        borderBottom="1px"
        borderColor="blackAlpha.200"
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          height="100%"
          p={4}
          // pt={2}
          // pb={2}
          pl={0}
          boxSizing="border-box"
        >
          <Button
            leftIcon={<ArrowBackIcon />}
            onClick={() => history.goBack()}
            // mt={1}
            pr={0}
            bg="transparent"
            _hover={{ bg: "transparent" }}
          ></Button>

          <Text
            fontSize="md"
            fontWeight="bold"
            mb={0}
            // mt={2}
            color="blackAlpha.700"
          >
            {post.trip_name}
          </Text>
          <Spacer />
          <HStack spacing={4} mt={1}>
            <Button
              color="#286328"
              variant="outline"
              borderRadius="md"
              borderColor="#286328"
              textColor="black"
              size="sm"
              onClick={() => setIsEditing(!isEditing)}
            >
             
              <Translate text= {isEditing ? "Cancel" : "Edit"} />
            </Button>
            <Button bg="red" color="white" size="sm" onClick={handleDelete}>
              
              <Translate text="Delete" />
            </Button>
          </HStack>
        </Box>
      </Box>
      <Box h="90vh" overflow="auto">
        {/* Post Content: Title, Date, Text */}
        <Box
          w={{ base: "90%", md: "75%", lg: "50%" }}
          mx="auto"
          mt={6}
          p={6}
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="lg"
          bg="white"
        >
          <HStack spacing={4} mb={4}>
            <VStack align="start" spacing={0}>
              {isEditing ? (
                <Input
                  value={updatedPost.trip_title}
                  onChange={(e) =>
                    setUpdatedPost({
                      ...updatedPost,
                      trip_title: e.target.value,
                    })
                  }
                  mb={2}
                />
              ) : (
                <Text
                  fontWeight="bold"
                  mb={0}
                  fontSize={{ base: "lg", md: "xl" }}
                >
                  {post.trip_title}
                </Text>
              )}
              <Text fontSize="sm" color="gray.500" mb={0}>
                {new Date().toLocaleDateString()}
              </Text>

              {isEditing ? (
                <Input
                  value={updatedPost.trip_options}
                  onChange={(e) =>
                    setUpdatedPost({
                      ...updatedPost,
                      trip_options: e.target.value,
                    })
                  }
                  mb={2}
                />
              ) : (
                <Text fontSize="sm" color="gray.500" mb={0}>
                  {post.trip_options}
                </Text>
              )}
            </VStack>
          </HStack>

          {/* Post Text */}
          <Box mb={6}>
            {isEditing ? (
              <Textarea
                value={updatedPost.trip_post_text}
                onChange={(e) =>
                  setUpdatedPost({
                    ...updatedPost,
                    trip_post_text: e.target.value,
                  })
                }
                rows={5}
                mb={4}
              />
            ) : (
              <Text
                fontSize={{ base: "md", md: "md" }}
                id="post-text"
                noOfLines={isTextExpanded ? undefined : 3}
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: isTextExpanded ? "none" : "3",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                dangerouslySetInnerHTML={{ __html: post.trip_post_text }}
                mb={0}
              />
            )}

            {showMore && !isTextExpanded && !isEditing && (
              <Button
                size="sm"
                onClick={toggleTextExpansion}
                variant="link"
                colorScheme="blue"
              >
                
                <Translate text="Show More" />
              </Button>
            )}
            {isTextExpanded && !isEditing && (
              <Button
                size="sm"
                onClick={toggleTextExpansion}
                variant="link"
                colorScheme="blue"
              >
                
                <Translate text="Show Less" />
              </Button>
            )}
          </Box>
        </Box>

        {/* New Box for Images */}
        <Box
          w={{ base: "90%", md: "75%", lg: "50%" }}
          mx="auto"
          mt={2}
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="lg"
          bg="white"
        >
          {/* Post Images with Navigation */}
          {!isEditing && (
            <Flex
              alignItems="center"
              justifyContent="center"
              mb={4}
              w="100%"
              position="relative" // Ensure the Flex is the relative parent
              minH="300px" // You can set a minimum height to ensure the box holds its place
              bg="gray.200" // Add background color for better visibility when no images
              borderRadius="md"
            >
              {post.trip_images.length > 0 ? (
                <>
                  <IconButton
                    icon={<ArrowBackIcon />}
                    isRound={true}
                    onClick={prevImage}
                    disabled={currentImageIndex === 0}
                    size="lg"
                    position="absolute" // Set absolute to position over image
                    left="10px"
                    top="50%" // Center vertically
                    transform="translateY(-50%)" // Adjust to be centered vertically
                    bg="rgba(0, 0, 0, 0.6)"
                    color="white"
                    _hover="none"
                    boxShadow="base"
                    zIndex="overlay"
                  />
                  <Image
                    src={post.trip_images[currentImageIndex]}
                    alt={post.trip_title}
                    borderRadius="md"
                    h="full"
                    w="100%"
                  />
                  <IconButton
                    icon={<ArrowForwardIcon />}
                    isRound={true}
                    onClick={nextImage}
                    disabled={currentImageIndex === post.trip_images.length - 1}
                    size="lg"
                    position="absolute" // Set absolute to position over image
                    right="10px"
                    top="50%" // Center vertically
                    transform="translateY(-50%)" // Adjust to be centered vertically
                    bg="rgba(0, 0, 0, 0.6)"
                    color="white"
                    _hover="none"
                    boxShadow="base"
                    zIndex="overlay"
                  />
                </>
              ) : (
                <Text color="gray.500" fontSize="lg">
                  
                  <Translate text="No images available" />
                </Text>
              )}
            </Flex>
          )}

          {/* Existing and New Images in Editing Mode */}
          {isEditing && (
            <>
              <Box mb={6} p={4}>
                <Text fontWeight="bold" mb={2}>
                  
                  <Translate text="Existing Images:" />
                </Text>
                <Flex wrap="wrap" justifyContent="center">
                  {updatedPost.trip_images.map((image, index) => (
                    <Box key={index} position="relative" m={2}>
                      <Image
                        src={image}
                        alt={`Image ${index + 1}`}
                        borderRadius="md"
                        h="150px"
                        w="150px"
                        objectFit="cover"
                      />
                      <CloseButton
                        position="absolute"
                        top="0"
                        right="0"
                        onClick={() => handleImageRemove(index)}
                      />
                    </Box>
                  ))}
                </Flex>
              </Box>

              {/* Upload New Images */}
              <Box mb={6} p={4}>
                <Text fontWeight="bold" mb={2}>
                  
                  <Translate text="Upload New Images:" />
                </Text>
                <Input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleNewImageUpload}
                />
                <Flex wrap="wrap" justifyContent="center" mt={4}>
                  {newImages.map((image, index) => (
                    <Box key={index} position="relative" m={2}>
                      <Image
                        src={URL.createObjectURL(image)}
                        alt={`New Image ${index + 1}`}
                        borderRadius="md"
                        h="150px"
                        w="150px"
                        objectFit="cover"
                      />
                    </Box>
                  ))}
                </Flex>
              </Box>
            </>
          )}

          {isEditing && (
            <Button
              bg="#286328"
              color="white"
              onClick={handleUpdate}
              ml="4"
              mb="4"
            >
              
              <Translate text="Save Changes" />
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PostDetail;
