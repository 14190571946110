// DonationSuccess.js
import React, { useEffect } from "react";
import { Box, Heading, Text, VStack, useToast } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";
import banner from "../../assets/images/donation_banner.jpg";

const DonationSuccess = () => {
  const toast = useToast();

  useEffect(() => {
    const fetchSuccessMessage = async () => {
      try {
        const response = await axiosInstance.get("/donations/success");
        if (response.data.message) {
          toast({ title: response.data.message, status: "success" });
        }
      } catch (error) {
        console.error("Error fetching success message:", error);
        toast({
          title: "Error displaying success message",
          description: "Please contact support if you have any issues.",
          status: "error",
        });
      }
    };
    fetchSuccessMessage();
  }, [toast]);

  return (
    <Box
      h="100vh"
      w="100vw"
      bgImage={`url(${banner})`}
      bgSize="cover"
      bgPosition="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={6}
      color="white"
    >
      <Box
        bg="rgba(0, 0, 0, 0.7)"
        borderRadius="lg"
        p={10}
        maxW="500px"
        textAlign="center"
      >
        <VStack spacing={4}>
          <Heading size="xl" mb={4}>
            Thank you for your donation!
          </Heading>
          <Text fontSize="lg">
            Your support means a lot to us and helps make a positive impact on our planet. 😊
          </Text>
        </VStack>
      </Box>
    </Box>
  );
};

export default DonationSuccess;
