import React, { useState, useEffect } from "react";
import {
  Box,
  useColorMode,
  useToast,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom"; // Import useParams from react-router-dom
import axiosInstance from "../../../helpers/axiosInstance"; // Import axiosInstance
import Header from "./Header";
import TripsSections from "../TripsSections"; // Import the TripsSections component
import ProfileSection from "../ProfileSection"; // Import the ProfileSection component
import Posts from "../Posts/Posts"; // Import the new Posts component

const SearchedUser = () => {
  const { colorMode } = useColorMode();
  const { socialId: urlSocialId } = useParams(); // Fetch socialId from the URL
  const [socialId, setSocialId] = useState(urlSocialId || ""); // Set socialId from the URL
  const [profileData, setProfileData] = useState(null);
  const [posts, setPosts] = useState([]);
  const toast = useToast();

  // State for the selected tab, initialize with 0 (default tab) or from local storage
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    localStorage.getItem("selectedTab")
      ? parseInt(localStorage.getItem("selectedTab"), 10)
      : 0
  );

  const constructImageUrl = (filename) => {
    if (!filename) return null;
    
    // Replace backslashes with forward slashes and remove the leading "./"
    const cleanPath = filename.replace(/\\/g, '/').replace(/^\.\//, '');
    
    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';
    const url = `${baseUrl}/${cleanPath}`;
    console.log("Constructed URL:", url);
    return url;
  };

  const fetchPosts = async (socialId) => {
    try {
      const response = await axiosInstance.get("/posts/", {
        params: socialId ? { social_id: socialId } : {},
      });

      if (response.data.success) {
        const posts = response.data.data.map((post) => ({
          id: post._id,
          title: post.trip_title,
          description: post.trip_post_text,
          image: constructImageUrl(post.trip_images[0]),
          datePosted: new Date(),
        }));
        setPosts(posts);
      } else {
        console.error("Failed to fetch posts:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts(socialId);
  }, [socialId]);

  // Save the selected tab index to localStorage when it changes
  const handleTabChange = (index) => {
    setSelectedTabIndex(index);
    localStorage.setItem("selectedTab", index); // Persist the selected tab index
  };

  return (
    <Box
      display="flex"
      bg={colorMode === "dark" ? "gray.800" : "white"}
      w="100%"
      h="100vh"
    >
      <Box flex="5" w="50%" display="flex" flexDirection="column">
        <Header />
        <Box overflowY="scroll" h="92vh" p={4}>
          <ProfileSection
            profileData={profileData}
            setProfileData={setProfileData}
            setSocialId={setSocialId}
            socialId={socialId}
          />{" "}
          {/* Pass socialId to ProfileSection */}
          {/* Modern Tabs with Icons and Badges */}
          <Tabs
            variant="unstyled"
            isFitted
            w="100%"
            index={selectedTabIndex} // Set the index to the selected tab state
            onChange={handleTabChange} // Handle tab change
          >
            <Flex justify="center">
              <TabList>
                <Tab
                  _selected={{
                    color: "blue.500",
                    borderBottom: "2px solid",
                    borderColor: "blue.500",
                  }}
                  px={4}
                  py={2}
                >
                  Posts
                </Tab>
                <Tab
                  _selected={{
                    color: "green.500",
                    borderBottom: "2px solid",
                    borderColor: "green.500",
                  }}
                  px={4}
                  py={2}
                >
                  Trips
                </Tab>
              </TabList>
            </Flex>

            <TabPanels>
              <TabPanel>
                {/* First Tab - Posts */}
                <Posts
                  posts={posts}
                  setPosts={setPosts}
                  colorMode={colorMode}
                />
              </TabPanel>
              <TabPanel>
                {/* Second Tab - Trips */}
                <TripsSections colorMode={colorMode} socialId={socialId}/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchedUser;
