import React from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import darkLogo from "../assets/images/Newlogo-black.png";
import BackToTopButton from "../components/common/BackToTopButton";
import Footer from "./Footer";

const Disclaimer = () => {
  return (
    <Box>
      <Navbar bgColor="white" linkColor="black" logoSrc={darkLogo} />
      <Box
        maxW={{ base: "100vw", sm: "85vw", md: "85vw", lg: "60vw" }}
        mx="auto"
        px={4}
        py={8}
        pt={8}
      >
        <Heading as="h1" size="xl" mb={4}>
          Disclaimer
        </Heading>
        <VStack align="start" spacing={4}>
          <Text>
            This work is governed by the licensing and distribution agreement
            with Lancewood Holding B.V.
          </Text>
          <Text>
            Nomad Tours™ is a trademark for which a formal registration
            application has been filed.
          </Text>
          <Text>
            © 2024 Nomad Tours. All Rights Reserved. Unauthorized distribution,
            duplication, or reproduction of this material is strictly
            prohibited.
          </Text>
        </VStack>
      </Box>
      <Footer/>
      <BackToTopButton />
    </Box>
  );
};

export default Disclaimer;
