import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  Input,
  useToast,
  IconButton,
  HStack,
  Flex,
  CloseButton,
} from "@chakra-ui/react";
import { AttachmentIcon } from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axiosInstance from "../../../helpers/axiosInstance"; // Ensure axios instance is imported
import TripDropdown from "../../common/TripDropdown";

const AddPostForm = ({ onAddTrip, socialId, fetchPosts }) => {
  const [selectedTrip, setSelectedTrip] = useState("");
  const [tripName, setTripName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tripData, setTripData] = useState(null);
  const toast = useToast();

  useEffect(() => {
    if (selectedTrip) {
      fetchTripById(selectedTrip);
    }
  }, [selectedTrip]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("selectedTrip", tripName);
    console.log("title", title);
    console.log("description", description);
    console.log("images.length", images.length);
    console.log("selectedOptions", selectedOptions);

    // Updated condition to include selectedOptions
    if (
      !tripName ||
      !title ||
      !description ||
      !images.length ||
      !selectedOptions.length
    ) {
      toast({
        title: "Error",
        description: "Please fill in all fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("trip_name", tripName);
    formData.append(
      "trip_options",
      selectedOptions.map((opt) => opt.label).join(", ")
    );
    formData.append("trip_title", title);
    formData.append("trip_post_text", description);

    images.forEach((image) => {
      formData.append("trip_images", image);
    });

    try {
      const response = await axiosInstance.post("/posts/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        onAddTrip(response.data.data); // You can also update your state with the response data if needed

        // Reset the form fields
        setSelectedTrip("");
        setTitle("");
        setDescription("");
        setImages([]);
        setSelectedOptions([]);

        toast({
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchPosts(socialId);
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to create post.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchTripById = async (tripId) => {
    try {
      const response = await axiosInstance.get(`/bucket-list/${tripId}`);
      setTripData(response.data.data);
    } catch (error) {
      console.error("Error fetching trip by ID:", error);
    }
  };

  const animatedComponents = makeAnimated();

  // const selectedTripData = useMemo(
  //   () => data.find((trip) => trip.title === selectedTrip),
  //   [selectedTrip, data]
  // );

  // const combinedOptions = useMemo(() => {
  //   if (!tripData) return [];
  //   const activities =
  //     tripData.viator_api_response?.flatMap(
  //       (response) =>
  //         response.viator_response?.map((activity) => ({
  //           value: `activity-${activity.title}`,
  //           label: `Activity: ${activity.title}`,
  //         })) || []
  //     ) || [];
  //   const hotels =
  //     tripData.home_to_go_api_response?.flatMap(
  //       (response) =>
  //         response.home_to_go_response?.map((hotel) => ({
  //           value: `hotel-${hotel.title}`,
  //           label: `Hotel: ${hotel.title}`,
  //         })) || []
  //     ) || [];
  //   const flights =
  //     tripData.flight_info?.flatMap(
  //       (flightGroup) =>
  //         flightGroup?.map((flight) => ({
  //           value: `flight-${flight.flight_number}`,
  //           label: `Flight: ${flight.airline} (${flight.origin_airport} - ${flight.destination_airport})`,
  //         })) || []
  //     ) || [];
  //   return [...activities, ...hotels, ...flights];
  // }, [tripData]);

  const combinedOptions = useMemo(() => {
    if (!tripData) return [];
    
    const activities =
      tripData.viator_api_response?.flatMap(
        (response) =>
          response.viator_response?.map((activity) => ({
            value: `activity-${activity.title}`,
            label: activity.title, // Removed "Activity: " prefix
          })) || []
      ) || [];
    
    const hotels =
      tripData.home_to_go_api_response?.flatMap(
        (response) =>
          response.home_to_go_response?.map((hotel) => ({
            value: `hotel-${hotel.title}`,
            label: hotel.title, // Removed "Hotel: " prefix
          })) || []
      ) || [];
    
    const flights =
      tripData.flight_info?.flatMap(
        (flightGroup) =>
          flightGroup?.map((flight) => ({
            value: `flight-${flight.flight_number}`,
            label: `${flight.airline} (${flight.origin_airport} - ${flight.destination_airport})`, // Removed "Flight: " prefix
          })) || []
      ) || [];
    
    return [...activities, ...hotels, ...flights];
  }, [tripData]);
  
  return (
    <Box p={0}>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="center" justifyContent="left" width="100%">
          <TripDropdown
            selectedTrip={selectedTrip} // Pass the selected trip state
            setSelectedTrip={setSelectedTrip} // Pass the setter for selected trip
            setTripName={setTripName} 
            onTripSelect={setSelectedTrip} // Handle trip selection
          />
          <Select
            isMulti
            placeholder="Select options"
            width="100%"
            value={selectedOptions}
            onChange={setSelectedOptions}
            options={combinedOptions}
            components={animatedComponents}
            isDisabled={!selectedTrip}
            styles={{
              container: (provided) => ({ ...provided, width: "100%" }),
              control: (provided) => ({ ...provided, borderRadius: "xl" }),
            }}
          />
          <Input
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            width="100%"
            size="sm"
            borderRadius="xl"
          />
          <ReactQuill
            value={description}
            onChange={setDescription}
            placeholder="Description"
            style={{
              height: "40vh",
              width: "100%",
              marginBottom: "40px",
            }}
          />
          <HStack
            width="100%"
            justifyContent="flex-start"
            alignItems="center"
            mt={4}
          >
            <label htmlFor="image-upload">
              <IconButton
                icon={<AttachmentIcon />}
                as="span"
                cursor="pointer"
                bg="#1A202C"
                color="white"
              />
            </label>
            <Input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
              display="none"
              id="image-upload"
            />
          </HStack>
          <HStack
            width="100%"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
          >
            {images.map((image, index) => (
              <Box position="relative" boxSize="50px" mb={4} key={index}>
                <CloseButton
                  position="relative"
                  top="0"
                  right="0"
                  size="sm"
                  colorScheme="black"
                  _hover="none"
                  onClick={() => handleRemoveImage(index)}
                />
                <img
                  src={URL.createObjectURL(image)}
                  alt="Preview"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Box>
            ))}
          </HStack>
          <Flex w="100%">
            <Button
              justifyContent="left"
              bg="#286328"
              color="white"
              _hover="none"
              type="submit"
            >
              Post
            </Button>
          </Flex>
        </VStack>
      </form>
    </Box>
  );
};

export default AddPostForm;
