import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Textarea,
  Button,
  IconButton,
  useToast,
  Input,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { ReactComponent as CoverIcon } from "../../assets/icons/cover_photo_icon.svg";
import { useLocation } from "react-router-dom";
import ProfilePictureUpload from "./ProfilePictureUpload"; // Import your existing ProfilePictureUpload component
import bg from "../../assets/images/bg.png";
import axiosInstance from "../../helpers/axiosInstance";
import { followUser, unfollowUser } from "./followUser"; // Import the follow logic
import CoverProfile from "./CoverProfile";
import FollowersModal from "./FollowersModal";
import FollowingModal from "./FollowingModal";
import Translate from "../../components/LanguageTranslation/Translate";

const ProfileSection = ({
  profileData,
  setProfileData,
  setSocialId,
  socialId,
  profilePrivateStatus,
  setProfilePrivateStatus,
}) => {
  const [bio, setBio] = useState("");
  const [publicName, setPublicName] = useState(""); // State for public name
  const [isEditingBio, setIsEditingBio] = useState(false);
  const [isEditingPublicName, setIsEditingPublicName] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const toast = useToast();
  const location = useLocation();
  const [showCoverUpload, setShowCoverUpload] = useState(false);
  const [isFollowersModalOpen, setIsFollowersModalOpen] = useState(false);
  const [isFollowingModalOpen, setIsFollowingModalOpen] = useState(false);

  const handleOpenFollowersModal = () => {
    setIsFollowersModalOpen(true);
  };

  const handleCloseFollowersModal = () => {
    setIsFollowersModalOpen(false);
  };

  const handleOpenFollowingModal = () => {
    setIsFollowingModalOpen(true);
  };

  const handleCloseFollowingModal = () => {
    setIsFollowingModalOpen(false);
  };

  // Get the following array from the URL state
  const followingArray = location.state?.following || []; // Expecting an array of usernames from the location state
  // console.log("followingArray",followingArray);
  const isCurrentUser = !socialId; // Determine if the profile belongs to the current user

  // Function to construct the image URL
  const constructImageUrl = (filename) => {
    if (!filename) return null;

    // Replace backslashes with forward slashes and remove the leading "./"
    const cleanPath = filename.replace(/\\/g, "/").replace(/^\.\//, "");

    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";
    const url = `${baseUrl}/${cleanPath}`;
    console.log("Constructed URL:", url);
    return url;
  };

  // Function to fetch user profile based on the socialId (if passed) or current user
  const fetchUserProfile = () => {
    const url = socialId
      ? `/social_media/profile/${socialId}` // Fetch specific user's profile by socialId
      : "/social_media/profile"; // Fetch current user's profile

    axiosInstance
      .get(url)
      .then((response) => {
        if (response.data.success) {
          const profile = response.data.data;
          console.log("profile data", response.data.data);
          const isUserFollowed = followingArray.includes(profile.username); // Compare by username
          setIsFollowing(isUserFollowed);

          profile.image_url = constructImageUrl(profile.image_url); // Construct the full URL
          setProfileData(profile);
          setBio(profile.bio);
          setPublicName(profile.public_name);
          setProfilePrivateStatus(profile.is_private);
          // Compare the profile's username with the following array

          // If this is the current user's profile, set the socialId for other operations
          if (!socialId) {
            setSocialId(profile._id);
          }
        } else {
          console.error("Error fetching profile:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch user profile", error);
      });
  };

  // Fetch the profile data when the component mounts or when the socialId changes
  useEffect(() => {
    fetchUserProfile();
  }, [socialId]);

  // Function to handle follow
  // Function to handle follow
  const handleFollow = async () => {
    const success = await followUser(socialId);
    if (success) {
      toast({
        title: "Followed successfully.",
        description: `You have followed ${publicName}.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Update follower count and follow state
      setProfileData((prevData) => ({
        ...prevData,
        follower_count: prevData.follower_count + 1,
      }));
      setIsFollowing(true);
    } else {
      toast({
        title: "Error",
        description: "Failed to follow the user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Function to handle unfollow
  const handleUnfollow = async () => {
    const success = await unfollowUser(socialId);
    if (success) {
      toast({
        title: "Unfollowed successfully.",
        description: `You have unfollowed ${publicName}.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Update follower count and follow state
      setProfileData((prevData) => ({
        ...prevData,
        follower_count: prevData.follower_count - 1,
      }));
      setIsFollowing(false);
    } else {
      toast({
        title: "Error",
        description: "Failed to unfollow the user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Function to upload profile picture
  const handleProfilePicSave = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const uploadResponse = await axiosInstance.post(
        "/social_media/upload-profile-picture",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (uploadResponse.data.success) {
        toast({
          title: "Profile picture updated.",
          description: uploadResponse.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchUserProfile();
      } else {
        console.error(
          "Error uploading profile picture:",
          uploadResponse.data.message
        );
      }
    } catch (error) {
      console.error("Failed to upload profile picture:", error);
    }
  };

  // Function to handle saving the bio
  const handleSaveBio = async () => {
    try {
      const response = await axiosInstance.post(
        "/social_media/update-profile-bio",
        {
          bio,
        }
      );

      if (response.data.success) {
        setProfileData(response.data.data);
        setBio(response.data.data.bio);
        setIsEditingBio(false);

        toast({
          title: "Bio updated.",
          description: "Your bio has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update bio.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Function to handle saving the public name
  const handleSavePublicName = async () => {
    try {
      const response = await axiosInstance.post(
        "/social_media/update-public-name",
        {
          public_name: publicName,
        }
      );

      if (response.data.success) {
        setProfileData(response.data.data);
        setPublicName(response.data.data.public_name);
        setIsEditingPublicName(false);

        toast({
          title: "Public name updated.",
          description: "Your public name has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update public name.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!profileData) {
    return <Text>Loading...</Text>;
  }

  // Add this function near the top of your component
  const getBackgroundImageUrl = () => {
    if (profileData.cover_photo_url) {
      const constructedUrl = constructImageUrl(profileData.cover_photo_url);
      console.log("Constructed cover photo URL:", constructedUrl);
      return constructedUrl;
    }
    console.log("Using default background image");
    return bg;
  };

  return (
    <Box position="relative" w="full" mb={4}>
      <Box
        position="absolute"
        top="0"
        left="0"
        w="full"
        h="full"
        backgroundImage={`url(${getBackgroundImageUrl()})`}
        backgroundPosition="center"
        backgroundSize="cover"
        // filter="blur(8px)"
        zIndex="0"
        borderRadius="md"
      />
      {isCurrentUser && (
        <IconButton
          icon={<CoverIcon />}
          position="absolute"
          bg="white"
          _hover="none"
          _active="none"
          top="2"
          right="2"
          zIndex="3"
          onClick={() => setShowCoverUpload(true)}
        />
      )}
      <Box
        position="absolute"
        top="0"
        left="0"
        w="full"
        h="full"
        bg="rgba(0, 0, 0, 0.5)"
        zIndex="1"
        borderRadius="md"
      />
      <VStack
        spacing={4}
        position="relative"
        zIndex="2"
        w="full"
        h="full"
        p={4}
      >
        {/* Always render the profile image but disable upload for other users */}
        <ProfilePictureUpload
          initialSrc={profileData.image_url}
          onSave={handleProfilePicSave}
          isDisabled={!isCurrentUser} // Disable the upload button for other users
        />

        <Box w="100%" textAlign="center">
          {isEditingPublicName ? (
            <>
              <Input
                value={publicName}
                onChange={(e) => setPublicName(e.target.value)}
                mb={2}
                textColor="white"
                placeholder="Enter your name here"
              />
              <Button
                size="sm"
                onClick={handleSavePublicName}
                textColor="white"
                bg="black"
                _hover="none"
              >
                <Translate text="Save" />
              </Button>
            </>
          ) : (
            <>
              <HStack justifyContent="center">
                <Text fontWeight="bold" textColor="white" mb={0}>
                  {publicName || <Translate text="Enter your name here" />}
                </Text>
                {/* Conditionally render EditIcon button for the current user */}
                {isCurrentUser && (
                  <IconButton
                    icon={<EditIcon />}
                    size="sm"
                    onClick={() => setIsEditingPublicName(true)}
                    textColor="white"
                    bg="#1A202C"
                    _hover="none"
                  />
                )}
              </HStack>
            </>
          )}
        </Box>

        <Box w="100%" textAlign="center">
          {isEditingBio ? (
            <>
              <Textarea
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                mb={2}
                textColor="white"
                placeholder="Enter your bio here"
              />
              <Button
                size="sm"
                onClick={handleSaveBio}
                textColor="white"
                bg="black"
                _hover="none"
              >
                <Translate text="Save" />
              </Button>
            </>
          ) : (
            <>
              <HStack justifyContent="center">
                <Text textColor="white" mb={0}>
                  {bio || <Translate text="Enter your bio here" />}
                </Text>
                {/* Conditionally render EditIcon button for bio */}
                {isCurrentUser && (
                  <IconButton
                    icon={<EditIcon />}
                    size="sm"
                    onClick={() => setIsEditingBio(true)}
                    textColor="white"
                    bg="#1A202C"
                    _hover="none"
                  />
                )}
              </HStack>
            </>
          )}
        </Box>

        {/* Additional Profile Info */}
        <HStack>
          <Box
            borderWidth="1px"
            borderRadius="md"
            p={2}
            borderColor="gray.400"
            textAlign="center"
            minWidth="100px"
            onClick={handleOpenFollowersModal}
            cursor="pointer"
          >
            <HStack>
              <Text fontWeight="bold" textColor="white" mb={0}>
                {profileData.follower_count}
              </Text>
              <Text fontSize="sm" textColor="white" mb={0}>
                <Translate text="Followers" />
              </Text>
              <FollowersModal
                isOpen={isFollowersModalOpen}
                onClose={handleCloseFollowersModal}
              />
            </HStack>
          </Box>
          <Box
            borderWidth="1px"
            borderRadius="md"
            p={2}
            borderColor="gray.400"
            textAlign="center"
            minWidth="100px"
            onClick={handleOpenFollowingModal}
            cursor="pointer"
          >
            <HStack>
              <Text fontWeight="bold" textColor="white" mb={0}>
                {profileData.following_count}
              </Text>
              <Text fontSize="sm" textColor="white" mb={0}>
                <Translate text="Following" />
              </Text>
            </HStack>
            <FollowingModal
              isOpen={isFollowingModalOpen}
              onClose={handleCloseFollowingModal}
            />
          </Box>
        </HStack>

        {/* Conditionally render the Follow button for other users */}
        {!isCurrentUser && (
          <HStack>
            {isFollowing ? (
              <Button
                bg="red.500"
                _hover="none"
                textColor="white"
                borderRadius="xl"
                onClick={handleUnfollow}
              >
                <Translate text="Unfollow" />
              </Button>
            ) : (
              <Button
                bg="#286328"
                _hover="none"
                textColor="white"
                borderRadius="xl"
                onClick={handleFollow}
              >
                <Translate text="Follow" />
              </Button>
            )}
          </HStack>
        )}
      </VStack>

      {showCoverUpload && (
        <CoverProfile
          isOpen={showCoverUpload}
          onClose={() => setShowCoverUpload(false)}
          onUploadSuccess={fetchUserProfile}
        />
      )}
    </Box>
  );
};

export default ProfileSection;
