import React from "react";
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Flex,
  Text,
  useColorMode,
  HStack,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiLogOut } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useAuth, logout } from "../auth";
import { useGoogleAuth } from "./common/google/GoogleAuthContext";
import { useUser } from "./userRole/UserContext";
import { useOTP } from "./AdminPanelAccess/OTPContext";
import Translate from "./LanguageTranslation/Translate";

const UserProfileMenu = () => {
  const history = useHistory();
  const [logged] = useAuth();
  const { resetOTP } = useOTP();
  const { authState } = useGoogleAuth();
  const { user, loading } = useUser();

  const isUserLoggedIn = logged || authState.isLoggedIn;

  const username = localStorage.getItem("username") || "";
  const email = localStorage.getItem("email") || "unknown@example.com";

  const logoutAndClear = () => {
    logout();
    const keysToRemove = [
      "username",
      "email",
      "perPage",
      "perPageFilter",
      "nPXg",
      "chat_messages",
      "conversation_id",
      "OTP_TOKEN,",
      "profilepic",
      "ongoingTrip",
      "selectedTab",
      "selectedTripPackingList",
      "selectedTripBudgetPlanner",
    ];
    keysToRemove.forEach((key) => localStorage.removeItem(key));
    resetOTP();
    history.push("/login");
  };

  return (
    <Flex align="center">
      {/* <Avatar
        name={username}
        src="https://bit.ly/broken-link"
        cursor="pointer"
        size="sm"
        mr={0}
      /> */}
      <Menu _hover="none" _focus="none">
        <MenuButton
          as={Button}
          bg="transparent"
          _hover="none"
          _active="none"
          _focus="none"
          // rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          size={{ base: "xs", md: "xs", lg: "sm" }}
          fontSize={{ base: "xs", md: "sm", lg: "sm" }}
          p={2}
        >
          <HStack>
            <Avatar
              name={username}
              src="https://bit.ly/broken-link"
              bg="#286328"
              color="white"
              cursor="pointer"
              _hover="none"
              size="sm"
              mr={0}
            />
            {/* <Text
              mb={0}
              bg="transparent"
              _hover="white"
              _focus="white"
              color="white"
            >
              {username}
            </Text> */}
          </HStack>
        </MenuButton>
        <MenuList borderRadius="lg" boxShadow="lg" p={2}>
          <Box textAlign="center" p={4} pb={0}>
            <Flex>
              <Avatar
                name={username}
                src="https://bit.ly/broken-link"
                size="lg"
                bg="#286328"
                color="white"
                mb={2}
              />
              <Box ml={4} align="left" pt={2} pb={2}>
                <Text fontWeight="bold" mb={0}>
                  {username}
                </Text>
                <Text fontSize="xs" color="gray.500">
                  {email}
                </Text>
              </Box>
            </Flex>
          </Box>
          <MenuDivider />
          <MenuItem
            onClick={logoutAndClear}
            icon={<FiLogOut />}
            borderRadius="md"
            _hover={{ bg: "gray.100" }}
            _focus={{ bg: "gray.100" }}
          >
            <Translate text="Logout" />
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default UserProfileMenu;
