import React from "react";
import {
  Box,
  Image,
  Text,
  Heading,
  Switch,
  VStack,
  IconButton,
  Spacer,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { FaHeart, FaTrash } from "react-icons/fa"; // Import FaTrash
import Translate from "../../components/LanguageTranslation/Translate";

const ItineraryCard = ({
  itinerary,
  tripId, // Receive the trip ID
  onToggleOngoingTrip,
  onToggleCompletion,
  isOngoingCard,
  isOnGoing, // Receive the is_on_going flag
  onDeleteItinerary, // Add onDeleteItinerary prop
}) => {
  const history = useHistory();

  const title = itinerary?.trip_name || "Untitled Trip";
  const mainImage =
    itinerary?.itinerary?.[0]?.activity_detail_list?.[0]?.thumbnailHiResURL ||
    "default-image-url";

  const handleCardClick = () => {
    history.push(`/itinerary_details/${tripId}`); // Navigate to the specific itinerary page using trip ID
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation(); // Prevent card click
    onDeleteItinerary(tripId); // Call the delete function with tripId
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      cursor="pointer" // Make every card clickable
      onClick={handleCardClick} // Handle click to navigate to the itinerary page
    >
      <Box position="relative">
        <Image
          src={mainImage}
          alt={title}
          width="100%"
          height="30vh"
          objectFit="cover"
        />
        <IconButton
          aria-label="Add to favorites"
          icon={<FaHeart />}
          size="md"
          bg="none"
          _hover={{ bg: "none" }}
          position="absolute"
          top="3px"
          right="3px"
          borderRadius="full"
        />
      </Box>
      <Box p="6">
        <Heading size="sm" mb="2">
          {title}
        </Heading>
        <VStack align="start" spacing={2} w="100%">
          {!isOngoingCard && (
            <Box display="flex" onClick={(e) => e.stopPropagation()} w="100%">
              <Text mb={0} mr={2} fontSize="sm">
                <Translate text="Ongoing Trip" />
              </Text>
              <Switch
                mt={1}
                size="sm"
                isChecked={isOnGoing} // Use the isOnGoing prop to determine if the switch is on
                onChange={onToggleOngoingTrip}
                colorScheme="customGreen"
              />
              <Spacer />
              <IconButton
                aria-label="Delete itinerary"
                icon={<FaTrash />}
                size="sm"
                ml={0}
                bg="none"
                _hover={{ bg: "none" }}
                onClick={handleDeleteClick} // Handle delete click
              />
            </Box>
          )}
          {isOngoingCard && (
            <Box display="flex" onClick={(e) => e.stopPropagation()}>
              <Text mb={0} mr={2} fontSize="sm">
                <Translate text="Trip Completed" />
              </Text>
              <Switch
                mt={1}
                size="sm"
                isChecked={itinerary.isCompleted}
                onChange={(e) => onToggleCompletion(e, tripId)} // Make sure tripId is passed
              />
            </Box>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default ItineraryCard;
