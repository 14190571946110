import React, { useState } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  HStack,
  IconButton,
  Spacer,
  useColorMode,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  useToast,
  Button,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { CloseIcon, AddIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axiosInstance from "../../helpers/axiosInstance"; // Import axios instance
import GridIcon from "../../assets/icons/dot_grid_icon.svg"; // Import the grid icon SVG
import Translate from "../../components/LanguageTranslation/Translate";

const ActivitiesList = ({
  places,
  setPlaces,
  onActivityClick,
  tripId, // Pass tripId for API requests
  selectedDayIndex, // Index of the current day
  itinerary, // Full itinerary object (trip_plan)
  setItinerary, // Setter for the full itinerary (trip_plan)
  setView,
}) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [hoveredItem, setHoveredItem] = useState(null);

  // Function to handle drag end event
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(places); // Array of places to reorder
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPlaces(items); // Update state with reordered activities

    // Ensure we access the correct part of the itinerary (trip_plan.itinerary)
    const updatedItinerary = { ...itinerary };
    if (
      updatedItinerary.itinerary &&
      Array.isArray(updatedItinerary.itinerary)
    ) {
      // Update the activity_detail_list for the selected day
      updatedItinerary.itinerary[selectedDayIndex].activity_detail_list = items;
      setItinerary(updatedItinerary); // Update itinerary state

      // Make PUT request to update the activities order in the backend
      try {
        const payload = {
          trip_plan: updatedItinerary, // Send updated trip_plan
        };
        const response = await axiosInstance.put(
          `/bucket-list/${tripId}/update/trip-plan`,
          payload
        );
        if (response.data.success) {
        }
      } catch (error) {
        console.error("Error updating activity order:", error);
        toast({
          title: "Error updating activity order",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      console.error("Itinerary data is not in the expected format.");
    }
  };

  const handleActivityDelete = async (indexToDelete) => {
    if (!itinerary || !Array.isArray(itinerary.itinerary)) {
      console.error("Itinerary is not an array or is undefined");
      return;
    }

    // Remove the place at the specific index (not yet updating state)
    const updatedPlaces = places.filter((_, index) => index !== indexToDelete);

    // Update the activity_detail_list for the selected day (not yet updating state)
    const updatedItinerary = { ...itinerary };
    updatedItinerary.itinerary[selectedDayIndex].activity_detail_list =
      updatedPlaces;

    // Make PUT request to update the itinerary in the backend after deletion
    try {
      const payload = {
        trip_plan: updatedItinerary,
      };
      const response = await axiosInstance.put(
        `/bucket-list/${tripId}/update/trip-plan`,
        payload
      );
      if (response.data.success) {
        setItinerary(updatedItinerary); // Update itinerary state only on success
        setPlaces(updatedPlaces); // Update state with remaining activities
      }
    } catch (error) {
      console.error("Error deleting activity:", error);
      toast({
        title: "Error deleting activity",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCloseDetail = () => {
    setView(null);
  };

  const [currentStep, setCurrentStep] = useState(0);
  const [title, setTitle] = useState("");
  const [attractionStreetAddress, setAttractionStreetAddress] = useState("");
  const [attractionCity, setAttractionCity] = useState("");

  const handleNextStep = async () => {
    if (currentStep < 2) {
      // Update steps based on number of input fields
      setCurrentStep(currentStep + 1);
    } else {
      const newActivity = {
        sortOrder: 0,
        title,
        attractionStreetAddress,
        attractionCity,
        webURL: "",
        pageUrlName: "",
        primaryDestinationUrlName: "",
        publishedDate: "",
        attractionLatitude: null,
        attractionLongitude: null,
        attractionState: "",
        destinationId: null,
        seoId: null,
        productCount: 0,
        thumbnailHiResURL: "",
        photoCount: 0,
        primaryDestinationId: null,
        rating: 0,
        primaryDestinationName: "",
        thumbnailURL: "",
      };

      // Proceed to add the activity and update itinerary as in your original code
      const updatedItinerary = { ...itinerary };
      if (
        updatedItinerary.itinerary &&
        Array.isArray(updatedItinerary.itinerary)
      ) {
        updatedItinerary.itinerary[selectedDayIndex].activity_detail_list.push(
          newActivity
        );
        setItinerary(updatedItinerary);

        try {
          const payload = { trip_plan: updatedItinerary };
          const response = await axiosInstance.put(
            `/bucket-list/${tripId}/update/trip-plan`,
            payload
          );
          if (response.data.success) {
            toast({
              title: "New activity added successfully",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            setTitle("");
            setAttractionStreetAddress("");
            setAttractionCity("");
            setCurrentStep(0);
          }
        } catch (error) {
          console.error("Error adding new activity:", error);
          toast({
            title: "Error adding new activity",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    }
  };

  return (
    <Box
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
      w="100%"
      h="100vh"
      overflowY="hidden"
    >
      <HStack justifyContent="space-between" bg="white">
        <Box
          flex="1"
          display="flex"
          justifyContent="left"
          p={4}
          pt={2}
          pb={2}
          pl={4}
          maxH="8vh"
          borderBottom="1px"
          borderLeft="1px"
          borderColor="blackAlpha.200"
        >
          <Text
            fontSize="md"
            fontWeight="bold"
            mt={1}
            pb={2}
            color="blackAlpha.700"
          >
            <Translate text="Update Places" />
          </Text>
          <Spacer />
          <IconButton
            icon={<CloseIcon boxSize={2.5} />}
            onClick={handleCloseDetail}
            colorScheme="gray"
            size="sm"
            isRound={true}
            position="relative"
            zIndex="tooltip"
            bg="white"
            boxShadow="md"
          />
        </Box>
      </HStack>
      <Box h="89vh">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="places">
            {(provided) => (
              <VStack
                spacing={4}
                h="100%"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
                bg="white"
                align="stretch"
                p={4}
                m={2}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {places.map((place, index) => (
                  <Draggable
                    key={place.id || index} // Using index as a fallback if place.id is undefined
                    draggableId={
                      place.id ? String(place.id) : `draggable-${index}`
                    } // Ensure it's a string and unique
                    index={index}
                  >
                    {(provided) => (
                      <Box
                        position="relative"
                        bg="white"
                        borderRadius="lg"
                        boxShadow="md"
                        border="1px solid"
                        borderColor="gray.200"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        onMouseEnter={() => setHoveredItem(index)}
                        onMouseLeave={() => setHoveredItem(null)}
                      >
                        {/* Numbering circle */}
                        <Box
                          position="absolute"
                          top="-10px"
                          left="-10px"
                          bg="black"
                          color="white"
                          borderRadius="full"
                          width="24px"
                          height="24px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          fontSize="12px"
                          fontWeight="bold"
                        >
                          {index + 1}
                        </Box>
                        <Box
                          {...provided.dragHandleProps}
                          bg="gray.100"
                          alignContent="center"
                          visibility={
                            hoveredItem === index ? "visible" : "hidden"
                          }
                          h="100%"
                        >
                          <Image
                            src={GridIcon}
                            alt="Drag Handle"
                            boxSize="4"
                            opacity={hoveredItem === index ? 1 : 0.5}
                          />
                        </Box>
                        <VStack ml={1} w="full" p={4} pl={0} h="100%">
                          <HStack align="center" spacing={4} w="100%">
                            <VStack
                              align="flex-start"
                              spacing={1}
                              flex="1"
                              h="100%"
                            >
                              <Text fontSize="18px" fontWeight="bold" mb={0}>
                                {place.title}
                              </Text>

                              <HStack spacing={1}>
                                <Text fontSize="sm" color="gray.500" mb={0}>
                                  {place.attractionStreetAddress}
                                </Text>
                                <Text fontSize="sm" color="gray.500" mb={0}>
                                  ,
                                </Text>
                                <Text fontSize="sm" color="gray.500" mb={0}>
                                  {place.attractionCity}
                                </Text>
                              </HStack>
                              <Text fontSize="14px" color="gray.600" mb={0}>
                                <Translate text="Rating:" />{" "}
                                {place.rating
                                  ? `${place.rating} ★`
                                  : "Not Available"}
                              </Text>
                            </VStack>
                            {place.thumbnailHiResURL && (
                            <Image
                              src={place.thumbnailHiResURL}
                              alt={place.primaryDestinationName}
                              boxSize="120px"
                              borderRadius="lg"
                              objectFit="cover"
                            />
                            )}
                          </HStack>
                          <HStack spacing={1} w="100%">
                            {place.webURL && (
                              <Button
                                as="a"
                                href={place.webURL}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="sm"
                                bg="#286328"
                                _hover="none"
                                _active="none"
                                textColor="white"
                                mr={8}
                              >
                                <Translate text="Book" />
                              </Button>
                            )}

                            <Spacer />

                            <IconButton
                              icon={<FaTrash />}
                              aria-label="Delete place"
                              borderRadius="full"
                              size="xs"
                              visibility={
                                hoveredItem === index ? "visible" : "hidden"
                              }
                              onClick={() => handleActivityDelete(index)}
                            />
                          </HStack>
                        </VStack>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <InputGroup>
                  {currentStep === 0 && (
                    <Input
                      placeholder="Enter Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 1 && (
                    <Input
                      placeholder="Enter Street Address"
                      value={attractionStreetAddress}
                      onChange={(e) =>
                        setAttractionStreetAddress(e.target.value)
                      }
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 2 && (
                    <Input
                      placeholder="Enter City"
                      value={attractionCity}
                      onChange={(e) => setAttractionCity(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  <InputRightElement>
                    <IconButton
                      onClick={handleNextStep}
                      bg="black"
                      color="white"
                      _hover="none"
                      size="sm"
                      icon={
                        currentStep < 2 ? <ArrowForwardIcon /> : <AddIcon />
                      }
                      aria-label={
                        currentStep < 2 ? "Next Step" : "Add Activity"
                      }
                    />
                  </InputRightElement>
                </InputGroup>
              </VStack>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};

export default ActivitiesList;
