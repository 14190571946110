import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { UserProvider, useUser } from "./components/userRole/UserContext";
import {
  useGoogleAuth,
  GoogleAuthProvider,
} from "./components/common/google/GoogleAuthContext";
import { OTPProvider } from "./components/AdminPanelAccess/OTPContext";
import { ScrollProvider } from "./components/ScrollContext";
import { CurrencyProvider } from "./components/CurrencyExchange/CurrencyContext";
import { TranslationProvider } from "./components/LanguageTranslation/TranslationContext";
import HomePage from "./components/Home";
import LoginPage from "./components/Login";
import SignUpPage from "./components/SignUp";
import BusinessPage from "./components/BusinessPage";
import CreatorPage from "./components/CreatorPage";
import NextAdventure from "./components/NextAdventure";
import ProtectedRoute from "./components/ProtectedRoute";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import Disclaimer from "./components/Disclaimer";
import Chat from "./components/chat/Chat";
import UserAccount from "./components/AccountSettings/UserAccount";
import PackingList from "./components/PackingList/PackingList";
import Itinerary from "./components/Itinerary/Itinerary";
import TravelTracker from "./components/TravelTracker/TravelTracker";
import BackToTrips from "./components/TravelTracker/BackToTrips";
import PostDetail from "./components/TravelTracker/Posts/PostDetail";
import SearchedUser from "./components/TravelTracker/SearchedUser/SearchedUser";
import Blog from "./components/Blog/Blog";
import BlogDetail from "./components/Blog/BlogDetail";
import BucketList from "./components/BucketList/BucketList";
import BudgetPlanner from "./components/BudgetPlanner/BudgetPlanner";
import ItineraryDetail from "./components/BucketList/ItineraryDetail";
import SettingsAndPrivacy from "./components/SettingsAndPrivacy/SettingsAndPrivacy";
import SideBar from "./components/Sidebar";
import ContactUs from "./components/ContactUs";
import AdminPanel from "./components/userRole/AdminPanel";
import AdminFeedbackSurvey from "./components/AdminFeedbackSurvey/AdminFeedbackSurvey";
import SurveyFeedback from "./components/AdminFeedbackSurvey/SurveyFeedback";
import Filters from "./components/Filters/Filters";
import UpdateBlog from "./components/Blog/UpdateBlog";
import AdminBlogUpload from "./components/Blog/AdminBlogUpload";
import AdminTimeAnalytics from "./components/AdminTimeAnalytics/AdminTimeAnalytics";
import BlogFilters from "./components/Blog/BlogFilters";
import BlogTitlesList from "./components/Blog/BlogTitlesList";
import Newsletter from "./components/AdminNewsletter/Newsletter";
import UserForm from "./components/AdminUserFrom/UserForm";
import usePageTracking from "./components/usePageTracking";
import DonationForm from "./components/Donation/DonationForm";
import DonationSuccess from "./components/Donation/DonationSuccess";
import DonationFailure from "./components/Donation/DonationFailure";

const AppContent = () => {
  const location = useLocation();
  const history = useHistory();
  const { login } = useGoogleAuth();
  const { user, setUser } = useUser();
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
  const { visitCounts } = usePageTracking();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const access_token = params.get("access_token");
    const refresh_token = params.get("refresh_token");
    const email = params.get("email");
    const username = params.get("username");
    const role = params.get("role");

    if (access_token) {
      login(access_token);
      const userData = {
        access_token,
        refresh_token,
        email,
        username,
        role: role,
      };
      setUser(userData);
      localStorage.setItem("REACT_TOKEN_AUTH_KEY", JSON.stringify(userData));
      localStorage.setItem("email", email);
      localStorage.setItem("username", username);
      history.push("/travel-planner");
      setIsLoginSuccessful(true);
    }
  }, [window.location.search]);

  const shouldHideSidebar = () => {
    const paths = [
      "/get-started",
      "/forgot_password",
      "/auth/reset_password/",
      "/signup",
      "/policies/privacy-policy",
      "/policies/terms-of-use",
      "/disclaimer",
      "/home",
      "/blogs/:id",
      "/blogs",
      "/contact",
      "/businesses",
      "/creators",
      "/login",
      "/donations",
      "/donations/success",
      "/donations/failure",
    ];
    return paths.some((path) => location.pathname.startsWith(path));
  };

  return (
    <Box display="flex">
      {!shouldHideSidebar() && <SideBar />}
      <Box flex="1" transition="margin 0.2s ease-in-out">
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          {/* <Route path="/signup">
            <SignUpPage />
          </Route> */}
          {/* <Route path="/home">
            <HomePage />
          </Route> */}
          <ProtectedRoute
            path="/home"
            component={HomePage}
            roles={["Admin", "User"]}
          />
          {/* <Route path="/businesses">
            <BusinessPage />
          </Route> */}
          <ProtectedRoute
            path="/businesses"
            component={BusinessPage}
            roles={["Admin", "User"]}
          />
          <ProtectedRoute
            path="/creators"
            component={CreatorPage}
            roles={["Admin", "User"]}
          />
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          {/* <Route path="/contact">
            <ContactUs />
          </Route> */}
          <ProtectedRoute
            path="/contact"
            component={ContactUs}
            roles={["Admin", "User"]}
          />
          <Route path="/forgot_password">
            <ForgotPassword />
          </Route>
          <Route path="/auth/reset_password/:token">
            <ResetPassword />
          </Route>
          <Route path="/policies/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/policies/terms-of-use">
            <TermsOfService />
          </Route>
          <Route path="/disclaimer">
            <Disclaimer />
          </Route>
          {/* <Route path="/travel-planner">
            <NextAdventure />
          </Route> */}
          <ProtectedRoute
            path="/travel-planner"
            component={NextAdventure}
            roles={["Admin", "User"]}
          />
          {/* <Route path="/ava/chat">
            <Chat />
          </Route> */}

          <ProtectedRoute
            path="/ava/chat"
            component={Chat}
            roles={["Admin", "User"]}
          />

          {/* <Route path="/blog/:id">
            <BlogDetail />
          </Route> */}

          <ProtectedRoute
            path="/blogs/:id"
            component={BlogDetail}
            roles={["Admin", "User"]}
          />
          {/* 
          <Route path="/blog">
            <Blog />
          </Route> */}

          <ProtectedRoute
            path="/blogs"
            component={Blog}
            roles={["Admin", "User"]}
          />

          <ProtectedRoute
            path="/donations/success"
            component={DonationSuccess}
            roles={["Admin", "User"]}
          />
          <ProtectedRoute
            path="/donations/failure"
            component={DonationFailure}
            roles={["Admin", "User"]}
          />
          <ProtectedRoute
            path="/donations"
            component={DonationForm}
            roles={["Admin", "User"]}
          />
          <Route path="/search-user/:socialId">
            <SearchedUser />
          </Route>
          <Route path="/backtotrips/:tripId">
            <BackToTrips />
          </Route>
          <Route path="/post/:postId">
            <PostDetail />
          </Route>
          <Route path="/itinerary">
            <Itinerary />
          </Route>
          <Route path="/itinerary_details/:tripId">
            <ItineraryDetail />
          </Route>
          {/* Admin Routes */}
          <ProtectedRoute
            path="/admin_panel"
            component={AdminPanel}
            roles={["Admin"]}
            requiresOTP={true}
          />

          <ProtectedRoute
            path="/filter"
            component={Filters}
            roles={["Admin"]}
          />
          <ProtectedRoute
            path="/admin/blog/upload"
            component={AdminBlogUpload}
            roles={["Admin"]}
          />
          <ProtectedRoute
            path="/admin/analytics"
            component={AdminTimeAnalytics}
            roles={["Admin"]}
          />
          <ProtectedRoute
            path="/admin/blog/filters"
            component={BlogFilters}
            roles={["Admin"]}
          />

          <ProtectedRoute
            path="/admin/all-blog"
            component={BlogTitlesList}
            roles={["Admin"]}
          />
          <ProtectedRoute
            path="/admin/update-blog/:id"
            component={UpdateBlog}
            roles={["Admin"]}
          />
          <ProtectedRoute
            path="/newsletters"
            component={Newsletter}
            roles={["Admin"]}
          />
          <ProtectedRoute
            path="/userform"
            component={UserForm}
            roles={["Admin"]}
          />
          <ProtectedRoute
            path="/feedback"
            component={AdminFeedbackSurvey}
            roles={["Admin"]}
          />
          <ProtectedRoute
            path="/feedback-response"
            component={SurveyFeedback}
            roles={["Admin"]}
          />
          {/* Admin Routes */}

          <ProtectedRoute
            path="/bucketlists"
            component={BucketList}
            roles={["Admin", "User"]}
          />
          <ProtectedRoute
            path="/search-user/:socialId"
            component={SearchedUser}
            roles={["Admin", "User"]}
          />
          <ProtectedRoute
            path="/travel_tracker"
            component={TravelTracker}
            roles={["Admin", "User"]}
          />
          <ProtectedRoute
            path="/account"
            component={UserAccount}
            roles={["Admin", "User"]}
          />
          <ProtectedRoute
            path="/packingLists"
            component={PackingList}
            roles={["Admin", "User"]}
          />
          <ProtectedRoute
            path="/budget"
            component={BudgetPlanner}
            roles={["Admin", "User"]}
          />
          <ProtectedRoute
            path="/settings/privacy"
            component={SettingsAndPrivacy}
            roles={["Admin", "User"]}
          />
        </Switch>
      </Box>
    </Box>
  );
};

const App = () => {
  return (
    <UserProvider>
      <GoogleAuthProvider>
        <OTPProvider>
          <ScrollProvider>
            <CurrencyProvider>
              <TranslationProvider>
                <Router>
                  <AppContent />
                </Router>
              </TranslationProvider>
            </CurrencyProvider>
          </ScrollProvider>
        </OTPProvider>
      </GoogleAuthProvider>
    </UserProvider>
  );
};

export default App;
