import React, { useState, useRef } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  Stack,
  Icon,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid,
  Input,
  Select,
  Circle
} from "@chakra-ui/react";
import axiosInstance from "../helpers/axiosInstance";
import { FaCheckCircle } from "react-icons/fa";
import aiCreationImage from "../assets/images/for-business-sleep.jpg";
import vibeImage from "../assets/images/for-business-taste.jpg";
import exploreImage from "../assets/images/for-business-explore.jpg";
import Navbar from "../components/Navbar";
import darkLogo from "../assets/images/Newlogo-black.png";
import BackToTopButton from "../components/common/BackToTopButton";
import CreatorsBusinessBanner from "../components/common/CreatorsBusinessBanner";
import Footer from "./Footer";
import FAQBusinessPage from "./FAQBusinessPage";
import Translate from "../components/LanguageTranslation/Translate";
import banner from "../assets/images/for-business-banner.jpg";
import businessProgramImage from "../assets/images/for-business-section-5.jpg";

const BusinessPage = () => {
  // State variables to track form inputs
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [websiteOrInstagram, setWebsiteOrInstagram] = useState("");
  const [creator, setCreator] = useState("");
  const [phone, setPhone] = useState(""); // Add state for phone
  const [loading, setLoading] = useState(false);

  // Create refs for the sections
  const sectionTwoRef = useRef(null);
  const sectionFiveRef = useRef(null); // New ref for section five

  // Function to scroll to the second section
  const scrollToSectionTwo = () => {
    if (sectionTwoRef.current) {
      sectionTwoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Function to scroll to the fifth section
  const scrollToSectionFive = () => {
    if (sectionFiveRef.current) {
      sectionFiveRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Define payload to send to the backend
      const payload = {
        name,
        email,
        websiteOrInstagram,
        phone,
        creator,
        type: "For Businesses",
      };

      // Make the POST request using axios
      const response = await axiosInstance.post("/userform/", payload); // Adjust endpoint as per your backend setup

      if (response.status === 201) {
        setWebsiteOrInstagram("");
        setEmail("");
        setName("");
        setPhone("");
        setCreator("");
        setLoading(false);
        alert("Form submitted successfully!");
        // You can clear the form or add additional success handling here
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting form:", error);
      alert("There was an error submitting the form. Please try again.");
    }
  };

  return (
    <Box>
      <Navbar bgColor="white" linkColor="black" logoSrc={darkLogo} />
      <CreatorsBusinessBanner heading="For Businesses" banner={banner} />
      <Box bg="white" px={{ base: 4, md: 12 }} py={8}>
        {/* Section 1: Sales Growth (Image on Right, Text on Left) */}
        <Flex
          direction={{ base: "column-reverse", md: "row" }}
          mb={20}
          maxW="80vw"
          justifyContent="center"
          mx="auto"
          h={{ base: "auto", md: "500px" }} // Responsive height
        >
          <VStack spacing={0}>
            <Flex
              w="100%"
              // bg="#EBEAF2"
              h={{ base: "auto", md: "auto", lg: "auto", xl: "auto" }}
              alignItems="flex-start"
              justify="space-between"
              direction={{ base: "column-reverse", md: "row" }}
            >
              {/* Text Section */}
              <Box
                flex="1"
                borderRadius="lg"
                p={6}
                pt={0}
                textAlign={{ base: "center", md: "left" }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                h="100%"
              >
                <Heading
                  fontSize={{ base: "2xl", md: "3xl" }}
                  textAlign="left"
                  color="black"
                >
                  <Text
                    as="span"
                    bgGradient="linear(to-r, #286328, #A8E063)" // Changed to the new gradient colors
                    bgClip="text"
                    fontWeight="bold"
                  >
                    <Translate text="Increased" />
                  </Text>{" "}
                  <Translate text=" sales efficiency growth" />
                </Heading>
                <Text
                  mt={4}
                  fontSize={{ base: "md", md: "lg" }}
                  textAlign="left"
                  color="gray.600"
                >
                  <Translate
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam."
                  />
                </Text>

                {/* <Box mt={4}>
                <Text
                  fontSize={{ base: "md", md: "lg" }}
                  textAlign="left"
                  color="gray.600"
                >
                  <Translate
                    text=" sales effAccelerate sales growth with advanced tools tailored to your
                  business."
                  />
                </Text>
                <Stack mt={2} spacing={1}>
                  <Flex alignItems="center">
                    <Icon as={FaCheckCircle} color="black" mr={2} />
                    <Text mb={0}>
                      <Translate text="Advanced pipeline management" />
                    </Text>
                  </Flex>
                  <Flex alignItems="center">
                    <Icon as={FaCheckCircle} color="black" mr={2} />
                    <Text mb={0}>
                      <Translate text="Real-time sales analytics" />
                    </Text>
                  </Flex>
                  <Flex alignItems="center">
                    <Icon as={FaCheckCircle} color="black" mr={2} />
                    <Text mb={0}>
                      <Translate text="Mobile and offline access" />
                    </Text>
                  </Flex>
                </Stack>
              </Box> */}
                <Stack direction="row" spacing={4} mt={6}>
                  <Button
                    bg="black"
                    color="white"
                    _hover="none"
                    size={{ base: "sm", md: "lg" }}
                    onClick={scrollToSectionFive} // Scroll to section five on click
                  >
                    <Translate text="Get Started" />
                  </Button>
                  <Button
                    border="1px"
                    borderColor="black"
                    bg="none"
                    _hover="none"
                    size={{ base: "sm", md: "lg" }}
                    onClick={scrollToSectionTwo} // Scroll to section two on click
                  >
                    <Translate text="Learn More" />
                  </Button>
                </Stack>
              </Box>
              {/* Form Section */}
              <Box
                flex="1"
                borderRadius="xl"
                overflow="hidden"
                // boxShadow="md"
                h="100%"
                w="100%"
                minH={{ base: "300px", md: "100%" }} // Ensure responsive min-height
                // p={6}
                // mb={{ base: 6 }}
                // bgImage={`url(${salesGrowthImage})`} // Use image as background
                bgPosition="center"
                bgSize="cover"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {/* Semi-transparent background overlay for form */}
                <Box
                  bg="rgba(255, 255, 255, 0.85)" // Semi-transparent white background
                  p={6}
                  pl={0}
                  pb={2}
                  borderRadius="lg"
                  boxShadow="lg"
                  w="100%"
                  h="100%"
                >
                  <VStack spacing={1.5} align="flex-start" w="100%">
                    <Text fontWeight="bold" color="black" mb={0}>
                      <Translate text="Name" />
                    </Text>
                    <Input
                      placeholder={Translate({
                        text: "Your name",
                        as: "string",
                      })}
                      // size="md"
                      borderRadius="full"
                      borderColor="gray.300"
                      bg="white"
                      w="100%"
                      value={name}
                      onChange={(e) => setName(e.target.value)} // Update name state
                    />

                    <Text fontWeight="bold" color="black" mb={0}>
                      <Translate text="Email" />
                    </Text>
                    <Input
                      placeholder={Translate({
                        text: "Your email address",
                        as: "string",
                      })}
                      // size="lg"
                      borderRadius="full"
                      borderColor="gray.300"
                      bg="white"
                      w="100%"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)} // Update email state
                    />

                    <Text fontWeight="bold" color="black" mb={0}>
                      <Translate text="Phone Number" />
                    </Text>
                    <Input
                      placeholder={Translate({
                        text: "Your phone number",
                        as: "string",
                      })}
                      // size="lg"
                      borderRadius="full"
                      borderColor="gray.300"
                      bg="white"
                      w="100%"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)} // Update phone state
                    />
                    <Text fontWeight="bold" color="black" mb={0}>
                      <Translate text="Business Type" />
                    </Text>
                    <Select
                      placeholder={Translate({
                        text: "Select an option",
                        as: "string",
                      })}
                      // size="lg"
                      borderRadius="full"
                      borderColor="gray.300"
                      bg="white"
                      w="100%"
                      value={creator}
                      onChange={(e) => setCreator(e.target.value)} // Update creator state
                    >
                      <option value="Sleep">
                        <Translate text="Sleep" />
                      </option>
                      <option value="Taste">
                        <Translate text="Taste" />
                      </option>
                      <option value="Explore">
                        <Translate text="Explore" />
                      </option>
                      <option value="other">
                        <Translate text="Other" />
                      </option>
                    </Select>

                    <Text fontWeight="bold" color="black" mb={0}>
                      <Translate text="Website or Instagram" />
                    </Text>
                    <Input
                      placeholder={Translate({
                        text: "Link to your website or Instagram",
                        as: "string",
                      })}
                      // size="lg"
                      borderRadius="full"
                      borderColor="gray.300"
                      bg="white"
                      w="100%"
                      value={websiteOrInstagram}
                      onChange={(e) => setWebsiteOrInstagram(e.target.value)} // Update websiteOrInstagram state
                    />
                  </VStack>
                </Box>
              </Box>
            </Flex>
            {/* Button aligned to the right */}
            <Box w="100%" textAlign="right" mt={2} pr={6}>
              <Button
                bg="#286328"
                color="white"
                size="md"
                borderRadius="full"
                _hover="none"
                onClick={handleSubmit} // Submit form on click
                isLoading={loading}
              >
                <Translate text="Submit" />
              </Button>
            </Box>
          </VStack>
        </Flex>

        {/* Section 2: AI Creation (Image on Left, Text on Right) */}
        <Flex
          ref={sectionTwoRef} // Attach ref to the second section
          direction={{ base: "column", md: "row-reverse" }}
          alignItems="flex-start" // Align content to the left
          justify="space-between"
          mb={20}
          maxW="80vw"
          mx="auto"
          h={{ base: "auto", md: "550px", lg: "400px" }} // Responsive height
        >
          {/* Text Section */}
          <Box
            flex="1"
            borderRadius="lg"
            p={6}
            pl={4}
            pt={0}
            textAlign={{ base: "center", md: "left" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            h="100%"
            minH={{ base: "300px", md: "100%" }} // Ensure responsive min-height
          >
            <Box
              textAlign="center"
              p={2}
              w={{ base: "60%", md: "50%", lg: "30%" }}
              boxShadow="xl"
              borderRadius="full"
            >
              <Text fontSize="sm" color="gray.400" mb={0}>
                <Translate text="Sleep" />
              </Text>
            </Box>
            <Heading
              fontSize={{ base: "2xl", md: "3xl", lg: "5xl" }}
              mt={6}
              w={{ base: "100%", md: "100%", lg: "90%" }}
              textAlign="left"
            >
              <Translate text="For" />{" "}
              <Text
                as="span"
                bgGradient="linear(to-r, #A8E063, #286328)" // Changed to the new gradient colors
                bgClip="text"
                fontWeight="bold"
              >
                <Translate text="Holiday Rentals, Hotels " />{" "}
              </Text>
              <Translate text="& More" />
            </Heading>
            <Text
              mt={2}
              fontSize={{ base: "md", md: "md" }}
              color="gray.600"
              w={{ base: "100%", md: "100%", lg: "80%" }}
              textAlign="left"
            >
              <Translate
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam."
              />
            </Text>
            <Text
              mt={0}
              fontSize={{ base: "md", md: "md" }}
              color="gray.600"
              w={{ base: "100%", md: "100%", lg: "80%" }}
              textAlign="left"
            >
              <Translate text="Excepteur sint occaecat cupidatat non proident." />
            </Text>
            <Stack
              spacing={2}
              mt={2}
              w={{ base: "100%", md: "100%", lg: "70%" }}
              textAlign="left"
            >
              <Text fontSize={{ base: "md", md: "sm" }} fontWeight="bold">
                <Box
                  as="span"
                  bg="#E9F5E9"
                  p={1}
                  pl={2}
                  pr={2}
                  borderRadius="md"
                  mr={2}
                >
                  ✔
                </Box>

                <Translate text="Customizable layouts for efficient coding." />
              </Text>
              <Text fontSize={{ base: "md", md: "sm" }} fontWeight="bold">
                <Box
                  as="span"
                  bg="#E9F5E9"
                  p={1}
                  pl={2}
                  pr={2}
                  borderRadius="md"
                  mr={2}
                >
                  ✔
                </Box>

                <Translate text="Font preferences to match your style." />
              </Text>
              <Text fontSize={{ base: "md", md: "sm" }} fontWeight="bold">
                <Box
                  as="span"
                  bg="#E9F5E9"
                  p={1}
                  pl={2}
                  pr={2}
                  borderRadius="md"
                  mr={2}
                >
                  ✔
                </Box>

                <Translate text="Create multiple profiles for versatility." />
              </Text>
            </Stack>
          </Box>

          {/* Image Section */}
          <Box
            flex="1"
            // bg="#EBEAF2"
            pl={6}
            pr={6}
            // pt={2}
            // pb={2}
            textAlign={{ base: "center", md: "left" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            h="100%"
            minH={{ base: "300px", md: "100%" }} // Ensure responsive min-height
          >
            <Image
              src={aiCreationImage}
              alt="AI Creation"
              boxSize="100%"
              objectFit="cover"
              borderRadius="40px"
            />
          </Box>
        </Flex>

        {/* Section 3: Vibe Features (Image on Left, Text on Right) */}
        <Flex
          direction={{ base: "column", md: "row-reverse" }}
          alignItems="flex-start" // Align content to the left
          justify="space-between"
          mb={20}
          maxW="80vw"
          mx="auto"
          h={{ base: "auto", md: "550px", lg: "400px" }} // Responsive height
        >
          <Box
            flex="1"
            // bg="#EBEAF2"
            pt={2}
            pb={2}
            textAlign={{ base: "center", md: "left" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            // boxShadow="md"
            h="100%"
            minH={{ base: "300px", md: "100%" }} // Ensure responsive min-height
          >
            <Image
              src={vibeImage}
              alt="Vibe Creation"
              boxSize="100%"
              objectFit="cover"
              borderRadius="40px"
            />
          </Box>
          {/* Text Section */}
          <Box
            flex="1"
            borderRadius="lg"
            p={6}
            textAlign={{ base: "center", md: "left" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            h="100%"
            minH={{ base: "300px", md: "100%" }} // Ensure responsive min-height
          >
            <Box
              textAlign="center"
              p={2}
              w={{ base: "60%", md: "50%", lg: "30%" }}
              boxShadow="xl"
              borderRadius="full"
            >
              <Text fontSize="sm" color="gray.400" mb={0}>
                <Translate text="Taste" />
              </Text>
            </Box>
            <Heading
              fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }}
              mt={6}
              w={{ base: "100%", md: "100%", lg: "80%" }}
              textAlign="left"
            >
              <Translate text=" For" />{" "}
              <Text
                as="span"
                bgGradient="linear(to-r, #286328, #A8E063)" // Changed to the new gradient colors
                bgClip="text"
                fontWeight="bold"
              >
                <Translate text="Restaurants, Bars & Pubs" />
              </Text>
            </Heading>
            <Text
              mt={2}
              fontSize={{ base: "md", md: "md" }}
              color="gray.600"
              w={{ base: "100%", md: "100%", lg: "80%" }}
              textAlign="left"
            >
              <Translate
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod."
              />
            </Text>
            <Text
              mt={0}
              fontSize={{ base: "md", md: "md" }}
              color="gray.600"
              w={{ base: "100%", md: "100%", lg: "80%" }}
              textAlign="left"
            >
              <Translate text="Excepteur sint occaecat cupidatat non proident." />
            </Text>
            <Stack
              spacing={2}
              mt={2}
              w={{ base: "100%", md: "100%", lg: "70%" }}
              textAlign="left"
            >
              <Text fontSize={{ base: "md", md: "sm" }} fontWeight="bold">
                <Box
                  as="span"
                  bg="#E9F5E9"
                  p={1}
                  pl={2}
                  pr={2}
                  borderRadius="md"
                  mr={2}
                >
                  ✔
                </Box>

                <Translate text="Customizable layouts for efficient coding." />
              </Text>
              <Text fontSize={{ base: "md", md: "sm" }} fontWeight="bold">
                <Box
                  as="span"
                  bg="#E9F5E9"
                  p={1}
                  pl={2}
                  pr={2}
                  borderRadius="md"
                  mr={2}
                >
                  ✔
                </Box>

                <Translate text="Font preferences to match your style." />
              </Text>
              <Text fontSize={{ base: "md", md: "sm" }} fontWeight="bold">
                <Box
                  as="span"
                  bg="#E9F5E9"
                  p={1}
                  pl={2}
                  pr={2}
                  borderRadius="md"
                  mr={2}
                >
                  ✔
                </Box>

                <Translate text="Create multiple profiles for versatility." />
              </Text>
            </Stack>
          </Box>
        </Flex>

        {/* Section 4: AI Creation (Image on Left, Text on Right) */}
        <Flex
          direction={{ base: "column", md: "row-reverse" }}
          alignItems="flex-start" // Align content to the left
          justify="space-between"
          mb={20}
          maxW="80vw"
          mx="auto"
          h={{ base: "auto", md: "550px", lg: "400px" }} // Responsive height
        >
          {/* Text Section */}
          <Box
            flex="1"
            borderRadius="lg"
            p={6}
            pl={4}
            pt={0}
            textAlign={{ base: "center", md: "left" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            h="100%"
            minH={{ base: "300px", md: "100%" }} // Ensure responsive min-height
          >
            <Box
              textAlign="center"
              p={2}
              w={{ base: "60%", md: "50%", lg: "30%" }}
              boxShadow="xl"
              borderRadius="full"
            >
              <Text fontSize="sm" color="gray.400" mb={0}>
                <Translate text="Explore" />
              </Text>
            </Box>
            <Heading
              fontSize={{ base: "2xl", md: "3xl", lg: "5xl" }}
              mt={6}
              w={{ base: "100%", md: "100%", lg: "100%" }}
              textAlign="left"
            >
              <Translate text="For" />{" "}
              <Text
                as="span"
                bgGradient="linear(to-r, #A8E063, #286328)" // Changed to the new gradient colors
                bgClip="text"
                fontWeight="bold"
              >
                <Translate text="Local Guides, Tour Operators & Travel Agencies" />{" "}
              </Text>
            </Heading>
            <Text
              mt={2}
              fontSize={{ base: "md", md: "md" }}
              color="gray.600"
              w={{ base: "100%", md: "100%", lg: "80%" }}
              textAlign="left"
            >
              <Translate
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod."
              />
            </Text>
            <Text
              mt={0}
              fontSize={{ base: "md", md: "md" }}
              color="gray.600"
              w={{ base: "100%", md: "100%", lg: "80%" }}
              textAlign="left"
            >
              <Translate text="Excepteur sint occaecat cupidatat non proident." />
            </Text>
            <Stack
              spacing={2}
              mt={2}
              w={{ base: "100%", md: "100%", lg: "70%" }}
              textAlign="left"
            >
              <Text fontSize={{ base: "md", md: "sm" }} fontWeight="bold">
                <Box
                  as="span"
                  bg="#E9F5E9"
                  p={1}
                  pl={2}
                  pr={2}
                  borderRadius="md"
                  mr={2}
                >
                  ✔
                </Box>

                <Translate text="Customizable layouts for efficient coding." />
              </Text>
              <Text fontSize={{ base: "md", md: "sm" }} fontWeight="bold">
                <Box
                  as="span"
                  bg="#E9F5E9"
                  p={1}
                  pl={2}
                  pr={2}
                  borderRadius="md"
                  mr={2}
                >
                  ✔
                </Box>

                <Translate text="Font preferences to match your style." />
              </Text>
              <Text fontSize={{ base: "md", md: "sm" }} fontWeight="bold">
                <Box
                  as="span"
                  bg="#E9F5E9"
                  p={1}
                  pl={2}
                  pr={2}
                  borderRadius="md"
                  mr={2}
                >
                  ✔
                </Box>

                <Translate text="Create multiple profiles for versatility." />
              </Text>
            </Stack>
          </Box>

          {/* Image Section */}
          <Box
            flex="1"
            // bg="#EBEAF2"
            pl={6}
            pr={6}
            // pt={2}
            // pb={2}
            textAlign={{ base: "center", md: "left" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            h="100%"
            minH={{ base: "300px", md: "100%" }} // Ensure responsive min-height
          >
            <Image
              src={exploreImage}
              alt="AI Creation"
              boxSize="100%"
              objectFit="cover"
              borderRadius="40px"
            />
          </Box>
        </Flex>

         {/* Section 5: Vibe Features (Image on Left, Text on Right) */}
         <Flex
         ref={sectionFiveRef}
          // bg="#EBEAF2"
          direction={{ base: "column", md: "row-reverse" }}
          alignItems="center" // Align content to the left
          justify="space-between"
          mb={20}
          maxW={{ base: "auto", md: "80vw", lg: "80vw", xl: "80vw" }}
          mx="auto"
          // h={{ base: "auto", md: "550px", lg: "400px" }} // Responsive height
          h="auto"
          // bg="#EBEAF2"
          // boxShadow="md"
          borderRadius="xl"
        >
          <Box
            flex="1"
            pt={2}
            pb={2}
            display="flex"
            flexDirection="column"
            alignItems="center" // Center horizontally
            justifyContent="center" // Center vertically
            h="100%"
            maxH="100%"
            minH={{ base: "300px", md: "100%" }}
          >
            <Image
              src={businessProgramImage}
              alt="AI Creation"
              maxW="100%" // Constrain max width to the box's width
              maxH="100%" // Constrain max height to the box's height
              objectFit="contain" // Contain the image without cropping
              borderRadius="40px"
            />
          </Box>

          {/* Text Section */}
          <Box
            flex="1"
            borderRadius="lg"
            p={6}
            pt={0}
            textAlign={{ base: "center", md: "left" }}
            display="flex"
            flexDirection="column"
            justifyContent="top"
            h="100%"
            // minH={{ base: "300px", md: "100%" }} // Ensure responsive min-height
          >
            <Heading
              fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }}
              mt={6}
              w={{ base: "100%", md: "100%", lg: "100%" }}
              textAlign="left"
            >
              <Translate text="Join Our" />
              <br></br>
              <Text
                as="span"
                bgGradient="linear(to-r, #286328, #A8E063)" // Changed to the new gradient colors
                bgClip="text"
                fontWeight="bold"
              >
                <Translate text="Business Program" />
              </Text>
            </Heading>
            <Stack spacing={2} mt={2}>
              <Flex
                bg="white"
                p={2}
                borderRadius="xl"
                boxShadow="md"
                alignItems="center"
              >
                <Circle bg="#286328" color="white" size="8" mr={4}>
                  <Text fontWeight="bold" mb={0}>
                    1
                  </Text>
                </Circle>
                <Box w="100%">
                  <Accordion allowToggle>
                    <AccordionItem border="none">
                      <AccordionButton p={0} _hover={{ bg: "transparent" }}>
                        <Box
                          flex="1"
                          textAlign="left"
                          fontWeight="bold"
                          fontSize="lg"
                        >
                          <Translate text="Set up your Business Profile" />
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel
                        pl={0}
                        pb={4}
                        color="gray.600"
                        fontSize="sm"
                      >
                        <Translate text="Fill in your details and personalise your profile by adding your profile picture or logo, your website, and social media channels." />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              </Flex>

              <Flex
                bg="white"
                p={2}
                borderRadius="xl"
                boxShadow="md"
                alignItems="center"
              >
                <Circle bg="#286328" color="white" size="8" mr={4}>
                  <Text fontWeight="bold" mb={0}>
                    2
                  </Text>
                </Circle>
                <Box w="100%">
                  <Accordion allowToggle>
                    <AccordionItem border="none">
                      <AccordionButton p={0} _hover={{ bg: "transparent" }}>
                        <Box
                          flex="1"
                          textAlign="left"
                          fontWeight="bold"
                          fontSize="lg"
                        >
                          <Translate text="Upload Content" />
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel
                        pl={0}
                        pb={4}
                        color="gray.600"
                        fontSize="sm"
                      >
                        <Translate text="Ons team neemt contact met u op zodra we uw account hebben geverifieerd" />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              </Flex>

              <Flex
                bg="white"
                p={2}
                borderRadius="xl"
                boxShadow="md"
                alignItems="center"
              >
                <Circle bg="#286328" color="white" size="8" mr={4}>
                  <Text fontWeight="bold" mb={0}>
                    3
                  </Text>
                </Circle>
                <Box w="100%">
                  <Accordion allowToggle>
                    <AccordionItem border="none">
                      <AccordionButton p={0} _hover={{ bg: "transparent" }}>
                        <Box
                          flex="1"
                          textAlign="left"
                          fontWeight="bold"
                          fontSize="lg"
                        >
                          <Translate text="Connect your profile and blog" />
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel
                        pl={0}
                        pb={4}
                        color="gray.600"
                        fontSize="sm"
                      >
                        <Translate text="Zorg ervoor dat uw profiel is ingesteld en up-to-date" />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              </Flex>

              <Box w="100%" alignContent="center">
                <Button
                  mt={2}
                  bg="#286328"
                  color="white"
                  _hover="none"
                  borderRadius="full"
                  size={{ base: "sm", md: "md" }}
                >
                  <Translate text="Sign Up" />
                </Button>
              </Box>
            </Stack>
          </Box>
        </Flex>

        {/* Section 6: FAQ */}
        <FAQBusinessPage />
      </Box>
      <Footer />
      <BackToTopButton />
    </Box>
  );
};

export default BusinessPage;
