import React, { useState } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  HStack,
  IconButton,
  Spacer,
  useColorMode,
  Button,
  useToast,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { CloseIcon, AddIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axiosInstance from "../../helpers/axiosInstance"; // Import axios instance
import GridIcon from "../../assets/icons/dot_grid_icon.svg"; // Import the grid icon SVG
import { useCurrency } from "../CurrencyExchange/CurrencyContext"; // Import Currency Context
import Translate from "../../components/LanguageTranslation/Translate";

const RVsList = ({ rvs, setRVs, tripId, location, setView }) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [hoveredItem, setHoveredItem] = useState(null);
  const { convertCurrency, currency } = useCurrency(); // Get the currency context

  const handleCloseDetail = () => {
    setView(null);
  };

  // Function to handle drag end event
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    // Reorder RVs array based on drag result
    const items = Array.from(rvs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setRVs(items);
    // Payload with updated rentals list
    const payload = {
      outdoorsy_api_response: [
        {
          location,
          outdoorsy_response: {
            rentals: items, // Pass the updated array
          },
        },
      ],
    };

    try {
      // Send PUT request to update order in backend
      await axiosInstance.put(`/bucket-list/${tripId}/update/rv`, payload);

      // Update state only after successful response

      toast({
        title: "Order updated successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating RV order:", error);
      toast({
        title: "Error updating order",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Function to handle RV deletion
  const handleRVDelete = async (rv) => {
    const updatedRVs = rvs.filter((r) => r !== rv);
    setRVs(updatedRVs); // Update state with filtered RVs

    // Payload with updated rentals list
    const payload = {
      outdoorsy_api_response: [
        {
          location,
          outdoorsy_response: {
            rentals: updatedRVs,
          },
        },
      ],
    };

    try {
      // Send PUT request to update list in backend after deletion
      const response = await axiosInstance.put(
        `/bucket-list/${tripId}/update/rv`,
        payload
      );
      if (response.status === 200) {
        toast({
          title: "RV removed successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error deleting RV:", error);
      toast({
        title: "Error deleting RV",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Function to add a new accommodation
  const [currentStep, setCurrentStep] = useState(0);
  const [title, setTitle] = useState(""); // Empty string for text input
  const [perDay, setPerDay] = useState(null); // Null for optional or unfilled number

  const handleNextStep = async () => {
    if (currentStep < 1) {
      setCurrentStep(currentStep + 1);
    } else {
      // Construct the new accommodation object with required structure
      const newRVS = {
        vehicle_name: title,
        booking_url: "",
        price_per_day: parseFloat(perDay) || 0,
        price_per_week: 0,
        price_per_month: 0,
        rating: 0,
        reviews: 0,
        image_url: "",
      };

      try {
        const updatedRVs = [...rvs, newRVS];
        const payload = {
          outdoorsy_api_response: [
            {
              location,
              outdoorsy_response: {
                rentals: updatedRVs, // Pass the updated array
              },
            },
          ],
        };

        const response = await axiosInstance.put(
          `/bucket-list/${tripId}/update/rv`,
          payload
        );

        if (response.data.success) {
          setRVs(updatedRVs);
          toast({
            title: "New rvs added successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          // Reset form fields
          setTitle("");
          setPerDay(0);
          setCurrentStep(0);
        }
      } catch (error) {
        console.error("Error adding new rvs:", error);
        toast({
          title: "Error adding new rvs",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
      w="100%"
      h="100vh"
      overflowY="hidden"
    >
      <HStack justifyContent="space-between" bg="white">
        <Box
          flex="1"
          display="flex"
          justifyContent="left"
          p={4}
          pt={2}
          pb={2}
          pl={4}
          maxH="8vh"
          borderBottom="1px"
          borderLeft="1px"
          borderColor="blackAlpha.200"
        >
          <Text
            fontSize="md"
            fontWeight="bold"
            mt={1}
            pb={2}
            color="blackAlpha.700"
          >
            <Translate text="Update Accommodations" />
          </Text>
          <Spacer />
          <IconButton
            icon={<CloseIcon boxSize={2.5} />}
            onClick={handleCloseDetail}
            colorScheme="gray"
            size="sm"
            isRound={true}
            position="relative"
            zIndex="tooltip"
            bg="white"
            boxShadow="md"
          />
        </Box>
      </HStack>
      <Box h="89vh">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="rvs">
            {(provided) => (
              <VStack
                spacing={4}
                h="100%"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
                bg="white"
                align="stretch"
                p={4}
                m={2}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {rvs &&
                  rvs.length > 0 &&
                  rvs.map((rv, index) => {
                    // Convert price if needed
                    const convertedPrice = rv.price_per_day
                      ? convertCurrency(rv.price_per_day, "USD") // Assuming flight prices are in USD
                      : null;
                    return (
                      <Draggable
                        key={index}
                        draggableId={`${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <Box
                            position="relative"
                            bg="white"
                            borderRadius="lg"
                            boxShadow="md"
                            border="1px solid"
                            borderColor="gray.200"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            onMouseEnter={() => setHoveredItem(index)}
                            onMouseLeave={() => setHoveredItem(null)}
                          >
                            <Box
                              position="absolute"
                              top="-10px"
                              left="-10px"
                              bg="black"
                              color="white"
                              borderRadius="full"
                              width="24px"
                              height="24px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              fontSize="12px"
                              fontWeight="bold"
                            >
                              {index + 1}
                            </Box>
                            <Box
                              {...provided.dragHandleProps}
                              bg="gray.100"
                              alignContent="center"
                              visibility={
                                hoveredItem === index ? "visible" : "hidden"
                              }
                              h="100%"
                            >
                              <Image
                                src={GridIcon}
                                alt="Drag Handle"
                                boxSize="4"
                                opacity={hoveredItem === index ? 1 : 0.5}
                              />
                            </Box>
                            <VStack ml={1} w="full" p={4} pl={0} h="100%">
                              <HStack align="center" spacing={4} w="100%">
                                <VStack
                                  align="flex-start"
                                  spacing={1}
                                  flex="1"
                                  h="100%"
                                >
                                  <Text
                                    fontSize="18px"
                                    fontWeight="bold"
                                    mb={0}
                                  >
                                    {rv.vehicle_name}
                                  </Text>
                                  <Text fontSize="14px" color="gray.600" mb={0}>
                                    {currency}{" "}
                                    {convertedPrice
                                      ? convertedPrice
                                      : rv?.price_per_day}
                                      {" "}per night -{" "}
                                    {rv?.rating ? (
                                      `${rv.rating} ★`
                                    ) : (
                                      <Translate text="No Rating" />
                                    )}{" "}
                                    (
                                    {rv.reviews || (
                                      <Translate text="No reviews available" />
                                    )}
                                    )
                                  </Text>
                                </VStack>
                                {rv?.image_url && (
                                  <Image
                                    src={rv.image_url}
                                    alt={rv.vehicle_name}
                                    boxSize="120px"
                                    borderRadius="lg"
                                    objectFit="cover"
                                  />
                                )}
                              </HStack>
                              <HStack spacing={1} w="100%">
                                {rv.booking_url && (
                                  <Button
                                    as="a"
                                    href={rv.booking_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    size="sm"
                                    bg="#286328"
                                    _hover="none"
                                    _active="none"
                                    textColor="white"
                                    mt={2}
                                  >
                                    <Translate text="Book" />
                                  </Button>
                                )}
                                <Spacer />
                                <IconButton
                                  icon={<FaTrash />}
                                  aria-label="Delete RV"
                                  borderRadius="full"
                                  size="xs"
                                  visibility={
                                    hoveredItem === index ? "visible" : "hidden"
                                  }
                                  onClick={() => handleRVDelete(rv)}
                                />
                              </HStack>
                            </VStack>
                          </Box>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
                <InputGroup>
                  {currentStep === 0 && (
                    <Input
                      placeholder="Enter Vechicle Name"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}

                  {currentStep === 1 && (
                    <Input
                      placeholder="Enter Price Per Day"
                      type="number"
                      value={perDay}
                      onChange={(e) => setPerDay(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}

                  <InputRightElement>
                    <IconButton
                      onClick={handleNextStep}
                      bg="black"
                      color="white"
                      _hover="none"
                      size="sm"
                      icon={
                        currentStep < 1 ? <ArrowForwardIcon /> : <AddIcon />
                      }
                      aria-label={
                        currentStep < 1 ? "Next Step" : "Add Accommodation"
                      }
                    />
                  </InputRightElement>
                </InputGroup>
              </VStack>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};

export default RVsList;
