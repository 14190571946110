import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  Avatar,
  Text,
  Box,
  useToast,
} from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance"; // Adjust the path according to your project structure
import Translate from "../../components/LanguageTranslation/Translate";

const constructImageUrl = (filename) => {
  if (!filename) return null;

  // Replace backslashes with forward slashes and remove the leading "./"
  const cleanPath = filename.replace(/\\/g, "/").replace(/^\.\//, "");

  const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";
  const url = `${baseUrl}/${cleanPath}`;
  return url;
};

const FollowersModal = ({ isOpen, onClose }) => {
  const [followers, setFollowers] = useState([]);
  const toast = useToast();

  // Fetch followers data on modal open
  useEffect(() => {
    if (isOpen) {
      fetchFollowers();
    }
  }, [isOpen]);

  const fetchFollowers = async () => {
    try {
      const response = await axiosInstance.get("/social_media/followers");
      if (response.data.success) {
        setFollowers(response.data.data);
      } else {
        toast({
          title: "Error fetching followers",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error fetching followers",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleViewProfile = (socialId) => {
    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000"; // Use the base URL from .env file
    const profileUrl = `${baseUrl}/search-user/${socialId}`;

    // Open the profile in a new tab
    window.open(profileUrl, "_blank");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent maxH="78vh">
        <ModalHeader><Translate text="Followers" /></ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH="70vh" overflowY="auto" mb={4}>
          <VStack spacing={4} align="start">
            {followers.length > 0 ? (
              followers.map((follower) => (
                <Box
                  key={follower._id}
                  borderWidth="1px"
                  borderRadius="lg"
                  boxShadow="lg"
                  p={4}
                  w="100%"
                >
                  <HStack spacing={4} w="100%" align="center">
                    <Avatar
                      size="md"
                      src={constructImageUrl(follower.image_url)}
                      alt={follower.public_name}
                    />
                    <VStack align="start" flex="1" spacing={0}>
                      <Text fontWeight="bold" mb={0}>
                        {follower.public_name}
                      </Text>
                      <Text fontSize="sm" mb={0}>
                        {follower.bio || "No bio available"}
                      </Text>
                    </VStack>
                    <Button
                      bg="#286328"
                      color="white"
                      _hover="none"
                      size="xs"
                      onClick={() => handleViewProfile(follower._id)}
                    >
                       <Translate text="View Profile" />
                    </Button>
                  </HStack>
                </Box>
              ))
            ) : (
              <Text><Translate text="No followers found." /></Text>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FollowersModal;
