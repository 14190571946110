import React, { useState, useEffect } from "react";
import { Box, useColorMode, useToast } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import ChatHeader from "./ChatHeader";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import AuthModal from "../AuthModal";
import ItineraryDetails from "./ItineraryDetails";
import usePersistedState from "../usePersistedState";
import axiosInstance from "../../helpers/axiosInstance";
import profilePicture from "../../assets/images/Ava.png";
import backgroundImage from "../../assets/images/Ava.png";
import Translate from "../../components/LanguageTranslation/Translate";

const MAX_MESSAGES = 3; // Maximum number of messages a non-logged-in user can send

const Chat = () => {
  const { colorMode } = useColorMode();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [conversationId, setConversationId] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(null);
  const [tripPlan, setTripPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(null);
  const toast = useToast();
  const [isSignUpOpen, setSignUpOpen] = usePersistedState(
    "isSignUpOpen",
    false
  );
  const [lastUpdated, setLastUpdated] = useState(null);
  const [messageCount, setMessageCount] = useState(0); // State to hold the message count from backend

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initialMessage = params.get("message");
    const storedConversationId = localStorage.getItem("conversation_id");

    if (storedConversationId) {
      setConversationId(storedConversationId);
      fetchMessages(storedConversationId);
    } else if (initialMessage) {
      if (canSendMessage()) {
        const newMessage = {
          user_message: initialMessage,
          bot_message: "",
        };
        setIsLoading(true);
        setMessages([newMessage]);
        handleBotResponse([newMessage]);
      }
    }
  }, [location.search]);

  const canSendMessage = () => {
    const isAuthenticated = !!localStorage.getItem("REACT_TOKEN_AUTH_KEY");

    if (!isAuthenticated && messageCount >= MAX_MESSAGES) {
      setSignUpOpen(true);
      return false;
    }

    return true;
  };

  const fetchMessages = async (conversationId) => {
    try {
      const response = await axiosInstance.get(
        `/chat/conversation/${conversationId}`
      );
      
      if (response.status === 200) {
        console.log("Chat fetch responase", response.data.data);

        const fetchedMessages = response.data.data.conversation;
        const fetchedTripPlan = response.data.data.trip_plan || null;
        const lastUpdated = response.data.data.last_updated || "";
        const homeToGoResponse =
          response.data.data.home_to_go_api_response || [];
        const outdoorsyResponse =
          response.data.data.outdoorsy_api_response || [];
        const viatorResponse = response.data.data.viator_api_response || [];
        const flightInfo = response.data.data.flight_info || [];

        setMessageCount(response.data.data.conversation_counter); // Set message count from backend

        // Attach the tripPlan and viatorResponse to the last message if they exist
        if (fetchedTripPlan) {
          fetchedMessages[fetchedMessages.length - 1].tripPlan =
            fetchedTripPlan;
        }
        if (viatorResponse.length > 0) {
          fetchedMessages[fetchedMessages.length - 1].viatorResponse =
            viatorResponse;
        }
        if (homeToGoResponse.length > 0) {
          fetchedMessages[fetchedMessages.length - 1].homeToGoResponse =
            homeToGoResponse;
        }
        if (outdoorsyResponse.length > 0) {
          fetchedMessages[fetchedMessages.length - 1].outdoorsyResponse =
            outdoorsyResponse;
        }
        if (flightInfo.length > 0) {
          fetchedMessages[fetchedMessages.length - 1].flightInfo = flightInfo;
        }

        setMessages(fetchedMessages);
        setTripPlan(fetchedTripPlan);
        setLastUpdated(lastUpdated); // Set lastUpdated in state
      } else {
        console.error("Failed to fetch conversation:", response.data.message);
      }
    } catch (error) {
      setError("An error occurred while trying to fetch the conversation.");
      toast({
        title: "Error",
        description:
          "An error occurred while trying to fetch the conversation.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSendMessage = () => {
    if (message.trim() === "") return;

    if (canSendMessage()) {
      setIsLoading(true);
      const newMessage = {
        user_message: message,
        bot_message: "",
      };

      setMessage("");
      setMessages([...messages, newMessage]);
      handleBotResponse([...messages, newMessage]);
    }
  };

  const handleBotResponse = async (newMessages) => {
    let messageBody = {
      user_message: newMessages[newMessages.length - 1].user_message,
    };

    try {
      let response;
      if (conversationId) {
        response = await axiosInstance.post(
          `/chat/conversation/${conversationId}`,
          messageBody
        );
      } else {
        response = await axiosInstance.post("/chat/conversation", messageBody);
        if (response.data.success) {
          setConversationId(response.data.data.conversation_id);
          localStorage.setItem(
            "conversation_id",
            response.data.data.conversation_id
          );
        }
      }

      console.log("Chat Response", response.data.data);

      if (response.status === 200) {
        const lastUpdated = response.data.data.last_updated;
        newMessages[newMessages.length - 1].bot_message =
          response.data.data.bot_message;

        if (response.data.data.trip_plan) {
          newMessages[newMessages.length - 1].tripPlan =
            response.data.data.trip_plan;
        }
        if (response.data.data.home_to_go_api_response) {
          newMessages[newMessages.length - 1].homeToGoResponse =
            response.data.data.home_to_go_api_response;
        }
        if (response.data.data.outdoorsy_api_response) {
          newMessages[newMessages.length - 1].outdoorsyResponse =
            response.data.data.outdoorsy_api_response;
        }

        if (response.data.data.flight_info) {
          newMessages[newMessages.length - 1].flightInfo =
            response.data.data.flight_info;
        }
        if (
          response.data.data.viator_api_response &&
          response.data.data.viator_api_response.length > 0
        ) {
          newMessages[newMessages.length - 1].viatorResponse =
            response.data.data.viator_api_response;
        }

        setSuggestions(response.data.data.suggestion_button_list || []);
        setLastUpdated(lastUpdated || "");
        setMessageCount(response.data.data.conversation_counter); // Update message count from backend
        setMessages(newMessages);
      } else {
        console.error("Response status not successful:", response.status);
      }
    } catch (error) {
      setError("An error occurred while trying to send a message.");
      toast({
        title: "Error",
        description: "An error occurred while trying to send a message.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setIsLoading(true);
    if (canSendMessage()) {
      const newMessage = {
        user_message: suggestion,
        bot_message: "",
      };
      setMessage("");
      setMessages([...messages, newMessage]);
      handleBotResponse([...messages, newMessage]);
    }
  };

  const handleSelectItem = (item, type) => {
    setSelectedItem({ ...item, type });
  };

  const handleCloseDetail = () => {
    setSelectedItem(null);
  };

  return (
    <Box display="flex" bg="white" w="100%" h="100vh">
      <Box
        flex="5"
        w="50%"
        h="100vh"
        display="flex"
        flexDirection="column"
        ml={0}
        bg={colorMode === "dark" ? "gray.700" : "white"}
      >
        <ChatHeader conversationId={conversationId} />
        <Box
          flex="8"
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          maxH="72vh"
          bg={colorMode === "dark" ? "gray.700" : "white"}
        >
          <Box
            display="flex"
            bg={colorMode === "dark" ? "gray.700" : "white"}
            width="100%"
            height="100%"
            boxSizing="border-box"
            p={4}
            // overflowY="auto"
            overflowX="hidden"
            overflowY="auto"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <MessageList
              messages={messages}
              handleSelectItem={handleSelectItem}
              isLoading={isLoading}
              profilePicture={profilePicture}
            />
          </Box>
        </Box>
        <Box>
        <Box flex="5">
        <MessageInput
          message={message}
          setMessage={setMessage}
          handleSendMessage={handleSendMessage}
          colorMode={colorMode}
          suggestions={suggestions}
          handleSuggestionClick={handleSuggestionClick}
        />
        </Box>
        </Box>
        <AuthModal
          isOpen={isSignUpOpen}
          onClose={() => setSignUpOpen(false)}
          redirectPath="/ava/chat"
        />
      </Box>

      <Box
        flex="5"
        maxW="50vw"
        w="50%"
        h="100vh"
        overflowY="auto"
        backgroundImage={`url(${backgroundImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
        display="flex"
        flexDirection="column"
        borderLeftRadius="xl"
        boxLeftShadow="xl"
      >
        <Box borderLeftRadius="xl" boxLeftShadow="xl" h="100vh" w="100%">
          <ItineraryDetails
            tripPlan={selectedItem}
            onClose={handleCloseDetail}
            lastUpdated={lastUpdated}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Chat;
