import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  IconButton,
  VStack,
  Image,
  Button,
  useColorMode,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon, StarIcon } from "@chakra-ui/icons";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import axiosInstance from "../../helpers/axiosInstance";
import Translate from "../../components/LanguageTranslation/Translate";

const Activities = ({ data = [], location, onActivityClick }) => {
  const [likedActivities, setLikedActivities] = useState({});
  const [current, setCurrent] = useState(0);
  const { colorMode } = useColorMode();

  const numVisibleCards = 2;
  const numTotalCards = data.length;

  if (!data || numTotalCards === 0) {
    return <Text mt={2}>No activities available</Text>;
  }

  const next = () => {
    setCurrent((prev) => (prev + numVisibleCards) % numTotalCards);
  };

  const prev = () => {
    setCurrent(
      (prev) => (prev - numVisibleCards + numTotalCards) % numTotalCards
    );
  };

  const sendLikedActivity = async (activity, isLiked) => {
    const payload = {
      viator_api_response: [
        {
          location: location,
          viator_response: [activity], // Send only the current liked/unliked activity
        },
      ],
      conversation_type: "places",
    };
    try {
      await axiosInstance.post(
        "/bucket-list/save-third-party-service",
        payload
      );
      // console.log(`Activity ${isLiked ? "liked" : "unliked"}:`, activity);
    } catch (error) {
      console.error("Error saving liked activity:", error);
    }
  };

  const toggleLike = (index, activity) => {
    const updatedLikes = { ...likedActivities };
    const isLiked = !updatedLikes[index];

    if (isLiked) {
      updatedLikes[index] = activity; // Add activity if liked
    } else {
      delete updatedLikes[index]; // Remove activity if unliked
    }

    setLikedActivities(updatedLikes);

    // Send only the current activity to backend
    sendLikedActivity(activity, isLiked);
  };

  const hasPrev = current > 0;
  const hasNext = current + numVisibleCards < numTotalCards;

  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      padding={0}
      h="auto"
      w="100%"
    >
      {hasPrev && (
        <IconButton
          icon={<ArrowBackIcon />}
          isRound={true}
          size="md"
          onClick={prev}
          position="absolute"
          left="10px"
          backgroundColor="white"
          boxShadow="base"
          zIndex="overlay"
        />
      )}
      <HStack overflow="hidden" width="full" spacing={4}>
        {data.slice(current, current + numVisibleCards).map((item, index) => {
          const actualIndex = current + index;
          const isLiked = likedActivities[actualIndex] !== undefined;

          return (
            <Box
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              key={actualIndex}
              width="50%"
              onClick={() =>
                onActivityClick &&
                onActivityClick(
                  item.attractionLatitude,
                  item.attractionLongitude
                )
              }
            >
              <VStack
                padding={2}
                spacing={0}
                borderRadius="xl"
                width="100%"
                display="flex"
                flexDirection="column"
                bg="blackAlpha.50"
                height="100%"
                position="relative"
              >
                <Image
                  src={item.thumbnailHiResURL}
                  fallbackSrc="https://via.placeholder.com/300x200.png?text=No+Image"
                  alt={item.title}
                  borderTopRadius="xl"
                  objectFit="cover"
                  width="100%"
                  height="23vh"
                />
                <IconButton
                  aria-label="Add to favorites"
                  icon={isLiked ? <FaHeart /> : <FaRegHeart />}
                  size="md"
                  color={isLiked ? "red.500" : "white"}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleLike(actualIndex, item);
                  }}
                  position="absolute"
                  top="3px"
                  right="3px"
                  borderRadius="full"
                  bg="none"
                  _hover={{ bg: "none" }}
                />

                <VStack
                  spacing={1}
                  alignItems="start"
                  p={3}
                  bg="white"
                  w="100%"
                  h="40%"
                  justifyContent="space-between"
                  borderBottomRadius="xl"
                >
                  <Text
                    fontWeight="bold"
                    fontSize="sm"
                    mb={0}
                    overflow="hidden"
                    w="100%"
                    noOfLines={1}
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.title}
                  </Text>
                  <HStack spacing={2} mb={0}>
                    {item.rating ? (
                      <>
                        <StarIcon boxSize={3} color="#3A4135" mb={0} />
                        <Text mb={0} fontSize="sm">
                          {item.rating}
                        </Text>
                      </>
                    ) : (
                      <Text fontSize="sm" mb={0}>
                        <Translate text="Rating not available" />
                      </Text>
                    )}
                  </HStack>
                  {item.webURL && (
                    <Button
                      as="a"
                      href={item.webURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="sm"
                      bg="#286328"
                      _hover="none"
                      _active="none"
                      textColor="white"
                      width="full"
                      mt={2}
                    >
                      <Translate text="Book" />
                    </Button>
                  )}
                </VStack>
              </VStack>
            </Box>
          );
        })}
      </HStack>
      {hasNext && (
        <IconButton
          icon={<ArrowForwardIcon />}
          isRound={true}
          size="md"
          onClick={next}
          position="absolute"
          right="10px"
          backgroundColor="white"
          boxShadow="base"
          zIndex="overlay"
        />
      )}
    </Flex>
  );
};

export default Activities;
