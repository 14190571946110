import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  VStack,
  useToast,
  Spinner,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { FaKey, FaCheckCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';
import axiosInstance from "../../helpers/axiosInstance";
import { useOTP } from './OTPContext';

const MotionBox = motion(Box);
const MotionButton = motion(Button);

const AdminPanelAccess = () => {
  const [otpRequested, setOtpRequested] = useState(false);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();
  const { verifyOTP } = useOTP();
  const toast = useToast();

  const requestOtp = () => {
    setLoading(true);
    axiosInstance.post('/two_fctor/2fa/request', {})
      .then(response => {
        setLoading(false);
        setOtpRequested(true);
        toast({
          title: "OTP Requested",
          description: response.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(error => {
        setLoading(false);
        setError(error.response.data.message || "Something went wrong");
      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance.post('/two_fctor/2fa/verify', { otp })
      .then(response => {
        setLoading(false);
        if (response.data.token) {
          verifyOTP(response.data.token); // Save the token
          history.push('/admin_panel');
          toast({
            title: "Verified",
            description: response.data.message,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError("Verification failed: No token received.");
        }
      })
      .catch(error => {
        setLoading(false);
        setError(error.response.data.message || "Invalid OTP");
      });
  };

  return (
    <MotionBox
      p={8}
      maxWidth="50%"
      mx="auto"
      mt={12}
      borderRadius="md"
      boxShadow="xl"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <Heading as="h2" size="xl" textAlign="center" mb={6} display="flex" alignItems="center" justifyContent="center">
        <FaKey style={{ marginRight: '8px' }} />
        Two Factor Verification
      </Heading>
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}
      {!otpRequested ? (
        <VStack spacing={4}>
          <Text>Please request an OTP.</Text>
          <MotionButton
            colorScheme="blue"
            onClick={requestOtp}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            isLoading={loading}
            leftIcon={<FaKey />}
          >
            Request OTP
          </MotionButton>
        </VStack>
      ) : (
        <form onSubmit={verifyOtp}>
          <VStack spacing={4}>
            <FormControl id="otp">
              <FormLabel>OTP</FormLabel>
              <Input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter your OTP"
                disabled={loading}
              />
            </FormControl>
            <MotionButton
              type="submit"
              colorScheme="blue"
              width="full"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              isLoading={loading}
              leftIcon={<FaCheckCircle />}
            >
              Verify OTP
            </MotionButton>
            {loading && <Spinner size="lg" />}
          </VStack>
        </form>
      )}
    </MotionBox>
  );
};

export default AdminPanelAccess;
