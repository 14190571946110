import React, { useState, useEffect } from "react";
import { Box, Button, Text, Spacer, useColorMode } from "@chakra-ui/react";
import SettingsMenu from "../../components/common/SettingsMenu";

const FeedbackHeader = ({ handleDownload }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      pb={0}
      maxH="8vh"
      borderBottom="1px"
      borderColor="blackAlpha.200"
      overflowY="hidden"
    >
      <Box
        display="flex"
        alignItems="center"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        width="100%"
        height="100%"
        p={4}
        boxSizing="border-box"
        overflowY="hidden"
      >
        <Text
          fontSize="md"
          fontWeight="bold"
          mb={0}
          mt={0}
          color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
        >
          Feedback Responses
        </Text>
        <Spacer />
        {/* Download button */}
        <Button
          onClick={handleDownload}
          bg="#286328"
          borderColor="#286328"
          color="white"
          _hover="none"
          size="sm"
        >
          Download Feedbacks
        </Button>
        <SettingsMenu />
      </Box>
    </Box>
  );
};

export default FeedbackHeader;
