import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Button,
  Wrap,
  WrapItem,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";

const CategoryModal = ({
  isOpen,
  onClose,
  isEditing,
  newCategoryName,
  setNewCategoryName,
  newValue,
  setNewValue,
  newCategoryValues,
  handleValueKeyPress,
  addValueToNewCategory,
  addOrEditCategory,
  isNameEditable,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent h="70vh">
      <ModalHeader>
        {isEditing ? "Edit Category" : "Add New Category"}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody overflowY="auto">
        <FormControl mb={4}>
          <FormLabel>Category Name</FormLabel>
          <Input
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            placeholder="Enter category name"
            borderRadius="full"
            isReadOnly={!isNameEditable} // Make the input read-only if isNameEditable is false
          />
        </FormControl>

        <FormControl>
          <FormLabel>Category Values</FormLabel>
          <HStack>
            <Input
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
              placeholder="Add a value"
              borderRadius="full"
              onKeyPress={handleValueKeyPress}
            />
            <Button
              onClick={addValueToNewCategory}
              borderRadius="full"
              size="sm"
              bg="black"
              _hover="none"
              textColor="white"
            >
              +
            </Button>
          </HStack>
        </FormControl>

        <Wrap spacing={2} mt={4}>
          {newCategoryValues.map((value, index) => (
            <WrapItem key={index}>
              <Tag
                borderRadius="full"
                variant="solid"
                bg="#286328"
                textColor="white"
              >
                <TagLabel p={2}>{value}</TagLabel>
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </ModalBody>

      <ModalFooter>
        <Button
          size="sm"
          bg="#286328"
          _hover="none"
          textColor="white"
          borderRadius="xl"
          onClick={addOrEditCategory}
        >
          Save
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default CategoryModal;
