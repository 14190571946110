import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  Select,
  Text,
  VStack,
  FormControl,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import axiosInstance from "../../helpers/axiosInstance";
import { useHistory } from "react-router-dom";
import banner from "../../assets/images/donation_banner.jpg"; // Use your background image here

const SUPPORTED_CURRENCIES = [
  "EUR",
  "USD",
  "GBP",
  "AUD",
  "CAD",
  "JPY",
  "CHF",
  "SEK",
  "NOK",
  "DKK",
  "ZAR",
];

const DonationForm = () => {
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const donationAmount = parseFloat(amount);

    if (isNaN(donationAmount) || donationAmount <= 0) {
      toast({ title: "Invalid donation amount", status: "warning" });
      return;
    }

    setLoading(true);
    try {
      const response = await axiosInstance.post("/donations/", {
        amount: donationAmount,
        currency,
      });
      const { payment_url } = response.data;

      if (payment_url) {
        window.location.href = payment_url;
      } else {
        throw new Error("Payment URL not found.");
      }
    } catch (error) {
      console.error("Error creating donation:", error);
      toast({ title: "Error creating donation", status: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      h="100vh"
      w="100vw"
      bgImage={`url(${banner})`}
      bgSize="cover"
      bgPosition="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={6}
    >
      {/* Heading and Subheading */}
      <VStack spacing={3} align="center" mb={10}>
        <Text fontSize="lg" fontWeight="bold" color="white" opacity={0.8}>
          Support the Green Planet
        </Text>
        <Text fontSize="4xl" fontWeight="bold" color="white" textAlign="center">
        Join us in making a difference for our planet.
        </Text>
      </VStack>

      {/* Donation Form */}
      <Box
        w="100%"
        maxW="400px"
        bg="rgba(0, 0, 0, 0.6)"
        borderRadius="lg"
        p={6}
        color="white"
      >
        <VStack align="start" spacing={6}>
          <HStack w="100%" spacing={4}>
            <FormControl id="currency" w="30%">
              <Select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                bg="white"
                color="black"
                borderRadius="full"
                required
              >
                {SUPPORTED_CURRENCIES.map((cur) => (
                  <option key={cur} value={cur}>
                    {cur}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl id="amount" w="70%">
              <Input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter amount"
                bg="white"
                color="black"
                borderRadius="full"
                required
              />
            </FormControl>
          </HStack>

          <Button
            type="submit"
            colorScheme="green"
            isLoading={loading}
            w="100%"
            onClick={handleSubmit}
            mt={4}
            rightIcon={<ArrowForwardIcon />}
          >
            Donate Now
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default DonationForm;
