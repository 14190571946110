import React from "react";
import {
  Box,
  Text,
  Icon,
  Button,
  HStack,
  Divider,
  useColorMode,
} from "@chakra-ui/react";
import { FaHeart } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Translate from "../../components/LanguageTranslation/Translate";

const NoBucketListMessage = () => {
  const { colorMode } = useColorMode();
  const history = useHistory();

  const redirectToChat = () => {
    history.push("/ava/chat"); // Replace '/chat' with the actual path to your chat page
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      w="100%"
      h="100%"
      bg={colorMode === "dark" ? "gray.800" : "white"}
    >
      <Box textAlign="center" mb={4}>
        <Icon
          as={FaHeart}
          boxSize={12}
          color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
        />
        <Text fontSize="2xl" fontWeight="bold" mt={2} color="#1A202C" mb={0}>
          <Translate text="Your Bucketlist is Empty" />
        </Text>
        <Text
          fontSize="md"
          mt={2}
          color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
          mb={0}
        >
          <Translate text="It looks like you haven't planned any trips yet." />
        </Text>

        <Button
          mt={6}
          bg="#286328"
          color="white"
          _hover="none"
          borderRadius="lg"
          onClick={redirectToChat}
        >
          <Translate text="Plan a trip" />
        </Button>
      </Box>
    </Box>
  );
};

export default NoBucketListMessage;
