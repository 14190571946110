import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  VStack,
  useColorMode,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { FaWallet } from "react-icons/fa";
import axiosInstance from "../../helpers/axiosInstance";
import ExpensesList from "./ExpensesList";
import CategoryList from "./CategoryList";
import BudgetMenu from "./BudgetMenu";
import ExpenseChart from "./ExpenseChart";
import ExpenseModal from "./ExpenseModal";
import usePersistedState from "../usePersistedState";
import Translate from "../../components/LanguageTranslation/Translate";

const BudgetPlanner = () => {
  const [trips, setTrips] = useState([]);
  const [selectedTrip, setSelectedTrip] = usePersistedState(
    "selectedTripBudgetPlanner",
    ""
  );
  const [categories, setCategories] = useState([]);
  const [tripName, setTripName] = useState("");
  const [expenses, setExpenses] = useState([]);
  const [view, setView] = useState("stats"); // Track view: "stats", "expenses", "categories"

  const [newCost, setNewCost] = useState("");
  const [newExpenseName, setNewExpenseName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState({
    value: "USD",
    label: "USD",
  });
  const [editingExpenseIndex, setEditingExpenseIndex] = useState(null);
  const { colorMode } = useColorMode();
  const history = useHistory();

  const redirectToChat = () => {
    history.push("/ava/chat"); // Replace '/chat' with the actual path to your chat page
  };

  const redirectToBucketList = () => {
    history.push("/bucketlists"); // Replace '/chat' with the actual path to your chat page
  };

  // Separate modal state for ExpensesList
  const {
    isOpen: isExpenseModalOpen,
    onOpen: onOpenExpenseModal,
    onClose: onCloseExpenseModal,
  } = useDisclosure();

  // Separate modal state for CategoryList
  const {
    isOpen: isCategoryModalOpen,
    onOpen: onOpenCategoryModal,
    onClose: onCloseCategoryModal,
  } = useDisclosure();

  const [newCategoryName, setNewCategoryName] = useState("");

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await axiosInstance.get("/bucket-list/");
        setTrips(response.data.data); // Set trips from the API response
      } catch (error) {
        console.error("Error fetching trips:", error);
      }
    };

    fetchTrips();
  }, []);

  // Function to add a new category
  const addCategory = async () => {
    try {
      await axiosInstance.post(
        `/budget_planner/${selectedTrip}/create_category`,
        { name: newCategoryName }
      );
      setNewCategoryName(""); // Clear input field after adding the category
      onCloseCategoryModal(); // Close the modal after adding
      // Re-fetch categories to update the list
      const response = await axiosInstance.get(
        `/budget_planner/${selectedTrip}/categories`
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const addExpense = async () => {
    if (newExpenseName.trim() && selectedCategory && newCost > 0) {
      // console.log("Adding expense", selectedDate);
      // console.log("Adding expense", selectedLocation);
      // console.log("Adding expense selectedCurrency", selectedCurrency.value);
      // const expense_date = selectedDate.toISOString().split('T')[0];

      if (editingExpenseIndex !== null) {
        // Update existing expense
        try {
          await axiosInstance.put(
            `/budget_planner/${selectedTrip}/update_expense`,
            {
              category: selectedCategory,
              expense_name: newExpenseName,
              expense_currency: selectedCurrency.value,
              new_cost: parseFloat(newCost),
              expense_date: selectedDate,
              expense_location: selectedLocation,
            }
          );
          setEditingExpenseIndex(null);
        } catch (error) {
          console.error("Error updating expense:", error);
        }
      } else {
        // Add new expense
        try {
          await axiosInstance.post(
            `/budget_planner/${selectedTrip}/add_expense`,
            {
              category: selectedCategory,
              expense_name: newExpenseName,
              expense_currency: selectedCurrency,
              cost: parseFloat(newCost),
              expense_date: selectedDate,
              expense_location: selectedLocation,
            }
          );
        } catch (error) {
          console.error("Error adding expense:", error);
        }
      }

      // Re-fetch expenses to update the list
      const response = await axiosInstance.get(
        `/budget_planner/${selectedTrip}/expenses`
      );
      setExpenses(response.data);

      setNewExpenseName("");
      setNewCost("");
      setSelectedCategory(null);
      onCloseExpenseModal();
    }
  };

  const resetForm = () => {
    setNewExpenseName("");
    setNewCost("");
    setSelectedCategory(null);
    setEditingExpenseIndex(null);
    onCloseExpenseModal();
  };

  return (
    <Box display="flex" bg="white" w="100%" h="100vh" overflowY="hidden">
      {trips.length === 0 ? (
        <Flex
          w="100%"
          h="100vh"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          {/* <Icon as={FaWallet} boxSize={12} color="#1A202C" mb={4} />{" "}
         
          <Text fontSize="xl" color="#1A202C">
            You haven't saved any trip yet
          </Text> */}
          <Box textAlign="center" mb={4}>
            <Icon
              as={FaWallet}
              boxSize={12}
              color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
            />
            <Text
              fontSize="2xl"
              fontWeight="bold"
              mt={2}
              color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
              mb={0}
            >
              <Translate text="Your Budget Planner is Empty" />
            </Text>
            <Text
              fontSize="md"
              mt={2}
              color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
              mb={0}
            >
              <Translate text="You don't have any trips planned at the moment." />
            </Text>
            <Text
              fontSize="md"
              mt={2}
              color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
              mb={0}
            >
              <Translate text="Start planning your next adventure or enable your saved trip!" />
            </Text>
            <HStack alignContent="center" justifyContent="center">
              <Button
                mt={6}
                bg="#286328"
                color="white"
                _hover="none"
                borderRadius="lg"
                onClick={redirectToChat}
              >
                <Translate text="Plan a trip" />
              </Button>
              <Divider
                orientation="vertical"
                h="40px"
                borderColor={colorMode === "dark" ? "black" : "black"}
                mt={6}
                mb={0}
              />
              <Button
                mt={6}
                bg="#286328"
                color="white"
                _hover="none"
                borderRadius="lg"
                onClick={redirectToBucketList}
              >
                <Translate text="Bucket list" />
              </Button>
            </HStack>
          </Box>
        </Flex>
      ) : (
        <>
          <BudgetMenu
            selectedTrip={selectedTrip}
            setSelectedTrip={setSelectedTrip}
            tripName={tripName}
            setTripName={setTripName}
            setView={setView} // Pass setView to switch between views
            onOpen={onOpenExpenseModal} // Trigger the expense modal when "Add Expense" is clicked
            onOpenCategoryModal={onOpenCategoryModal}
          />

          {/* Render ExpenseChart, ExpensesList, and CategoryList only if a trip is selected */}
          {selectedTrip && (
            <>
              {view === "stats" && (
                <ExpenseChart
                  total={3748}
                  expenses={expenses}
                  setExpenses={setExpenses}
                  categories={categories}
                  setCategories={setCategories}
                  selectedTripId={selectedTrip}
                />
              )}

              {view === "expenses" && (
                <ExpensesList
                  expenses={expenses}
                  setExpenses={setExpenses}
                  categories={categories}
                  selectedTripId={selectedTrip}
                  isOpen={isExpenseModalOpen}
                  onOpen={onOpenExpenseModal}
                  onClose={onCloseExpenseModal}
                  addExpense={addExpense}
                  newExpenseName={newExpenseName}
                  setNewExpenseName={setNewExpenseName}
                  newCost={newCost}
                  setNewCost={setNewCost}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  setEditingExpenseIndex={setEditingExpenseIndex}
                />
              )}

              {view === "categories" && (
                <CategoryList
                  categories={categories}
                  setCategories={setCategories}
                  selectedTripId={selectedTrip}
                />
              )}
            </>
          )}

          <ExpenseModal
            isOpen={isExpenseModalOpen}
            onClose={resetForm}
            addExpense={addExpense}
            categories={categories}
            newExpenseName={newExpenseName}
            setNewExpenseName={setNewExpenseName}
            newCost={newCost}
            setNewCost={setNewCost}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
          />

          {/* Modal for adding a new category */}
          <Modal isOpen={isCategoryModalOpen} onClose={onCloseCategoryModal}>
            <ModalOverlay />
            <ModalContent p={4}>
              <ModalHeader>Add New Category</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack spacing={4} align="stretch">
                  <Input
                    placeholder="Enter new category name"
                    bg="white"
                    borderRadius="md"
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                  />
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button
                  // bg="#286328"
                  bg="#286328"
                  color="white"
                  _hover="none"
                  borderRadius="xl"
                  w="full"
                  onClick={addCategory}
                >
                  Add Category
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default BudgetPlanner;
