import {
  FaTshirt,
  FaToiletPaper,
  FaSuitcase,
  FaPlug,
  FaUserFriends,
} from "react-icons/fa";

export const initialCategoryItems = {
  Essentials: [
    { name: "Passport", completed: false },
    { name: "Wallet", completed: false },
    { name: "Keys", completed: false },
  ],
  Clothes: [
    { name: "T-shirt", completed: false },
    { name: "Jeans", completed: true },
    { name: "Sweater", completed: true },
    { name: "Underwear", completed: false },
    { name: "Socks", completed: false },
    { name: "Jacket", completed: false },
  ],
  Toiletries: [
    { name: "Toothbrush", completed: false },
    { name: "Toothpaste", completed: false },
    { name: "Shampoo", completed: false },
  ],
  Electronics: [
    { name: "Phone Charger", completed: false },
    { name: "Laptop", completed: false },
  ],
  "Shared packing": [
    { name: "First aid kit", completed: false },
    { name: "Snacks", completed: false },
  ],
};

export const members = [
  { value: "personal", name: "Personal" },
  { value: "father", name: "Father" },
  { value: "mother", name: "Mother" },
  { value: "husband", name: "Husband" },
  { value: "wife", name: "Wife" },
  { value: "daughter", name: "Daughter" },
  { value: "son", name: "Son" },
  { value: "sister", name: "Sister" },
  { value: "brother", name: "Brother" },
  { value: "friend", name: "Friend" },
];

export const getIconForCategory = (category) => {
  switch (category) {
    case "Essentials":
      return FaSuitcase;
    case "Clothes":
      return FaTshirt;
    case "Toiletries":
      return FaToiletPaper;
    case "Electronics":
      return FaPlug;
    case "Shared packing":
      return FaUserFriends;
    default:
      return FaSuitcase;
  }
};

export const calculatePercentage = (categoryItems) => {
  if (!categoryItems || !Array.isArray(categoryItems)) return 0;
  const totalItems = categoryItems.length;
  const completedItems = categoryItems.filter((item) => item.completed).length;
  return totalItems === 0 ? 0 : (completedItems / totalItems) * 100;
};
