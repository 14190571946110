import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  useToast,
  HStack,
  IconButton,
  Spacer,
  useColorMode,
} from "@chakra-ui/react";
import { ArrowBackIcon, DeleteIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import SettingsMenu from "../../components/common/SettingsMenu";
import axiosInstance from "../../helpers/axiosInstance";

const BlogTitlesList = () => {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const [blogs, setBlogs] = useState([]);
  const toast = useToast();

  useEffect(() => {
    fetchAllBlogs();
  }, []);

  const fetchAllBlogs = async () => {
    try {
      const response = await axiosInstance.get("/blogs/");
      if (response.data.success) {
        setBlogs(response.data.data);
      } else {
        throw new Error(response.data.message || "Failed to fetch blogs");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBlogClick = (id) => {
    history.push(`/admin/update-blog/${id}`);
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation(); // Prevent the card from being clicked

    try {
      const response = await axiosInstance.delete(`/blogs/${id}`);
      if (response.data.success) {
        toast({
          title: "Deleted",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // Remove the deleted blog from the state
        setBlogs(blogs.filter((blog) => blog._id !== id));
      } else {
        throw new Error(response.data.message || "Failed to delete blog");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box h="100vh" overflowY="auto" bg="#F5F7F9">
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        maxH="8vh"
        borderBottom="1px"
        borderColor="blackAlpha.200"
        overflowY="hidden"
        bg="white"
      >
        <Box
          display="flex"
          alignItems="center"
          bg={colorMode === "dark" ? "gray.700" : "white"}
          width="100%"
          height="100%"
          p={4}
          pt={2}
          pl={0}
          pb={2}
          boxSizing="border-box"
          overflowY="hidden"
        >
          <IconButton
            icon={<ArrowBackIcon />}
            variant="ghost"
            _hover="none"
            onClick={() => history.goBack()}
          />
          <Text
            fontSize="md"
            fontWeight="bold"
            mb={0}
            mt={0}
            color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
          >
            All Blogs
          </Text>
          <Spacer />
          <SettingsMenu />
        </Box>
      </Box>
      <VStack
        align="start"
        spacing={0}
        m={4}
        bg="white"
        border="1px"
        h="86vh"
        borderColor="blackAlpha.200"
        borderRadius="md"
        boxShadow="md"
        overflowY="auto"
      >
        {blogs.map((blog) => (
          <Box
            w="100%"
            key={blog._id}
            borderBottom="1px"
            borderColor="blackAlpha.200"
            onClick={() => handleBlogClick(blog._id)}
            cursor="pointer"
            position="relative"
          >
            <HStack justifyContent="space-between" p={4}>
              <Text fontWeight="bold" fontSize="md" mb={0}>
                {blog.seo_title}
              </Text>
              <IconButton
                icon={<DeleteIcon />}
                bg="none"
                _hover="none"
                size="sm"
                onClick={(e) => handleDelete(e, blog._id)}
                aria-label="Delete blog"
              />
            </HStack>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default BlogTitlesList;
