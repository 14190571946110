import React, { useState, useCallback } from "react";
import {
  Avatar,
  Button,
  VStack,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Input,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage"; // Utility function for cropping the image
import "./main.css";

const ProfilePictureUpload = ({ initialSrc, onSave, isDisabled }) => {
  const [imageFile, setImageFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  const handleSave = async () => {
    if (imageFile) {
      try {
        const croppedImageBlob = await getCroppedImg(
          URL.createObjectURL(imageFile),
          croppedAreaPixels
        );

        // Convert the blob to a file and pass it to the onSave function
        const croppedImageFile = new File([croppedImageBlob], imageFile.name, {
          type: imageFile.type,
        });
        onSave(croppedImageFile);
        onClose();
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to crop the image.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleAvatarClick = () => {
    // Only allow the modal to open if not disabled
    if (!isDisabled) {
      onOpen();
    }
  };

  return (
    <>
      {/* Display the avatar, but only allow clicking to upload when not disabled */}
      <Avatar
        size="xl"
        src={initialSrc}
        onClick={handleAvatarClick} // Only trigger modal if not disabled
        cursor={isDisabled ? "default" : "pointer"} // Change cursor if disabled
      />
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Profile Picture</ModalHeader>
          <ModalCloseButton />
          <ModalBody h="60vh">
            <VStack h="55vh" alignItems="center" justifyContent="center">
              {!imageFile && (
                <Button
                  onClick={() => document.getElementById("file-upload").click()}
                >
                  Choose Image
                </Button>
              )}
              <Input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                display="none"
                id="file-upload"
              />
              {imageFile && (
                <div className="crop-container">
                  <Cropper
                    image={URL.createObjectURL(imageFile)}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                    h="50vh"
                  />
                </div>
              )}
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                {imageFile && (
                  <Button
                    onClick={() =>
                      document.getElementById("file-upload").click()
                    }
                  >
                    Choose Image
                  </Button>
                )}
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            {imageFile && (
              <Button
                bg="#286328"
                color="white"
                _hover="none"
                onClick={handleSave}
              >
                Save
              </Button>
            )}
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfilePictureUpload;
