import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  useColorMode,
  Spacer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  TableContainer,
  IconButton,
} from "@chakra-ui/react";
import { AiOutlineFileExcel } from "react-icons/ai"; // Excel icon for download
import SettingsMenu from "../common/SettingsMenu";
import axiosInstance from "../../helpers/axiosInstance";

const Newsletter = () => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch all subscribed emails
  const fetchEmails = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get("/newsletter/");
      if (response.data) {
        setEmails(response.data.emails);
      } else {
        toast({
          title: "Error",
          description: "Failed to fetch emails",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching emails:", error);
      toast({
        title: "Error",
        description: "An error occurred while fetching emails.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch emails on component mount
  useEffect(() => {
    fetchEmails();
  }, []);

  // Handle file download
  const handleDownload = async () => {
    try {
      const response = await axiosInstance.get("/newsletter/download", {
        responseType: "blob", // Important for handling files
      });

      const filename = response.headers["content-disposition"]
        ?.split("filename=")[1]
        ?.replace(/['"]/g, ""); // Extract the filename from the headers

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Use the filename from response
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Clean up after download

      toast({
        title: "Download Successful",
        description: `File "${filename}" has been downloaded.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error downloading file:", error);
      toast({
        title: "Error",
        description: "An error occurred while downloading the file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box h="100vh" bg="white">
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        maxH="8vh"
        borderBottom="1px"
        borderColor="blackAlpha.200"
        overflowY="hidden"
      >
        <Box
          display="flex"
          alignItems="center"
          bg={colorMode === "dark" ? "gray.700" : "white"}
          width="100%"
          height="100%"
          p={4}
          pt={2}
          pb={2}
          boxSizing="border-box"
          overflowY="hidden"
        >
          <Text
            fontSize="md"
            fontWeight="bold"
            mb={0}
            mt={0}
            color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
          >
            Newsletters Subscriptions
          </Text>
          <Spacer />
          <IconButton
            aria-label="Download Excel"
            icon={<AiOutlineFileExcel />}
            color="green"
            bg="none"
             _hover="none"
            size="lg"
            onClick={handleDownload}
            isLoading={isLoading}
          />
          <SettingsMenu />
        </Box>
      </Box>

      {/* Table to display emails */}
      <Box p={4} h="90vh" overflowY="auto">
        <TableContainer w="50%" mx="auto"> {/* Centered Table */}
          <Table variant="simple" border="1px" borderColor="gray.200">
            <Thead>
              <Tr>
                <Th border="1px" borderColor="gray.300">
                  Email Address
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {emails.map((emailObj, index) => (
                <Tr key={index}>
                  <Td border="1px" borderColor="gray.300">
                    {emailObj.email}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Newsletter;
