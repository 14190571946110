import React, { useState } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  Button,
  HStack,
  Icon,
  Flex,
  useColorMode,
  IconButton,
} from "@chakra-ui/react";
import { FaBed } from "react-icons/fa";
import { ArrowBackIcon, ArrowForwardIcon, StarIcon } from "@chakra-ui/icons";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import axiosInstance from "../../helpers/axiosInstance";
import { useCurrency } from "../CurrencyExchange/CurrencyContext";
import Translate from "../../components/LanguageTranslation/Translate";

const HotelsCard = ({ hotels, location, onActivityClick }) => {
  // console.log("hotels", hotels);
  const { colorMode } = useColorMode();
  const [current, setCurrent] = useState(0);
  const [likedActivities, setLikedActivities] = useState({});
  const { convertCurrency, currency } = useCurrency(); // Get the conversion function and selected currency from context

  // Track current image index for each hotel
  const [imageIndices, setImageIndices] = useState(hotels.map(() => 0));

  // Check if hotels is defined and has length
  if (!hotels || hotels.length === 0) {
    return (
      <Text mt={2}>
        <Translate text="No hotels available" />
      </Text>
    ); // Don't render anything if hotels array is not defined or empty
  }

  const numVisibleCards = 2;
  const numTotalCards = hotels.length;

  const next = () => {
    setCurrent((prev) => (prev + numVisibleCards) % numTotalCards);
  };

  const prev = () => {
    setCurrent(
      (prev) => (prev - numVisibleCards + numTotalCards) % numTotalCards
    );
  };

  const hasPrev = current > 0;
  const hasNext = current + numVisibleCards < numTotalCards;

  const handleNextImage = (hotelIndex) => {
    setImageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      const currentHotel = hotels[hotelIndex];
      newIndices[hotelIndex] =
        (prevIndices[hotelIndex] + 1) % currentHotel.images.length;
      return newIndices;
    });
  };

  const handlePrevImage = (hotelIndex) => {
    setImageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      const currentHotel = hotels[hotelIndex];
      newIndices[hotelIndex] =
        (prevIndices[hotelIndex] - 1 + currentHotel.images.length) %
        currentHotel.images.length;
      return newIndices;
    });
  };

  const sendLikedActivity = async (hotel, isLiked) => {
    const payload = {
      home_to_go_api_response: [
        {
          location: location,
          home_to_go_response: [hotel], // Send only the current liked/unliked activity
        },
      ],
      conversation_type: "accommodations",
    };
    try {
      await axiosInstance.post(
        "/bucket-list/save-third-party-service",
        payload
      );
      // console.log(`Activity ${isLiked ? "liked" : "unliked"}:`, hotel);
    } catch (error) {
      console.error("Error saving liked activity:", error);
    }
  };

  const toggleLike = (index, hotel) => {
    const updatedLikes = { ...likedActivities };
    const isLiked = !updatedLikes[index];

    if (isLiked) {
      updatedLikes[index] = hotel; // Add activity if liked
    } else {
      delete updatedLikes[index]; // Remove activity if unliked
    }

    setLikedActivities(updatedLikes);

    // Send only the current activity to backend
    sendLikedActivity(hotel, isLiked);
  };

  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      padding={0}
      mt={3}
      width="100%"
    >
      {hasPrev && (
        <IconButton
          icon={<ArrowBackIcon />}
          isRound={true}
          size="md"
          onClick={prev}
          position="absolute"
          left="10px"
          backgroundColor="white"
          boxShadow="base"
          zIndex="overlay"
        />
      )}
      <HStack
        overflow="hidden"
        width="full"
        h="auto"
        spacing={3}
        justifyContent="space-between"
      >
        {hotels
          .slice(current, current + numVisibleCards)
          .map((hotel, index) => {
            const actualIndex = current + index;
            const isLiked = likedActivities[actualIndex] !== undefined;
            // Add console log to debug the values here
            const convertedPrice = convertCurrency(
              hotel.price.perNight,
              hotel.price.currency
            );

            return (
              <Box
                key={index}
                bg="gray.100"
                padding={2}
                borderRadius="xl"
                width="50%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                onClick={() =>
                  onActivityClick &&
                  onActivityClick(
                    hotel.geoLocation?.lat,
                    hotel.geoLocation?.lon
                  )
                }
              >
                <Flex
                  alignContent="top"
                  justifyContent="space-between"
                  width="100%"
                >
                  <HStack spacing={2} mb={1}>
                    <Icon as={FaBed} color="#286328" boxSize={4} />
                    <Text
                      fontWeight="bold"
                      fontSize="sm"
                      mb={0}
                      noOfLines={1}
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {hotel.type.replace(/_/g, " ")}
                    </Text>
                  </HStack>
                </Flex>
                <Box
                  borderTopRadius="md"
                  boxShadow="md"
                  width="100%"
                  position="relative"
                >
                  <IconButton
                    icon={<ArrowBackIcon />}
                    size="sm"
                    onClick={() => handlePrevImage(index)}
                    position="absolute"
                    left="1px"
                    top="50%"
                    transform="translateY(-50%)"
                    zIndex="1"
                    color="white"
                    bg="rgba(0, 0, 0, 0.6)"
                    _hover="none"
                  />
                  <Image
                    src={`https:${
                      hotel.images[imageIndices[index]]?.large ||
                      "https://via.placeholder.com/300x200.png?text=No+Image"
                    }`}
                    fallbackSrc="https://via.placeholder.com/300x200.png?text=No+Image"
                    alt={hotel.title || "Hotel"}
                    borderTopRadius="xl"
                    objectFit="cover"
                    width="100%"
                    height="200px"
                  />
                  <IconButton
                    aria-label="Add to favorites"
                    icon={isLiked ? <FaHeart /> : <FaRegHeart />}
                    size="md"
                    color={isLiked ? "red.500" : "white"}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleLike(actualIndex, hotel);
                    }}
                    position="absolute"
                    top="0px"
                    right="0px"
                    borderRadius="full"
                    bg="none"
                    _hover={{ bg: "none" }}
                  />
                  <IconButton
                    icon={<ArrowForwardIcon />}
                    size="sm"
                    onClick={() => handleNextImage(index)}
                    position="absolute"
                    right="1px"
                    top="50%"
                    transform="translateY(-50%)"
                    zIndex="1"
                    bg="rgba(0, 0, 0, 0.6)"
                    color="white"
                    _hover="none"
                  />
                </Box>
                <VStack
                  alignItems="flex-start"
                  width="100%"
                  p={3}
                  spacing={2}
                  textAlign="left"
                  bg="white"
                  borderBottomRadius="xl"
                >
                  <Text
                    fontWeight="bold"
                    fontSize="md"
                    noOfLines={1}
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    mb={0}
                  >
                    {hotel.title}
                  </Text>
                  {hotel.rating?.value ? (
                    <HStack spacing={1}>
                      <StarIcon boxSize={3} color="#3A4135" mb={0} />
                      <Text
                        color="#1A202C"
                        fontWeight="bold"
                        fontSize="sm"
                        mb={0}
                      >
                        {(hotel.rating.value / 10).toFixed(1)}
                      </Text>
                      <Text fontSize="xs" color="gray.500" mb={0}>
                        ({hotel.rating.value} reviews)
                      </Text>
                    </HStack>
                  ) : (
                    <Text color="gray.500" fontSize="sm" mb={0}>
                      <Translate text="No reviews available" />
                    </Text>
                  )}
                  <HStack spacing={1} alignItems="flex-start">
                    <Text
                      fontWeight="bold"
                      fontSize="sm"
                      color="gray.800"
                      mb={0}
                    >
                      {currency} {convertedPrice.toLocaleString()}{" "}
                    </Text>
                    <Text fontSize="sm" color="gray.800" mb={0}>
                      <Translate text="night" />
                    </Text>
                  </HStack>
                  <Button
                    as="a"
                    href={hotel.link}
                    target="_blank"
                    mt={2}
                    size="sm"
                    bg="#286328"
                    _hover="none"
                    _active="none"
                    textColor="white"
                    width="full"
                  >
                    <Translate text="Open" />
                  </Button>
                </VStack>
              </Box>
            );
          })}
      </HStack>
      {hasNext && (
        <IconButton
          icon={<ArrowForwardIcon />}
          isRound={true}
          size="md"
          onClick={next}
          position="absolute"
          right="10px"
          backgroundColor="white"
          boxShadow="base"
          zIndex="overlay"
        />
      )}
    </Flex>
  );
};

export default HotelsCard;
