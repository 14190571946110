import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Image,
  Flex,
  Stack,
  Link,
  VStack,
  IconButton,
  HStack,
  Input,
  InputGroup,
  Button,
  useToast, // Chakra's toast for notifications
  useDisclosure,
} from "@chakra-ui/react";
import axiosInstance from "../helpers/axiosInstance"; // Import Axios instance for API calls
import logo from "../assets/images/Newlogo-black.png";
import { FaInstagram } from "react-icons/fa";
import { EmailIcon } from "@chakra-ui/icons";
import profilePicture from "../assets/images/AvaAvatar.png";
import { ReactComponent as LinktreeIcon } from "../assets/icons/linktree_logo_icon.svg";
import { SurveyPopup } from "./Popups";
import { useTranslation } from "../components/LanguageTranslation/TranslationContext";
import Translate from "../components/LanguageTranslation/Translate";

const Footer = () => {
  const history = useHistory();
  const { useTranslate } = useTranslation();
  const [email, setEmail] = useState(""); // State for storing email input
  const toast = useToast(); // For notifications
  const {
    isOpen: isSurveyOpen,
    onOpen: onOpenSurvey,
    onClose: onCloseSurvey,
  } = useDisclosure(); // Renamed this for clarity

  const handleSurveyPopupClose = () => {
    onCloseSurvey(); // Use the correct close function from `useDisclosure`
  };

  const handleLinkClick = (message) => {
    localStorage.removeItem("conversation_id"); // Clear any existing conversation
    history.push(`/ava/chat?message=${encodeURIComponent(message)}`); // Redirect to the chat system with the clicked text
  };

  const handleSubscribe = async () => {
    if (!email) {
      toast({
        title: "Email Required",
        description: "Please enter a valid email address.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axiosInstance.post("/newsletter/", { email });
      if (response.status === 201) {
        toast({
          title: "Subscribed!",
          description: "You've successfully subscribed to the newsletter.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setEmail(""); // Clear the email input after successful submission
      } else {
        toast({
          title: "Subscription Failed",
          description: "Unable to subscribe. Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error subscribing to newsletter:", error);
      toast({
        title: "Error",
        description: "An error occurred while subscribing. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const travelAdventureText = useTranslate("Plan a Travel Adventure");
  const campingTripText = useTranslate("Plan a Camping Trip");
  const roadTripText = useTranslate("Plan a Road Trip Adventure");
  const winterAdventureText = useTranslate(
    "Plan a Winter Adventure & Ski Trip"
  );
  const hikingExpeditionText = useTranslate("Plan a Hiking Expedition");
  const backpackingText = useTranslate("Plan a Backpacking");

  return (
    <Box p={8} borderTop="1px" borderColor="blackAlpha.100">
      <Flex
        justifyContent="space-between"
        alignItems="start"
        direction={{ base: "column", md: "row" }}
        wrap="wrap"
      >
        <Box flex="1.5" mb={{ base: 8, md: 0 }}>
          <Image
            src={logo}
            alt="Nomad Tours"
            mb={5}
            width={{ base: "100px", md: "150px" }}
            height={{ base: "auto", md: "auto" }}
          />
          <Text color="#4B5563" w="80%">
            <Text as="span" fontWeight="bold">
              Nomad Tours
            </Text>{" "}
            <Translate
              text="creates personalized travel plans in seconds, tailored to your
            outdoor lifestyle. Stress-free planning and epic adventures await!"
            />
          </Text>
        </Box>
        <Box flex="1" mb={{ base: 8, md: 0 }}>
          <Heading color="#374155" as="h4" size="md" mb={4}>
            <Translate text="Resources" />
          </Heading>
          <Stack>
            <Link color="#4B5563" href="/blogs">
              <Translate text="Blogs" />
            </Link>

            <Link color="#4B5563" onClick={onOpenSurvey}>
              <Translate text="Give us Feedback" />
            </Link>
            <Link color="#4B5563" href="/policies/terms-of-use">
              <Translate text="Terms of Use" />
            </Link>
            <Link color="#4B5563" href="/policies/privacy-policy">
              <Translate text="Privacy Policy" />
            </Link>
            <Link color="#4B5563" href="/disclaimer">
              <Translate text="Disclaimer" />
            </Link>
          </Stack>
          <HStack spacing={2} mt={3}>
            <IconButton
              as={Link}
              href="https://mail.google.com/mail/?view=cm&fs=1&to=info@nomadtours.io"
              aria-label="contact us"
              icon={<EmailIcon />}
              isRound
              bg="blackAlpha.100"
              mt={2}
              target="_blank"
              rel="noopener noreferrer"
            />
            <IconButton
              aria-label="Instagram"
              icon={<FaInstagram />}
              isRound
              bg="blackAlpha.100"
              mt={2}
              as="a"
              href="https://www.instagram.com/nomadtours.io/"
              target="_blank"
              rel="noopener noreferrer"
            />
            <IconButton
              aria-label="Linktree"
              icon={<LinktreeIcon style={{ width: "20px", height: "20px" }} />}
              isRound
              bg="blackAlpha.100"
              mt={2}
              as="a"
              href="https://linktr.ee/nomadtours"
              target="_blank"
              rel="noopener noreferrer"
            />
          </HStack>
        </Box>

        {/* Get started section */}
        <Box flex="1.5" mb={{ base: 8, md: 0 }}>
          <Heading color="#374155" as="h4" size="md" mb={4}>
            <Translate text="Get started" />
          </Heading>
          <Stack>
            <Link
              color="#4B5563"
              onClick={() => handleLinkClick(travelAdventureText)}
            >
              {travelAdventureText}
            </Link>
            <Link
              color="#4B5563"
              onClick={() => handleLinkClick(campingTripText)}
            >
              {campingTripText}
            </Link>
            <Link color="#4B5563" onClick={() => handleLinkClick(roadTripText)}>
              {roadTripText}
            </Link>
            <Link
              color="#4B5563"
              onClick={() => handleLinkClick(winterAdventureText)}
            >
              {winterAdventureText}
            </Link>
            <Link
              color="#4B5563"
              onClick={() => handleLinkClick(hikingExpeditionText)}
            >
              {hikingExpeditionText}
            </Link>
            <Link
              color="#4B5563"
              onClick={() => handleLinkClick(backpackingText)}
            >
              {backpackingText}
            </Link>
          </Stack>
        </Box>

        {/* Newsletter Subscription Section */}
        <Box flex="1.5">
          <VStack alignItems="left" spacing={3} width="100%">
            <HStack alignItems="center" spacing={4} mb={2}>
              <Image
                src={profilePicture}
                alt="Ask Ava"
                borderRadius="full"
                border="2px solid"
                borderColor="black"
                objectFit="cover"
                width={{ base: "40px", md: "60px" }}
                height={{ base: "40px", md: "60px" }}
              />
              <Heading
                color="#374155"
                as="h4"
                size={{ base: "sm", md: "md" }}
                mb={0}
              >
                <Translate text="Join the Green & Wild Community!" />
              </Heading>
            </HStack>
            <Text color="#4B5563" fontSize={{ base: "sm", md: "md" }} mb={0}>
              <Translate
                text="Subscribe to Nomad Tours' newsletter for personalized travel tips,
              epic outdoor experiences, and exclusive offers."
              />
            </Text>
            <InputGroup mt={2} width="100%" maxWidth="400px">
              <Input
                borderRadius="full"
                placeholder={Translate({
                  text: "Enter email",
                  as: "string",
                })}
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Update email state on change
              />
            </InputGroup>
            <Button
              bg="black"
              color="white"
              width="100%"
              maxWidth="400px"
              borderRadius="full"
              _hover={{ bg: "black" }}
              onClick={handleSubscribe} // Handle subscription click
            >
              <Translate text="Subscribe" />
            </Button>
          </VStack>
        </Box>
      </Flex>
      <Text color="#374155" textAlign="center" fontSize="sm" mt={8} mb={0}>
        {/* © 2024 Nomad Tours. All Rights Reserved. */}

        <Translate
          text="This work is governed by the licensing and distribution agreement with
        Lancewood AI Solutions B.V."
        />
      </Text>
      <Text color="#374155" textAlign="center" fontSize="sm" mb={0}>
        Nomad Tours™{" "}
        <Translate
          text="is a trademark for which a formal registration application
        has been filed."
        />
      </Text>
      <Text color="#374155" textAlign="center" fontSize="sm" mb={0}>
        © 2024 Nomad Tours.{" "}
        <Translate
          text=" All Rights Reserved. Unauthorized distribution,
        duplication, or reproduction of this material is strictly prohibited."
        />
      </Text>
      <SurveyPopup
        isOpen={isSurveyOpen}
        onClose={handleSurveyPopupClose} // Use the renamed function here
      />
    </Box>
  );
};

export default Footer;
