import React from "react";
import {
  Box,
  Text,
  IconButton,
  Spacer,
  useColorMode,
  Button,
} from "@chakra-ui/react";
import Translate from "../../components/LanguageTranslation/Translate";

const RightHeader = () => {
  const { colorMode } = useColorMode();

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      // pb={1}
      maxH="8vh"
      borderBottom="1px"
      borderColor="blackAlpha.200"
      overflowY="hidden"
    >
      <Box
        display="flex"
        alignItems="center"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        width="100%"
        height="100%"
        p={4}
        pt={0}
        pb={0}
        boxSizing="border-box"
        overflowY="hidden"
      >
        <Text
          fontSize="md"
          fontWeight="bold"
          mb={0}
          // mt={2}
          color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
        >
          <Translate text="Categories" />
        </Text>
        <Spacer />
      </Box>
    </Box>
  );
};

export default RightHeader;
