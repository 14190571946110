import React from 'react';
import { Button, Flex, Image, Spacer } from "@chakra-ui/react";
import googleLogo from "../../../assets/images/google.png";

const GoogleLoginButton = () => {
  
  return (
    <Button
      onClick={() => window.location.href = 'http://localhost:5000/google_auth/login'}
      bg="white"
      borderRadius="full"
      borderWidth="1px"
      borderColor="gray.300"
      _hover={{ bg: "gray.50" }}
      w="100%"
    >
      <Flex align="center" justify="center" w="100%">
        <Image src={googleLogo} alt="Google logo" boxSize="20px" mr="8px" />
        <Spacer />
        <Flex direction="column" align="center">
          Continue with Google
        </Flex>
        <Spacer />
      </Flex>
    </Button>
  );
};

export default GoogleLoginButton;









// import React, { useState, useEffect } from "react";
// import { useGoogleLogin } from "@react-oauth/google";
// import { Button, Flex, Image, Spacer } from "@chakra-ui/react";
// import axios from "axios";
// import { useUser } from "../../userRole/UserContext";
// import googleLogo from "../../../assets/images/google.png";
// import { useHistory } from "react-router-dom";
// import { useGoogleAuth } from "./GoogleAuthContext"; 

// const GoogleLoginButton = () => {
  // const { login } = useGoogleAuth();
  // const history = useHistory();
  // const { user, setUser } = useUser();
//   const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);

//   function getDeviceDetails() {
//     return {
//         userAgent: navigator.userAgent,
//         platform: navigator.platform,
//         screenWidth: window.screen.width,
//         screenHeight: window.screen.height,
//         loginTime: new Date().toISOString() // ISO 8601 format
//     };
// }

//   const handleLoginSuccess = async (tokenResponse) => {
//     //console.log("Token response:", tokenResponse);

//     const deviceDetails = getDeviceDetails();

//     const payload = {
//       access_token: tokenResponse.access_token,
//       device: deviceDetails
//     }
//     //console.log("payload", payload);
//     try {
//       const response = await axios.post("/google_auth/google_login", payload);

//       if (response.status === 200) {
//         // Assuming the backend returns user data including email and name
//         login(tokenResponse.access_token);
        // const userData = {
        //   access_token: tokenResponse.access_token,
        //   email: response.data.user.email,
        //   role: response.data.user.role_name,
        //   username: response.data.user.name,
        // };
//         localStorage.setItem("REACT_TOKEN_AUTH_KEY", JSON.stringify(userData));
        

//         localStorage.setItem("email", response.data.user.email);
//         localStorage.setItem("username", response.data.user.name);

//         const role = response.data.user.role_name;
        // setUser({
        //   role,
        // });
//         setIsLoginSuccessful(true);
//         // Redirect user to the homepage
        // history.push("/home");
//       } else {
//         console.error("Login failed:", response.data.message);
//       }
//     } catch (error) {
//       console.error("Error sending token to backend:", error);
//     }
//   };

//   useEffect(() => {
//     if (user && isLoginSuccessful) {
//       history.push("/home");
//       setIsLoginSuccessful(false); // Reset the flag after redirecting
//     }
//   }, [user, isLoginSuccessful]);
  
//   const initiateLogin = useGoogleLogin({
//     onSuccess: handleLoginSuccess,
//   });

//   return (
//     <Button
//       onClick={() => initiateLogin()}
//       bg="white"
//       borderWidth="1px"
//       borderColor="gray.300"
//       _hover={{ bg: "gray.50" }}
//       w="100%"
//     >
//       <Flex align="center" justify="center" w="100%">
//         <Image src={googleLogo} alt="Google logo" boxSize="20px" mr="8px" />
//         <Spacer />
//         <Flex direction="column" align="center">
//           Continue with Google
//         </Flex>
//         <Spacer />
//       </Flex>
//     </Button>
//   );
// };

// export default GoogleLoginButton;
