import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  useToast,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  VStack,
  Divider,
  Spacer,
  Tooltip,
} from "@chakra-ui/react";
import { FaFileExcel } from "react-icons/fa";
import { ChevronDownIcon } from "@chakra-ui/icons";
import axiosInstance from "../../helpers/axiosInstance";
import usePersistedState from "../usePersistedState";

const Filter = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = usePersistedState("perPageFilter", 8); // Default to 10 messages per page
  const [totalPages, setTotalPages] = useState(0);
  const [usersMinDate, setUsersMinDate] = useState("");
  const [usersMaxDate, setUsersMaxDate] = useState("");
  const [usersMinLogin, setUsersMinLogin] = useState("");
  const [usersMaxLogin, setUsersMaxLogin] = useState("");
  const [registrationDevices, setRegistrationDevices] = useState([]);
  const [loginDevices, setLoginDevices] = useState([]);
  const [excelFile, setExcelFile] = useState("");
  const [filters, setFilters] = useState({
    minDate: usersMinDate,
    maxDate: usersMaxDate,
    deviceType: [],
    logindeviceType: [],
    minLogins: usersMinLogin,
    maxLogins: usersMaxLogin,
  });
  const toast = useToast();

  const handleDeviceTypeChange = (device) => {
    setFilters((prevFilters) => {
      const currentDevices = prevFilters.deviceType;
      if (currentDevices.includes(device)) {
        return {
          ...prevFilters,
          deviceType: currentDevices.filter((d) => d !== device), // Remove device if already selected
        };
      } else {
        return {
          ...prevFilters,
          deviceType: [...currentDevices, device], // Add device if not selected
        };
      }
    });
  };

  const isDeviceSelected = (device) => {
    return filters.deviceType.includes(device);
  };

  const handleLoginDeviceTypeChange = (device) => {
    setFilters((prevFilters) => {
      const currentDevices = prevFilters.logindeviceType;
      if (currentDevices.includes(device)) {
        return {
          ...prevFilters,
          logindeviceType: currentDevices.filter((d) => d !== device), // Remove device if already selected
        };
      } else {
        return {
          ...prevFilters,
          logindeviceType: [...currentDevices, device], // Add device if not selected
        };
      }
    });
  };

  const isLoginDeviceSelected = (device) => {
    return filters.logindeviceType.includes(device);
  };

  // Function to fetch users based on current filters and pagination
  const fetchUsers = () => {
    const params = {
      page: currentPage,
      per_page: perPage,
      ...filters,
    };
    console.log("payload", params);
    if (
      new Date(filters.minDate) > new Date(filters.maxDate) ||
      parseInt(filters.minLogins) > parseInt(filters.maxLogins)
    ) {
      setFilters({
        minDate: usersMinDate,
        maxDate: usersMaxDate,
        deviceType: [],
        logindeviceType: [],
      });
      toast({
        title: "Date Range Error",
        description: "The minimum date cannot be later than the maximum date.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Stop the function execution if the dates are invalid
    }
    if (new Date(filters.minDate) > new Date(filters.maxDate)) {
      setFilters({
        deviceType: [],
        logindeviceType: [],
        minLogins: usersMinLogin,
        maxLogins: usersMaxLogin,
      });
      toast({
        title: "Range Error",
        description:
          "The minimum logins cannot be later than the maximum logins.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Stop the function execution if the dates are invalid
    }
    //console.log("payload", params);
    axiosInstance
      .post(`/filter/get_users`, params)
      .then((response) => {
        const data = response.data.users_info;
        console.log("Response", data);
        setUsers(data.users);
        setTotalPages(data.total_pages);
        setUsersMinDate(data.min_registration_date);
        setUsersMaxDate(data.max_registration_date);
        setUsersMinLogin(data.min_login_count);
        setUsersMaxLogin(data.max_login_count);
        setFilters((prev) => ({
          ...prev,
          minDate: prev.minDate || data.min_registration_date,
          maxDate: prev.maxDate || data.max_registration_date,
          minLogins: prev.minLogins || data.min_login_count,
          maxLogins: prev.maxLogins || data.max_login_count,
        }));
        setExcelFile(data.excel_file);
        if (currentPage > totalPages) {
          setCurrentPage(Math.max(totalPages, 1));
        }
        // Extract unique devices
        const uniqueRegistrationDevices = [
          ...new Set(data.users.map((user) => user.registration_device_type)),
        ];
        const uniqueLoginDevices = [
          ...new Set(data.users.map((user) => user.login_device_type)),
        ];
        setRegistrationDevices(uniqueRegistrationDevices);
        setLoginDevices(uniqueLoginDevices);
      })
      .catch((error) => {});
  };

  const handleDownloadClick = () => {
    if (!excelFile) {
      toast({
        title: "Error",
        description: "No file to download.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const link = document.createElement("a");
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excelFile}`;
    link.download = "users.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (perPage > 0) {
      fetchUsers();
    }
  }, [currentPage, perPage]);

  // Handlers for pagination
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow the field to be empty
    setPerPage(value === "" ? "" : Math.max(1, parseInt(value, 10)));
  };

  const handleInputBlur = () => {
    // When input loses focus, enforce minimum and correct invalid entries
    setPerPage((prev) =>
      prev === "" || isNaN(prev) ? 1 : Math.max(1, parseInt(prev, 10))
    );
  };
  // Handler for filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value === "" ? null : value, // Convert empty string to null
    }));
  };

  const [filtersCleared, setFiltersCleared] = useState(false);

  useEffect(() => {
    if (filtersCleared) {
      // You may need to add a new state to track if filters are cleared
      fetchUsers();
      setFiltersCleared(false); // Reset the flag
    }
  }, [filters]); // Runs fetchUsers() whenever filters state changes

  const clearFilters = () => {
    setFilters({
      minDate: usersMinDate,
      maxDate: usersMaxDate,
      deviceType: [],
      logindeviceType: [],
      minLogins: usersMinLogin,
      maxLogins: usersMaxLogin,
    });
    setFiltersCleared(true); // Set a flag that filters are cleared
  };

  return (
    <Box h="100vh" overflowY="auto">
      <Flex
        bg="white"
        // h={{ base: "40%", md: "27%" }}
        h="auto"
        justifyContent="center"
        alignItems="center"
        p={4}
        w="100%"
      >
        <Box bg="white" m={0} p={4} borderRadius="lg" shadow="lg">
          {/* <VStack align="stretch" spacing={4} w="100%"> */}
          <HStack justifyContent="center" spacing={2} wrap="wrap">
            <FormControl w="17%">
              <FormLabel>Min Registration Date:</FormLabel>
              <Input
                type="date"
                name="minDate"
                value={filters.minDate}
                onChange={handleFilterChange}
                size="sm"
                borderColor="#E2E8F0"
                borderRadius="md"
              />
            </FormControl>
            <FormControl w="17%">
              <FormLabel>Max Registration Date:</FormLabel>
              <Input
                type="date"
                name="maxDate"
                value={filters.maxDate}
                onChange={handleFilterChange}
                size="sm"
                borderColor="#E2E8F0"
                borderRadius="md"
              />
            </FormControl>
            <FormControl w="13%">
              <FormLabel>Register Device:</FormLabel>
              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  variant="outline"
                  borderColor="#E2E8F0"
                  borderRadius="md"
                  p={2}
                  fontWeight="normal"
                  size="sm"
                  w="full"
                >
                  Select Device
                </MenuButton>
                <MenuList border="1px" borderColor="gray.200" borderRadius="md">
                  {registrationDevices.map((device, index) => (
                    <MenuItem minH="48px" key={index}>
                      <Checkbox
                        isChecked={isDeviceSelected(device)}
                        onChange={() => handleDeviceTypeChange(device)}
                      >
                        {device}
                      </Checkbox>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </FormControl>
            <FormControl w="13%">
              <FormLabel>Login Device:</FormLabel>
              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  variant="outline"
                  size="sm"
                  borderColor="#E2E8F0"
                  borderRadius="md"
                  p={2}
                  fontWeight="normal"
                  w="full"
                >
                  Select Device
                </MenuButton>
                <MenuList border="1px" borderColor="gray.200" borderRadius="md">
                  {loginDevices.map((device, index) => (
                    <MenuItem minH="48px" key={index}>
                      <Checkbox
                        isChecked={isLoginDeviceSelected(device)}
                        onChange={() => handleLoginDeviceTypeChange(device)}
                      >
                        {device}
                      </Checkbox>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </FormControl>
            {/* <FormControl w="14%">
                <FormLabel>Register Device:</FormLabel>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    variant="outline"
                    borderColor="#E2E8F0"
                    borderRadius="md"
                    p={2}
                    fontWeight="normal"
                    w="full"
                  >
                    Select Device
                  </MenuButton>
                  <MenuList border="1px" borderColor="gray.200" borderRadius="md">
                    <MenuItem minH="48px">
                      <Checkbox
                        isChecked={isDeviceSelected('Mac OS X Chrome')}
                        onChange={() => handleDeviceTypeChange('Mac OS X Chrome')}
                      >
                        Mac OS X Chrome
                      </Checkbox>
                    </MenuItem>
                    <MenuItem minH="48px">
                      <Checkbox
                        isChecked={isDeviceSelected('MacOS Chrome')}
                        onChange={() => handleDeviceTypeChange('MacOS Chrome')}
                      >
                        MacOS Chrome
                      </Checkbox>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </FormControl> */}
            {/* <FormControl w="14%">
                <FormLabel>Login Device:</FormLabel>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    variant="outline"
                    borderColor="#E2E8F0"
                    borderRadius="md"
                    p={2}
                    fontWeight="normal"
                    w="full"
                  >
                    Select Device
                  </MenuButton>
                  <MenuList border="1px" borderColor="gray.200" borderRadius="md">
                    <MenuItem minH="48px">
                      <Checkbox
                        isChecked={isLoginDeviceSelected('Mac OS X Chrome')}
                        onChange={() =>
                          handleLoginDeviceTypeChange('Mac OS X Chrome')
                        }
                      >
                        Mac OS X Chrome
                      </Checkbox>
                    </MenuItem>
                    <MenuItem minH="48px">
                      <Checkbox
                        isChecked={isLoginDeviceSelected('MacOS Chrome')}
                        onChange={() =>
                          handleLoginDeviceTypeChange('MacOS Chrome')
                        }
                      >
                        MacOS Chrome
                      </Checkbox>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </FormControl> */}
            <FormControl w="11%">
              <FormLabel>Min Logins:</FormLabel>
              <Input
                name="minLogins"
                value={filters.minLogins}
                onChange={handleFilterChange}
                size="sm"
                borderColor="#E2E8F0"
                borderRadius="md"
              />
            </FormControl>
            <FormControl w="11%">
              <FormLabel>Max Logins:</FormLabel>
              <Input
                name="maxLogins"
                value={filters.maxLogins}
                onChange={handleFilterChange}
                size="sm"
                borderColor="#E2E8F0"
                borderRadius="md"
              />
            </FormControl>
            <VStack justifyContent="center" alignItems="center" spacing={2}>
              <Button
                colorScheme="blue"
                onClick={fetchUsers}
                w="full"
                size="sm"
                borderColor="#E2E8F0"
                borderRadius="md"
              >
                Find
              </Button>
              <Button
                colorScheme="red"
                onClick={clearFilters}
                w="full"
                size="sm"
                borderColor="#E2E8F0"
                borderRadius="md"
              >
                Clear
              </Button>
            </VStack>
          </HStack>
          {/* </VStack> */}
        </Box>
      </Flex>
      {/* <Divider m={0} /> */}
      <Box h={{ base: "50%", md: "67%" }} overflowX="auto" scroll="auto">
        <Divider m={0} />
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Username</Th>
              <Th>Email</Th>
              <Th>Registration Date</Th>
              <Th>Register Device</Th>
              <Th>Login Device</Th>
              <Th>Login Count</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user, index) => (
              <Tr key={index}>
                <Td>{user.username}</Td>
                <Td>{user.email}</Td>
                <Td>{user.registration_date}</Td>
                <Td>{user.registration_device_type}</Td>
                <Td>{user.login_device_type}</Td>
                <Td>{user.login_count}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Box mt={1} mb={1} ml={4} display="flex" justifyContent="left">
        <HStack spacing={4} align="center">
          <Button
            size="sm"
            onClick={handlePreviousClick}
            disabled={currentPage <= 1}
          >
            Previous
          </Button>
          <Text pt={3} size="sm">
            {currentPage} of {totalPages}
          </Text>
          <Button
            size="sm"
            onClick={handleNextClick}
            disabled={currentPage >= totalPages}
          >
            Next
          </Button>
          <Box>
            <FormControl>
              <HStack>
                <FaFileExcel
                  onClick={handleDownloadClick}
                  style={{
                    cursor: "pointer",
                    fontSize: "24px",
                    color: "green",
                  }}
                />
              </HStack>
            </FormControl>
          </Box>
        </HStack>
      </Box>
    </Box>
  );

  // return (
  //   <Box h="calc(100vh - 52px)" overflowY="hidden" scroll="auto">
  //     <Flex bg="white" h="27%" justifyContent="center" alignItems="center">
  //       <Box
  //         bg="white"
  //         h="80%"
  //         w="90%"
  //         m={0}
  //         p={4}
  //         pt={0}
  //         pb={2}
  //         borderRadius="lg"
  //         shadow="lg"
  //       >
  // <VStack align="stretch">
  //   <HStack spacing={3}>
  //     <FormControl>
  //       <FormLabel>Min Registration Date:</FormLabel>
  //       <Input
  //         type="date"
  //         name="minDate"
  //         value={filters.minDate}
  //         onChange={handleFilterChange}
  //       />
  //     </FormControl>
  //     <FormControl>
  //       <FormLabel>Max Registration Date:</FormLabel>
  //       <Input
  //         type="date"
  //         name="maxDate"
  //         value={filters.maxDate}
  //         onChange={handleFilterChange}
  //       />
  //     </FormControl>
  //     <FormControl w="300px">
  //       <FormLabel>Register Device:</FormLabel>
  //       <Menu closeOnSelect={false}>
  //         <MenuButton
  //           as={Button}
  //           rightIcon={<ChevronDownIcon />}
  //           variant="outline"
  //           borderColor="#E2E8F0"
  //           borderRadius="md"
  //           p={2}
  //           colorScheme="#E2E8F0"
  //           fontWeight="normal"
  //         >
  //           Select Device
  //         </MenuButton>
  //         <MenuList
  //           border="1px"
  //           borderColor="gray.200"
  //           borderRadius="md"
  //         >
  //           <MenuItem minH="48px">
  //             <Checkbox
  //               isChecked={isDeviceSelected("Mac OS X Chrome")}
  //               onChange={() =>
  //                 handleDeviceTypeChange("Mac OS X Chrome")
  //               }
  //             >
  //               Mac OS X Chrome
  //             </Checkbox>
  //           </MenuItem>
  //           <MenuItem minH="48px">
  //             <Checkbox
  //               isChecked={isDeviceSelected("MacOS Chrome")}
  //               onChange={() => handleDeviceTypeChange("MacOS Chrome")}
  //             >
  //               MacOS Chrome
  //             </Checkbox>
  //           </MenuItem>
  //           {/* <MenuItem minH="48px">
  //             <Checkbox
  //               isChecked={isDeviceSelected("Android Chrome")}
  //               onChange={() =>
  //                 handleDeviceTypeChange("Android Chrome")
  //               }
  //             >
  //               Android Chrome
  //             </Checkbox>
  //           </MenuItem> */}
  //         </MenuList>
  //       </Menu>
  //     </FormControl>
  //     <FormControl w="260.8px">
  //       <FormLabel>Login Device:</FormLabel>
  //       <Menu closeOnSelect={false}>
  //         <MenuButton
  //           as={Button}
  //           rightIcon={<ChevronDownIcon />}
  //           variant="outline"
  //           borderColor="#E2E8F0"
  //           borderRadius="md"
  //           p={2}
  //           colorScheme="#E2E8F0"
  //           fontWeight="normal"
  //         >
  //           Select Device
  //         </MenuButton>
  //         <MenuList
  //           border="1px"
  //           borderColor="gray.200"
  //           borderRadius="md"
  //         >
  //           <MenuItem minH="48px">
  //             <Checkbox
  //               isChecked={isLoginDeviceSelected("Mac OS X Chrome")}
  //               onChange={() =>
  //                 handleLoginDeviceTypeChange("Mac OS X Chrome")
  //               }
  //             >
  //               Mac OS X Chrome
  //             </Checkbox>
  //           </MenuItem>
  //           <MenuItem minH="48px">
  //             <Checkbox
  //               isChecked={isLoginDeviceSelected("MacOS Chrome")}
  //               onChange={() =>
  //                 handleLoginDeviceTypeChange("MacOS Chrome")
  //               }
  //             >
  //               MacOS Chrome
  //             </Checkbox>
  //           </MenuItem>
  //           {/* <MenuItem minH="48px">
  //             <Checkbox
  //               isChecked={isLoginDeviceSelected("Android Chrome")}
  //               onChange={() =>
  //                 handleLoginDeviceTypeChange("Android Chrome")
  //               }
  //             >
  //               Android Chrome
  //             </Checkbox>
  //           </MenuItem> */}
  //         </MenuList>
  //       </Menu>
  //     </FormControl>
  //     <FormControl>
  //       <FormLabel>Min Logins:</FormLabel>
  //       <Input
  //         name="minLogins"
  //         value={filters.minLogins}
  //         onChange={handleFilterChange}
  //       />
  //     </FormControl>
  //     <FormControl>
  //       <FormLabel>Max Logins:</FormLabel>
  //       <Input
  //         name="maxLogins"
  //         value={filters.maxLogins}
  //         onChange={handleFilterChange}
  //       />
  //     </FormControl>
  //     <Divider
  //       orientation="vertical"
  //       height="100px"
  //       colorScheme="black"
  //     />
  //     <VStack justifyContent="center" alignItems="center">
  //       <Button colorScheme="blue" onClick={fetchUsers}>
  //         Find
  //       </Button>
  //       <Button
  //         // w="50%"
  //         // mt={8}
  //         colorScheme="red"
  //         onClick={clearFilters}
  //         size="md"
  //       >
  //         Clear
  //       </Button>
  //     </VStack>
  //   </HStack>
  // </VStack>
  //       </Box>
  //     </Flex>
  // <Divider m={0} />
  // <Box h="63%" overflowX="auto" scroll="auto">
  // <Divider m={0} />
  //       <Table variant="simple">
  //         <Thead>
  //           <Tr>
  //             <Th>Username</Th>
  //             <Th>Email</Th>
  //             <Th>Registration Date</Th>
  //             <Th>Register Device</Th>
  //             <Th>Login Device</Th>
  //             <Th>Login Count</Th>
  //           </Tr>
  //         </Thead>
  //         <Tbody>
  //           {users.map((user, index) => (
  //             <Tr key={index}>
  //               <Td>{user.username}</Td>
  //               <Td>{user.email}</Td>
  //               <Td>{user.registration_date}</Td>
  //               <Td>{user.registration_device_type}</Td>
  //               <Td>{user.login_device_type}</Td>
  //               {/* for when we do not need to show users multiple time according to their login devices */}
  //               {/*
  //               <Td>{user.registration_date_device.registration_date}</Td>
  //               <Td>{user.registration_date_device.simplified_user_agent}</Td>
  //               <Td>
  //                 {user.logins.reduce(
  //                   (acc, login) => acc + login.loginCount,
  //                   0
  //                 )}
  //               </Td> */}
  //               <Td>{user.login_count}</Td>
  //             </Tr>
  //           ))}
  //         </Tbody>
  //       </Table>
  //     </Box>

  //     <Box mt={1} mb={1} ml={4} display="flex" justifyContent="bottom">
  //       <HStack spacing={4} align="center">
  //         <Button
  //           size="sm"
  //           onClick={handlePreviousClick}
  //           disabled={currentPage <= 1}
  //         >
  //           Previous
  //         </Button>
  //         <Text pt={3} size="sm">
  //           {currentPage} of {totalPages}
  //         </Text>
  //         <Button
  //           size="sm"
  //           onClick={handleNextClick}
  //           disabled={currentPage >= totalPages}
  //         >
  //           Next
  //         </Button>
  //         <Box>
  //           <FormControl>
  //             <HStack>
  //               {/* <FormLabel htmlFor="per-page" pt={2} size="sm">
  //                 Per Page:
  //               </FormLabel>
  //               <Input
  //                 size="sm"
  //                 id="per-page"
  //                 type="number"
  //                 value={perPage}
  //                 onChange={handleInputChange}
  //                 onBlur={handleInputBlur}
  //                 min={1}
  //                 max={100}
  //                 width="35%"
  //               /> */}
  //               {/* <Tooltip label="Download"> */}
  //                <FaFileExcel
  //                 onClick={handleDownloadClick}
  //                 style={{ cursor: "pointer", fontSize: "24px",color: 'green' }}
  //               />
  //               {/* </Tooltip> */}
  //             </HStack>
  //           </FormControl>
  //         </Box>
  //       </HStack>
  //     </Box>
  //   </Box>
  // );
};

export default Filter;
