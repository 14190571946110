// markdownHelpers.js
import { marked } from "marked";

export const markdownToHtml = (markdown) => {
  return marked(markdown);
};

export const transformHtml = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const links = doc.querySelectorAll("a");
  links.forEach((link) => {
    link.target = "_blank";
    link.rel = "noopener noreferrer";
  });
  return doc.body.innerHTML;
};
