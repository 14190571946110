import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  Stack,
  Link,
  SimpleGrid,
  useColorMode,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Input,
  InputGroup,
  IconButton,
  InputRightElement,
  VStack,
  useDisclosure,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaLocationArrow } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import adventure from "../assets/images/Route66.jpg";
import HomeToGo from "../assets/images/HomeToGo.png";
import Viaiator from "../assets/images/logo-viator-facebook.png";
import Aviasales from "../assets/images/Aviasales_logo_horizontal.png";
import Outdoorsy from "../assets/images/outdoorsy.png";
import Wise from "../assets/images/wise.png";
import HeroImage from "../assets/images/hero_image.png";
import logo from "../assets/images/Newlogo-black.png";
import { useScroll } from "../components/ScrollContext";
import { CookieConsentPopup, NewsletterSubscriptionPopup } from "./Popups"; // Import the popups
import TripSection from "./TripSection";
import Footer from "./Footer";
import VideoSection from "./VideoSection";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlickSlider from "react-slick";
import Navbar from "../components/Navbar";
import BackToTopButton from "../components/common/BackToTopButton";
import { useTranslation } from "../components/LanguageTranslation/TranslationContext";
import Translate from "../components/LanguageTranslation/Translate";

const HomePage = () => {
  const { colorMode } = useColorMode();
  const { partnersRef } = useScroll();
  const history = useHistory();
  const [showCookiePopup, setShowCookiePopup] = useState(false);

  const {
    isOpen: isNewsletterOpen,
    onOpen: onOpenNewsletter,
    onClose: onCloseNewsletter,
  } = useDisclosure();

  const {
    isOpen: isSurveyOpen,
    onOpen: onOpenSurvey,
    onClose: onCloseSurvey,
  } = useDisclosure(); // Renamed this for clarity

  useEffect(() => {
    const cookiePopupShown = localStorage.getItem("cookiePopupShown");
    const newsletterPopupShown = localStorage.getItem("newsletterPopupShown");
    const surveyPopupShown = localStorage.getItem("surveyPopupShown");

    if (!cookiePopupShown) {
      setTimeout(() => {
        setShowCookiePopup(true); // Show cookie popup after 1 second
      }, 1000);
    }

    if (cookiePopupShown && !newsletterPopupShown) {
      // Show newsletter subscription popup after 1 second of accepting cookies
      setTimeout(() => {
        onOpenNewsletter();
      }, 1000);
    }

    if (cookiePopupShown && newsletterPopupShown && !surveyPopupShown) {
      // Show survey popup 2 seconds after newsletter is closed
      setTimeout(() => {
        onOpenSurvey();
      }, 2000);
    }
  }, [onOpenNewsletter, onOpenSurvey]);

  const handleCookieAccept = () => {
    localStorage.setItem("cookiePopupShown", "true");
    setShowCookiePopup(false);

    // Show the newsletter popup 1 second after accepting cookies
    if (!localStorage.getItem("newsletterPopupShown")) {
      setTimeout(() => {
        onOpenNewsletter();
      }, 1000);
    }
  };

  const handleNewsletterClose = () => {
    localStorage.setItem("newsletterPopupShown", "true");
    onCloseNewsletter();

    // Show the survey popup after the newsletter popup is closed
    if (!localStorage.getItem("surveyPopupShown")) {
      setTimeout(() => {
        onOpenSurvey();
      }, 2000);
    }
  };

  const [userMessage, setUserMessage] = useState("");

  const handleSubmit = (e) => {
    localStorage.removeItem("conversation_id");
    e.preventDefault();
    history.push(`/ava/chat?message=${encodeURIComponent(userMessage)}`);
  };

  const handleSuggestionClick = (message) => {
    localStorage.removeItem("conversation_id");
    setUserMessage(message);
    history.push(`/ava/chat?message=${encodeURIComponent(message)}`);
  };

  const suggestions = [
    "Find lodging",
    "Inspire where to go",
    "Find activities",
    "Build an itinerary",
  ];

  const handleLinkClick = (message) => {
    localStorage.removeItem("conversation_id"); // Clear any existing conversation
    history.push(`/ava/chat?message=${encodeURIComponent(message)}`); // Redirect to the chat system with the clicked text
  };

  return (
    <Box bg={colorMode === "light" ? "white" : "gray.800"}>
      <Box backgroundColor="#061019" position="relative" zIndex="0" p={0} m={0}>
        {/* Navbar */}
        <Box position="absolute" top={0} left={0} width="100%" zIndex="2">
          <Navbar
            bgColor="transparent" // Make the background of the navbar transparent
            linkColor="white"
            logoSrc={logo}
            languageCodeColor="white"
          />
        </Box>

        {/* Hero Section */}
        <Box
          position="relative"
          zIndex="1" // Keep this lower than the navbar's zIndex
          height={{ base: "auto", md: "100vh" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          p={0}
          m={0}
        >
          {/* Background Image */}
          <Box
            width="100%"
            height="100%"
            backgroundImage={`url(${HeroImage})`}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            h="100vh"
            position="relative"
            borderRadius="0"
            zIndex="1" // Lower zIndex than the navbar
            // _before={{
            //   content: '""',
            //   position: "absolute",
            //   top: 0,
            //   left: 0,
            //   width: "100%",
            //   height: "100%",
            //   backgroundColor: "rgba(0, 0, 0, 0)", // Semi-transparent black overlay
            //   zIndex: 1, // Behind the text and other content
            // }}
          >
            {/* Content Section */}
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              height="100%"
              w="100%"
              color="white"
              p={0}
              m={0}
              position="relative"
              zIndex="2" // Ensure content is above the background image
            >
              <VStack
                alignContent="center"
                textAlign="center"
                justifyContent="center"
                borderRadius="xl"
                // bg="white"
                // bg="rgba(255, 255, 255, 0.4)"
                mb={0}
                spacing={0}
                w={{ base: "100%", md: "65%", lg: "65%", xl: "65%" }}
              >
                {/* Heading */}
                <Heading
                  as="h1"
                  fontSize={{ base: "3xl", md: "3xl", lg: "5xl", xl: "6xl" }}
                  fontFamily="Poppins, sans-serif"
                  // w={{ base: "100%", md: "100%", lg: "100%", xl: "100%"}}
                  mb={{ base: 2, md: 8 }}
                  mt={8}
                  color="white"
                  textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
                >
                  <Translate text="Your Adventure, Perfectly Planned" />
                </Heading>
                <Text
                  fontSize={{ base: "sm", md: "md", lg: "lg", xl: "2xl" }}
                  w={{ base: "50%", md: "60%", lg: "60%", xl: "60%" }}
                  mb={8}
                  color="white"
                  textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
                >
                  <Text as="span" fontWeight="bold">
                    Nomad Tours
                  </Text>{" "}
                  <Translate
                    text="creates personalized travel plans in seconds, tailored to your
                  outdoor lifestyle. Stress-free planning and epic adventures
                  await!"
                  />
                </Text>
              </VStack>
              <Box
                position="relative"
                zIndex="3"
                w={{ base: "100%", md: "65%", lg: "65%", xl: "65%" }}
              >
                {/* Ensures form is clickable */}
                <form onSubmit={handleSubmit} w="100%">
                  <HStack
                    spacing={4}
                    flexWrap="wrap"
                    justifyContent="center"
                    mb={4}
                    w="100%"
                  >
                    <Input
                      placeholder={Translate({
                        text: "Ask me anything",
                        as: "string",
                      })}
                      _placeholder={{ color: "gray.100" }}
                      value={userMessage}
                      onChange={(e) => setUserMessage(e.target.value)}
                      // size="lg"
                      size={{ base: "sm", md: "md", lg: "lg" }}
                      borderRadius="xl"
                      bg="blackAlpha.300"
                      textColor="white"
                      // w="65%"
                      w={{ base: "40%", md: "60%", lg: "65%", xl: "40%" }}
                      // w={inputWidth}
                    />
                    <Button
                      type="submit"
                      bg="black"
                      _hover="none"
                      // size="lg"
                      size={{ base: "sm", md: "md", lg: "lg" }}
                      fontSize={{ base: "xs", md: "md", lg: "md" }}
                      textColor="white"
                      borderRadius="xl"
                      w={{ base: "auto", md: "auto", lg: "auto", xl: "auto" }}
                    >
                      <Translate text="Get started - it's free" />
                      <FaLocationArrow style={{ marginLeft: "8px" }} />
                    </Button>
                  </HStack>
                </form>
                <HStack spacing={4} flexWrap="wrap" justifyContent="center">
                  {suggestions.map((suggestion, index) => (
                    <Button
                      key={index}
                      bg="blackAlpha.400"
                      _hover="none"
                      textColor="white"
                      borderRadius="xl"
                      variant="outline"
                      onClick={() => handleSuggestionClick(suggestion)}
                      m={1}
                    >
                      <Translate text={suggestion} />
                    </Button>
                  ))}
                </HStack>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>

      <VideoSection />
      {/* Partners Section */}
      <Box
        p={6}
        pb={0}
        pl={30}
        pr={30}
        ref={partnersRef}
        bg="#F3F4F6"
        textAlign="center"
        maxW="100%"
        overflow="hidden"
      >
        <Heading as="h2" size="md" fontFamily="Poppins, sans-serif" mb={2}>
          <Translate text="Our Co-Pilots in Global Adventures" />
        </Heading>
        <Box maxW="1200px" mx="auto" overflow="hidden">
          <SlickSlider
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={5} // Number of images to show in a row
            slidesToScroll={1}
            autoplay={true}
            autoplaySpeed={2000}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4, // Show 4 logos on medium screens
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3, // Show 3 logos on smaller screens
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2, // Show 2 logos on very small screens
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            <Box p={0} textAlign="center">
              <Image
                src={HomeToGo}
                alt="Partner logo"
                boxSize="100px"
                objectFit="contain"
                mx="auto"
              />
            </Box>
            <Box p={0} textAlign="center">
              <Image
                src={Viaiator}
                alt="Partner logo"
                boxSize="100px"
                objectFit="contain"
                mx="auto"
              />
            </Box>
            <Box p={0} textAlign="center">
              <Image
                src={Aviasales}
                alt="Partner logo"
                boxSize="100px"
                objectFit="contain"
                mx="auto"
              />
            </Box>
            <Box p={0} textAlign="center">
              <Image
                src={Outdoorsy}
                alt="Partner logo"
                boxSize="100px"
                objectFit="contain"
                mx="auto"
              />
            </Box>
            <Box p={0} textAlign="center">
              <Image
                src={Wise}
                alt="Partner logo"
                boxSize="100px"
                objectFit="contain"
                mx="auto"
              />
            </Box>
          </SlickSlider>
        </Box>
      </Box>

      <TripSection />

      {/* Adventure Section */}
      <Box
        p={8}
        pt={5}
        pl={60}
        pr={60}
        textAlign="center"
        bg={colorMode === "light" ? "blackAlpha.50" : "blackAlpha.50"}
      >
        <Heading as="h2" size="xl" fontFamily="Poppins, sans-serif" mb={2}>
          <Translate text="Start Planning Your Next Adventure" />
        </Heading>
        <Text mb={8}>
          <Translate text="Instantly Customized Travel Plans for Your Outdoor Adventures" />
        </Text>
        <Box
          position="relative"
          maxW="90%"
          // mx="auto"
          h={{ base: "100%", md: "70vh" }}
          mb={{ base: 8, md: 0 }}
        >
          <Image
            src={adventure}
            alt="Adventure image"
            borderRadius="xl"
            objectFit="cover"
            width="100%"
            height="100%"
          />
          <Box
            position={{ base: "relative", md: "absolute" }}
            top={{ base: "unset", md: "50%" }}
            right={{ base: "unset", md: "-15%" }}
            transform={{ base: "unset", md: "translateY(-50%)" }}
            bg="white"
            borderRadius="xl"
            p={6}
            boxShadow="lg"
            h={{ base: "100%", md: "auto" }}
            maxW={{ base: "90%", md: "35%" }}
            mt={{ base: 4, md: 0 }}
            mb={{ base: 4, md: 0 }}
            textAlign="left"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Text fontSize={{ base: "xs", md: "sm" }} color="gray.500">
              <Translate text="Family Adventure" />
            </Text>
            <Box mb={2}>
              <Heading
                as="h3"
                fontSize={{ base: "2xl", md: "4x1", lg: "6x1" }}
                fontFamily="Poppins, sans-serif"
              >
                Route 66
              </Heading>
              <Text mt={2} fontSize={{ base: "sm", md: "sm", lg: "sm" }}>
                <Translate
                  text="Embark on Route 66 with your family for an unforgettable RV
                journey. Discover historic landmarks, scenic byways, and
                family-friendly attractions across this iconic American road
                trip."
                />
              </Text>
            </Box>
            <Button
              // mt={4}
              bg="black"
              color="white"
              size={{ base: "sm", md: "sm", lg: "md" }}
              alignSelf="flex-start"
              _hover="none"
              // as={RouterLink}
              // to="/travel-planner"
              onClick={() => handleLinkClick("Plan a road trip on route 66")}
              pt={2}
              pb={2}
            >
              <Translate text="Plan a trip" />
            </Button>
          </Box>
        </Box>
      </Box>

      {/* FAQ Section */}
      <Box p={8} pl={60} pr={60}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="right"
          w="100%"
        >
          <Box flex="2">
            <Heading
              as="h2"
              size="xl"
              fontFamily="Poppins, sans-serif"
              mb={4}
              ml={{ md: "6", lg: "6", xl: "20" }}
              textAlign={{ base: "left", md: "left", lg: "left", xl: "left" }}
            >
              <Translate text="FAQs" />
            </Heading>
          </Box>
          <Box flex="2" w="50%">
            <Accordion allowToggle w={{ md: "100%", lg: "100%", xl: "70%" }}>
              <AccordionItem>
                <AccordionButton p={4} _hover="none" fontWeight="bold">
                  <Box flex="1" textAlign="left">
                    <Translate text="What is Nomad Tours?" />
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Translate
                    text="Nomad Tours creates personalized travel plans in seconds,
                  tailored to your outdoor lifestyle. Stress-free planning and
                  epic adventures await!"
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton p={4} _hover="none" fontWeight="bold">
                  <Box flex="1" textAlign="left">
                    <Translate text="Who can use Nomad Tours?" />
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Translate
                    text="Nomad Tours is an AI-powered travel planner designed for
                  outdoor and adventure travelers. It creates personalized
                  travel itineraries for hikers, campers, backpackers, families,
                  digital nomads, photographers, wildlife enthusiast,
                  overlanders, and much more."
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton p={4} _hover="none" fontWeight="bold">
                  <Box flex="1" textAlign="left">
                    <Translate text="Is Nomad Tours free to use?" />
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Translate
                    text="Yes, Nomad Tours is completely free! However, donations are
                  greatly appreciated to help maintain the platform and support
                  our mission to protect a greener world and improve (mental)
                  health for all."
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton p={4} _hover="none" fontWeight="bold">
                  <Box flex="1" textAlign="left">
                    <Translate text="How do I adjust my Nomad Tours' itinerary?" />
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Translate
                    text={`You can easily adjust your personalized Nomad Tours' travel plans by visiting the itinerary section. 
  If no trips are displayed, ensure you toggle on "Current Trip" in the Bucket List section. 
  You can also book accommodations, transportation, and activities directly through the planner, 
  making it simple to customize your adventure.`}
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton p={4} _hover="none" fontWeight="bold">
                  <Box flex="1" textAlign="left">
                    <Translate text="Can I set a budget limit in Nomad Tours for my trip?" />
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Translate
                    text="Yes, Nomad Tours offers a comprehensive budget planner that
                  allows you to set your financial preferences and supports
                  multiple currencies. This tool helps you track your expenses
                  while traveling, ensuring you stay on top of your spending."
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton p={4} _hover="none" fontWeight="bold">
                  <Box flex="1" textAlign="left">
                    <Translate text="Can I access Nomad Tours offline?" />
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Translate
                    text="Yes, you can access Nomad Tours offline! By downloading your
                  itinerary and essential information before your trip, you'll
                  be well-prepared for areas without internet connectivity. This
                  allows you to enjoy your adventure worry-free, even when
                  you're off the grid."
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton p={4} _hover="none" fontWeight="bold">
                  <Box flex="1" textAlign="left">
                    <Translate text=" Where can I receive support for using Nomad Tours?" />
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Translate
                    text="If you have any specific questions or comments, please fill
                  out our feedback form, and our team will get back to you as
                  soon as possible. We’re here to help you make the most of your
                  travel experience!"
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </Flex>
      </Box>

      {/* Footer Section */}
      <Footer />

      {showCookiePopup && <CookieConsentPopup onAccept={handleCookieAccept} />}
      <NewsletterSubscriptionPopup
        isOpen={isNewsletterOpen}
        onClose={handleNewsletterClose}
      />
      {/* <SurveyPopup
        isOpen={isSurveyOpen}
        onClose={handleSurveyPopupClose} // Use the renamed function here
      /> */}
      <BackToTopButton />
    </Box>
  );
};

export default HomePage;
