import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Image,
  Grid,
  GridItem,
  VStack,
} from "@chakra-ui/react";
import BackgroundSvg from "../assets/images/gradient-blob.svg";
import OutDoor from "../assets/images/out-door-holidays.jpg";
import OffGrid from "../assets/images/off-grid.jpg";
import RoadTrip from "../assets/images/road-trip.jpg";
import SkiTrip from "../assets/images/winter-adventure.jpg";
import HikingTrip from "../assets/images/hiking-trip.jpg";
import BackPackingTrip from "../assets/images/backpacking-trip.jpg";
import Translate from "../components/LanguageTranslation/Translate";

const TripSection = () => {
  const history = useHistory();

  const handleLinkClick = (message) => {
    localStorage.removeItem("conversation_id"); // Clear any existing conversation
    history.push(`/ava/chat?message=${encodeURIComponent(message)}`); // Redirect to the chat system with the clicked text
  };

  const trips = [
    {
      title: "Travel Adventure",
      tag: "Outdoor Holidays",
      imageUrl: OutDoor, // High resolution (default)
    },
    {
      title: "Camping Trip",
      tag: "Off-Grid",
      imageUrl: OffGrid, // High resolution (default)
    },
    {
      title: "Road Trip Adventure",
      tag: "Family-Friendly",
      imageUrl: RoadTrip, // High resolution (default)
    },
    {
      title: "Winter Adventure",
      tag: "Winter Sports",
      imageUrl: SkiTrip, // High resolution (default)
    },
    {
      title: "Hiking Expedition",
      tag: "Eco Tourism",
      imageUrl: HikingTrip, // High resolution (default)
    },
    {
      title: "Backpacking",
      tag: "Budget Friendly",
      imageUrl: BackPackingTrip, // High resolution (default)
    },
  ];

  return (
    <Box
      p={{ base: 8, md: 8, lg: 12 }}
      pl={60}
      pr={60}
      bg="#F3F4F6"
      position="relative"
      backgroundImage={`url(${BackgroundSvg})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(3, 0.5fr)",
          lg: "repeat(4, 250px)",
        }}
        templateRows={{
          base: "repeat(6, 100px)",
          md: "repeat(6, 100px)",
          lg: "repeat(5, 120px)",
        }}
        gap={4}
        justifyContent="center"
      >
        {/* Heading and Text */}
        <GridItem
          colStart={{ base: 1, md: 1, lg: 1 }}
          colEnd={{ base: 2, md: 3, lg: 3 }}
          rowStart={{ base: 1, md: 1, lg: 1 }}
          rowEnd={{ base: 2, md: 2, lg: 2 }}
        >
          <Box textAlign={{ base: "center", md: "left" }} p={4}>
            <Heading
              as="h2"
              fontSize={{ base: "30px", md: "35px", lg: "50px" }}
              fontFamily="Poppins, sans-serif"
              mb={4}
            >
              <Translate text="Click, Plan, Go" />
            </Heading>
            <Text fontSize={{ base: "sm", md: "md" }}>
              {/* Add your own constraints and requirements to customize a plan that works well for you. */}

              <Translate
                text="Full travel planning with itinerary, budget planner and packing
              list."
              />
            </Text>
          </Box>
        </GridItem>

        {/* Responsive Card Placements */}
        <GridItem
          colStart={{ base: 1, md: 1, lg: 1 }}
          rowStart={{ base: 2, md: 3, lg: 3 }}
          rowEnd={{ base: 3, md: 5, lg: 5 }}
          transition="transform 0.3s"
          _hover={{ transform: "scale(1.05)" }}
        >
          <Box
            as="a"
            href={trips[0]?.href}
            w="100%"
            h="100%"
            position="relative"
            overflow="hidden"
            borderRadius="xl"
            cursor="pointer"
            onClick={() => handleLinkClick("Plan a Travel Adventure")}
          >
            <Image
              src={trips[0]?.imageUrl}
              sizes="(max-width: 600px) 600px, (max-width: 1200px) 1024px, 1440px"
              alt={trips[0]?.title}
              w="100%"
              h="100%"
              objectFit="cover"
              objectPosition="center"
              filter="brightness(100%)"
              borderRadius="xl"
            />
            <Box
              position="absolute"
              bottom="0"
              left="0"
              w="100%"
              p={4}
              borderBottomRadius="xl"
              bg="rgba(0, 0, 0, 0.3)"
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={{ base: "lg", md: "xl" }}
                mb={0}
              >
                <Translate text={trips[0]?.title} />
              </Text>
              <Text
                // color="#153D16"
                color="#D98D41"
                fontSize="sm"
                mb={0}
                textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
              >
                <Translate text={trips[0]?.tag} />
              </Text>
            </Box>
          </Box>
        </GridItem>

        <GridItem
          colStart={{ base: 1, md: 1, lg: 2 }}
          rowStart={{ base: 3, md: 5, lg: 2 }}
          rowEnd={{ base: 4, md: 7, lg: 4 }}
          transition="transform 0.3s"
          _hover={{ transform: "scale(1.05)" }}
        >
          <Box
            as="a"
            href={trips[1]?.href}
            w="100%"
            h="100%"
            position="relative"
            overflow="hidden"
            borderRadius="xl"
            cursor="pointer"
            onClick={() => handleLinkClick("Plan a Camping Trip")}
          >
            <Image
              src={trips[1]?.imageUrl}
              sizes="(max-width: 600px) 600px, (max-width: 1200px) 1024px, 1440px"
              alt={trips[1]?.title}
              w="100%"
              h="100%"
              objectFit="cover"
              objectPosition="right"
              filter="brightness(100%)"
              borderRadius="xl"
            />
            <Box
              position="absolute"
              bottom="0"
              left="0"
              w="100%"
              p={4}
              borderBottomRadius="xl"
              bg="rgba(0, 0, 0, 0.3)"
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={{ base: "lg", md: "xl" }}
                mb={0}
              >
                <Translate text={trips[1]?.title} />
              </Text>
              <Text
                color="#D98D41"
                fontSize="sm"
                mb={0}
                textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
              >
                <Translate text={trips[1]?.tag} />
              </Text>
            </Box>
          </Box>
        </GridItem>

        <GridItem
          colStart={{ base: 1, md: 2, lg: 2 }}
          rowStart={{ base: 4, md: 2, lg: 4 }}
          rowEnd={{ base: 5, md: 4, lg: 6 }}
          transition="transform 0.3s"
          _hover={{ transform: "scale(1.05)" }}
        >
          <Box
            as="a"
            href={trips[2]?.href}
            w="100%"
            h="100%"
            position="relative"
            overflow="hidden"
            borderRadius="xl"
            cursor="pointer"
            onClick={() => handleLinkClick("Plan a Road Trip Adventure")}
          >
            <Image
              src={trips[2]?.imageUrl}
              sizes="(max-width: 600px) 600px, (max-width: 1200px) 1024px, 1440px"
              alt={trips[2]?.title}
              w="100%"
              h="100%"
              objectFit="cover"
              filter="brightness(100%)"
              borderRadius="xl"
            />
            <Box
              position="absolute"
              bottom="0"
              left="0"
              w="100%"
              p={4}
              borderBottomRadius="xl"
              bg="rgba(0, 0, 0, 0.3)"
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={{ base: "lg", md: "xl" }}
                mb={0}
              >
                <Translate text={trips[2]?.title} />
              </Text>
              <Text
                color="#D98D41"
                fontSize="sm"
                mb={0}
                textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
              >
                <Translate text={trips[2]?.tag} />
              </Text>
            </Box>
          </Box>
        </GridItem>

        <GridItem
          colStart={{ base: 1, md: 2, lg: 3 }}
          rowStart={{ base: 5, md: 4, lg: 1 }}
          rowEnd={{ base: 6, md: 6, lg: 3 }}
          transition="transform 0.3s"
          _hover={{ transform: "scale(1.05)" }}
        >
          <Box
            as="a"
            href={trips[3]?.href}
            w="100%"
            h="100%"
            position="relative"
            overflow="hidden"
            borderRadius="xl"
            cursor="pointer"
            onClick={() =>
              handleLinkClick("Plan a Winter Adventure & Ski Trip")
            }
          >
            <Image
              src={trips[3]?.imageUrl}
              sizes="(max-width: 600px) 600px, (max-width: 1200px) 1024px, 1440px"
              alt={trips[3]?.title}
              w="100%"
              h="100%"
              objectFit="cover"
              filter="brightness(100%)"
              borderRadius="xl"
            />
            <Box
              position="absolute"
              bottom="0"
              left="0"
              w="100%"
              p={4}
              borderBottomRadius="xl"
              bg="rgba(0, 0, 0, 0.4)"
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={{ base: "lg", md: "xl" }}
                mb={0}
              >
                <Translate text={trips[3]?.title} />
              </Text>
              <Text
                color="#D98D41"
                fontSize="sm"
                mb={0}
                textShadow="1px 1px 2px rgba(0, 0, 0, 0.7)"
              >
                <Translate text={trips[3]?.tag} />
              </Text>
            </Box>
          </Box>
        </GridItem>

        <GridItem
          colStart={{ base: 1, md: 3, lg: 3 }}
          rowStart={{ base: 2, md: 1, lg: 3 }}
          rowEnd={{ base: 3, md: 3, lg: 5 }}
          transition="transform 0.3s"
          _hover={{ transform: "scale(1.05)" }}
        >
          <Box
            as="a"
            href={trips[4]?.href}
            w="100%"
            h="100%"
            position="relative"
            overflow="hidden"
            borderRadius="xl"
            cursor="pointer"
            onClick={() => handleLinkClick("Plan a Hiking Expedition")}
          >
            <Image
              src={trips[4]?.imageUrl}
              //   srcSet={`
              //   ${trips[4]?.imageUrlSmall} 300w,
              //   ${trips[4]?.imageUrlMedium} 768w,
              //   ${trips[4]?.imageUrlLarge} 1024w
              // `}
              sizes="(max-width: 600px) 600px, (max-width: 1200px) 1024px, 1440px"
              alt={trips[4]?.title}
              w="100%"
              h="100%"
              objectFit="cover"
              filter="brightness(100%)"
              borderRadius="xl"
            />
            <Box
              position="absolute"
              bottom="0"
              left="0"
              w="100%"
              p={4}
              borderBottomRadius="xl"
              bg="rgba(0, 0, 0, 0.3)"
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={{ base: "lg", md: "xl" }}
                mb={0}
              >
                <Translate text={trips[4]?.title} />
              </Text>
              <Text
                color="#D98D41"
                fontSize="sm"
                mb={0}
                textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
              >
                <Translate text={trips[4]?.tag} />
              </Text>
            </Box>
          </Box>
        </GridItem>

        <GridItem
          colStart={{ base: 1, md: 3, lg: 4 }}
          rowStart={{ base: 6, md: 3, lg: 2 }}
          rowEnd={{ base: 7, md: 5, lg: 4 }}
          transition="transform 0.3s"
          _hover={{ transform: "scale(1.05)" }}
        >
          <Box
            as="a"
            href={trips[5]?.href}
            w="100%"
            h="100%"
            position="relative"
            overflow="hidden"
            borderRadius="xl"
            cursor="pointer"
            onClick={() => handleLinkClick("Plan a Backpacking")}
          >
            <Image
              src={trips[5]?.imageUrl}
              //   srcSet={`
              //   ${trips[5]?.imageUrlSmall} 300w,
              //   ${trips[5]?.imageUrlMedium} 768w,
              //   ${trips[5]?.imageUrlLarge} 1024w
              // `}
              sizes="(max-width: 600px) 600px, (max-width: 1200px) 1024px, 1440px"
              alt={trips[5]?.title}
              w="100%"
              h="100%"
              objectFit="cover"
              filter="brightness(100%)"
              borderRadius="xl"
            />
            <Box
              position="absolute"
              bottom="0"
              left="0"
              w="100%"
              p={4}
              borderBottomRadius="xl"
              bg="rgba(0, 0, 0, 0.3)"
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={{ base: "lg", md: "xl" }}
                mb={0}
              >
                <Translate text={trips[5]?.title} />
              </Text>
              <Text
                color="#D98D41"
                fontSize="sm"
                mb={0}
                textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
              >
                <Translate text={trips[5]?.tag} />
              </Text>
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default TripSection;
