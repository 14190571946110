import React from "react";
import {
  Box,
  Heading,
  useColorMode,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid,
  Text,
  Flex,
} from "@chakra-ui/react";
import Translate from "../components/LanguageTranslation/Translate";

const FAQBusinessPage = () => {
  const { colorMode } = useColorMode();

  return (
    <Box
    // bg="#E9F5E9"
    py={12}
    pt={0}
    pb={0}
    px={6}
    borderRadius="xl"
    mb={8}
    maxW={{ base: "auto", md: "80vw", lg: "80vw", xl: "80vw" }}
    mx="auto"
  >
    <Flex
      direction={{ base: "column", md: "row" }}
      alignItems="right"
      w="100%"
    >
      <Box
        flex="2"
        borderRadius="lg"
        p={6}
        pt={0}
        textAlign={{ base: "center", md: "left" }}
        display="flex"
        flexDirection="column"
        justifyContent="top"
        h="100%"
      >
        <Heading
          as="h2"
          size="xl"
          fontFamily="Poppins, sans-serif"
          mb={4}
          ml={{ md: "6", lg: "6", xl: "10" }}
          textAlign={{ base: "left", md: "left", lg: "left", xl: "left" }}
        >
          FAQs
        </Heading>
      </Box>
      <Box flex="2" w={{ base: "100%", md: "50%", lg: "50%", xl: "50%" }}>
        <Accordion
          allowToggle
          w={{ base: "100%", md: "100%", lg: "100%", xl: "70%" }}
        >
          <AccordionItem>
            <AccordionButton p={4} _hover="none" fontWeight="bold">
              <Box flex="1" textAlign="left">
                <Translate text="What is Nomad Tours?" />
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Translate
                text="Nomad Tours creates personalized travel plans in seconds,
              tailored to your outdoor lifestyle. Stress-free planning and
              epic adventures await!"
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton p={4} _hover="none" fontWeight="bold">
              <Box flex="1" textAlign="left">
                <Translate text="Who can use Nomad Tours?" />
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Translate
                text="Nomad Tours is an AI-powered travel planner designed for outdoor
              and adventure travelers. It creates personalized travel
              itineraries for hikers, campers, backpackers, families, digital
              nomads, photographers, wildlife enthusiast, overlanders, and
              much more."
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton p={4} _hover="none" fontWeight="bold">
              <Box flex="1" textAlign="left">
                <Translate text="Is Nomad Tours free to use?" />
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Translate
                text="Yes, Nomad Tours is completely free! However, donations are
              greatly appreciated to help maintain the platform and support
              our mission to protect a greener world and improve (mental)
              health for all."
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton p={4} _hover="none" fontWeight="bold">
              <Box flex="1" textAlign="left">
                <Translate text="How do I adjust my Nomad Tours' itinerary?" />
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Translate
                text="You can easily adjust your personalized Nomad Tours' travel
              plans by visiting the itinerary section. If no trips are
              displayed, ensure you toggle on Current Trip in the Bucket
              List section. You can also book accommodations, transportation,
              and activities directly through the planner, making it simple to
              customize your adventure.is Nomad TourWhats?"
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton p={4} _hover="none" fontWeight="bold">
              <Box flex="1" textAlign="left">
                <Translate text="Can I set a budget limit in Nomad Tours for my trip?" />
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Translate
                text="Yes, Nomad Tours offers a comprehensive budget planner that
              allows you to set your financial preferences and supports
              multiple currencies. This tool helps you track your expenses
              while traveling, ensuring you stay on top of your spending."
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton p={4} _hover="none" fontWeight="bold">
              <Box flex="1" textAlign="left">
                <Translate text="Can I access Nomad Tours offline?" />
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Translate
                text="Yes, you can access Nomad Tours offline! By downloading your
              itinerary and essential information before your trip, you'll be
              well-prepared for areas without internet connectivity. This
              allows you to enjoy your adventure worry-free, even when you're
              off the grid."
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton p={4} _hover="none" fontWeight="bold">
              <Box flex="1" textAlign="left">
                <Translate text="Where can I receive support for using Nomad Tours?" />
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Translate
                text="If you have any specific questions or comments, please fill out
              our feedback form, and our team will get back to you as soon as
              possible. We’re here to help you make the most of your travel
              experience!"
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Flex>
    </Box>
  );
};

export default FAQBusinessPage;
