import React from "react";
import {
  Box,
  Icon,
  Text,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";

const PackingCategory = ({
  category,
  selectedCategory,
  setSelectedCategory,
}) => {
  // Calculate the progress percentage
  const progress = (category.completed / category.total) * 100;

  return (
    <Box
      p={4}
      border="1px solid"
      borderColor={selectedCategory === category.name ? "#286328" : "gray.200"}
      borderRadius="xl"
      onClick={() => setSelectedCategory(category.name)}
      cursor="pointer"
      textAlign="center"
    >
      <CircularProgress
        value={progress}
        size="60px"
        color="#286328"
        thickness="4px"
      >
        <CircularProgressLabel>
          <Icon
            as={category.icon}
            w={6}
            h={6}
            color={selectedCategory === category.name ? "#286328" : "gray.600"}
          />
        </CircularProgressLabel>
      </CircularProgress>
      <Text fontWeight="bold" mt={2}>
        {category.name}
      </Text>
      <Text fontSize="xs" color="gray.500">
        {category.completed}/{category.total}
      </Text>
    </Box>
  );
};

export default PackingCategory;
