import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  VStack,
  HStack,
  Progress,
  Select,
  Icon,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import axiosInstance from "../../helpers/axiosInstance";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  FaPlane,
  FaHotel,
  FaCar,
  FaUtensils,
  FaShoppingBag,
  FaTshirt,
  FaGift,
  FaGlassCheers,
  FaTools,
  FaMapMarkerAlt,
  FaCamera,
  FaTrash,
} from "react-icons/fa"; // Add category icons
import { useCurrency } from "../CurrencyExchange/CurrencyContext";
import BudgetManager from "./BudgetManager";
import Translate from "../../components/LanguageTranslation/Translate";

// Register the necessary Chart.js elements
ChartJS.register(ArcElement, Tooltip, Legend);

const ExpenseChart = ({
  expenses,
  setExpenses,
  categories,
  setCategories,
  selectedTripId,
}) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [aggregatedCategoryExpenses, setAggregatedCategoryExpenses] = useState(
    []
  );
  const [total, setTotal] = useState(0); // New state for total
  const { convertCurrency, currency } = useCurrency();
  const [totalBudget, setTotalBudget] = useState(""); // Default total budget
  const [newTotalBudget, setNewTotalBudget] = useState(totalBudget); // User input for new total budget
  const [isEditing, setIsEditing] = useState(false); // To toggle between text and input

  const [totalDailyBudget, setTotalDailyBudget] = useState(""); // Default total budget
  const [newTotalDailyBudget, setNewTotalDailyBudget] =
    useState(totalDailyBudget); // User input for new total budget
  const [isDailyEditing, setDailyIsEditing] = useState(false); // To toggle between text and input

  const categoryIcons = {
    Places: { icon: FaMapMarkerAlt, color: "#FF6384" }, // Pink
    Accommodations: { icon: FaHotel, color: "#36A2EB" }, // Blue
    Transportation: { icon: FaCar, color: "#FFCE56" }, // Yellow
    Food: { icon: FaUtensils, color: "#4CAF50" }, // Green
    Shopping: { icon: FaShoppingBag, color: "#FF9800" }, // Orange
    Entertainment: { icon: FaGlassCheers, color: "#FFC107" }, // Amber
    Gifts: { icon: FaGift, color: "#AB47BC" }, // Purple
    Clothing: { icon: FaTshirt, color: "#FF7043" }, // Deep Orange
    Photography: { icon: FaCamera, color: "#FF5722" }, // Orange Red
    Tools: { icon: FaTools, color: "#757575" }, // Grey
    Flights: { icon: FaPlane, color: "#29B6F6" }, // Light Blue
  };

  // Fetch expenses for the selected trip
  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await axiosInstance.get(
          `/budget_planner/${selectedTripId}/expenses`
        );
        // console.log("fetchExpenses", response.data);
        setExpenses(response.data);
      } catch (error) {
        console.error("Error fetching expenses:", error);
      }
    };

    if (selectedTripId) {
      fetchExpenses();
    }
  }, [selectedTripId]);

  // Fetch categories based on selectedTripId
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get(
          `/budget_planner/${selectedTripId}/categories`
        );
        setCategories(response.data); // Set categories based on API response
        // console.log("fetchCategories", response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    if (selectedTripId) {
      fetchCategories();
    }
  }, [selectedTripId]);

  // Aggregate expenses per category and calculate total
  useEffect(() => {
    if (!selectedCategory) {
      // Calculate total expense for each category when no category is selected
      const aggregatedExpenses = categories.map((category) => {
        const categoryExpenses = expenses.find(
          (expense) => expense.category_name === category.name
        );
        const totalExpense = categoryExpenses
          ? categoryExpenses.expenses.reduce(
              (total, exp) => total + exp.cost,
              0
            )
          : 0;
        return {
          name: category.name,
          amount: totalExpense,
        };
      });

      setAggregatedCategoryExpenses(aggregatedExpenses);

      // Calculate the total amount for all categories
      const totalAmount = aggregatedExpenses.reduce(
        (acc, category) => acc + category.amount,
        0
      );
      setTotal(totalAmount); // Set the total state with the calculated total amount
    }
  }, [categories, expenses, selectedCategory]);

  // Filter expenses based on selected category
  useEffect(() => {
    if (selectedCategory) {
      const categoryExpenses = expenses.find(
        (expense) => expense.category_name === selectedCategory
      );
      setFilteredExpenses(categoryExpenses ? categoryExpenses.expenses : []);
    } else {
      setFilteredExpenses(aggregatedCategoryExpenses);
    }
  }, [selectedCategory, aggregatedCategoryExpenses, expenses]);

  // Chart Data
  const chartData = {
    labels: filteredExpenses.map((expense) =>
      selectedCategory ? expense.expense_name : expense.name
    ),
    datasets: [
      {
        data: filteredExpenses.map((expense) =>
          selectedCategory ? expense.cost : expense.amount
        ),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4CAF50",
          "#FF9800",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4CAF50",
          "#FF9800",
        ],
      },
    ],
  };

  const handleTotalBudgetChange = (e) => {
    setNewTotalBudget(e.target.value);
  };

  return (
    <Box
      w="80%"
      p={2}
      bg="#FAFDFC"
      borderRadius="lg"
      shadow="lg"
      h="100vh"
      overflowY="auto"
      sx={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <VStack spacing={4} align="stretch">
        <BudgetManager
          total={total}
          currency={currency}
          convertCurrency={convertCurrency}
          selectedTripId={selectedTripId}
        />

        <Box
          bg="white"
          p={4}
          borderRadius="xl"
          //   h="75vh"
        >
          {/* Category and Expense Select Section */}
          <HStack justify="space-between" w="100%" mt={4}>
            <Select
              placeholder={Translate({
                text: "Categories",
                as: "string",
              })}
              size="sm"
              variant="filled"
              bg="#FAFDFC"
              _hover="none"
              borderRadius="md"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              {/* <option value="">All Categories</option> */}
              {categories.map((category, index) => (
                <option key={index} value={category.name}>
                  <Translate text={category.name} />
                </option>
              ))}
            </Select>

            <Select
              placeholder={Translate({
                text: "Expenses",
                as: "string",
              })}
              size="sm"
              variant="filled"
              bg="white"
              border="1px"
              borderColor="blackAlpha.50"
              borderRadius="md"
              _hover="none"
              isDisabled={!selectedCategory || filteredExpenses.length === 0}
            >
              {filteredExpenses.map((expense, index) => {
                const convertedCost = parseFloat(
                  convertCurrency(expense.cost, "USD")
                );
                return (
                  <option key={index} value={expense.expense_name}>
                    <Translate text={expense.expense_name} /> ({currency}{" "}
                    {convertedCost?.toFixed(2) ?? "0.00"})
                  </option>
                );
              })}
            </Select>
          </HStack>

          {/* Chart and Legend Section */}
          <Flex justify="center" align="center" w="100%" mt={6}>
            {/* Chart Section */}
            <Box w="30%" mr={8}>
              <Doughnut
                data={chartData}
                options={{
                  cutout: "70%",
                  plugins: {
                    legend: {
                      display: false, // Disable default legend
                    },
                  },
                }}
              />
            </Box>

            {/* Custom Legend */}
            <VStack spacing={3} align="start">
              {chartData.labels.map((label, index) => (
                <HStack key={index}>
                  <Box
                    w="12px"
                    h="12px"
                    bg={chartData.datasets[0].backgroundColor[index]}
                    borderRadius="full"
                  />
                  <Text mb={0}>
                    <Translate text={label} />
                  </Text>
                </HStack>
              ))}
            </VStack>
          </Flex>

          {/* Categories Section (Under the Chart) */}
          <VStack w="100%" align="stretch" mt={6} spacing={3}>
            {filteredExpenses.map((expense, index) => {
              const convertedCost = parseFloat(
                convertCurrency(expense.cost, "USD")
              );
              const convertedAmount = parseFloat(
                convertCurrency(expense.amount, "USD")
              );
              const convertedTotalAmount = parseFloat(
                convertCurrency(total, "USD")
              );
              return (
                <Flex
                  justify="space-between"
                  key={index}
                  borderBottom="1px"
                  borderColor="blackAlpha.100"
                  pb={2}
                >
                  <HStack>
                    <Box
                      w="40px"
                      h="40px"
                      bg={categoryIcons[expense.name]?.color || "#FF9800"}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="full"
                    >
                      <Icon
                        as={categoryIcons[expense.name]?.icon || FaShoppingBag} // Default icon
                        color="white"
                        boxSize={5}
                      />
                    </Box>
                    <Text mb={0} ml={2}>
                      <Translate
                        text={
                          selectedCategory ? expense.expense_name : expense.name
                        }
                      />
                    </Text>
                  </HStack>

                  <Text fontWeight="bold">
                    {currency}{" "}
                    {selectedCategory
                      ? convertedCost
                        ? convertedCost.toFixed(2)
                        : "0.00"
                      : convertedAmount
                      ? convertedAmount.toFixed(2)
                      : "0.00"}
                  </Text>
                </Flex>
              );
            })}

            {/* Total Amount Spent */}
            <HStack justify="right" pt={2}>
              <Text fontWeight="bold">
                <Translate text="Total" />
              </Text>
              <Text fontWeight="bold">=</Text>
              <Text fontWeight="bold">
                {currency}{" "}
                {convertCurrency(total, "USD") &&
                !isNaN(convertCurrency(total, "USD"))
                  ? Number(convertCurrency(total, "USD")).toFixed(2)
                  : "0.00"}
              </Text>
            </HStack>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default ExpenseChart;
