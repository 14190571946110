import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";

const breakpoints = {
  sm: "480px",
  md: "768px",
  lg: "1024px",
  xl: "1440px", // Adjusted xl breakpoint to 1200px instead of 1280px
  "2xl": "1536px", // You can add additional breakpoints if needed
};

const theme = extendTheme({
  breakpoints,
  fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: true,
  },
  colors: {
    customGreen: {
      500: "#286328",
    },
  },
  components: {
    Popover: {
      baseStyle: {
        content: {
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          _checked: {
            bg: "customGreen.500",
          },
        },
      },
    },
  },
});

export default theme;

const container = document.getElementById("root"); // Get the container to mount your app
const root = createRoot(container); // Create a root

root.render(
  // Use the root to render your app
  <I18nextProvider i18n={i18n}>
    <ChakraProvider theme={theme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <App />
      </GoogleOAuthProvider>
    </ChakraProvider>
  </I18nextProvider>
);
