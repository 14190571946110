import React from "react";
import { Flex, ListItem, ListIcon, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import Translate from "../components/LanguageTranslation/Translate";

function SidebarItem({ icon, to, label, isOpen, onClick }) {
  const location = useLocation(); // Gets the current location
  const isActive = to && location.pathname === to; // Checks if the current path is the link's target path, only if `to` is defined

  const handleClick = (e) => {
    if (onClick) {
      e.preventDefault(); // Prevent default link behavior if onClick is provided
      onClick(); // Execute the onClick function
    }
  };

  return (
    <ListItem
      _hover={{
        bg: isActive ? "blackAlpha.100" : "blackAlpha.100", // Keep active color or apply hover color
        borderRadius: "md",
      }}
      p={isOpen ? 1 : 1}
      bg={isActive ? "blackAlpha.50" : "transparent"} // Active background color
      borderRadius={isActive ? "md" : "none"}
    >
      <ChakraLink
        as={to ? Link : "div"} // Use <Link> only if `to` is defined
        to={to || "#"} // Use "#" or a dummy link if `to` is undefined
        onClick={handleClick} // Handle clicks if onClick is provided
        style={{ textDecoration: "none", width: "100%" }}
        display="flex"
        alignItems="center"
      >
        <Flex align="center" ml={isOpen ? 5 : 5}>
          <ListIcon
            as={icon}
            color={isActive ? "black" : "black"}
            mr={isOpen ? 3 : 6}
          />
          {isOpen && (
            <Text fontWeight="normal" fontSize="sm" color="#000000" m={0}>
              {typeof label === "string" ? (
                <Translate text={label} />
              ) : (
                label // Render JSX directly if `label` is a JSX element
              )}
            </Text>
          )}
        </Flex>
      </ChakraLink>
    </ListItem>
  );
}

export default SidebarItem;
