import React, { useState, useEffect } from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import ItineraryDetails from "../Itinerary/ItineraryDetails";
import ActivitiesList from "../Itinerary/ActivitiesList";
import HotelsList from "../Itinerary/HotelsList";
import FlightsList from "../Itinerary/FlightsList";
import AllActivitiesList from "../Itinerary/AllActivitiesList";
import RVsList from "../Itinerary/RVsList";
import GoogleMapsLink from "../Itinerary/MapEmbed";
// import NoOngoingTrip from "../NoOngoingTrip";
import ItineraryHeader from "./ItineraryHeader";
import axiosInstance from "../../helpers/axiosInstance"; // Make sure to import your axios instance

const ItineraryDetail = () => {
  const { colorMode } = useColorMode();
  const { tripId } = useParams(); // Extract the trip ID from the route parameters
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [itinerary, setItinerary] = useState(null);
  const [hotels, setHotels] = useState([]);
  const [hotelLocation, setHotelLocation] = useState([]);
  const [flights, setFlights] = useState([]);
  const [rvs, setRVs] = useState([]);
  const [activities, setActivities] = useState([]); // Used for AllActivitiesList
  const [activitiesLocation, setActivitiesLocation] = useState([]);
  const [selectedDayIndex, setSelectedDayIndex] = useState(0);
  const [view, setView] = useState(null);
  const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
  const [tripDetails, setTripDetails] = useState(null);

  useEffect(() => {
    const fetchTripById = async () => {
      try {
        // console.log("tripId", tripId);
        const response = await axiosInstance.get(`/bucket-list/${tripId}`);
        const trip = response.data.data;

        if (trip) {
          setItinerary(trip.trip_plan); // Access the `itinerary` array directly

          setHotels(trip.home_to_go_api_response[0]?.home_to_go_response || []);
          setHotelLocation(trip.home_to_go_api_response[0]?.location || "");

          setFlights(trip.flight_info[0] || []);
          setRVs(trip?.outdoorsy_api_response[0]?.outdoorsy_response.rentals || []);

          setActivities(trip.viator_api_response[0]?.viator_response || []);
          setActivitiesLocation(trip.viator_api_response[0]?.location || "");
          setLastUpdatedDate(trip.last_updated);
          setTripDetails(trip.trip_details);
        } else {
          // console.log("No trip found with the given ID");
        }
      } catch (error) {
        console.error("Error fetching trip by ID:", error);
      }
    };

    fetchTripById();
  }, [tripId]);

  const handleActivityClick = (latitude, longitude) => {
    setSelectedLocation({ latitude, longitude });
    setView("map"); // Switch view to map when a location is selected
  };

  const handleUpdateClick = () => {
    setView("activities"); // Switch view to activities when update button is clicked
  };

  const handleDayChange = (index) => {
    setSelectedDayIndex(index);
    setView("activities"); // Ensure activities view is shown when switching days
  };

  const handlePlacesUpdate = (updatedPlaces) => {
    const newItinerary = { ...itinerary }; // Clone the itinerary object
    newItinerary.itinerary[selectedDayIndex].activity_detail_list =
      updatedPlaces;
    setItinerary(newItinerary);
  };

  const handleHotelClick = (hotelName) => {
    const updatedHotels = hotels.filter((hotel) => hotel.name !== hotelName);
    setHotels(updatedHotels);
  };

  const handleHotelUpdateClick = () => {
    setView("hotels"); // Switch view to hotels when the "Update Hotels" button is clicked
  };

  const handleRVsClick = (rv) => {
    const updatedRVs = rvs.filter((r) => r !== rv);
    setRVs(updatedRVs);
  };

  const handleRVsUpdateClick = () => {
    setView("rvs"); // Switch view to hotels when the "Update Hotels" button is clicked
  };

  const handleFlightClick = (flight) => {
    const updatedFlights = flights.filter((f) => f !== flight);
    setFlights(updatedFlights);
  };

  const handleFlightUpdateClick = () => {
    setView("flights"); // Switch view to flights when the "Update Flights" button is clicked
  };

  const handleAllActivitiesClick = (activity) => {
    const updatedActivities = activities.filter((a) => a !== activity);
    setActivities(updatedActivities);
  };

  const handleAllActivitiesUpdateClick = () => {
    setView("allActivities"); // Switch view to allActivities when the "Update Activities" button is clicked
  };

  return (
    <Box
      display="flex"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
      w="100%"
      h="100vh"
      overflowY="hidden"
    >
      <Box
        flex="5"
        w="50%"
        display="flex"
        flexDirection="column"
        ml={0}
        bg={colorMode === "dark" ? "gray.700" : "white"}
        overflowY="hidden"
      >
        <ItineraryHeader tripId={tripId}/>

        <Box
          flex="9"
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          maxH="90vh"
          bg={colorMode === "dark" ? "gray.700" : "white"}
        >
          <ItineraryDetails
            item={itinerary}
            activities={activities} // Pass for AllActivitiesList
            hotels={hotels}
            flights={flights}
            RVs={rvs}
            onActivityClick={handleActivityClick}
            onUpdateClick={handleUpdateClick}
            onDayChange={handleDayChange}
            selectedDayIndex={selectedDayIndex}
            handleHotelUpdateClick={handleHotelUpdateClick}
            handleRVsUpdateClick={handleRVsUpdateClick}
            handleFlightUpdateClick={handleFlightUpdateClick}
            handleAllActivitiesUpdateClick={handleAllActivitiesUpdateClick}
            lastUpdatedDate={lastUpdatedDate}
            tripDetails={tripDetails}
            tripId={tripId}
          />
        </Box>
      </Box>

      <Box
        flex="5"
        w="50%"
        h="100vh"
        backgroundPosition="center"
        display="flex"
        flexDirection="column"
        borderLeftRadius="xl"
        boxLeftShadow="xl"
      >
        {view === "map" && (
          <GoogleMapsLink
            latitude={selectedLocation.latitude}
            longitude={selectedLocation.longitude}
          />
        )}
        {view === "activities" && (
          <ActivitiesList
            places={
              itinerary.itinerary[selectedDayIndex]?.activity_detail_list || []
            }
            setPlaces={handlePlacesUpdate}
            onActivityClick={handleActivityClick}
            tripId={tripId}
            selectedDayIndex={selectedDayIndex}
            itinerary={itinerary}
            setItinerary={setItinerary}
          />
        )}

        {view === "hotels" && (
          <HotelsList
            hotels={hotels}
            setHotels={setHotels}
            onHotelClick={handleHotelClick}
            tripId={tripId} // Pass the tripId to HotelsList
            location={hotelLocation}
          />
        )}
        {view === "flights" && (
          <FlightsList
            flights={flights}
            setFlights={setFlights}
            onFlightClick={handleFlightClick}
            tripId={tripId}
          />
        )}
        {view === "allActivities" && (
          <AllActivitiesList
            activities={activities} // Use activities state for AllActivitiesList
            setActivities={setActivities}
            onActivityClick={handleAllActivitiesClick}
            tripId={tripId}
            location={activitiesLocation}
          />
        )}

        {view === "rvs" && (
              <RVsList
                rvs={rvs}
                setRVs={setRVs}
                onRVsClick={handleRVsClick}
                tripId={tripId} // Pass the tripId to HotelsList
                location={hotelLocation}
                setView={setView}
              />
            )}
      </Box>
    </Box>
  );
};

export default ItineraryDetail;
