import React from "react";
import {
  Box,
  Text,
  SimpleGrid,
  Flex,
  Icon,
  Avatar,
} from "@chakra-ui/react";
import { FaCog } from "react-icons/fa"; // Icon for Settings
import { useHistory } from "react-router-dom";

const UserAccount = () => {
  const history = useHistory();

  const handleCardClick = (path) => {
    history.push(path);
  };
  
  const username = localStorage.getItem("username");
  const email = localStorage.getItem("email");

  return (
    <Box p={6}>
      <Flex alignItems="center" mb={6} p={6} shadow="md"
          borderWidth="1px"
          borderRadius="lg">
        <Flex alignItems="center" cursor="pointer">
          <Avatar name={username} src="https://bit.ly/broken-link" size="md" />
          <Box ml={2}>
            <Text fontWeight="bold" fontSize="md" mb={0} ml={2}>
              {username}
            </Text>
            <Text fontWeight="semibold" fontSize="xs" mb={0} ml={2}>
              {email}
            </Text>
          </Box>
        </Flex>
      </Flex>

      <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={6}>
        
        {/* Card for Settings and Privacy */}
        <Box
          p={5}
          shadow="md"
          borderWidth="1px"
          borderRadius="lg"
          onClick={() => handleCardClick("/settings/privacy")} // Redirect to settings page
          cursor="pointer"
          _hover={{ shadow: "lg" }}
        >
          <Flex alignItems="center">
            <Icon as={FaCog} w={10} h={10} mr={4} /> {/* Settings Icon */}
            <Box>
              <Text fontSize="xl" fontWeight="bold">
                Settings and Privacy
              </Text>
              <Text mt={2}>
                Manage your account privacy and settings.
              </Text>
            </Box>
          </Flex>
        </Box>

        {/* Existing cards */}
        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
          <Text fontSize="lg" fontWeight="bold">
            Contact Us
          </Text>
          <Text mt={2}>info@nomadtours.io</Text>
        </Box>

        <Box
          p={5}
          shadow="md"
          borderWidth="1px"
          borderRadius="lg"
          onClick={() => handleCardClick("/policies/privacy-policy")}
          cursor="pointer"
          _hover={{ shadow: "lg" }}
        >
          <Text fontSize="lg" fontWeight="bold">
            Privacy Policy
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default UserAccount;

// import React, { useEffect, useState, useRef } from "react";
// import {
//   Box,
//   Text,
//   SimpleGrid,
//   Flex,
//   Icon,
//   Avatar,
// } from "@chakra-ui/react";
// import { FaCog } from "react-icons/fa"; // Icon for Settings
// import { useHistory } from "react-router-dom";
// import { useLanguage } from "../LanguageTranslation/LanguageContext"; // Import Language Context
// import SettingsMenu from "../common/SettingsMenu";

// const UserAccount = () => {
//   const history = useHistory();
//   const { language, translateText } = useLanguage(); // Access the language and translateText function

//   const handleCardClick = (path) => {
//     history.push(path);
//   };

//   const username = localStorage.getItem("username");
//   const email = localStorage.getItem("email");

//   // State variables for translated text
//   const [settingsText, setSettingsText] = useState("Settings and Privacy");
//   const [manageText, setManageText] = useState("Manage your account privacy and settings.");
//   const [contactText, setContactText] = useState("Contact Us");
//   const [privacyPolicyText, setPrivacyPolicyText] = useState("Privacy Policy");
//   const [infoText, setInfoText] = useState("info@nomadtours.io");

//   const isMounted = useRef(false); // Track if component is mounted

//   // Translate text when language changes
//   useEffect(() => {
//     const translateUI = async () => {
//       try {
//         if (isMounted.current) {
//           setSettingsText(await translateText("Settings and Privacy", language));
//           setManageText(await translateText("Manage your account privacy and settings.", language));
//           setContactText(await translateText("Contact Us", language));
//           setPrivacyPolicyText(await translateText("Privacy Policy", language));
//           setInfoText(await translateText("info@nomadtours.io", language));
//         }
//       } catch (error) {
//         console.error("Error translating text:", error);
//       }
//     };

//     if (!isMounted.current) {
//       isMounted.current = true; // Prevents initial infinite loop
//     } else {
//       translateUI();
//     }
//   }, [language, translateText]); // Only run when the language changes

//   return (
//     <Box p={6}>
//       <Flex alignItems="center" mb={6} p={6} shadow="md"
//         borderWidth="1px"
//         borderRadius="lg">
//         <Flex alignItems="center" cursor="pointer">
//           <Avatar name={username} src="https://bit.ly/broken-link" size="md" />
//           <Box ml={2}>
//             <Text fontWeight="bold" fontSize="md" mb={0} ml={2}>
//               {username}
//             </Text>
//             <Text fontWeight="semibold" fontSize="xs" mb={0} ml={2}>
//               {email}
//             </Text>
//           </Box>
//           <SettingsMenu />
//         </Flex>
//       </Flex>

//       <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={6}>

//         {/* Card for Settings and Privacy */}
//         <Box
//           p={5}
//           shadow="md"
//           borderWidth="1px"
//           borderRadius="lg"
//           onClick={() => handleCardClick("/settings/privacy")} // Redirect to settings page
//           cursor="pointer"
//           _hover={{ shadow: "lg" }}
//         >
//           <Flex alignItems="center">
//             <Icon as={FaCog} w={10} h={10} mr={4} /> {/* Settings Icon */}
//             <Box>
//               <Text fontSize="xl" fontWeight="bold">
//                 {settingsText} {/* Translated text for Settings */}
//               </Text>
//               <Text mt={2}>
//                 {manageText} {/* Translated text for Manage */}
//               </Text>
//             </Box>
//           </Flex>
//         </Box>

//         {/* Contact Us Card */}
//         <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
//           <Text fontSize="lg" fontWeight="bold">
//             {contactText} {/* Translated text for Contact Us */}
//           </Text>
//           <Text mt={2}>{infoText}</Text> {/* Translated text for contact info */}
//         </Box>

//         {/* Privacy Policy Card */}
//         <Box
//           p={5}
//           shadow="md"
//           borderWidth="1px"
//           borderRadius="lg"
//           onClick={() => handleCardClick("/policies/privacy-policy")}
//           cursor="pointer"
//           _hover={{ shadow: "lg" }}
//         >
//           <Text fontSize="lg" fontWeight="bold">
//             {privacyPolicyText} {/* Translated text for Privacy Policy */}
//           </Text>
//         </Box>
//       </SimpleGrid>
//     </Box>
//   );
// };

// export default UserAccount;
