import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
  useToast,
  HStack,
  Heading,
  Textarea,
  Progress,
  VStack,
  Checkbox,
} from "@chakra-ui/react";
import AvatarVideo from "../assets/videos/Animated_avatar.mp4"; // Importing the avatar video
import axiosInstance from "../helpers/axiosInstance"; // Import your axios instance
import CookieConsent from "react-cookie-consent";

const CookieConsentPopup = ({ onAccept }) => {
  const [showConfigure, setShowConfigure] = useState(false); // Toggle configuration options
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true, // Necessary cookies are always enabled
    analytics: false,
    marketing: false,
  });

  // Handle changes in cookie preferences
  const handlePreferenceChange = (category) => {
    setCookiePreferences((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const savePreferences = () => {
    // Save preferences to localStorage or cookie
    localStorage.setItem("cookiePreferences", JSON.stringify(cookiePreferences));
    onAccept(); // Mark cookies as accepted
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept All"
      cookieName="nomadToursCookieConsent"
      style={{ background: "none", color: "white" }}
      expires={150}
      overlay={false}
      contentClasses="content"
      ButtonComponent={() => null} // Prevent default button rendering
    >
      <Box
        p={{ base: 3, md: 4 }}
        bg="gray.800"
        color="white"
        borderRadius="md"
        boxShadow="lg"
        zIndex="1000"
        maxW={{ base: "90%", md: "400px" }}
      >
        <Text mb={2} fontSize={{ base: "sm", md: "md" }}>
          Nomad Tours uses cookies.
        </Text>
        <Text fontSize={{ base: "xs", md: "sm" }} mb={4}>
          By choosing "Accept All", you consent to our use of cookies and other tracking technologies.
        </Text>

        {!showConfigure ? (
          <>
            <Button
              mr={4}
              onClick={() => savePreferences()}
              colorScheme="red"
              size={{ base: "sm", md: "md" }}
            >
              Accept All
            </Button>
            <Button
              variant="outline"
              colorScheme="whiteAlpha"
              size={{ base: "sm", md: "md" }}
              onClick={() => setShowConfigure(true)} // Show configuration options
            >
              Configure
            </Button>
          </>
        ) : (
          <VStack align="start" spacing={3} mt={4}>
            <Checkbox
              isChecked={cookiePreferences.necessary}
              isDisabled
              colorScheme="green"
            >
              Necessary (Always enabled)
            </Checkbox>
            <Checkbox
              isChecked={cookiePreferences.analytics}
              onChange={() => handlePreferenceChange("analytics")}
              colorScheme="green"
            >
              Analytics Cookies
            </Checkbox>
            <Checkbox
              isChecked={cookiePreferences.marketing}
              onChange={() => handlePreferenceChange("marketing")}
              colorScheme="green"
            >
              Marketing Cookies
            </Checkbox>

            <Button
              color="white"
              bg="#286328"
              _hover="none"
              _active="none"
              size={{ base: "sm", md: "md" }}
              onClick={savePreferences}
              mt={4}
            >
              Save Preferences
            </Button>
          </VStack>
        )}
      </Box>
    </CookieConsent>
  );
};


const NewsletterSubscriptionPopup = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState(""); // State to capture email input
  const toast = useToast();

  const handleSubscribe = async () => {
    if (!email) {
      toast({
        title: "Email Required",
        description: "Please enter a valid email address.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axiosInstance.post("/newsletter/", { email });
      if (response.status === 201) {
        toast({
          title: "Subscribed!",
          description: "You've successfully subscribed to the newsletter.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setEmail(""); // Clear the email input after successful submission
        onClose(); // Close the modal after successful subscription
      } else {
        toast({
          title: "Subscription Failed",
          description: "Unable to subscribe. Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while subscribing. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInRight"
      size="md"
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent
        bg="gray.800"
        color="white"
        mr={{ base: 2, md: 4 }}
        mt={{ base: "auto", md: "10vh" }}
        right={0}
        position="fixed"
        maxW={{ base: "90%", md: "400px" }}
      >
        <ModalCloseButton />
        <ModalBody>
          <HStack alignItems="center" spacing={4} mt={8} mb={4}>
            {/* Avatar video */}
            <Box
              as="video"
              src={AvatarVideo}
              borderRadius="full"
              autoPlay
              loop
              muted
              width={{ base: "40px", md: "80px" }}
              height={{ base: "40px", md: "80px" }}
              objectFit="cover"
            />

            <Heading
              color="white"
              as="h4"
              size={{ base: "sm", md: "md" }}
              mb={0}
            >
              Join the Green & Wild Community!
            </Heading>
          </HStack>
          <Text mb={4} textAlign="left" fontSize={{ base: "sm", md: "md" }}>
            Subscribe to Nomad Tours' newsletter for personalized travel tips,
            epic outdoor experiences, and exclusive offers.
          </Text>
          <Input
            placeholder="Enter your email..."
            mb={4}
            borderColor="gray.600"
            size={{ base: "sm", md: "md" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#286328"
            color="white"
            _hover="none"
            mr={3}
            onClick={handleSubscribe}
            size={{ base: "sm", md: "md" }}
          >
            Subscribe
          </Button>
          <Button
            variant="outline"
            colorScheme="whiteAlpha"
            onClick={onClose}
            size={{ base: "sm", md: "md" }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SurveyPopup = ({ isOpen, onClose }) => {
  const [surveyData, setSurveyData] = useState(null); // State to store survey data
  const [responses, setResponses] = useState([]); // State to store all the answers
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track current question index
  const toast = useToast();

  // Fetch the survey data when the modal is opened
  useEffect(() => {
    if (isOpen) {
      const fetchSurveyData = async () => {
        try {
          const response = await axiosInstance.get("/survey/fetch"); // Fetch the survey
          if (response.status === 200 && response.data.survey) {
            const survey = response.data.survey;
            setSurveyData(survey); // Set the entire survey data
          } else {
            toast({
              title: "No survey found.",
              description: "Please try again later.",
              status: "info",
              duration: 3000,
              isClosable: true,
            });
          }
        } catch (error) {
          console.error("Error fetching survey data: ", error);
          toast({
            title: "No survey found.",
            // description: "Please try again later.",
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        }
      };
      fetchSurveyData();
    }
  }, [isOpen, toast]);

  // Handle the changes in responses (both radio and text questions)
  const handleResponseChange = (value, questionText, questionType) => {
    setResponses((prevResponses) => {
      const existingResponseIndex = prevResponses.findIndex(
        (response) => response.question === questionText
      );

      if (existingResponseIndex !== -1) {
        // Update the existing response
        const updatedResponses = [...prevResponses];
        updatedResponses[existingResponseIndex] = {
          question: questionText,
          answer: value,
          type: questionType,
        };
        return updatedResponses;
      }

      // Add a new response
      return [
        ...prevResponses,
        { question: questionText, answer: value, type: questionType },
      ];
    });
  };

  // Handle the click for "Continue" and "Submit" buttons
  const handleNextQuestion = () => {
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    }
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        heading: surveyData?.heading || "Survey", // Include the survey heading
        responses, // Send the responses including question text and answers
      };
      console.log("Submitted Payload:", payload);
      const response = await axiosInstance.post("/survey/submit", payload);
      if (response.status === 201) {
        onClose(); // Close the modal after successful submission
        setResponses([]);
      }
    } catch (error) {
      console.error("Error submitting survey: ", error);
      toast({
        title: "Error",
        description: "Failed to submit the survey.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!surveyData) return null;

  // Correctly calculate the total number of questions
  const totalQuestions =
    (surveyData?.questions?.length || 0) + (surveyData?.feedbackField?.length || 0);

  const isLastQuestion = currentQuestionIndex === totalQuestions - 1;

  const currentQuestion = surveyData.questions
    ? surveyData.questions[currentQuestionIndex] ||
      surveyData.feedbackField?.[currentQuestionIndex - surveyData.questions.length]
    : surveyData.feedbackField?.[currentQuestionIndex];

  const progressPercentage = totalQuestions > 0
    ? ((currentQuestionIndex + 1) / totalQuestions) * 100
    : 0;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInLeft"
      size="md"
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent
        bg="gray.800"
        color="white"
        ml={{ base: 2, md: 4 }}
        mt={{ base: "auto", md: "10vh" }}
        left={0}
        position="fixed"
        maxW={{ base: "90%", md: "50%" }}
        maxH="78vh"
        minH="auto"
      >
        <ModalCloseButton />
        <ModalBody>
          <Heading color="white" as="h4" size="md" pl={2} mt={2} mb={4}>
            {surveyData?.heading || "Survey"}
          </Heading>
          <Box
            pl={2}
            pr={2}
            maxH="59vh"
            minH="auto"
            overflowY="auto"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <Text mb={4} fontSize="sm">
              {surveyData?.subtext || "We would love to hear your feedback."}
            </Text>

            {/* Only show progress bar if there is more than one question */}
            {totalQuestions > 1 && (
              <>
              <Box position="relative" mb={4} height="30px" textAlign="center">
                {/* Progress bar with rounded corners and gradient */}
                <Progress
                  value={progressPercentage}
                  size="lg"
                  borderRadius="full"
                  hasStripe={false}
                  isAnimated={false}
                  colorScheme="green"
                  bg="gray.200"
                  sx={{
                    '& > div': {
                      background: 'linear-gradient(90deg, #286328 0%, #6ecf80 100%)',
                    },
                  }}
                />
                {/* Overlay percentage text */}
                <Text
                  position="absolute"
                  top="30%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  color="white"
                  fontWeight="bold"
                  mb={0}
                >
                  {Math.round(progressPercentage)}%
                </Text>
              </Box>
              </>
            )}

            {/* Display current question */}
            <Box mb={4}>
              <Text fontSize="sm" mb={2} color="#D98D41">
                {currentQuestion?.text}
              </Text>

              {currentQuestion?.type === "radio" && (
                <RadioGroup
                  onChange={(value) =>
                    handleResponseChange(value, currentQuestion.text, "radio")
                  }
                  value={
                    responses.find(
                      (res) => res.question === currentQuestion.text
                    )?.answer || ""
                  }
                >
                  <Stack direction="column">
                    {currentQuestion?.options?.map((option, optIndex) => (
                      <Radio key={optIndex} value={option}>
                        {option}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              )}

              {currentQuestion?.type === "textarea" && (
                <Textarea
                  placeholder="Write your feedback here..."
                  value={
                    responses.find(
                      (res) => res.question === currentQuestion.text
                    )?.answer || ""
                  }
                  onChange={(e) =>
                    handleResponseChange(
                      e.target.value,
                      currentQuestion.text,
                      "textarea"
                    )
                  }
                  size="sm"
                  borderColor="gray.600"
                />
              )}
            </Box>
          </Box>
        </ModalBody>

        <ModalFooter>
          {/* Show "Continue" only if there is more than one question */}
          {totalQuestions > 1 && !isLastQuestion ? (
            <Button
              bg="#286328"
              color="white"
              _hover="none"
              mr={3}
              size="sm"
              onClick={handleNextQuestion}
            >
              Continue
            </Button>
          ) : (
            <Button
              bg="#286328"
              color="white"
              _hover="none"
              mr={3}
              size="sm"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};


export { CookieConsentPopup, NewsletterSubscriptionPopup, SurveyPopup };
