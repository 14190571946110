import React from "react";
import {
  Box,
  Image,
  Text,
  Heading,
  Badge,
  IconButton,
  HStack,
  Button,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import Translate from "../../components/LanguageTranslation/Translate";
import { FaHeart } from "react-icons/fa";
import { useCurrency } from "../CurrencyExchange/CurrencyContext";

const RVsCard = ({ rvs }) => {
  const { convertCurrency, currency } = useCurrency();
  const convertedPrice = convertCurrency(rvs?.price_per_day, "USD");
  // console.log("rvs", rvs);

  // Function to handle box click and open link
  const handleBoxClick = () => {
    window.open(rvs.booking_url, "_blank", "noopener,noreferrer");
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      cursor="pointer"
      onClick={handleBoxClick}
    >
      <Box position="relative">
        <Image
          src={rvs.image_url}
          alt={rvs.title || "RVs Image"}
          width="100%"
          height="30vh"
          objectFit="cover"
        />

        <IconButton
          aria-label="Add to favorites"
          icon={<FaHeart />}
          size="md"
          bg="none"
          _hover={{ bg: "none" }}
          position="absolute"
          top="3px"
          right="3px"
          borderRadius="full"
          color="black"
        />
      </Box>
      <Box p="6">
        <Heading size="sm" mb="2" noOfLines="1">
          {rvs.vehicle_name || "RVs Name"}
        </Heading>
        <HStack mb="2">
          <Box display="flex" alignItems="center">
            {rvs?.rating ? (
              <HStack spacing={1}>
                <StarIcon boxSize={3} color="#3A4135" />
                <Text fontWeight="bold" fontSize="sm" mb={0}>
                  {rvs?.rating.toFixed(1)}
                </Text>
                <Text fontSize="xs" color="gray.500" mb={0}>
                  ({rvs?.reviews} reviews)
                </Text>
              </HStack>
            ) : (
              <Text color="gray.500" fontSize="sm" mb={0}>
                <Translate text="No reviews available" />
              </Text>
            )}
          </Box>
          <Text mb={0}>,</Text>
          <HStack spacing={1} alignItems="flex-start">
            <Text fontWeight="bold" fontSize="sm" color="gray.800" mb={0}>
              {currency} {convertedPrice}
            </Text>
            <Text fontSize="sm" color="gray.800" mb={0}>
              <Translate text="night" />
            </Text>
          </HStack>
        </HStack>
        {/* Book Button */}
        <Button
          as="a"
          href={rvs.booking_url}
          target="_blank"
          rel="noopener noreferrer"
          mt={2}
          size="sm"
          bg="#286328"
          _hover="none"
          _active="none"
          textColor="white"
          width="full"
        >
          <Translate text="Book" />
        </Button>
      </Box>
    </Box>
  );
};

export default RVsCard;
