import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Input,
  Button,
  VStack,
  Text,
  Image,
  HStack,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaLocationArrow } from "react-icons/fa";
import profilePicture from "../assets/images/AvaAvatar.png";
import "@fontsource/bubblegum-sans";
import SettingsMenu from "../components/common/SettingsMenu";
import Translate from "../components/LanguageTranslation/Translate";

const NextAdventure = () => {
  const [userMessage, setUserMessage] = useState("");
  const history = useHistory();

  const handleSubmit = (e) => {
    localStorage.removeItem("conversation_id");
    e.preventDefault();
    history.push(`/ava/chat?message=${encodeURIComponent(userMessage)}`);
  };

  const handleSuggestionClick = (message) => {
    localStorage.removeItem("conversation_id");
    setUserMessage(message);
    history.push(`/ava/chat?message=${encodeURIComponent(message)}`);
  };

  const suggestions = [
    "Find lodging",
    "Inspire where to go",
    "Find activities",
    "Build an itinerary",
  ];

  const inputWidth = useBreakpointValue({
    base: "80vw",
    sm: "60vw",
    md: "40vw",
  });

  return (
    <Box
      h="98vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="white"
    >
      <VStack spacing={6} p={2} w="100%" h="98vh">
        <Box
          display="flex"
          w="100%"
          alignContent="top"
          justifyContent="flex-end"
        >
          <SettingsMenu />
        </Box>
        <Flex align="center" p={4} pb={0}>
          <Image
            src={profilePicture}
            alt="Profile"
            w={{ base: "20", sm: "30", md: "40" }}
            h={{ base: "20", sm: "30", md: "40" }}
            borderRadius="full"
            border="4px solid"
            borderColor="#1A202C"
            objectFit="cover"
            mr={4}
          />
          <Box>
            <Text
              fontFamily="'Bubblegum Sans', sans-serif"
              fontSize={{ base: "4xl", sm: "5xl", md: "6xl" }}
              fontWeight="bold"
              mb={-4}
              lineHeight="shorter"
            >
              <Translate text="Ask" />
            </Text>
            <Text
              fontFamily="'Bubblegum Sans', sans-serif"
              fontSize={{ base: "8xl", sm: "10xl", md: "12xl" }}
              fontWeight="bold"
              mt={-4}
              lineHeight="shorter"
            >
              Ava
            </Text>
          </Box>
        </Flex>
        <Text textAlign="center" lineHeight="shorter">
          <Translate text="Don't search, just ask Ava. Your Personal AI Travel Planner." />
        </Text>
        <form onSubmit={handleSubmit}>
          <HStack spacing={4} flexWrap="wrap" justifyContent="center">
            <Input
              placeholder={Translate({
                text: "Ask me anything",
                as: "string",
              })}
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              size="lg"
              borderRadius="xl"
              bg="white"
              w={inputWidth}
            />
            <Button
              type="submit"
              bg="#1A202C"
              _hover="none"
              size="lg"
              textColor="white"
              borderRadius="xl"
            >
              <Translate text="Ask" />{" "}
              <FaLocationArrow style={{ marginLeft: "8px" }} />
            </Button>
          </HStack>
        </form>
        <HStack spacing={4} flexWrap="wrap" justifyContent="center">
          {suggestions.map((suggestion, index) => (
            <Button
              key={index}
              bg="#286328"
              _hover="none"
              textColor="white"
              borderRadius="xl"
              variant="outline"
              onClick={() => handleSuggestionClick(suggestion)}
              m={1}
            >
              <Translate text={suggestion} />
            </Button>
          ))}
        </HStack>
      </VStack>
    </Box>
  );
};

export default NextAdventure;
