import React, { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const GoogleMapsLink = ({ latitude, longitude }) => {
  const mapRef = useRef(null);


  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyBFvxPFaAvl37cNAwlZgHkMyGQqGWjcyik",
      version: "weekly"
    });

    loader.load().then(() => {
      if (!window.google) {
        console.error("Google Maps API failed to load.");
        return;
      }

      const google = window.google;

      const map = new google.maps.Map(mapRef.current, {
        center: { lat: latitude, lng: longitude },
        zoom: 10
      });

      new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: map,
      });
    }).catch(error => {
      console.error("Error loading Google Maps API:", error);
    });
  }, [latitude, longitude]);

  return <div ref={mapRef} style={containerStyle}></div>;
};

export default GoogleMapsLink;
