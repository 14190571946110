import React, { useState } from "react";
import {
  Box,
  Image,
  Text,
  Heading,
  Badge,
  IconButton,
  HStack,
  VStack,
  Button,
  Spacer,
} from "@chakra-ui/react";
import Translate from "../../components/LanguageTranslation/Translate";
import { useCurrency } from "../CurrencyExchange/CurrencyContext";
import { FaHeart } from "react-icons/fa";

const FlightCard = ({ flights }) => {
  // console.log("flights", flights);
  const { convertCurrency, currency } = useCurrency();

  const convertedPrice = convertCurrency(flights.price, "USD");

  // Function to handle box click and open link
  const handleBoxClick = () => {
    window.open(flights.link, "_blank", "noopener,noreferrer");
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      cursor="pointer"
      onClick={handleBoxClick}
      p={2}
    >
      <VStack
        p={2}
        borderRadius="xl"
        alignItems="flex-start"
        w="100%"
        spacing={3}
        bg="white"
        h="100%"
      >
        {/* Price and Airline Info */}
        <Box width="100%" position="relative">
          <Text fontWeight="bold" fontSize="md" mt={2} mb={2}>
            <Translate text="Live price:" /> {currency} {convertedPrice}
          </Text>
          <IconButton
            aria-label="Add to favorites"
            icon={<FaHeart />}
            size="md"
            bg="none"
            _hover={{ bg: "none" }}
            position="absolute"
            top="0px"
            right="0px"
            borderRadius="full"
            color="black"
          />
          <Text fontSize="sm" color="gray.500" mb={0}>
            <Translate text="Powered by" /> {flights.airline}
          </Text>
        </Box>

        {/* Flight Information */}
        <HStack
          justify="space-between"
          width="full"
          spacing={0}
          bg="blackAlpha.50"
          padding={2}
          borderRadius="xl"
        >
          <VStack align="center" spacing={0}>
            <Text fontWeight="bold">{flights.origin}</Text>
            <Text fontSize="xs" color="gray.500">
              {new Date(flights.departure_at).toLocaleDateString()}
            </Text>
          </VStack>
          <VStack align="center" spacing={0} mt={3}>
            <Text fontSize="xs">
              {Math.floor(flights.duration_to / 60)}h {flights.duration_to % 60}
              m
            </Text>
            <Text fontSize="xs">➔</Text>
            <Text fontSize="xs" color="gray.500">
              {flights.transfers > 0 ? `${flights.transfers} stop` : "Non-stop"}
            </Text>
          </VStack>
          <VStack align="center" spacing={0}>
            <Text fontWeight="bold">{flights.destination}</Text>
            <Text fontSize="xs" color="gray.500">
              {new Date(flights.return_at).toLocaleDateString()}
            </Text>
          </VStack>
        </HStack>
        <Text fontWeight="bold" fontSize="md" mt={2} mb={2}>
          {" "}
          <Translate text="Flight No:" /> {flights.flight_number}
        </Text>
        {/* <Spacer /> */}
        {/* Book Button */}
        <Button
          as="a"
          href={flights.link}
          // mb={2}
          target="_blank"
          rel="noopener noreferrer"
          size="sm"
          bg="#286328"
          _hover="none"
          _active="none"
          textColor="white"
          width="full"
        >
          <Translate text="Book" />
        </Button>
      </VStack>
    </Box>
  );
};

export default FlightCard;
