import React, { createContext, useContext, useRef } from "react";

// Create a context
const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const partnersRef = useRef(null);

  const scrollToPartners = () => {
    if (partnersRef.current) {
      partnersRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ScrollContext.Provider value={{ partnersRef, scrollToPartners }}>
      {children}
    </ScrollContext.Provider>
  );
};

// Custom hook to use the ScrollContext
export const useScroll = () => useContext(ScrollContext);
