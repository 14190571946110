import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import axios from "axios";

const TranslationContext = createContext();

export const useTranslation = () => useContext(TranslationContext);

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || "en");

  const generateCacheKey = (text, lang) => `${text}_${lang}`;

  const translateText = useCallback(
    async (text) => {
      // If language is English, return the text directly without an API call
      if (language === "en") {
        return text;
      }

      const cacheKey = generateCacheKey(text, language);
      const cachedTranslation = localStorage.getItem(cacheKey);

      // Return cached translation if available
      if (cachedTranslation) {
        return cachedTranslation;
      }

      // If no cached translation, call backend API
      try {
        const response = await axios.post(`/translation/translate`, {
          text,
          target_lang: language,
        });

        // Replace HTML entity for apostrophe with an actual apostrophe
        const translatedText = response.data.translatedText.replace(/&#39;/g, "'");

        // Cache translation in localStorage
        localStorage.setItem(cacheKey, translatedText);

        return translatedText;
      } catch (error) {
        console.error("Translation error:", error);
        return text; // Return original text on error
      }
    },
    [language]
  );

  const switchLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };

  const useTranslate = (text) => {
    const cacheKey = generateCacheKey(text, language);
    const [translatedText, setTranslatedText] = useState(() => {
      return localStorage.getItem(cacheKey) || text;
    });

    useEffect(() => {
      const fetchTranslation = async () => {
        if (language === "en") {
          setTranslatedText(text); // Directly set the text if language is English
        } else {
          const translation = await translateText(text);
          setTranslatedText(translation);
          localStorage.setItem(cacheKey, translation); // Cache the translation
        }
      };

      fetchTranslation();
    }, [text, cacheKey, translateText, language]);

    return translatedText;
  };

  return (
    <TranslationContext.Provider
      value={{
        language,
        setLanguage: switchLanguage,
        translateText,
        useTranslate,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};
