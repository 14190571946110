import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Progress,
  SimpleGrid,
  Text,
  Button,
  Center,
  VStack,
  Image,
  Icon,
  HStack,
  Divider,
  useColorMode,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { FaSuitcase } from "react-icons/fa";
import { useToast } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";
import TripDropdown from "../common/TripDropdown";
import PackingListModal from "./PackingListModal";
import PackingCategory from "./PackingCategory";
import PackingListDetails from "./PackingListDetails";
import { getIconForCategory } from "./utils";
import icon from "../../assets/images/packing-icon.png";
import usePersistedState from "../usePersistedState";
import Translate from "../../components/LanguageTranslation/Translate";

const PackingList = () => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const history = useHistory();
  const [packingLists, setPackingLists] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryItems, setCategoryItems] = useState({});
  const [packingCategories, setPackingCategories] = useState([]);
  const [selectedTrip, setSelectedTrip] = usePersistedState(
    "selectedTripPackingList",
    ""
  );
  const [tripName, setTripName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const fetchPackingLists = async (setPackingLists, toast) => {
    try {
      const response = await axiosInstance.get("/packing_lists/");
      if (response.data && Array.isArray(response.data.data)) {
        setPackingLists(response.data.data);
      } else {
        toast({
          title: "Error",
          description: "Failed to fetch packing lists: Invalid data format.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch packing lists.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchPackingLists(setPackingLists, toast);
  }, [toast]);

  // Handle deleting the packing list
  const handleDeletePackingList = async () => {
    try {
      const response = await axiosInstance.delete(
        `/packing_lists/${selectedPackingListId}`
      );
      if (response.data.success) {
        toast({
          title: "Success",
          description: "Packing list deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        fetchPackingLists(setPackingLists, toast);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete packing list.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (selectedTrip && Array.isArray(packingLists)) {
      const filteredPackingLists = packingLists.filter(
        (list) => list.trip_id === selectedTrip
      );

      const updatedCategoryItems = filteredPackingLists.reduce((acc, list) => {
        acc[list.packing_category] = list.packing_list_items.map((item) => ({
          name: item.name || item, // Check if `item` is an object with `name`, or a string
          completed: item.completed || false,
        }));
        return acc;
      }, {});

      setCategoryItems(updatedCategoryItems);

      const updatedPackingCategories = filteredPackingLists.map((list) => {
        const total = list.packing_list_items.length;
        const completed = list.packing_list_items.filter(
          (item) => item.completed
        ).length;
        return {
          name: list.packing_category,
          packing_list_name: list.packing_list_name,
          packing_list_id: list._id, // Include packing list id
          icon: getIconForCategory(list.packing_category),
          completed,
          total,
        };
      });

      setPackingCategories(updatedPackingCategories);
      setSelectedCategory(updatedPackingCategories[0]?.name || "");
    }
  }, [selectedTrip, packingLists]);

  const handleUpdatePackingCategories = (categoryName, completed, total) => {
    setPackingCategories((prevCategories) =>
      prevCategories.map((cat) =>
        cat.name === categoryName ? { ...cat, completed, total } : cat
      )
    );
  };

  const selectedPackingListId = packingCategories.find(
    (category) => category.name === selectedCategory
  )?.packing_list_id; // Extract the packing list id

  const redirectToChat = () => {
    history.push("/ava/chat"); // Replace '/chat' with the actual path to your chat page
  };

  const redirectToBucketList = () => {
    history.push("/bucketlists"); // Replace '/chat' with the actual path to your chat page
  };

  return (
    <Flex height="100vh" p={0} bg="gray.50">
      {!packingLists.length ? (
        <Center w="100%" bg ="white">
          {/* <VStack spacing={0} p={4} borderRadius="md">
            <Image
              src={FaSuitcase} // replace with the path to your image
              alt="Packing list Icon"
              boxSize="100px"
              mb={4}
            />
            <Icon
              as={FaSuitcase}
              boxSize={16}
              color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
            />
            <Text fontSize="lg" mb={0} color="#1A202C">
              You haven't saved any trip yet.
            </Text>
          </VStack> */}
          <Box textAlign="center" mb={4}>
            <Icon
              as={FaSuitcase}
              boxSize={12}
              color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
            />
            <Text
              fontSize="2xl"
              fontWeight="bold"
              mt={2}
              color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
              mb={0}
            >
             
             <Translate text="Your Packing List is Empty" />
            </Text>
            <Text
              fontSize="md"
              mt={2}
              color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
              mb={0}
            >
              
              <Translate text="You don't have any trips planned at the moment." />
            </Text>
            <Text
              fontSize="md"
              mt={2}
              color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
              mb={0}
            >
              
              <Translate text="Start planning your next adventure or enable your saved trip!" />
            </Text>
            <HStack alignContent="center" justifyContent="center">
              <Button
                mt={6}
                bg="#286328"
                color="white"
                _hover="none"
                borderRadius="lg"
                onClick={redirectToChat}
              >
                
                <Translate text="Plan a trip" />
              </Button>
              <Divider
                orientation="vertical"
                h="40px"
                borderColor={colorMode === "dark" ? "black" : "black"}
                mt={6}
                mb={0}
              />
              <Button
                mt={6}
                bg="#286328"
                color="white"
                _hover="none"
                borderRadius="lg"
                onClick={redirectToBucketList}
              >
                
                <Translate text="Bucket list" />
              </Button>
            </HStack>
          </Box>
        </Center>
      ) : (
        <>
          <Box
            w="70%"
            p={5}
            pt={4}
            bg="#FAFDFC"
            borderRadius="none"
            boxShadow="none"
          >
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <Heading size="md"><Translate text="Packing list" /></Heading>
              {selectedTrip && (
                <Button
                  bg="#286328"
                  color="white"
                  _hover="none"
                  size="sm"
                  borderRadius="lg"
                  onClick={openModal}
                >
                  + <Translate text="Add list" />
                </Button>
              )}
            </Flex>
            {selectedTrip && (
              <Flex alignItems="center" mt={7} mb={5}>
                <Text fontWeight="bold" mr={3} mb={0} color="#286328">
                  {packingCategories.length > 0
                    ? (
                        (packingCategories.reduce(
                          (acc, category) => acc + category.completed,
                          0
                        ) /
                          packingCategories.reduce(
                            (acc, category) => acc + category.total,
                            0
                          )) *
                        100
                      ).toFixed(0)
                    : 0}
                  %
                </Text>
                <Progress
                  value={
                    packingCategories.length > 0
                      ? (packingCategories.reduce(
                          (acc, category) => acc + category.completed,
                          0
                        ) /
                          packingCategories.reduce(
                            (acc, category) => acc + category.total,
                            0
                          )) *
                        100
                      : 0
                  }
                  size="sm"
                  colorScheme="green"
                  flex="1"
                  borderRadius="lg"
                />
              </Flex>
            )}
            <Heading fontSize="md" fontWeight="bold" mb={0}>
              <Translate text="Trips" />
            </Heading>

            <Text fontSize="xs" color="gray.500" mb={2}>
               <Translate text="Select Trip" />
            </Text>

            <TripDropdown
              selectedTrip={selectedTrip}
              setSelectedTrip={setSelectedTrip}
              setTripName={setTripName}
              onTripSelect={setSelectedTrip}
            />
            {selectedTrip && (
              <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
                  <Translate text="Packing" />
              </Heading>
            )}
            {selectedTrip && (
              <Text fontSize="xs" color="gray.500" mb={4}>
                  <Translate text="Select packing category" />
              </Text>
            )}
            {selectedTrip && (
              <Box
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
                h="58vh"
              >
                <SimpleGrid columns={4} spacing={4}>
                  {packingCategories.map((category) => (
                    <PackingCategory
                      key={category.name}
                      category={category}
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      packingListName={category.packing_list_name}
                      packingListId={category.packing_list_id}
                    />
                  ))}
                </SimpleGrid>
              </Box>
            )}
          </Box>

          <PackingListDetails
            selectedCategory={selectedCategory}
            selectedPackingListId={selectedPackingListId}
            categoryItems={categoryItems}
            setCategoryItems={setCategoryItems}
            onUpdatePackingCategories={handleUpdatePackingCategories}
            handleDeletePackingList={handleDeletePackingList}
          />

          <PackingListModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            setCategoryItems={setCategoryItems}
            setPackingCategories={setPackingCategories}
            toast={toast}
            selectedTrip={selectedTrip}
            setPackingLists={setPackingLists}
            fetchPackingLists={fetchPackingLists}
          />
        </>
      )}
    </Flex>
  );
};

export default PackingList;
