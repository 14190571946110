import React from "react";
import {
  Flex,
  Input,
  IconButton,
  Tooltip,
  Box,
  Button,
  VStack,
} from "@chakra-ui/react";
import { MdSend } from "react-icons/md";
import Translate from "../../components/LanguageTranslation/Translate";

const MessageInput = ({
  message,
  setMessage,
  handleSendMessage,
  colorMode,
  suggestions,
  handleSuggestionClick,
}) => {
  return (
    <VStack p={4} maxH="20vh" justifyContent="space-between" spacing={2}>
      {/* Suggestions Box */}
      <Box
        w="100%"
        minH="40px"
        display="flex"
        flexWrap="wrap"
        justifyContent="left"
        overflowX="auto"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
        noOfLines="1"
      >
        {suggestions.length > 0 ? (
          suggestions.map((suggestion, idx) => (
            <Button
              key={idx}
              m={1}
              ml={0}
              mr={2}
              onClick={() => handleSuggestionClick(suggestion)}
              size="sm"
              bg="white"
              border="1px"
              borderColor="gray.300"
              _hover="none"
            >
              {suggestion}
            </Button>
          ))
        ) : (
          <Box w="100%" />
        )}
      </Box>
      <Flex as="form" width="100%" onSubmit={(e) => e.preventDefault()}>
        <Input
          placeholder={Translate({
            text: "Ask anything...",
            as: "string",
          })}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          mr={2}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSendMessage(e);
            }
          }}
        />

        <Tooltip label="Send message" fontSize="md">
          <IconButton
            aria-label="Send"
            icon={<MdSend />}
            onClick={handleSendMessage}
            colorScheme="alpha"
            textColor={colorMode === "dark" ? "white" : "black"}
          />
        </Tooltip>
      </Flex>
    </VStack>
  );
};

export default MessageInput;
