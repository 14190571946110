import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Image,
  Text,
  Heading,
  IconButton,
  HStack,
  Badge,
  Input,
  Button,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useParams, useHistory } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaPinterest,
  FaEnvelope,
  FaWhatsapp,
  FaLinkedin,
} from "react-icons/fa"; // Import share icons
import axiosInstance from "../../helpers/axiosInstance"; // Adjust the path according to your project structure
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg";
import Navbar from "../../components/Navbar";
import darkLogo from "../../assets/images/Newlogo-black.png";
import BackToTopButton from "../common/BackToTopButton";
import Footer from "../../components/Footer";
import InstagramFeed from "./InstagramFeed";
import { useTranslation } from "../../components/LanguageTranslation/TranslationContext";
import Translate from "../../components/LanguageTranslation/Translate";

const BlogDetail = () => {
  const history = useHistory();
  const { useTranslate } = useTranslation();
  const placeholderText = useTranslate("Enter email"); 
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State for button loading

  const constructImageUrl = (filename) => {
    if (!filename) return null;

    // Replace backslashes with forward slashes and remove the leading "./"
    const cleanPath = filename.replace(/\\/g, "/").replace(/^\.\//, "");

    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";
    const url = `${baseUrl}/${cleanPath}`;
    console.log("Constructed URL:", url);
    return url;
  };

  useEffect(() => {
    fetchBlogById(id);
  }, [id]);

  const fetchBlogById = async (blogId) => {
    try {
      const response = await axiosInstance.get(`/blogs/${blogId}`);
      if (response.data.success) {
        const blogData = response.data.data;
        blogData.image_list = blogData.image_list.map((image) =>
          image.startsWith("http") ? image : constructImageUrl(image)
        );
        setBlog(blogData);
      } else {
        throw new Error(response.data.message || "Failed to fetch blog");
      }
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  const handleSubscribe = async () => {
    if (!email) {
      console.error("Email is required");
      return;
    }

    setIsLoading(true); // Start loading
    try {
      const response = await axiosInstance.post("/newsletter/", { email });
      if (response.status === 201) {
        setEmail(""); // Clear the email input after successful submission
      } else {
        console.error("Subscription failed");
      }
    } catch (error) {
      console.error("Error subscribing to newsletter:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  if (!blog) {
    return <Text fontFamily="Poppins, sans-serif"></Text>;
  }

  

  return (
    <Box>
      {/* Meta Tags for SEO */}
      <Helmet>
        {/* General Meta Tags */}
        <title>{blog.seo_title}</title>
        <meta name="description" content={blog.seo_description} />

        {/* Open Graph (Facebook, LinkedIn) */}
        <meta property="og:title" content={blog.seo_title} />
        <meta property="og:description" content={blog.seo_description} />
        <meta property="og:image" content={blog.image_list[0]} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.seo_title} />
        <meta name="twitter:description" content={blog.seo_description} />
        <meta name="twitter:image" content={blog.image_list[0]} />

        {/* WhatsApp, Google, and general sharing */}
        <meta itemProp="name" content={blog.seo_title} />
        <meta itemProp="description" content={blog.seo_description} />
        <meta itemProp="image" content={blog.image_list[0]} />
      </Helmet>

      <Navbar bgColor="white" linkColor="black" logoSrc={darkLogo} />
      <Box
        w="100%"
        mx="auto"
        px={4}
        py={8}
        fontFamily="Poppins, sans-serif" // Set the font for the entire page
      >
        {/* Center-aligned Blog Box */}
        <Box display="flex" justifyContent="center" w="100%">
          <Box
            maxW={{ base: "100vw", sm: "85vw", md: "85vw", lg: "60vw" }}
            mx="auto"
            textAlign="left" // Ensures text inside the Box is left aligned
          >
            <VStack spacing={4} align="stretch">
              {/* Blog Header */}
              <HStack spacing={2} mb={2}>
                {blog.category.length > 0 ? (
                  blog.category.map((cat) => (
                    <Badge
                      key={cat.category_name}
                      bg="black"
                      variant="solid"
                      borderRadius="md"
                      fontSize="md"
                      p={4}
                      pt={1}
                      pb={1}
                      textTransform="none"
                    >
                      {cat.category_value_list.join(", ")}
                    </Badge>
                  ))
                ) : (
                  <Badge
                    bg="black"
                    variant="solid"
                    borderRadius="md"
                    fontSize="md"
                    p={4}
                    pt={1}
                    pb={1}
                    textTransform="none"
                  >
                    General
                  </Badge>
                )}
                <Text fontSize="sm" color="gray.500" mb={0}>
                  {blog.creation_date
                    ? new Date(blog.creation_date).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                    : "N/A"}
                </Text>
              </HStack>

              {/* Blog Title */}
              <Heading
                as="h1"
                mt={4}
                mb={4}
                fontSize={{ base: "30px", sm: "48px", md: "48px" }}
                fontWeight="bold"
                textAlign="left" // Aligning the title to the left
              >
                <Translate text={blog.seo_title} />
              </Heading>

              {/* Blog Image */}
              <Image
                src={blog.image_list[0]} // Displaying the first image in the list
                alt={blog.seo_title}
                objectFit="cover"
                width="800px"
                height={{ base: "200px", sm: "300px", md: "400px" }}
                borderRadius="md"
                mb={8}
              />

              {/* Blog Content */}
              <Box
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
                lineHeight="tall"
                textAlign="left" // Ensure the blog content is left-aligned
                dangerouslySetInnerHTML={{ __html: blog.content }} // Rendering HTML content
              />
            </VStack>
          </Box>
        </Box>

        {/* Share Section */}
        <Box mt={8} mb={8} textAlign="center">
          <Text fontSize="lg" mb={4} fontWeight="bold">
            <Translate text="Share" />
          </Text>
          <HStack spacing={4} justify="center">
            <IconButton
              icon={<FaFacebookF />}
              aria-label="Share on Facebook"
              bg="black"
              color="white"
              as="a"
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
            />
            <IconButton
              icon={
                <TwitterIcon
                  style={{ width: "20px", height: "20px", borderRadius: "xl" }}
                />
              } // Set the size of the SVG icon
              // icon={<FaTwitter />}
              aria-label="Share on Twitter"
              bg="black"
              color="white"
              _active="none"
              _hover="none"
              as="a"
              href={`https://twitter.com/share?url=${
                window.location.href
              }&text=${encodeURIComponent(blog.seo_title)}`}
              target="_blank"
              rel="noopener noreferrer"
            />
            <IconButton
              icon={<FaLinkedin />}
              aria-label="Share on LinkedIn"
              bg="black"
              color="white"
              _active="none"
              _hover="none"
              as="a"
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${
                window.location.href
              }&title=${encodeURIComponent(
                blog.seo_title
              )}&summary=${encodeURIComponent(blog.seo_description)}`}
              target="_blank"
              rel="noopener noreferrer"
            />

            <IconButton
              icon={<FaWhatsapp />}
              aria-label="Share on WhatsApp"
              bg="black"
              color="white"
              _active="none"
              _hover="none"
              as="a"
              href={`https://wa.me/?text=${encodeURIComponent(
                blog.seo_title + " " + window.location.href
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            />
            <IconButton
              icon={<FaEnvelope />}
              aria-label="Share via Email"
              bg="black"
              color="white"
              _active="none"
              _hover="none"
              as="a"
              // href={`mailto:?subject=${blog.seo_title}&body=Check out this post: ${window.location.href}`}
              href="https://mail.google.com/mail/?view=cm&fs=1&to=info@nomadtours.io"
            />
          </HStack>
        </Box>

        {/* Newsletter Section */}
        <Box
          mt={12}
          // mb={8}
          p={8}
          pb
          bg="#F7F7F7"
          borderRadius="lg"
          textAlign="center"
        >
          <Heading as="h2" size="lg" mb={4}>
            <Translate text="Join the Green & Wild Community!" />
          </Heading>
          <Text
            mb={6}
            w={{ base: "100%", sm: "100%", md: "50%" }}
            mx="auto"
            textAlign="center"
          >
            <Translate
              text="Subscribe to Nomad Tours' newsletter for personalized travel tips,
            epic outdoor experiences, and exclusive offers. Connect with fellow
            explorers and start planning your next adventure today!"
            />
          </Text>
          <HStack
            maxW="500px"
            mx="auto"
            p={2}
            borderRadius="full"
            bg="white"
            boxShadow="sm"
            spacing={4}
          >
            <Input
              type="email"
              placeholder={placeholderText}
              variant="unstyled"
              px={4}
              flex="1"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Bind email state
            />
            <Button
              bg="black"
              color="white"
              borderRadius="full"
              px={6}
              _hover={{ bg: "gray.800" }}
              isLoading={isLoading} // Show spinner while loading
              onClick={handleSubscribe} // Call the subscribe function
            >
              <Translate text="Subscribe" />
            </Button>
          </HStack>
        </Box>
        {/* <InstagramFeed/> */}
      </Box>
      <Footer />
      <BackToTopButton />
    </Box>
  );
};

export default BlogDetail;
