import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  IconButton,
  InputGroup,
  InputRightElement,
  useToast,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import GoogleLoginButton from "./common/google/GoogleLoginButton";
import modalImage from "../assets/images/Ava.png";
import { useUser } from "./userRole/UserContext";
import { login } from "../auth";

const AuthModal = ({ isOpen, onClose, redirectPath = "/travel-planner" }) => {
  const [isLogin, setIsLogin] = useState(true); // State to toggle between Login and Signup
  const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm();
  const { user, setUser } = useUser();
  const history = useHistory();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [step, setStep] = useState(1); // Step 1 for email, Step 2 for password
  const [loading, setLoading] = useState(false);

  const switchToSignUp = () => setIsLogin(false);
  const switchToLogin = () => setIsLogin(true);

  function getDeviceDetails() {
    const ua = navigator.userAgent;
    let browser;
    if (/chrome|chromium|crios/i.test(ua)) {
      browser = "Chrome";
    } else if (/firefox|fxios/i.test(ua)) {
      browser = "Firefox";
    } else if (/safari/i.test(ua)) {
      browser = "Safari";
    } else if (/edg/i.test(ua)) {
      browser = "Edge";
    } else if (/opr\//i.test(ua)) {
      browser = "Opera";
    } else if (/msie|trident/i.test(ua)) {
      browser = "IE";
    } else {
      browser = "Unknown Browser";
    }

    let os;
    if (/android/i.test(ua)) {
      os = "Android";
    } else if (/iphone|ipad|ipod/i.test(ua)) {
      os = "iOS";
    } else if (/win/i.test(ua)) {
      os = "Windows";
    } else if (/mac/i.test(ua)) {
      os = "MacOS";
    } else if (/linux/i.test(ua)) {
      os = "Linux";
    } else {
      os = "Unknown OS";
    }

    return {
      full_user_agent: navigator.userAgent,
      simplified_user_agent: `${os} ${browser}`,
      registration_date: new Date().toISOString().slice(0, 10), // ISO 8601 format for YYYY-MM-DD
      loginDate: new Date().toISOString().slice(0, 10), // ISO 8601 format for YYYY-MM-DD
    };
  }

  const onSubmitSignUp = async (formData) => {
    if (formData.password !== formData.confirmPassword) {
      toast({
        title: "Password Mismatch",
        description: "The passwords do not match. Please try again.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    const signinPayload = {
      ...formData,
      device: getDeviceDetails(),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signinPayload),
    };

    try {
      const response = await fetch("/auth/signup", requestOptions);
      const responseData = await response.json();

      setLoading(false);
      if (response.ok) {
        toast({
          title: "Account Created",
          description: responseData.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // Switch to login form instead of redirecting
        setIsLogin(true);
      } else {
        toast({
          title: "Failed to Create Account",
          description: responseData.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: "Network Error",
        description: "Unable to connect to the server. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onSubmitLogin = async (formData) => {
    const deviceDetails = getDeviceDetails();

    const loginPayload = {
      ...formData,
      device: deviceDetails,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginPayload),
    };

    try {
      const response = await fetch("/auth/login", requestOptions);
      const data = await response.json();

      if (response.status === 200) {
        login(data);
        localStorage.setItem("email", data.email);
        localStorage.setItem("username", data.username);
        const role = data.role;
        const organization = data.organization;

        setUser({
          role,
          organization,
        });
        history.push(redirectPath);
        onClose(); 
        localStorage.removeItem("isSignUpOpen");
        localStorage.removeItem("messageCount");
      } else {
        toast({
          title: "Login failed",
          description: data.message || "Login failed",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Network Error",
        description: "Unable to connect to the server. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    reset();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent borderRadius="xl" w="80%" h="78vh" overflow="hidden">
        <ModalCloseButton borderRadius="full" bg="white" />
        <ModalBody p={0}>
          <Flex height="100%">
            <Box w="50%" p={8} h="78vh" overflowY="scroll">
              <Heading as="h1" fontSize="xl" mb={4}>
                {isLogin ? "Log in to your account" : "Create your account"}
              </Heading>
              <form onSubmit={handleSubmit(isLogin ? onSubmitLogin : onSubmitSignUp)}>
                <Stack spacing={2}>
                  {!isLogin && (
                    <FormControl isInvalid={errors.username}>
                      <FormLabel fontSize="sm">Username*</FormLabel>
                      <Input
                        placeholder="Username"
                        {...register("username", {
                          required: "Username is required",
                          maxLength: 25,
                        })}
                        borderRadius="full"
                        size="md"
                      />
                      <Text color="red.500">{errors.username?.message}</Text>
                    </FormControl>
                  )}
                  <FormControl isInvalid={errors.email}>
                    <FormLabel fontSize="sm">Email*</FormLabel>
                    <Input
                      type="email"
                      placeholder="Email Address"
                      {...register("email", {
                        required: "Email is required",
                        maxLength: 80,
                      })}
                      borderRadius="full"
                      size="md"
                    />
                    <Text color="red.500">{errors.email?.message}</Text>
                  </FormControl>
                  <FormControl isInvalid={errors.password}>
                    <FormLabel fontSize="sm">Password*</FormLabel>
                    <InputGroup>
                      <Input
                        pr="4.5rem"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        {...register("password", {
                          required: "Password is required",
                          minLength: 8,
                        })}
                        borderRadius="full"
                        size="md"
                      />
                      <InputRightElement width="4.5rem">
                        <IconButton
                          h="1.75rem"
                          size="sm"
                          onClick={() => setShowPassword(!showPassword)}
                          icon={showPassword ? <HiEyeOff /> : <HiEye />}
                          aria-label="Toggle password visibility"
                        />
                      </InputRightElement>
                    </InputGroup>
                    <Text color="red.500">{errors.password?.message}</Text>
                  </FormControl>
                  {!isLogin && (
                    <FormControl isInvalid={errors.confirmPassword}>
                      <FormLabel fontSize="sm">Confirm Password*</FormLabel>
                      <InputGroup>
                        <Input
                          pr="4.5rem"
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm Password"
                          {...register("confirmPassword", {
                            required: "Confirm password is required",
                            minLength: 8,
                          })}
                          borderRadius="full"
                          size="md"
                        />
                        <InputRightElement width="4.5rem">
                          <IconButton
                            h="1.75rem"
                            size="sm"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            icon={showConfirmPassword ? <HiEyeOff /> : <HiEye />}
                            aria-label="Toggle confirm password visibility"
                          />
                        </InputRightElement>
                      </InputGroup>
                      <Text color="red.500">{errors.confirmPassword?.message}</Text>
                    </FormControl>
                  )}
                  <Button
                    mt={4}
                    type="submit"
                    w="100%"
                    bg="#286328"
                    color="white"
                    _hover={{ bg: "#286328" }}
                    borderRadius="full"
                    size="md"
                    isDisabled={loading}
                  >
                    {/* {isLogin ? "Log In" : "Create Account"} */}
                    {loading ? <Spinner size="sm" /> : isLogin ? "Log In" : "Create Account"}
                  </Button>
                  <Text mt={2} fontSize="small">
                    {isLogin ? (
                      <>
                        Don't have an account?{" "}
                        <Link color="#004A45" onClick={switchToSignUp}>
                          Sign up
                        </Link>
                      </>
                    ) : (
                      <>
                        Already have an account?{" "}
                        <Link color="#004A45" onClick={switchToLogin}>
                          Log in
                        </Link>
                      </>
                    )}
                  </Text>
                </Stack>
              </form>
              <Flex align="center" mt={4}>
                <Divider />
                <Text mx={2}>or</Text>
                <Divider />
              </Flex>
              <GoogleLoginButton />
              <HStack justify="center" width="100%" mt={4}>
                <Text fontSize="sm" mb={0}>
                  By signing up, you agree to our{" "}
                  <Link
                    fontSize="sm"
                    href="/policies/terms-of-use"
                    textDecoration="underline"
                  >
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link
                    fontSize="sm"
                    href="/policies/privacy-policy"
                    textDecoration="underline"
                  >
                    Privacy Policy
                  </Link>
                  .
                </Text>
              </HStack>
            </Box>
            <Box
              w="50%"
              bgImage={`url(${modalImage})`}
              bgPosition="center"
              bgSize="cover"
              borderTopRightRadius="xl"
              borderBottomRightRadius="xl"
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AuthModal;
