import React, { useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  useColorMode,
  Text,
  Button,
  Spacer,
  VStack,
  HStack,
} from "@chakra-ui/react";
import ItineraryCard from "./ItineraryCard";
import HotelCard from "./HotelCard";
import ActivityCard from "./ActivityCard";
import FlightsCard from "./FlightsCard";
import RVsCard from "./RVsCard";
import SettingsMenu from "../common/SettingsMenu";
import usePersistedState from "../usePersistedState";
import axiosInstance from "../../helpers/axiosInstance";
import NoBucketListMessage from "./NoBucketListMessage"; // Import the new component
import Translate from "../../components/LanguageTranslation/Translate";

const BucketList = () => {
  const { colorMode } = useColorMode();
  const [selectedTag, setSelectedTag] = useState("all");
  const [ongoingTrip, setOngoingTrip] = usePersistedState("ongoingTrip", null);
  const [data, setData] = useState([]);

  const fetchTrips = async () => {
    try {
      const response = await axiosInstance.get("/bucket-list/");
      const trips = response.data.data;
      console.log("fetchTrips", trips);
      // Find the ongoing trip
      const ongoing = trips.find((trip) => trip.is_on_going);

      // Set the ongoing trip if it exists
      setOngoingTrip(ongoing ? ongoing : null);

      setData(trips);
    } catch (error) {
      console.error("Error fetching trip data:", error);
    }
  };

  useEffect(() => {
    fetchTrips();
  }, []);

  const handleToggleOngoingTrip = async (event, itinerary) => {
    event.stopPropagation();
    const newOngoingTripStatus = !itinerary.is_on_going;

    try {
      await axiosInstance.put(`/bucket-list/${itinerary._id}/update/status`, {
        status: newOngoingTripStatus,
      });

      fetchTrips(); // Refresh the data after updating the trip status
    } catch (error) {
      console.error("Error updating trip status:", error);
    }
  };

  const handleToggleCompletion = async (event, itinerary, isCompleted) => {
    event.stopPropagation();

    try {
      const response = await axiosInstance.put(
        `/bucket-list/${itinerary._id}/update/completion`,
        { is_completed: isCompleted } // Pass the new is_completed value
      );

      if (response.data.success) {
        // Toggle the local data and ongoing trip status based on completion
        const updatedData = data.map((item) =>
          item._id === itinerary._id
            ? { ...item, isCompleted: isCompleted }
            : item
        );
        setData(updatedData);
        handleToggleOngoingTrip(event, itinerary);
        // If the completed trip was the ongoing one, reset the ongoing trip
        if (itinerary._id === ongoingTrip?._id) {
          setOngoingTrip(null);
        }
      }
    } catch (error) {
      console.error("Error updating trip completion:", error);
    }
  };

  const handleDeleteItinerary = (tripId) => {
    axiosInstance
      .delete(`/bucket-list/${tripId}/delete`)
      .then((response) => {
        // Handle successful deletion
        fetchTrips();
      })
      .catch((error) => {
        // Handle error
        console.error("Error deleting itinerary:", error);
      });
  };

  const tagMapping = {
    all: "all",
    itinerary: "itinerary",
    accommodations: "accommodations",
    places: "places",
    flights: "flights",
    RVs: "RVs",
  };

  const filterItems = (tag) => {
    // Use mapped tag to filter data
    const mappedTag = tagMapping[tag];
    if (mappedTag === "all") {
      return data;
    }
    return data.filter((item) => item.conversation_type === mappedTag);
  };

  // If no data, show only the NoBucketListMessage
  if (data.length === 0) {
    return <NoBucketListMessage />;
  }

  return (
    <Box>
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        pb={1}
        maxH="8vh"
        borderBottom="1px"
        borderColor="blackAlpha.200"
        overflowY="hidden"
      >
        <Box
          display="flex"
          alignItems="center"
          bg={colorMode === "dark" ? "gray.700" : "white"}
          width="100%"
          height="100%"
          p={4}
          pt={2}
          pb={2}
          boxSizing="border-box"
          overflowY="hidden"
        >
          <Text
            fontSize="md"
            fontWeight="bold"
            mb={0}
            mt={2}
            color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
          >
            <Translate text="Bucketlist" />
          </Text>
          <Spacer />
          <SettingsMenu />
        </Box>
      </Box>

      <VStack align="left" h="88vh" overflow="auto">
        <HStack spacing={4} p={5} pb={0}>
          {Object.keys(tagMapping).map((tag) => (
            <Button
              key={tag}
              onClick={() => setSelectedTag(tag)}
              bg={selectedTag === tag ? "#286328" : "blackAlpha.100"}
              color={selectedTag === tag ? "white" : "black"}
              _hover="none"
              borderRadius="lg"
            >
              <Translate text={tag.charAt(0).toUpperCase() + tag.slice(1)} />
            </Button>
          ))}
        </HStack>

        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={5} p={5}>
          {filterItems(selectedTag).map((item) => {
            switch (item.conversation_type) {
              case "itinerary":
                return (
                  <ItineraryCard
                    key={item._id}
                    itinerary={item.trip_plan}
                    tripId={item._id}
                    ongoingTrip={ongoingTrip}
                    onToggleOngoingTrip={(event) =>
                      handleToggleOngoingTrip(event, item)
                    }
                    onToggleCompletion={(event) =>
                      handleToggleCompletion(event, item, !item.isCompleted)
                    }
                    isOngoingCard={false}
                    isOnGoing={item.is_on_going}
                    onDeleteItinerary={handleDeleteItinerary}
                  />
                );
              case "accommodations":
                return (
                  <HotelCard
                    key={item._id}
                    hotel={item.home_to_go_api_response[0].home_to_go_response[0]}
                  />
                );
              case "places":
                return (
                  <ActivityCard
                    key={item._id}
                    activity={item?.viator_api_response[0]?.viator_response[0]}
                  />
                );
              case "flights":
                return (
                  <FlightsCard key={item._id} flights={item.flight_info[0]} />
                );
              case "RVs":
                return (
                  <RVsCard
                    key={item._id}
                    rvs={item.outdoorsy_api_response[0].outdoorsy_response[0]}
                  />
                );
              default:
                return null;
            }
          })}
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default BucketList;
