import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Input,
  IconButton,
  HStack,
  SimpleGrid,
  Text,
  Tag,
  TagLabel,
  Button,
  useDisclosure,
  useColorMode,
} from "@chakra-ui/react";
import { FiFilter } from "react-icons/fi";
import axiosInstance from "../../helpers/axiosInstance"; // Adjust the path according to your axios instance
import banner from "../../assets/images/banner.jpg";
import Translate from "../../components/LanguageTranslation/Translate";

const BlogBanner = ({ selectedFilters, setSelectedFilters }) => {
  const { colorMode } = useColorMode();
  const { isOpen, onToggle } = useDisclosure(); // Use onToggle to handle open/close
  const [categories, setCategories] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // State for search input

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get("/categories/");
        if (response.data.success) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching categories", error);
      }
    };

    fetchCategories();
  }, []);

  const handleFilterChange = (category, value) => {
    const updatedFilters = { ...selectedFilters };
    if (updatedFilters[category]?.includes(value)) {
      updatedFilters[category] = updatedFilters[category].filter(
        (v) => v !== value
      );
      if (updatedFilters[category].length === 0) {
        delete updatedFilters[category];
      }
    } else {
      if (!updatedFilters[category]) updatedFilters[category] = [];
      updatedFilters[category].push(value);
    }
    setSelectedFilters(updatedFilters);
    console.log("selectedFilters", selectedFilters);
  };

  // Filter categories based on search input
  const filteredCategories = categories.map((category) => ({
    ...category,
    category_value_list: category.category_value_list.filter((value) =>
      value.toLowerCase().includes(searchInput.toLowerCase())
    ),
  }));

  return (
    <Box
      bgImage={`url(${banner})`} // Add your banner image path here
      bgSize="cover"
      bgPosition="bottom"
      w="100%"
      h="60vh"
      px={{ base: 4, md: 12 }}
      pt={0}
      color={colorMode === "light" ? "black" : "white"}
      position="relative" // Ensure relative position to allow absolute child positioning
      display="flex"
      flexDirection="column"
      justifyContent="center" // Center vertically
      alignItems="center" // Center horizontally
      fontFamily="Poppins, sans-serif"
    >
      <Box textAlign="center" w="100%">
        {/* Banner Heading */}
        <Heading
          as="h1"
          fontSize={{ base: "3xl", md: "6xl" }}
          mb={6}
          color="white"
          textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
        >
          <Translate text="Travel Blogs" />
        </Heading>

        {/* Search bar and filter icon */}
        <HStack
          spacing={4}
          p={1}
          w="50%"
          border="1px solid"
          borderRadius="full"
          borderColor="white"
          boxShadow="md"
          mx="auto"
          bg="blackAlpha.300"
        >
          <Input
            placeholder="To search"
            variant="unstyled"
            _placeholder={{ color: "gray.50" }}
            textColor="white"
            px={4}
            flex="1"
            size="sm"
            value={searchInput} // Bind the input to searchInput state
            onChange={(e) => setSearchInput(e.target.value)} // Update search input
          />

          <IconButton
            icon={<FiFilter />}
            size="lg"
            aria-label="Filter"
            onClick={onToggle} // Toggle the popup
            variant="unstyled"
            color="white"
          />
        </HStack>
      </Box>

      {/* Categories Popup */}
      {isOpen && (
        <Box
          position="absolute" // Use absolute positioning to overlay without pushing content
          top="68%" // Ensure it appears just below the filter button
          left="50%" // Center horizontally
          transform="translateX(-50%)" // Centering trick for absolute positioning
          w="80%" // Cover 80% of the screen width
          bg={colorMode === "light" ? "white" : "gray.700"}
          boxShadow="xl"
          p={6}
          mt={2}
          borderRadius="lg"
          zIndex={10} // Ensure it appears on top
        >
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
            {filteredCategories.map(
              (category) =>
                category.category_value_list.length > 0 && ( // Only show categories with results
                  <Box key={category.category_name}>
                    <Text fontWeight="bold" mb={4}>
                      {category.category_name}
                    </Text>
                    <HStack wrap="wrap">
                      {category.category_value_list.map((value) => (
                        <Tag
                          key={`${category.category_name}-${value}`}
                          size="lg"
                          borderRadius="full"
                          variant={
                            selectedFilters[category.category_name]?.includes(
                              value
                            )
                              ? "solid"
                              : "outline"
                          }
                          bg={
                            selectedFilters[category.category_name]?.includes(
                              value
                            )
                              ? "#286328"
                              : "none"
                          }
                          cursor="pointer"
                          onClick={() =>
                            handleFilterChange(category.category_name, value)
                          }
                        >
                          <TagLabel>{value}</TagLabel>
                        </Tag>
                      ))}
                    </HStack>
                  </Box>
                )
            )}
          </SimpleGrid>
          <HStack justify="space-between" mt={8}>
            <Button onClick={() => setSelectedFilters({})} variant="outline">
              <Translate text="Clear Filters" />
            </Button>
            <Button onClick={onToggle} bg="black" color="white">
              <Translate text=" Apply Filters" />
            </Button>
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default BlogBanner;
