import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  SimpleGrid,
  Text,
  useToast,
  useColorMode,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  Grid,
} from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"; // Import the necessary components
import axiosInstance from "../../helpers/axiosInstance";

// Register the components for chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Mapping long routes to shorter names
const routeNameMapping = {
  "/home": "Home",
  "/businesses": "Business Page",
  "/creators": "Creators Page",
  "/contact": "Contact Us",
  "/policies/privacy-policy": "Privacy Policy",
  "/policies/terms-of-use": "Terms of Service",
  "/travel-planner": "Travel Planner",
  "/ava/chat": "Chat",
  "/itinerary": "Itinerary",
  "/bucketlists": "Bucket Lists",
  "/travel_tracker": "Travel Tracker",
  "/packingLists": "Packing Lists",
  "/budget": "Budget Planner",
  "/blogs": "Blog",
};

const TimeAnalytics = () => {
  const { colorMode } = useColorMode();
  const [analyticsData, setAnalyticsData] = useState([]);
  const [blogAnalyticsData, setBlogAnalyticsData] = useState([]);
  const [summary, setSummary] = useState(null); // Set to null initially to handle async fetch
  const toast = useToast();

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const response = await axiosInstance.get("/time_tracking/all");
        console.log("response", response.data);
        if (response.status === 200) {
          const fullData = response.data.timeTrackingData;

          // Set summary data from backend (already in minutes)
          setSummary(response.data.summary);

          // Filter out blog data for the table
          const blogData = fullData.filter(
            (entry) =>
              entry.page.startsWith("/blogs/") &&
              entry.page.split("/").length > 2 // Include only blog routes with slug
          );

          setBlogAnalyticsData(blogData);

          // Exclude blog data from the bar chart
          const nonBlogData = fullData.filter(
            (entry) => !entry.page.startsWith("/blogs/")
          );

          // Set data for the bar chart (excluding blog routes)
          setAnalyticsData(nonBlogData);
        } else {
          toast({
            title: "Error fetching data",
            description: "Failed to retrieve time tracking data",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error fetching time tracking data:", error);
        toast({
          title: "Error",
          description: "Failed to retrieve time tracking data.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchAnalyticsData();
  }, [toast]);

  // Convert timeSpent and averageTimeSpent from seconds to minutes
  const convertSecondsToMinutes = (seconds) => {
    return (seconds / 60).toFixed(2);
  };

  // Extract slug from blog routes
  const getSlugFromBlogRoute = (page) => {
    const parts = page.split("/");
    return parts.length > 2 ? parts[parts.length - 1] : "Unknown";
  };

  // Bar chart data (excluding blog routes)
  const chartData = {
    labels: analyticsData.map(
      (entry) => routeNameMapping[entry.page] || entry.page
    ),
    datasets: [
      {
        label: "Avg. Time Spent (minutes)", // Convert to minutes
        data: analyticsData.map((entry) =>
          entry.averageTimeSpent
            ? convertSecondsToMinutes(entry.averageTimeSpent)
            : 0
        ),
        backgroundColor: "#2ECC71",
        borderColor: "#27AE60",
        borderWidth: 2,
        borderRadius: 10,
        barThickness: 30,
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false, // Ensure full space usage in the container
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#E0E0E0", // Light gray horizontal grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend for simplicity
      },
    },
  };

  return (
    <Box h="100vh" p={2} bg="gray.100">
      {/* Top Summary Section */}
      {summary && (
        <Box
          bg="white"
          borderRadius="lg"
          boxShadow="lg"
          p={6}
          width="100%" // Ensure full width
        >
          <Grid
            templateColumns="1fr auto 1fr auto 1fr"
            gap={4}
            alignItems="center"
          >
            <Stat ml={4}>
              <StatLabel fontSize="md" fontWeight="medium" color="gray.500">
                Total Visits
              </StatLabel>
              <StatNumber fontSize="2xl">{summary.totalVisits || 0}</StatNumber>
            </Stat>

            <Divider orientation="vertical" />

            <Stat ml={4}>
              <StatLabel fontSize="md" fontWeight="medium" color="gray.500">
                Total Time Spent
              </StatLabel>
              <StatNumber fontSize="2xl">
                {summary.totalTimeSpent ? summary.totalTimeSpent.toFixed(2) : 0}{" "}
                min
              </StatNumber>
            </Stat>

            <Divider orientation="vertical" />

            <Stat ml={4}>
              <StatLabel fontSize="md" fontWeight="medium" color="gray.500">
                Avg. Time Spent (per user)
              </StatLabel>
              <StatNumber fontSize="2xl">
                {summary.averageTimeSpent
                  ? summary.averageTimeSpent.toFixed(2)
                  : 0}{" "}
                min
              </StatNumber>
            </Stat>
          </Grid>
        </Box>
      )}

      <HStack w="100%" mt={4}>
        {/* Bar Chart */}
        <Box
          mb={8}
          bg="white"
          h="60vh"
          flex="1" // Use full width
          p={6}
          borderRadius="lg"
          boxShadow="lg"
        >
          <Heading fontSize="md" fontWeight="bold" color="black" mb={4}>
            Average Time Spent (per page)
          </Heading>
          <Box h="100%" pb={4}>
            {" "}
            {/* Ensure chart takes full height */}
            <Bar data={chartData} options={chartOptions} />
          </Box>
        </Box>

        {/* Table for Blog Data */}
        <Box
          mb={8}
          bg="white"
          h="60vh"
          w="50%"
          p={6}
          borderRadius="lg"
          boxShadow="lg"
          overflowY="auto"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Heading fontSize="md" fontWeight="bold" color="black" mb={4}>
            Blog Analytics
          </Heading>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Slug</Th>
                <Th isNumeric>Time Spent(min)</Th>
                <Th isNumeric>Visits</Th>
                <Th isNumeric>Avg. Time Spent(min)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {blogAnalyticsData.map((entry) => (
                <Tr key={entry.page}>
                  <Td>{getSlugFromBlogRoute(entry.page)}</Td>
                  <Td isNumeric>
                    {entry.timeSpent
                      ? convertSecondsToMinutes(entry.timeSpent)
                      : 0}
                  </Td>
                  <Td isNumeric>{entry.visits || 0}</Td>
                  <Td isNumeric>
                    {entry.averageTimeSpent
                      ? convertSecondsToMinutes(entry.averageTimeSpent)
                      : 0}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </HStack>
    </Box>
  );
};

export default TimeAnalytics;
