import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  Heading,
  SimpleGrid,
  useColorMode,
  Link as ChakraLink,
  HStack,
  Badge,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import axiosInstance from "../../helpers/axiosInstance"; // Adjust the path according to your project structure
import BlogBanner from "./BlogBanner";
import Navbar from "../../components/Navbar";
import darkLogo from "../../assets/images/Newlogo-black.png";
import BackToTopButton from "../common/BackToTopButton";
import Footer from "../../components/Footer";
import Translate from "../../components/LanguageTranslation/Translate";

const Blog = () => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [blogs, setBlogs] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({}); // Moved filter management to Blog component
  console.log("selectedFilters", selectedFilters);

  const constructImageUrl = (filename) => {
    if (!filename) return null;

    // Replace backslashes with forward slashes and remove the leading "./"
    const cleanPath = filename.replace(/\\/g, "/").replace(/^\.\//, "");

    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";
    const url = `${baseUrl}/${cleanPath}`;
    console.log("Constructed URL:", url);
    return url;
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axiosInstance.get("/blogs/");
      console.log("Got response:", response.data);
      if (response.data.success) {
        const blogsWithProcessedImages = response.data.data.map((blog) => ({
          ...blog,
          image_list: blog.image_list.map((image) =>
            image.startsWith("http") ? image : constructImageUrl(image)
          ),
        }));
        setBlogs(blogsWithProcessedImages);
      } else {
        console.error("Failed to fetch blogs:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching blogs:", error.message);
    }
  };

  // Filter blogs based on selected filters
  const filteredBlogs = blogs.filter((blog) =>
    Object.keys(selectedFilters).every((category) =>
      selectedFilters[category]?.some((value) =>
        blog.category.some(
          (cat) =>
            cat.category_name === category &&
            cat.category_value_list.includes(value)
        )
      )
    )
  );

  return (
    <Box bg={colorMode === "light" ? "white" : "gray.800"}>
      {/* Add Navbar at the top */}
      <Navbar bgColor="white" linkColor="black" logoSrc={darkLogo} />
      {/* BlogBanner with selectedFilters and setSelectedFilters as props */}
      <Box pb={{ base: 4, md: 16 }} pt={{ base: 4, md: 0 }}>
        <BlogBanner
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </Box>
      {/* Blog Content */}
      <Heading
        as="h1"
        fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }}
        fontWeight="bold"
        mt={7}
        mb={7}
        pl={{ base: 4, md: 16 }}
        pr={{ base: 4, md: 16 }}
        fontFamily="Poppins, sans-serif"
      >
        <Translate text="Featured Posts" />
      </Heading>

      <Box
        display="flex"
        transition="margin 0.2s ease-in-out"
        pl={{ base: 4, md: 16 }}
        pr={{ base: 4, md: 16 }}
        mb={8}
        fontFamily="Poppins, sans-serif"
      >
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {filteredBlogs.map((blog) => (
            <Box
              key={blog._id}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              display="flex"
              flexDirection="column"
              bg={colorMode === "light" ? "white" : "gray.700"}
            >
              <Image
                src={blog.image_list[0]}
                alt={blog.seo_title}
                objectFit="cover"
                height="200px"
                w="400px"
              />
              <Box p={4} flex="1" display="flex" flexDirection="column">
                <VStack align="start" spacing={2} flex="1">
                  <HStack spacing={2} mb={2}>
                    <Badge
                      bg="black"
                      variant="solid"
                      borderRadius="md"
                      textTransform="none"
                      p={3}
                      pt={1}
                      pb={1}
                    >
                      {blog.category.length > 0
                        ? blog.category[0].category_value_list.join(", ")
                        : "General"}
                    </Badge>
                    <Text fontSize="sm" color="gray.500" mb={0}>
                      {blog.creation_date || "N/A"}
                    </Text>
                  </HStack>
                  <Heading
                    as="h2"
                    fontSize="18px"
                    noOfLines={1}
                    width="100%"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    mb={0}
                  >
                    <Translate text={blog.seo_title} />
                  </Heading>
                  <Text
                    noOfLines={2}
                    fontSize="sm"
                    color={colorMode === "light" ? "gray.700" : "gray.400"}
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    mb={0}
                  >
                    <Translate text={blog.meta_description} />
                  </Text>
                </VStack>
                <ChakraLink
                  as={Link}
                  to={`/blogs/${blog._id}/${blog.slug}`}
                  color="#286328"
                  fontWeight="bold"
                  alignSelf="flex-start"
                  mt={2}
                >
                  Read more
                  <Translate text="Read more" />
                </ChakraLink>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
      <Footer />
      <BackToTopButton />
    </Box>
  );
};

export default Blog;
