// src/authToken.js
const getToken = () => {
    const storedToken = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
    //console.log("storedToken in auth token",storedToken);
    if (storedToken) {
        const authData = JSON.parse(storedToken);
        //console.log("authData in auth token",authData.access_token);
        return authData.access_token; // Assuming 'access_token' is the key
    }
    return null;
};

export default getToken;
