import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  IconButton,
  VStack,
  HStack,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import axiosInstance from "../../helpers/axiosInstance";
import { useCurrency } from "../CurrencyExchange/CurrencyContext"; // Import Currency Context
import Translate from "../../components/LanguageTranslation/Translate";

const FlightCard = ({ data }) => {
  const [current, setCurrent] = useState(0);
  const { colorMode } = useColorMode();
  const [likedActivities, setLikedActivities] = useState({});
  const { convertCurrency, currency } = useCurrency(); // Get the conversion function and selected currency

  // Safeguard against undefined or non-array data
  const numTotalCards = Array.isArray(data) ? data.length : 0;

  // If there's no data, return a fallback UI
  if (!data || data.length === 0) {
    return <Text mt={2}>No flight data available</Text>;
  }

  // Adjust number of visible cards based on screen size
  const numVisibleCards = 2;

  const next = () => {
    setCurrent((prev) => (prev + numVisibleCards) % numTotalCards);
  };

  const prev = () => {
    setCurrent(
      (prev) => (prev - numVisibleCards + numTotalCards) % numTotalCards
    );
  };

  const hasPrev = current > 0;
  const hasNext = current + numVisibleCards < numTotalCards;

  const sendLikedActivity = async (activity, isLiked) => {
    const payload = {
      flight_info: [activity], // Send only the current liked/unliked activity
      conversation_type: "flights",
    };
    try {
      await axiosInstance.post(
        "/bucket-list/save-third-party-service",
        payload
      );
      // console.log(`Activity ${isLiked ? "liked" : "unliked"}:`, activity);
    } catch (error) {
      console.error("Error saving liked activity:", error);
    }
  };

  const toggleLike = (index, activity) => {
    const updatedLikes = { ...likedActivities };
    const isLiked = !updatedLikes[index];

    if (isLiked) {
      updatedLikes[index] = activity; // Add activity if liked
    } else {
      delete updatedLikes[index]; // Remove activity if unliked
    }

    setLikedActivities(updatedLikes);

    // Send only the current activity to backend
    sendLikedActivity(activity, isLiked);
  };

  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      padding={0}
      mt={3}
      width={{ base: "100%", md: "80%", lg: "100%" }} // Responsive width
    >
      {hasPrev && (
        <IconButton
          icon={<ArrowBackIcon />}
          isRound={true}
          size="md"
          onClick={prev}
          position="absolute"
          left="10px"
          backgroundColor="white"
          boxShadow="base"
          zIndex="overlay"
        />
      )}
      <HStack
        overflow="hidden"
        width="full"
        h="auto"
        spacing={1}
        justifyContent="space-between"
      >
        {data.slice(current, current + numVisibleCards).map((item, index) => {
          // Convert the flight price
          const convertedPrice = convertCurrency(item.price, "USD"); // Assuming the base price is in USD
          const actualIndex = current + index;
          const isLiked = likedActivities[actualIndex] !== undefined;

          return (
            <Box
              key={index}
              bg="blackAlpha.50"
              padding={2}
              m={2}
              borderRadius="xl"
              boxShadow="md"
              width={{ base: "100%", sm: "80%", md: "50%", lg: "50%" }} // Responsive width for each card
              display="flex"
              flexDirection="column"
              alignItems="flex-start" // Align items to the left
            >
              <VStack
                p={2}
                borderRadius="xl"
                alignItems="flex-start"
                h="100%"
                width="100%"
                spacing={3}
                bg="white"
                position="relative"
              >
                {/* Live price and Powered by */}
                <Box width="100%">
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: "sm", md: "md" }}
                    mb={0}
                  >
                    <Translate text="Live price:" /> {currency} {convertedPrice}
                  </Text>
                  <IconButton
                    aria-label="Add to favorites"
                    icon={isLiked ? <FaHeart /> : <FaRegHeart />}
                    size="md"
                    color={isLiked ? "red.500" : "black"}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleLike(actualIndex, item);
                    }}
                    position="absolute"
                    top="0px"
                    right="0px"
                    borderRadius="full"
                    bg="none"
                    _hover={{ bg: "none" }}
                  />
                  <Text
                    fontSize={{ base: "xs", md: "sm" }}
                    color="gray.500"
                    mb={0}
                  >
                    <Translate text="Powered by" />
                    {item.airline}
                  </Text>
                </Box>

                {/* Flight Information */}
                <HStack
                  justify="space-between"
                  width="full"
                  spacing={0}
                  bg="blackAlpha.50"
                  padding={2}
                  borderRadius="xl"
                >
                  <VStack align="center" spacing={0}>
                    <Text
                      fontWeight="bold"
                      mb={0}
                      fontSize={{ base: "lg", md: "xs" }}
                    >
                      {item.origin}
                    </Text>
                    <Text
                      fontWeight="bold"
                      mb={0}
                      fontSize={{ base: "lg", md: "xs" }}
                    >
                      {item.origin_airport}
                    </Text>
                    <Text
                      fontSize={{ base: "xs", md: "xs" }}
                      mb={0}
                      color="gray.500"
                    >
                      {new Date(item.departure_at).toLocaleDateString()}
                    </Text>
                  </VStack>
                  <VStack align="center" spacing={0}>
                    <Text fontSize={{ base: "sm", md: "xs" }} mb={0}>
                      {Math.floor(item.duration_to / 60)}h{" "}
                      {item.duration_to % 60}m
                    </Text>
                    <Text fontSize={{ base: "sm", md: "xs" }} mb={0}>
                      ➔
                    </Text>
                    <Text
                      fontSize={{ base: "xs", md: "xs" }}
                      color="gray.500"
                      mb={0}
                    >
                      {item.transfers > 0
                        ? `${item.transfers} stop`
                        : "Non-stop"}
                    </Text>
                  </VStack>
                  <VStack align="center" spacing={0}>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "lg", md: "xs" }}
                      mb={0}
                    >
                      {item.destination}
                    </Text>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "lg", md: "xs" }}
                      mb={0}
                    >
                      {item.destination_airport}
                    </Text>
                    <Text
                      fontSize={{ base: "xs", md: "xs" }}
                      color="gray.500"
                      mb={0}
                    >
                      {new Date(item.return_at).toLocaleDateString()}
                    </Text>
                  </VStack>
                </HStack>
                {/* Book Button */}
                <Button
                  as="a"
                  href={item.link}
                  target="_blank"
                  size={{ base: "md", md: "sm", lg: "sm" }}
                  bg="#286328"
                  _hover="none"
                  _active="none"
                  textColor="white"
                  width="full"
                >
                  <Translate text="Book" />
                </Button>
              </VStack>
            </Box>
          );
        })}
      </HStack>
      {hasNext && (
        <IconButton
          icon={<ArrowForwardIcon />}
          isRound={true}
          size="md"
          onClick={next}
          position="absolute"
          right="10px"
          backgroundColor="white"
          boxShadow="base"
          zIndex="overlay"
        />
      )}
    </Flex>
  );
};

export default FlightCard;
