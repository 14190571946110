import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  IconButton,
  List,
  ListItem,
  ListIcon,
  Text,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { MdFiberManualRecord } from "react-icons/md";

const CategoryAccordion = ({
  categories,
  handleEditCategory,
  deleteCategory,
  deleteNewValue,
}) => (
  <Accordion allowMultiple w="100%">
    {categories.map((category, index) => (
      <AccordionItem key={index}>
        <AccordionButton>
          <Box flex="1" textAlign="left" fontWeight="bold">
            {category.name}
          </Box>
          <IconButton
            icon={<AddIcon />}
            colorScheme="black"
            size="sm"
            variant="ghost"
            onClick={(e) => handleEditCategory(e, index)}
          />
          <IconButton
            icon={<DeleteIcon />}
            colorScheme="black"
            size="sm"
            variant="ghost"
            onClick={() => deleteCategory(index)}
          />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <List spacing={3} ml={4}>
            {category.values.map((value, valueIndex) => (
              <ListItem key={valueIndex} display="flex" alignItems="center">
                <ListIcon as={MdFiberManualRecord} boxSize="2" color="black" />
                <Text flex="1" mb={0}>
                  {value}
                </Text>
                <IconButton
                  icon={<DeleteIcon />}
                  colorScheme="red"
                  size="xs"
                  variant="ghost"
                  onClick={() => deleteNewValue(index, value)} // Ensure both category index and value are passed
                />
              </ListItem>
            ))}
          </List>
        </AccordionPanel>
      </AccordionItem>
    ))}
  </Accordion>
);

export default CategoryAccordion;
