import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Radio,
  RadioGroup,
  VStack,
  Text,
  Button,
  useToast,
} from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance"; // Import axios instance
import Header from './Header';

const SettingsAndPrivacy = ({ initialPrivacy }) => {
  const location = useLocation();
  const { profilePrivateStatus } = location.state || {};
  const [privacy, setPrivacy] = useState(profilePrivateStatus ? 'private' : 'public');
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  // Function to toggle privacy (public/private)
  const togglePrivacy = async (newPrivacy) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/social_media/toggle-privacy", {
        privacy: newPrivacy,
      });

      if (response.data.success) {
        setPrivacy(newPrivacy);
        toast({
          title: "Privacy updated.",
          description: `Your profile is now ${newPrivacy}.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update privacy settings.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <Box w="full" h="100vh">
    <Header/>
    <Box p={4} borderRadius="md" boxShadow="md" bg="white" w="full">
      <Heading size="md" mb={4}>
        Profile Privacy Settings
      </Heading>
      <Text mb={4}>
        Select the privacy setting for your profile. If your profile is public,
        others will be able to see your posts and information.
      </Text>
      <RadioGroup
        onChange={togglePrivacy}
        value={privacy}
        isDisabled={loading}
      >
        <VStack align="start" spacing={4}>
          <Radio value="public">Public (Recommended)</Radio>
          <Radio value="private">Private</Radio>
        </VStack>
      </RadioGroup>
      {loading && <Text mt={2}>Updating...</Text>}
      </Box>
    </Box>
  );
};

export default SettingsAndPrivacy;
