import React from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import darkLogo from "../assets/images/Newlogo-black.png";
import BackToTopButton from "../components/common/BackToTopButton";
import Footer from "./Footer";

const TermsOfService = () => {
  return (
    <Box>
      <Navbar bgColor="white" linkColor="black" logoSrc={darkLogo} />
      <Box
        maxW={{ base: "100vw", sm: "85vw", md: "85vw", lg: "60vw" }}
        mx="auto"
        px={4}
        py={8}
        pt={8}
      >
        <Heading as="h1" size="xl" mb={4}>
          Terms of Use
        </Heading>
        <VStack align="start" spacing={4}>
          <Text>
            Welcome to our website. If you continue to browse and use this
            website, you are agreeing to comply with and be bound by the
            following terms and conditions of use, which together with our
            privacy policy govern [Your Company Name]'s relationship with you in
            relation to this website.
          </Text>

          <Heading as="h2" size="lg">
            1. Introduction
          </Heading>
          <Text>
            The content of the pages of this website is for your general
            information and use only. It is subject to change without notice.
          </Text>

          <Heading as="h2" size="lg">
            2. License to use website
          </Heading>
          <Text>
            Unless otherwise stated, [Your Company Name] and/or its licensors
            own the intellectual property rights published on this website and
            materials used on [Your Company Name]. Subject to the license below,
            all these intellectual property rights are reserved.
          </Text>

          <Heading as="h2" size="lg">
            3. Acceptable use
          </Heading>
          <Text>
            You must not use this website in any way that causes, or may cause,
            damage to the website or impairment of the availability or
            accessibility of [Your Company Name] or in any way which is
            unlawful, illegal, fraudulent or harmful, or in connection with any
            unlawful, illegal, fraudulent or harmful purpose or activity.
          </Text>

          {/* Add more sections as needed */}
        </VStack>
      </Box>
      <Footer/>
      <BackToTopButton />
    </Box>
  );
};

export default TermsOfService;
