import React from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Flex,
  Link,
  Image,
  IconButton,
  useDisclosure,
  Stack,
  Button,
  Collapse,
  Divider,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import AuthModal from "./AuthModal";
import UserProfileMenu from "./UserProfileMenu";
import { useAuth } from "../auth";
import SettingsMenu from "./common/SettingsMenu";
import Translate from "../components/LanguageTranslation/Translate";

const NavBar = ({
  bgColor = "black",
  linkColor = "white",
  logoSrc,
  languageCodeColor,
}) => {
  const history = useHistory();
  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
  } = useDisclosure();
  const {
    isOpen: isAuthModalOpen,
    onOpen: onAuthModalOpen,
    onClose: onAuthModalClose,
  } = useDisclosure();
  const [logged] = useAuth();

  const navLinks = [
    { to: "/travel-planner", text: "Travel Planner" },
    { to: "/creators", text: "For Creators" },
    { to: "/businesses", text: "For Businesses" },
    { to: "/blogs", text: "Blogs" },
  ];

  return (
    <Box
      top={{ base: "0", md: "0" }}
      bg={bgColor} // Navbar background color is passed as a prop
      width="100%" // Adjust the width of the navbar to a smaller percentage
      mx="auto" // Center the entire navbar horizontally
      zIndex="1000"
      p={2}
      pl={4}
      pr={4}
      pb={4}
      pt={4}
      borderRadius="lg"
    >
      <Flex h="35px" alignItems="center" justifyContent="space-between">
        <IconButton
          display={{ base: "black", md: "none" }}
          onClick={isMenuOpen ? onMenuClose : onMenuOpen}
          variant="outline"
          aria-label="Open Menu"
          icon={<HamburgerIcon />}
        />
        <Link as={RouterLink} to="/home" borderRadius="full" bg="white">
          <Image
            src={logoSrc} // Logo is passed as a prop
            alt="logo"
            width="120px"
            height="45px"
            objectFit="contain"
            pl={3}
            pr={3}
          />
        </Link>

        {/* Nav Links */}
        <Flex
          as="nav"
          justify="flex-start"
          alignItems="center"
          display={{ base: "none", md: "flex" }}
          flex="1"
          pl={2}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={4}
            borderRadius="full"
            p={3}
            pl={4}
            pr={4}
            // bg="white"
          >
            {navLinks.map((link, index) => (
              <React.Fragment key={index}>
                <Link
                  as={RouterLink}
                  to={link.to}
                  fontWeight="bold"
                  fontSize={{ base: "xs", md: "sm", lg: "sm" }}
                  color={linkColor} // Link color is passed as a prop
                  fontFamily="Poppins, sans-serif"
                >
                  
                  <Translate text={link.text}/>
                </Link>
                {index < navLinks.length - 1 && (
                  <Divider orientation="vertical" height="20px" borderColor={linkColor} />
                )}
              </React.Fragment>
            ))}
          </Stack>
        </Flex>

        {/* Right side buttons and user profile */}
        <Flex
          alignItems="center"
          borderRadius="full"
          p="2px"
          pl={4}
          pr={4}
          // bg="rgba(255, 255, 255, 0.4)"
        >
          <SettingsMenu languageCodeColor={languageCodeColor} />
          {logged ? (
            <UserProfileMenu />
          ) : (
            <Button
              onClick={onAuthModalOpen}
              bg="none"
              _hover="none"
              _active="none"
              color={linkColor} // Button text color passed as prop
              size={{ base: "xs", md: "sm", lg: "sm" }}
              fontSize={{ base: "xs", md: "sm", lg: "sm" }}
            >
              
              <Translate text="Sign In"/>
            </Button>
          )}
          <Button
            onClick={() => history.push("/travel-planner")}
            bg={linkColor === "white" ? "black" : linkColor}
            // borderColor="white"
            // border="1px"
            borderRadius="md"
            _hover="none"
            color={linkColor === "white" ? "white" : "white"}
            size={{ base: "xs", md: "sm", lg: "sm" }}
            fontSize={{ base: "xs", md: "sm", lg: "sm" }}
            ml={{ base: "0", md: "2", lg: "2" }}
          >
            
            <Translate text="Get Started"/>
          </Button>
        </Flex>
      </Flex>

      {/* Mobile Menu */}
      <Collapse in={isMenuOpen} animateOpacity>
        <Box display={{ md: "none" }} pb={4} mt={2}>
          <Stack as="nav" spacing={4}>
            {navLinks.map((link, index) => (
              <Link
                as={RouterLink}
                key={index}
                to={link.to}
                fontWeight="bold"
                onClick={link.onClick ? link.onClick : null}
                color={linkColor} // Apply link color to mobile menu links
              >
                
                <Translate text={link.text}/>
              </Link>
            ))}
          </Stack>
        </Box>
      </Collapse>
      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={onAuthModalClose}
        redirectPath="/home"
      />
    </Box>
  );
};

export default NavBar;
