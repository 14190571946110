import React, { useState } from "react";
import {
  Box,
  Text,
  Input,
  Button,
  IconButton,
  Spacer,
  useColorMode,
  VStack,
  HStack,
  Avatar,
  List,
  ListItem,
} from "@chakra-ui/react";
import { useHistory} from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import SettingsMenu from "../common/SettingsMenu";

const Header = () => {
  const { colorMode } = useColorMode();
  const history = useHistory();

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      h="8vh"
      borderBottom="1px"
      borderColor="blackAlpha.200"
    >
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        height="100%"
        p={4}
        // pt={2}
        // pb={2}
        pl={0}
        boxSizing="border-box"
      >
        <IconButton
          icon={<ArrowBackIcon />}
          variant="ghost"
          _hover="none"
          onClick={() => history.goBack()}
        />

        <Text
          fontSize="md"
          fontWeight="bold"
          mb={0}   
          color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
        >
          Settings & Privacy
        </Text>
        <Spacer />
        <SettingsMenu />
      </Box>
    </Box>
  );
};

export default Header;
