import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const CurrencyContext = createContext();

export const useCurrency = () => useContext(CurrencyContext);

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState("USD"); // Default currency
  const [rates, setRates] = useState({}); // Stores exchange rates
  const [loading, setLoading] = useState(true); // Loading state for fetching rates
  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await axios.get("https://api.exchangerate-api.com/v4/latest/USD"); 
        // Log the full response to see what the API is returning
        // console.log("API response:", response.data);
  
        if (response.data && response.data.rates) {
          const fetchedRates = response.data.rates;
  
          // Ensure that USD is always part of the rates
          setRates({ ...fetchedRates, USD: 1 });
          // console.log("Rates fetched:", { ...fetchedRates, USD: 1 });
        } else {
          console.error("Invalid rates data", response.data);
        }
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      } finally {
        setLoading(false); // Ensure loading is turned off after fetching or error
      }
    };
  
    fetchRates();
  }, []);
  

  const convertCurrency = (amount, baseCurrency) => {
    // console.log("Converting from:", baseCurrency, "to:", currency);
    // console.log("Amount before conversion:", amount);
    // console.log("Rates available:", rates); // Added log to see the available rates

    // Check if rates are available and the rates contain the currencies we need
    if (loading || !rates[baseCurrency] || !rates[currency]) {
      console.error(`Rates for ${baseCurrency} or ${currency} are not available`);
      return amount; // Return the original amount if rates are not available
    }

    // If the base currency is the same as the target currency, no conversion is needed
    if (baseCurrency === currency) {
      return amount;
    }

    let amountInUSD;

    // Convert to USD first if baseCurrency is not USD
    if (baseCurrency !== "USD") {
      amountInUSD = amount / rates[baseCurrency];
      // console.log("Amount in USD:", amountInUSD);
    } else {
      amountInUSD = amount; // If baseCurrency is USD, the amount is already in USD
    }

    // Convert from USD to the target currency
    const convertedAmount = amountInUSD * rates[currency];
    // console.log("Converted amount:", convertedAmount);

    return convertedAmount.toFixed(2); // Return with 2 decimal places
  };

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency, convertCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};
