import React, { useState } from "react";
import {
  Box,
  Text,
  Input,
  Button,
  IconButton,
  Spacer,
  useColorMode,
  VStack,
  HStack,
  Avatar,
  List,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useClipboard,
} from "@chakra-ui/react";
import { FaShareAlt, FaCog } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../helpers/axiosInstance";
import SettingsMenu from "../common/SettingsMenu";
import Translate from "../../components/LanguageTranslation/Translate";

const Header = ({
  onOpen,
  profilePrivateStatus = false,
  profileData,
  socialId,
}) => {
  const { colorMode } = useColorMode();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const history = useHistory();

  // Modal control
  const { isOpen, onOpen: openModal, onClose } = useDisclosure();
  const [shareUrl, setShareUrl] = useState("");
  const { hasCopied, onCopy } = useClipboard(shareUrl); // Chakra's useClipboard hook

  const handleSearch = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 2) {
      try {
        const response = await axiosInstance.get(
          `/social_media/search/${query}`
        );
        if (response.data.success) {
          setSearchResults(response.data.data);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error("Failed to search profiles", error);
        setSearchResults([]);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleSelectResult = (socialId) => {
    setSearchQuery("");
    setSearchResults([]);
    history.push(`/search-user/${socialId}`, {
      following: profileData.following,
    });
  };

  const navigateToPrivacySettings = (profilePrivateStatus) => {
    if (profilePrivateStatus === undefined || profilePrivateStatus === null) {
      console.warn("profilePrivateStatus is not provided");
      return;
    }
    history.push("/settings/privacy", { profilePrivateStatus });
  };

  const handleShare = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL; // Use the base URL from .env file
    const generatedUrl = `${baseUrl}/search-user/${socialId}`;
    setShareUrl(generatedUrl); // Set the share URL in state
    openModal(); // Open the modal
  };

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      pb={1}
      h="8vh"
      borderBottom="1px"
      borderColor="blackAlpha.200"
    >
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        height="100%"
        p={4}
        pt={2}
        pb={2}
        boxSizing="border-box"
      >
        <Text
          fontSize="md"
          fontWeight="bold"
          mb={0}
          mt={2}
          color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
        >
          <Translate text="Travel Tracker" />
        </Text>
        <Spacer />
        <Box position="relative" width="40%">
          <Input
            placeholder={Translate({
              text: "Search profiles...",
              as: "string",
            })}
            value={searchQuery}
            onChange={handleSearch}
            size="sm"
            mr={4}
            mt={1}
            borderRadius="full"
          />
          {searchResults.length > 0 && (
            <Box
              position="absolute"
              top="100%"
              left="0"
              width="100%"
              bg={colorMode === "dark" ? "gray.700" : "white"}
              borderRadius="md"
              boxShadow="lg"
              zIndex="1000"
              mt={2}
              p={2}
            >
              <List spacing={2} pl={0}>
                {searchResults.map((result) => (
                  <ListItem
                    key={result._id}
                    p={2}
                    borderRadius="md"
                    _hover={{
                      bg: colorMode === "dark" ? "gray.600" : "gray.100",
                    }}
                    onClick={() => handleSelectResult(result._id)}
                  >
                    <HStack spacing={3}>
                      <Avatar src={result.image_url} size="sm" />
                      <VStack align="start" spacing={0}>
                        <Text fontWeight="bold" fontSize="sm" mb={0}>
                          {result.public_name}
                        </Text>
                        <Text fontSize="xs" color="gray.500" mb={0}>
                          {result.bio}
                        </Text>
                      </VStack>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
        <Button
          colorScheme="teal"
          size="sm"
          mt={1}
          mr={2}
          onClick={onOpen}
          bg="#286328"
          color="white"
          _hover="none"
          ml={4}
        >
          <Translate text="Post" />
        </Button>
        <IconButton
          size="sm"
          icon={<FaShareAlt />}
          onClick={handleShare} // Call the new share function to open modal
          aria-label="Share"
          bg="none"
          _hover="none"
        />
        <IconButton
          size="sm"
          icon={<FaCog />}
          aria-label="Settings"
          onClick={() => navigateToPrivacySettings(profilePrivateStatus)}
          bg="none"
          _hover="none"
        />
        <SettingsMenu />
      </Box>

      {/* Modal for sharing the link */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Translate text="Share Profile" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              <Translate text="Here is the shareable link for the profile:" />
            </Text>
            <Box
              bg={colorMode === "light" ? "gray.100" : "gray.700"}
              p={3}
              borderRadius="md"
              wordBreak="break-all"
            >
              {shareUrl}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onCopy}
              _hover="none"
              bg="black"
              color="white"
              mr={3}
            >
              <Translate text={hasCopied ? "Copied" : "Copy Link"} />
            </Button>
            <Button variant="ghost" onClick={onClose}>
              <Translate text="Close" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Header;
