import React, { useState } from "react";
import {
  Box,
  VStack,
  Input,
  Button,
  Textarea,
  HStack,
  RadioGroup,
  Radio,
  IconButton,
  Text,
  Spacer,
  useColorMode,
  Heading,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import axiosInstance from "../../helpers/axiosInstance"; // Adjust import path for axios
import Header from "./Header";

const AdminFeedbackSurvey = () => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [questions, setQuestions] = useState([]);
  const [newQuestionText, setNewQuestionText] = useState("");
  const [newQuestionType, setNewQuestionType] = useState("radio");
  const [newOptions, setNewOptions] = useState([""]); // For radio options
  const [feedbackField, setFeedbackField] = useState(false); // To add feedback field
  const [heading, setHeading] = useState(""); // Survey heading
  const [subtext, setSubtext] = useState(""); // Survey subtext

  // Add a new question to the survey
  const addQuestion = () => {
    if (newQuestionText.trim() !== "") {
      const newQuestion = {
        text: newQuestionText,
        type: newQuestionType,
        options: newQuestionType === "radio" ? [...newOptions] : [], // Only add options for radio-type
      };
      setQuestions([...questions, newQuestion]);
      setNewQuestionText("");
      setNewOptions([""]); // Reset options for next radio button set
    }
  };

  // Add a new option for radio buttons
  const addOption = (index) => {
    const updatedOptions = [...newOptions];
    updatedOptions.splice(index + 1, 0, ""); // Add empty option
    setNewOptions(updatedOptions);
  };

  // Handle change for individual options
  const handleOptionChange = (value, index) => {
    const updatedOptions = [...newOptions];
    updatedOptions[index] = value;
    setNewOptions(updatedOptions);
  };

  // Delete a question from the survey
  const deleteQuestion = (index) => {
    setQuestions(questions.filter((_, qIndex) => qIndex !== index));
  };

  // Save survey logic with API call
  const saveSurvey = async () => {
    const radioQuestions = questions
      .filter((q) => q.type === "radio")
      .map((q) => ({
        ...q,
        options: q.options.filter((opt) => opt.trim() !== ""), // Remove empty options
      }));
  
    const feedbackFields = questions
      .filter((q) => q.type === "textarea")
      .map((q) => ({
        text: q.text,
        type: q.type,
      })); // Remove the options field for text areas
  
    // Frontend validation: Ensure heading is present and either questions or feedbackField is provided
    if (!heading) {
      toast({
        title: "Error",
        description: "Heading is required.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
  
    if (questions.length === 0 && !feedbackField) {
      toast({
        title: "Error",
        description: "At least one question or feedback field must be provided.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
  
    const surveyData = {
      heading,
      subtext,
      questions: radioQuestions,
      feedbackField: feedbackFields.length > 0 ? feedbackFields : feedbackField,
    };
  
    console.log("surveyData", surveyData);
  
    try {
      // Make a request to the backend to create the survey
      const response = await axiosInstance.post("/survey/create", surveyData);
  
      if (response.status === 201) {
        // Reset form fields after saving
        setHeading("");
        setSubtext("");
        setQuestions([]);
        setNewQuestionText("");
        setNewOptions([""]);
        setFeedbackField(false);
        setNewQuestionType("radio");
  
        // Show success message from backend
        toast({
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (response.status >= 400 && response.status < 500) {
        // Display backend validation error messages (client-side errors)
        toast({
          title: "Error",
          description: response.data.message || "Something went wrong.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Backend errors (like validation failures)
      if (error.response && error.response.data) {
        const backendErrors = error.response.data.errors || error.response.data.message;
        if (Array.isArray(backendErrors)) {
          // If backend returns multiple errors, map them and display as a list
          backendErrors.forEach((err) => {
            toast({
              title: "Error",
              description: err,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
        } else {
          // Single error message from backend
          toast({
            title: "Error",
            description: backendErrors,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        // General error handling for unexpected cases
        toast({
          title: "Error",
          description: "Failed to save the survey. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };
  

  return (
    <Box h="100vh">
    <Header saveSurvey={saveSurvey}/>
      {/* Main survey creation content */}
      <Box h="90vh" overflowY="auto">
        <VStack
          // Adjusted height to allow room for more content
          spacing={4}
          align="flex-start"
          justifyContent="flex-start"
          p={4}
          w="100%"
          // Added scroll for vertical content overflow
        >
          {/* Survey Heading Input */}
          <Heading fontSize="md" fontWeight="bold" mb={0}>
            Heading
          </Heading>

          <Input
            placeholder="Enter survey heading"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
            w="50%"
            minWidth="300px" // Set a minimum width to prevent compression
          />

          {/* Survey Subtext Input */}
          <Heading fontSize="md" fontWeight="bold" mb={0}>
            Text
          </Heading>
          <Textarea
            placeholder="Enter subtext for the survey"
            value={subtext}
            onChange={(e) => setSubtext(e.target.value)}
            w="50%"
            minWidth="300px" // Set a minimum width
          />

          <Heading fontSize="md" fontWeight="bold" mb={0}>
            Questions
          </Heading>
          {/* Question type selector */}
          <HStack spacing={4}>
            <Button
              bg={newQuestionType === "radio" ? "#286328" : "gray.300"}
              color={newQuestionType === "radio" ? "white" : "black"}
              onClick={() => setNewQuestionType("radio")}
              size="sm" // Set min-width for consistent button size
              _hover="none"
            >
              Radio Options
            </Button>
            <Button
              bg={newQuestionType === "textarea" ? "#286328" : "gray.300"}
              color={newQuestionType === "textarea" ? "white" : "black"}
              onClick={() => setNewQuestionType("textarea")}
              size="sm" // Set min-width for consistent button size
              _hover="none"
            >
              Text Area
            </Button>
          </HStack>
          {/* Survey question input */}

          <HStack w="50%" minWidth="300px" justifyContent="center">
            <Input
              placeholder="Enter survey question"
              value={newQuestionText}
              onChange={(e) => setNewQuestionText(e.target.value)}
            />
          </HStack>

          {/* Show radio options input if question type is 'radio' */}
          {newQuestionType === "radio" && (
            <VStack align="flex-start" spacing={2} w="50%">
              {newOptions.map((option, index) => (
                <HStack key={index} w="100%" minWidth="300px">
                  <Input
                    value={option}
                    placeholder={`Option ${index + 1}`}
                    onChange={(e) => handleOptionChange(e.target.value, index)}
                  />
                  <IconButton
                    icon={<AddIcon />}
                    onClick={() => addOption(index)}
                    size="sm"
                    bg="#286328"
                    _hover="none"
                    color="white"
                  />
                </HStack>
              ))}
            </VStack>
          )}

          <Button
            bg="#286328"
            color="white"
            _hover="none"
            onClick={addQuestion}
            size="sm"
          >
            Add Question
          </Button>
          {/* List of added questions */}
          <VStack align="flex-start" w="70%" minWidth="300px">
            {questions.length > 0 && <Text>Preview:</Text>}
            {questions.map((question, index) => (
              <Box
                key={index}
                p={4}
                bg="white"
                w="100%"
                borderRadius="md"
                shadow="sm"
              >
                <HStack justify="space-between">
                  <Text>{question.text}</Text>
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => deleteQuestion(index)}
                    size="sm"
                  />
                </HStack>
                {question.type === "radio" && (
                  <RadioGroup>
                    <VStack align="flex-start">
                      {" "}
                      {/* This ensures vertical stacking */}
                      {question.options.map((option, optIndex) => (
                        <Radio key={optIndex} value={option} isDisabled>
                          {option}
                        </Radio>
                      ))}
                    </VStack>
                  </RadioGroup>
                )}
                {question.type === "textarea" && (
                  <Textarea placeholder="Write your feedback here" isDisabled />
                )}
              </Box>
            ))}
          </VStack>
        </VStack>
      </Box>
    </Box>
  );
};

export default AdminFeedbackSurvey;
