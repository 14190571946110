import React from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import darkLogo from "../assets/images/Newlogo-black.png";
import BackToTopButton from "../components/common/BackToTopButton";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <Box>
      {" "}
      <Navbar bgColor="white" linkColor="black" logoSrc={darkLogo} />
      <Box
        maxW={{ base: "100vw", sm: "85vw", md: "85vw", lg: "60vw" }}
        mx="auto"
        px={4}
        py={8}
        pt={8}
      >
        <Heading as="h1" size="xl" mb={4}>
          Privacy Policy
        </Heading>
        <VStack align="start" spacing={4}>
          <Text>
            This privacy policy sets out how [Your Company Name] uses and
            protects any information that you give [Your Company Name] when you
            use this website.
          </Text>

          <Heading as="h2" size="lg">
            1. What we collect
          </Heading>
          <Text>
            We may collect the following information:
            <br />
            - Name and job title
            <br />
            - Contact information including email address
            <br />
            - Demographic information such as postcode, preferences, and
            interests
            <br />- Other information relevant to customer surveys and/or offers
          </Text>

          <Heading as="h2" size="lg">
            2. What we do with the information we gather
          </Heading>
          <Text>
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:
            <br />
            - Internal record keeping
            <br />
            - We may use the information to improve our products and services.
            <br />- We may periodically send promotional emails about new
            products, special offers, or other information which we think you
            may find interesting using the email address which you have
            provided.
          </Text>

          <Heading as="h2" size="lg">
            3. Security
          </Heading>
          <Text>
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorized access or disclosure, we have put in
            place suitable physical, electronic, and managerial procedures to
            safeguard and secure the information we collect online.
          </Text>

          {/* Add more sections as needed */}
        </VStack>
      </Box>
      <Footer/>
      <BackToTopButton />
    </Box>
  );
};

export default PrivacyPolicy;
