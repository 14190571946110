import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  useColorMode,
  Text,
  useToast,
  Spacer,
  Image,
  SimpleGrid,
  HStack,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Checkbox,
  CheckboxGroup,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  AddIcon,
  CloseIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDropzone } from "react-dropzone";
import SettingsMenu from "../../components/common/SettingsMenu";
import axiosInstance from "../../helpers/axiosInstance";

const AdminBlogUpload = () => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const history = useHistory();

  const [blogData, setBlogData] = useState({
    seoTitle: "",
    slug: "",
    metaDescription: "",
    focusKeyphrase: "",
    content: "",
    categories: [],
    values: {},
    imageFiles: [], // State to hold selected image files
    imageURLs: [], // State to hold inputted image URLs
  });

  const [allCategories, setAllCategories] = useState([]);

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const fetchAllCategories = async () => {
    try {
      const response = await axiosInstance.get("/categories/");
      if (response.data.success) {
        setAllCategories(response.data.data);
      } else {
        throw new Error(response.data.message || "Failed to fetch categories");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCategoryChange = (categoryId) => {
    const selectedCategories = [...blogData.categories];
    if (selectedCategories.includes(categoryId)) {
      setBlogData({
        ...blogData,
        categories: selectedCategories.filter((id) => id !== categoryId),
        values: { ...blogData.values, [categoryId]: [] },
      });
    } else {
      setBlogData({
        ...blogData,
        categories: [...selectedCategories, categoryId],
        values: { ...blogData.values, [categoryId]: [] },
      });
    }
  };

  const handleValueChange = (categoryId, selectedValues) => {
    setBlogData({
      ...blogData,
      values: {
        ...blogData.values,
        [categoryId]: selectedValues,
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBlogData({ ...blogData, [name]: value });
  };

  const handleFileDrop = (acceptedFiles) => {
    setBlogData({
      ...blogData,
      imageFiles: [...blogData.imageFiles, ...acceptedFiles],
    });
  };

  const addImageURLField = () => {
    setBlogData({
      ...blogData,
      imageURLs: [...blogData.imageURLs, ""],
    });
  };

  const handleImageURLChange = (index, value) => {
    const updatedImageURLs = blogData.imageURLs.map((url, i) =>
      i === index ? value : url
    );
    setBlogData({ ...blogData, imageURLs: updatedImageURLs });
  };

  const removeImageURLField = (index) => {
    setBlogData({
      ...blogData,
      imageURLs: blogData.imageURLs.filter((_, i) => i !== index),
    });
  };

  const handleRemoveImage = (index) => {
    setBlogData({
      ...blogData,
      imageFiles: blogData.imageFiles.filter((_, i) => i !== index),
    });
  };

  const handleContentChange = (value) => {
    setBlogData({ ...blogData, content: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the category structure for the payload
    const categoryPayload = blogData.categories.map((categoryId) => ({
      category_name: categoryId,
      category_value_list: blogData.values[categoryId],
    }));

    const formData = new FormData();

    formData.append("category", JSON.stringify(categoryPayload));
    formData.append("seo_title", blogData.seoTitle);
    formData.append("slug", blogData.slug);
    formData.append("meta_description", blogData.metaDescription);
    formData.append("focus_keyphrase", blogData.focusKeyphrase);
    formData.append("content", blogData.content);

    // Append image URLs to the payload
    formData.append("image_list", JSON.stringify(blogData.imageURLs));

    // Append each file as binary data
    blogData.imageFiles.forEach((file) => {
      formData.append("image_files", file);
    });

    // Log the formData content to check what's being sent
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    try {
      const response = await axiosInstance.post("/blogs/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        toast({
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Clear the form
        setBlogData({
          seoTitle: "",
          slug: "",
          metaDescription: "",
          focusKeyphrase: "",
          content: "",
          categories: [],
          values: {},
          imageFiles: [],
          imageURLs: [],
        });
      } else {
        throw new Error(response.data.message || "Failed to create blog");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileDrop,
    accept: "image/*",
  });

  return (
    <Box h="100vh" bg="white" overflowY="auto">
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        // pb={1}
        maxH="8vh"
        borderBottom="1px"
        borderColor="blackAlpha.200"
        overflowY="hidden"
      >
        <Box
          display="flex"
          alignItems="center"
          bg={colorMode === "dark" ? "gray.700" : "white"}
          width="100%"
          height="100%"
          p={4}
          pt={2}
          pb={2}
          boxSizing="border-box"
          overflowY="hidden"
        >
          <Text
            fontSize="md"
            fontWeight="bold"
            mb={0}
            mt={0}
            color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
          >
            Upload Blogs
          </Text>
          <Spacer />
          <Button
            size="sm"
            color="#286328" // This sets the text color
            variant="outline"
            _hover={{ borderColor: "#204F23", background: "none" }} // This ensures no background color change on hover
            textColor="black"
            borderRadius="xl"
            borderColor="#286328" // This sets the border color
            onClick={() => history.push("/admin/blog/filters")}
          >
            Add Filters
          </Button>

          <Button
            // mt={1}
            ml={3}
            size="sm"
            bg="#286328"
            _hover="none"
            textColor="white"
            borderRadius="xl"
            onClick={() => history.push("/admin/all-blog")}
          >
            Blogs
          </Button>
          <SettingsMenu />
        </Box>
      </Box>
      <Box
        p={6}
        bg={colorMode === "dark" ? "gray.700" : "white"}
        overflowY="auto"
        h="90vh"
        align="center"
        justify="left"
      >
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} p={8} w="80%" borderWidth={1} borderRadius="lg">
            <FormControl id="category">
              <FormLabel>Categories</FormLabel>
              <Popover placement="bottom-start" strategy="fixed">
                <PopoverTrigger>
                  <Box display="flex" justifyContent="flex-start">
                    <Button
                      bg="white"
                      border="1px solid"
                      borderColor="gray.200"
                      rightIcon={<ChevronDownIcon />}
                    >
                      Select Categories
                    </Button>
                  </Box>
                </PopoverTrigger>
                <PopoverContent zIndex="popover">
                  <PopoverBody p={0}>
                    <Accordion allowMultiple>
                      {allCategories.map((category) => (
                        <AccordionItem key={category.category_name}>
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left">
                                <Checkbox
                                  isChecked={blogData.categories.includes(
                                    category.category_name
                                  )}
                                  onChange={() =>
                                    handleCategoryChange(category.category_name)
                                  }
                                >
                                  {category.category_name}
                                </Checkbox>
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <CheckboxGroup
                              value={
                                blogData.values[category.category_name] || []
                              }
                              onChange={(values) =>
                                handleValueChange(
                                  category.category_name,
                                  values
                                )
                              }
                            >
                              <Stack spacing={2}>
                                {category.category_value_list.map(
                                  (value, index) => (
                                    <Box
                                      key={index}
                                      border="1px"
                                      borderColor="gray.200"
                                      borderRadius="md"
                                      p={2}
                                      textAlign="left"
                                    >
                                      <Checkbox value={value}>{value}</Checkbox>
                                    </Box>
                                  )
                                )}
                              </Stack>
                            </CheckboxGroup>
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </FormControl>

            <FormControl id="seoTitle">
              <FormLabel>SEO Title</FormLabel>
              <Input
                name="seoTitle"
                value={blogData.seoTitle}
                onChange={handleChange}
                placeholder="Enter SEO title"
                // zIndex="1" // Ensure this is above the popover
                // position="relative" // Add position relative
              />
            </FormControl>

            <FormControl id="slug">
              <FormLabel>Slug</FormLabel>
              <Input
                name="slug"
                value={blogData.slug}
                onChange={handleChange}
                placeholder="Enter slug (e.g., my-blog-post)"
                // zIndex="1" // Ensure this is above the popover
                // position="relative" // Add position relative
              />
            </FormControl>

            <FormControl id="image" isRequired>
              <FormLabel>Images</FormLabel>
              <Box
                {...getRootProps()}
                border="2px dashed"
                borderColor="gray.300"
                p={4}
                textAlign="center"
                cursor="pointer"
              >
                <input {...getInputProps()} />
                <p>Drag 'n' drop images here, or click to select files</p>
              </Box>
              {blogData.imageFiles.length > 0 && (
                <SimpleGrid columns={[2, 3, 6]} spacing={4}>
                  {blogData.imageFiles.map((file, index) => (
                    <Box key={index} position="relative">
                      <IconButton
                        icon={<CloseIcon boxSize="3" />}
                        bg="none"
                        size="xs"
                        position="relative"
                        top="6"
                        right="-9"
                        borderRadius="full"
                        onClick={() => handleRemoveImage(index)}
                      />
                      <Image
                        src={URL.createObjectURL(file)}
                        alt={`Preview ${index + 1}`}
                        boxSize="100px"
                        objectFit="cover"
                        borderRadius="md"
                      />
                    </Box>
                  ))}
                </SimpleGrid>
              )}

              <FormControl mt={4}>
                <FormLabel>Image URLs</FormLabel>
                {blogData.imageURLs.map((url, index) => (
                  <HStack key={index} mt={2}>
                    <Input
                      placeholder="Enter image URL"
                      value={url}
                      onChange={(e) =>
                        handleImageURLChange(index, e.target.value)
                      }
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      size="xs"
                      onClick={() => removeImageURLField(index)}
                    />
                  </HStack>
                ))}
                <Button
                  mt={2}
                  leftIcon={<AddIcon />}
                  onClick={addImageURLField}
                >
                  Add URL
                </Button>
              </FormControl>
            </FormControl>

            <FormControl id="metaDescription" isRequired>
              <FormLabel>Meta Description</FormLabel>
              <Textarea
                name="metaDescription"
                value={blogData.metaDescription}
                onChange={handleChange}
                placeholder="Enter meta description for SEO"
              />
            </FormControl>

            <FormControl id="focusKeyphrase" isRequired>
              <FormLabel>Focus Keyphrase</FormLabel>
              <Input
                name="focusKeyphrase"
                value={blogData.focusKeyphrase}
                onChange={handleChange}
                placeholder="Enter focus keyphrase"
              />
            </FormControl>

            <FormControl id="content" isRequired mb={6}>
              <FormLabel>Content</FormLabel>
              <ReactQuill
                value={blogData.content}
                onChange={handleContentChange}
                placeholder="Enter blog content"
                theme="snow"
                style={{
                  height: "30vh",
                }}
              />
            </FormControl>
            <Spacer />
            <Button type="submit" bg="#286328" textColor="white" width="full">
              Upload Blog
            </Button>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default AdminBlogUpload;
