import React, { useState, useEffect } from "react";
import { Box, HStack, VStack, Text, Link, Button } from "@chakra-ui/react";
import { FaLanguage, FaThermometerHalf, FaCreditCard } from "react-icons/fa";
import {
  MdOutlineAttachMoney,
  MdLocationOn,
  MdOutlineAccessTime,
  MdCurrencyExchange,
} from "react-icons/md";
import axios from "axios";
import countries from "i18n-iso-countries";
import eng from "i18n-iso-countries/langs/en.json";
import { ReactComponent as ExchangeIcon } from "../../assets/icons/exchange.svg";
import currencySymbolMap from "currency-symbol-map";
import Translate from "../../components/LanguageTranslation/Translate";

// Register the English locale for country codes
countries.registerLocale(eng);

const InfoCard = ({ country, city }) => {
  const [generalInfo, setGeneralInfo] = useState({
    currency_code: "",
    currency_name: "",
    language: "",
  });
  const currencyCode = generalInfo.currency_code || "N/A";
  const currencySymbol = currencySymbolMap(currencyCode) || currencyCode;

  const [localTime, setLocalTime] = useState({
    utc_time: "",
    utc_offset: "",
  });
  const [temperature, setTemperature] = useState("");
  const [currencyRate, setCurrencyRate] = useState("");

  // Convert country name to country code dynamically
  const countryCode = country ? countries.getAlpha2Code(country, "en") : null;

  // Fetch country info and reset states
  useEffect(() => {
    // Reset states when country or city changes
    setGeneralInfo({
      currency_code: "",
      currency_name: "",
      language: "",
    });
    setLocalTime({
      utc_time: "",
      utc_offset: "",
    });
    setTemperature("");
    setCurrencyRate(""); // Reset currency rate on country change

    // Fetch general country info based on the country
    const fetchCountryInfo = async () => {
      try {
        const response = await axios.get(`/general_info/country/${country}`);
        setGeneralInfo(response.data);
      } catch (error) {
        console.error("Error fetching country info:", error);
      }
    };

    if (country) {
      fetchCountryInfo();
    }
  }, [country]);

  // Fetch local time and temperature based on the city
  useEffect(() => {
    // Reset state when city changes
    setLocalTime({
      utc_time: "",
      utc_offset: "",
    });
    setTemperature("");

    const fetchLocalTime = async () => {
      try {
        const response = await axios.get(`/general_info/local_time/${city}`);
        setLocalTime(response.data);
        // console.log("fetchLocalTime",response.data);
      } catch (error) {
        console.error("Error fetching local time:", error);
      }
    };

    const fetchTemperature = async () => {
      try {
        const response = await axios.get(`/general_info/temperature/${city}`);
        setTemperature(response.data.temperature);
      } catch (error) {
        console.error("Error fetching temperature:", error);
      }
    };

    if (city) {
      fetchLocalTime();
      fetchTemperature();
    }
  }, [city]); // The `city` dependency ensures that the APIs are refetched when `city` changes

  // Fetch currency rate based on currency code after it is set in generalInfo
  useEffect(() => {
    const fetchCurrencyRate = async () => {
      if (generalInfo.currency_code) {
        try {
          const response = await axios.get(
            `/general_info/currency_rate/${generalInfo.currency_code}`
          );
          setCurrencyRate(response.data.conversion_rate);
        } catch (error) {
          console.error("Error fetching currency rate:", error);
        }
      }
    };

    if (generalInfo.currency_code) {
      fetchCurrencyRate(); // Fetch currency rate only after currency_code is available
    }
  }, [generalInfo.currency_code]); // Fetch the rate when currency_code is set

  return (
    <Box
      bg="white"
      p={{ base: 2, md: 4 }}
      borderRadius="md"
      boxShadow="md"
      border="1px solid"
      borderColor="gray.200"
      mb={{ base: 2, md: 4 }}
      mt={{ base: 2, md: 4 }}
    >
      <HStack
        spacing={{ base: 4, md: 12 }}
        justifyContent="left"
        alignItems="start"
        wrap="wrap"
        flexDirection={{ base: "column", md: "row" }}
      >
        {/* Left Column */}
        <VStack align="start" spacing={4}>
          <HStack>
            <Text size={16} fontWeight="bold" mb={0}>
              {currencySymbol}
            </Text>
            <Text
              mb={0}
              ml={2}
              fontWeight="semibold"
              fontSize={{ base: "xs", md: "sm" }}
            >
              {currencyCode}
            </Text>
          </HStack>
          <HStack alignItems="flex-start" justifyContent="flex-start">
            <VStack spacing={0} alignItems="flex-start">
              <HStack>
                <FaCreditCard size={16} />
                <Text
                  mb={0}
                  ml={2}
                  fontWeight="semibold"
                  fontSize={{ base: "xs", md: "sm" }}
                >
                  {generalInfo.currency_name || "N/A"}
                </Text>
              </HStack>
              {/* <Link
                href="https://wise.prf.hn/l/NdBGMYP" // URL to be opened
                fontSize={{ base: "xs", md: "xs" }} // Responsive font size
                mb={0} // Margin bottom
                isExternal // Opens the link in a new tab
                color="#286328" // Link color
                _hover={{
                  textDecoration: "underline", // Underline on hover
                }}
              >
                https://wise.prf.hn/l/NdBGMYP
              </Link> */}
            </VStack>
          </HStack>

          <HStack>
            <MdOutlineAccessTime size={16} />
            <Text
              mb={0}
              ml={2}
              fontWeight="semibold"
              fontSize={{ base: "xs", md: "sm" }}
            >
              {localTime.utc_offset || "N/A"}
            </Text>
          </HStack>
          <HStack>
            <FaLanguage size={25} />
            <Box bg="gray.100" borderRadius="sm" p={1}>
              <Text
                mb={0}
                fontWeight="semibold"
                fontSize={{ base: "xs", md: "sm" }}
              >
                {generalInfo.language || "N/A"}
              </Text>
            </Box>
          </HStack>
        </VStack>

        {/* Right Column */}
        <VStack align="start" spacing={4} mt={{ base: 4, md: 0 }}>
          <HStack>
            <MdCurrencyExchange size={16} />
            <Text
              mb={0}
              ml={2}
              fontWeight="semibold"
              fontSize={{ base: "xs", md: "sm" }}
            >
              {currencyRate || "N/A"}
            </Text>
          </HStack>
          <HStack>
            <MdLocationOn size={16} />
            <Text
              mb={0}
              ml={2}
              fontWeight="semibold"
              fontSize={{ base: "xs", md: "sm" }}
            >
              {city}
            </Text>
          </HStack>
          <HStack>
            <FaThermometerHalf size={16} />
            <Text
              mb={0}
              ml={2}
              fontWeight="semibold"
              fontSize={{ base: "xs", md: "sm" }}
            >
              {temperature ? `${Math.round(parseFloat(temperature))}°C` : "N/A"}
            </Text>
          </HStack>
          <HStack>
            <Button
              leftIcon={<ExchangeIcon width="24px" height="24px" />}
              onClick={() =>
                window.open("https://wise.prf.hn/l/NdBGMYP", "_blank")
              }
              bg="#286328"
              color="white"
              size="sm"
              _hover="none"
            >
              WISE - <Translate text="Explore More, Pay Less!" />
            </Button>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default InfoCard;

{
  /* <HStack>
            <Icon as={FaCar} boxSize={5} />
            <HStack spacing={2} ml={2}>
              <Box bg="gray.100" borderRadius="sm" p={1}>
                <Text mb={0} fontWeight="semibold" fontSize={{ base: "xs", md: "sm" }}>Tram</Text>
              </Box>
              <Box bg="gray.100" borderRadius="sm" p={1}>
                <Text mb={0} fontWeight="semibold" fontSize={{ base: "xs", md: "sm" }}>Train</Text>
              </Box>
              <Box bg="gray.100" borderRadius="sm" p={1}>
                <Text mb={0} fontWeight="semibold" fontSize={{ base: "xs", md: "sm" }}>Bus</Text>
              </Box>
            </HStack>
          </HStack> */
}
