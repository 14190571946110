import React, { createContext, useContext, useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    // Call a function to get user data from the backend
    fetchUserData();
  }, []);

  // Retrieve the stored auth data from local storage
  const storedToken = localStorage.getItem("REACT_TOKEN_AUTH_KEY");
  let token = "";

  if (storedToken) {
    const authData = JSON.parse(storedToken);
    token = authData.access_token; // Assuming 'access_token' is the key where the token is stored
  }
  const authHeader = token ? `Bearer ${token}` : null;

  const fetchUserData = async () => {
    //console.log("Fetching user role...");
    try {
      const response = await fetch("/auth/get_role", {
        method: "GET",
        headers: {
          Authorization: authHeader,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (data && data.role) {
        // console.log("Current User Role:", data.role);
        setUser(data); // Assume the backend returns a user object with a role and any other needed data
      } else {
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
