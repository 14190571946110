import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../auth";
import { useGoogleAuth } from "./common/google/GoogleAuthContext";
import { useUser } from "./userRole/UserContext";
import { useOTP } from "./AdminPanelAccess/OTPContext";
import AdminPanelAccess from "./AdminPanelAccess/AdminPanelAccess";
import AuthModal from "./AuthModal";

const ProtectedRoute = ({
  component: Component,
  roles: requiredRoles,
  requiresOTP,
  ...rest
}) => {
  const [logged] = useAuth();
  const {
    authState: { isLoggedIn },
  } = useGoogleAuth();
  const isUserLoggedIn = logged || isLoggedIn;
  const { user, loading } = useUser();
  const { isOTPVerified } = useOTP();
  const userRole = user ? user.role : null;

  const [isAuthModalOpen, setAuthModalOpen] = useState(false);

  useEffect(() => {
    if (!isUserLoggedIn || (requiredRoles && !requiredRoles.includes(userRole))) {
      if (!isAuthModalOpen) {
        setAuthModalOpen(true);
      }
    }
  }, [isUserLoggedIn, requiredRoles, userRole, isAuthModalOpen]);

  const onAuthModalClose = () => {
    setAuthModalOpen(false);
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          if (!isUserLoggedIn || (requiredRoles && !requiredRoles.includes(userRole))) {
            return (
              <>
                <AuthModal
                  isOpen={isAuthModalOpen}
                  onClose={onAuthModalClose}
                />
                <Component {...props} />
              </>
            );
          }

          if (requiresOTP && !isOTPVerified) {
            return <AdminPanelAccess Component={Component} {...props} />;
          }

          return <Component {...props} />;
        }}
      />
    </>
  );
};

export default ProtectedRoute;

// import React from "react";
// import { Route, Redirect } from "react-router-dom";
// import { useAuth } from "../auth";
// import { useGoogleAuth } from "./common/google/GoogleAuthContext";
// import { useUser } from "./userRole/UserContext";
// import { useOTP } from "./AdminPanelAccess/OTPContext";
// import AdminPanelAccess from "./AdminPanelAccess/AdminPanelAccess";

// const ProtectedRoute = ({
//   component: Component,
//   roles: requiredRoles,
//   requiresOTP,
//   ...rest
// }) => {
//   const [logged] = useAuth();
//   const { authState: { isLoggedIn } } = useGoogleAuth();
//   const isUserLoggedIn = logged || isLoggedIn;
//   const { user, loading } = useUser();
//   const { isOTPVerified } = useOTP();
//   const userRole = user ? user.role : null;

//   if (loading) {
//     return null;
//   }

//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         if (!isUserLoggedIn) {
//           return <Redirect to="/home" />;
//         }

//         if (requiredRoles && !requiredRoles.includes(userRole)) {
//           return <Redirect to="/home" />;
//         }

//         if (requiresOTP && !isOTPVerified) {
//           return <AdminPanelAccess Component={Component} {...props} />;
//         }

//         return <Component {...props} />;
//       }}
//     />
//   );
// };

// export default ProtectedRoute;
