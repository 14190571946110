import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  Spinner,
  useToast,
  TableContainer,
  Button,
} from "@chakra-ui/react";
import { utils, writeFile } from "xlsx"; // Import xlsx utilities
import axiosInstance from "../../helpers/axiosInstance"; // Adjust the import path for Axios instance
import FeedbackHeader from "./FeedbackHeader";

const SurveyFeedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortedFeedbacks, setSortedFeedbacks] = useState([]);
  const [sortOption, setSortOption] = useState("all");
  const toast = useToast();

  // Fetch all survey feedbacks on component mount
  useEffect(() => {
    const fetchSurveyFeedbacks = async () => {
      try {
        const response = await axiosInstance.get("/survey/feedbacks");
        if (response.status === 200) {
          setFeedbacks(response.data.feedbacks);
          setSortedFeedbacks(response.data.feedbacks); // Initialize the sorted list
        }
      } catch (error) {
        console.error("Error fetching survey feedbacks: ", error);
        toast({
          title: "Error",
          description: "Failed to load the survey feedbacks.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchSurveyFeedbacks();
  }, [toast]);

  // Handle sorting by survey heading
  const handleSortChange = (e) => {
    const selectedOption = e.target.value;
    setSortOption(selectedOption);

    if (selectedOption === "all") {
      setSortedFeedbacks(feedbacks); // Show all feedbacks
    } else {
      const sorted = feedbacks.filter(
        (feedback) => feedback.heading === selectedOption
      );
      setSortedFeedbacks(sorted); // Filter feedbacks based on the selected heading
    }
  };

  // Function to convert feedbacks to Excel format and download
  const handleDownload = () => {
    const feedbacksToDownload = sortedFeedbacks.length > 0 ? sortedFeedbacks : feedbacks;

    // Prepare the data for Excel
    const data = feedbacksToDownload.map((feedback) => ({
      "Survey Heading": feedback.heading,
      "Submitted At": new Date(feedback.submitted_at).toLocaleString(),
      ...feedback.responses.reduce((acc, response, idx) => {
        acc[`Question ${idx + 1}`] = response.question;
        acc[`Answer ${idx + 1}`] = response.answer;
        return acc;
      }, {}),
    }));

    // Create a new workbook and add a sheet
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, "Survey Feedbacks");

    // Download the file
    writeFile(wb, "survey_feedbacks.xlsx");
  };

  return (
    <Box h="100vh">
      <FeedbackHeader handleDownload={handleDownload}/>
      <Box p={4} minH="90vh" bg="white">
        {isLoading ? (
          <Spinner size="xl" />
        ) : (
          <Box>
            <Text mb={4} fontWeight="bold">
              Sort by Survey Heading:
              <Select
                w="30%"
                value={sortOption}
                onChange={handleSortChange}
                size="md"
                bg="white"
              >
                <option value="all">All</option>
                {[
                  ...new Set(feedbacks.map((feedback) => feedback.heading)),
                ].map((heading, idx) => (
                  <option key={idx} value={heading}>
                    {heading}
                  </option>
                ))}
              </Select>
            </Text>

            

            <TableContainer>
              <Table variant="simple" bg="white" borderRadius="md" shadow="md">
                <Thead>
                  <Tr>
                    <Th>Survey Heading</Th>
                    <Th>Submitted Responses</Th>
                    <Th>Submitted At</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sortedFeedbacks.length > 0 ? (
                    sortedFeedbacks.map((feedback, index) => (
                      <Tr key={index}>
                        <Td>{feedback.heading}</Td>
                        <Td>
                          {feedback.responses.map((response, idx) => (
                            <Box key={idx} mb={2}>
                              <Text mb={0}>
                                <strong>{response.question}:</strong>{" "}
                                {response.answer}
                              </Text>
                            </Box>
                          ))}
                        </Td>
                        <Td>
                          {new Date(feedback.submitted_at).toLocaleString()}
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="3" textAlign="center">
                        No feedback found
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SurveyFeedback;
