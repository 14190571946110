import { useState, useEffect } from "react";
import { IconButton } from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled beyond a certain point
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll the window to the top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <IconButton
      position="fixed"
      bottom="40px"
      right="40px"
      size="md" // Smaller size
      icon={<ArrowUpIcon />}
      borderRadius="full"
      onClick={scrollToTop}
      display={isVisible ? "inline-flex" : "none"}
      bg="black"
      color="white"
      _hover={{ bg: "gray.700" }} // Adjust hover effect
      zIndex={1000}
    />
  );
};

export default BackToTopButton;
