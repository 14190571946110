import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Grid,
  HStack,
  Input,
  IconButton,
  Text,
  Box,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { ArrowBackIcon, CalendarIcon } from "@chakra-ui/icons";
import { FaMapMarkerAlt, FaTag } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import axios from "axios";
import Select from "react-select";
import currencyFormatter from "currency-formatter";
import Translate from "../../components/LanguageTranslation/Translate";

const ExpenseModal = ({
  isOpen,
  onClose,
  addExpense,
  categories,
  newExpenseName,
  setNewExpenseName,
  newCost,
  setNewCost,
  selectedCategory,
  setSelectedCategory,
  selectedDate,
  setSelectedDate,
  selectedLocation,
  setSelectedLocation,
  selectedCurrency,
  setSelectedCurrency,
}) => {
  const [isCategorySelection, setIsCategorySelection] = useState(false);
  const [isLocationSelection, setIsLocationSelection] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const openCategorySelection = () => setIsCategorySelection(true);
  const openLocationSelection = () => setIsLocationSelection(true);

  const handleBack = () => {
    if (isCategorySelection) {
      setIsCategorySelection(false);
    } else if (isLocationSelection) {
      setIsLocationSelection(false);
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsCategorySelection(false);
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    setIsLocationSelection(false);
  };

  // Handle Location Search using OpenCage API
  const handleLocationSearch = async (inputValue) => {
    if (!inputValue || inputValue.length < 3) return;

    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json`,
        {
          params: {
            key: "66b78ee6f74941fd8645cf2c1dec446e", // Replace with your OpenCage API key
            q: inputValue,
            limit: 5,
          },
        }
      );

      const locations = response.data.results.map((result) => ({
        label: result.formatted,
        value: result.geometry, // You can also use this to get the lat/lng if needed
      }));

      setLocationOptions(locations);
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleDateChange = (date) => {
  //   // Convert the selected date to EST
  //   const estDate = moment(date)
  //     .tz("America/New_York")
  //     .format("YYYY-MM-DD HH:mm:ss");

  //   setSelectedDate(estDate); // Set the EST date in the state
  // };

  const handleDateChange = (date) => {
    // Store only the date part, ignoring the time
    const selectedDate = moment(date).format("YYYY-MM-DD");
    setSelectedDate(selectedDate);
  };

  // Get all currency codes from currency-formatter
  const currencyOptions = currencyFormatter.currencies.map((currency) => ({
    value: currency.code,
    label: `${currency.code} - ${currency.symbol || ""}`, // Display code and symbol (if available)
  }));

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent p={4} borderRadius="lg">
        <ModalHeader>
          {isCategorySelection || isLocationSelection ? (
            <HStack>
              <IconButton
                icon={<ArrowBackIcon />}
                onClick={handleBack}
                aria-label="Back"
              />
              <Text mb={0}>
                <Translate
                  text={
                    isCategorySelection ? "Select category" : "Select location"
                  }
                />
              </Text>
            </HStack>
          ) : (
            <Translate text="Create new expense" />
          )}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {!isCategorySelection && !isLocationSelection ? (
            <VStack spacing={4}>
              {/* Category, Date, and Location Row */}
              <HStack w="100%" spacing={2}>
                {/* Category Selection */}
                <Box flex="1" w="33%">
                  <Button
                    onClick={openCategorySelection}
                    variant="outline"
                    flex="1"
                    w="full"
                    noOfLines="1"
                    fontSize="sm"
                  >
                    {selectedCategory || "Category"}
                  </Button>
                </Box>

                {/* Date Picker */}
                <Box flex="1" w="35%">
                  <Button
                    leftIcon={<CalendarIcon />}
                    variant="outline"
                    w="100%"
                  >
                    <DatePicker
                      selected={selectedDate ? new Date(selectedDate) : null}
                      onChange={handleDateChange} // Use the date handler
                      dateFormat="dd MMM yyyy" // Display day, month, and year
                      customInput={
                        <Button
                          variant="unstyled"
                          w="100%"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          noOfLines="1"
                          fontSize="sm"
                        >
                          {selectedDate
                            ? moment(selectedDate).format("DD MMM YYYY") // Display only the date without time
                            : "Select date"}
                        </Button>
                      }
                    />
                  </Button>
                </Box>

                {/* Location Selection */}
                <Box flex="1" w="33%">
                  <Button
                    leftIcon={<FaMapMarkerAlt />}
                    onClick={openLocationSelection}
                    variant="outline"
                    w="100%"
                    fontSize="sm"
                    noOfLines="1"
                    maxWidth="100%" // Ensure button takes full width
                  >
                    {selectedLocation ? selectedLocation.label : "Location"}
                  </Button>
                </Box>
              </HStack>

              {/* Currency and Cost Input */}
              <Box w="full">
                <HStack spacing={2} w="full">
                  {/* Currency Selector */}
                  <Box flex="1" w="25%">
                    <Select
                      options={currencyOptions}
                      value={selectedCurrency}
                      onChange={setSelectedCurrency}
                      placeholder="Select Currency"
                      isClearable={false}
                    />
                  </Box>
                  <Input
                    w="75%"
                    placeholder="0.00"
                    type="number"
                    value={newCost}
                    onChange={(e) => setNewCost(e.target.value)}
                    bg="gray.100"
                    borderRadius="md"
                  />
                </HStack>
              </Box>

              {/* Expense Name Input with Price Tag Icon */}
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaTag color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Enter expense name..."
                  value={newExpenseName}
                  onChange={(e) => setNewExpenseName(e.target.value)}
                  bg="gray.100"
                  borderRadius="md"
                />
              </InputGroup>
            </VStack>
          ) : isCategorySelection ? (
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              {categories.map((category, index) => (
                <Button
                  key={index}
                  variant="outline"
                  onClick={() => handleCategorySelect(category.name)}
                  p={6}
                >
                  <Translate text={category.name} />
                </Button>
              ))}
            </Grid>
          ) : (
            <VStack spacing={4}>
              <Input
                placeholder="Search location..."
                onChange={(e) => handleLocationSearch(e.target.value)}
                bg="gray.100"
                borderRadius="md"
              />
              {loading ? (
                <Text>Loading...</Text>
              ) : locationOptions.length > 0 ? (
                <Box maxH="200px" overflowY="auto" w="full">
                  {locationOptions.map((location, index) => (
                    <Button
                      key={index}
                      variant="outline"
                      onClick={() => handleLocationSelect(location)}
                      w="full"
                      textAlign="left"
                      p={4}
                      whiteSpace="normal"
                    >
                      {location.label}
                    </Button>
                  ))}
                </Box>
              ) : (
                <Text>
                  {" "}
                  <Translate text="No locations found" />
                </Text>
              )}
            </VStack>
          )}
        </ModalBody>

        {!isCategorySelection && !isLocationSelection && (
          <ModalFooter>
            <Button
              onClick={addExpense}
              w="full"
              bg="#286328"
              color="white"
              _hover="none"
            >
              <Translate text="Save" />
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ExpenseModal;
