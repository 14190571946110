import React, { useState, useEffect } from "react";
import { Select } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";
import Translate from "../../components/LanguageTranslation/Translate";

const TripDropdown = ({
  selectedTrip,
  setSelectedTrip,
  setTripName,
  onTripSelect,
}) => {
  const [trips, setTrips] = useState([]);

  // Fetch real trips from API
  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await axiosInstance.get("/bucket-list/");
        const validTrips = response.data.data.filter((trip) => trip.trip_plan); // Filter out trips without trip_plan
        setTrips(validTrips); // Set only valid trips in state
        // setTrips(response.data.data); // Set trips from the API response
      } catch (error) {
        console.error("Error fetching trips:", error);
      }
    };

    fetchTrips();
  }, []);

  // Handle the dropdown change
  const handleSelectChange = (event) => {
    const selectedTripId = event.target.value;
    const selectedTrip = trips.find((trip) => trip._id === selectedTripId); // Find selected trip details
    setSelectedTrip(selectedTripId); // Update the selected trip ID in the parent
    setTripName(selectedTrip?.trip_plan?.trip_name || "Unknown Trip"); // Update trip name in the parent
    onTripSelect(selectedTripId); // Callback to parent component to handle selection
  };

  return (
    <Select
      placeholder={Translate({
        text: "Select a trip",
        as: "string",
      })}
      value={selectedTrip}
      onChange={handleSelectChange}
      size="sm"
      borderRadius="xl"
    >
      {trips.map((trip) => (
        <option key={trip._id} value={trip._id}>
          {trip.trip_plan.trip_name}
        </option>
      ))}
    </Select>
  );
};

export default TripDropdown;
