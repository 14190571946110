import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  useColorMode,
  useColorModeValue,
  useToast,
  Image,
  Divider,
  VStack,
  SlideFade,
  Slide,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import GoogleLoginButton from "./common/google/GoogleLoginButton";
import { login } from "../auth";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useUser } from "./userRole/UserContext";
import logo from "../assets/images/logo1.png";
import HeroImage from "../assets/images/hero_image.png";
import { FaInstagram } from "react-icons/fa";
import { EmailIcon } from "@chakra-ui/icons";
import { ReactComponent as LinktreeIcon } from "../assets/icons/linktree_logo_icon.svg";
import "./../styles/styles.css";
import axios from "axios";

const content = [
  {
    heading: "Welcome Message",
    text: "some welcoming text or additional content goes here.",
  },
];

const LoginPage = () => {
  // const { register, handleSubmit, formState: { errors } } = useForm();
  //console.log("In LoginPage function");
  const toast = useToast();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const { user, setUser } = useUser();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1); // Step 1 for email, Step 2 for password

  const handleNext = () => {
    const email = getValues("email");
    if (email) {
      setStep(2); // Move to password step
    } else {
      toast({
        title: "Error",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  function getDeviceDetails() {
    const ua = navigator.userAgent;

    // Detecting the browser
    let browser;
    if (/chrome|chromium|crios/i.test(ua)) {
      browser = "Chrome";
    } else if (/firefox|fxios/i.test(ua)) {
      browser = "Firefox";
    } else if (/safari/i.test(ua)) {
      browser = "Safari";
    } else if (/edg/i.test(ua)) {
      browser = "Edge";
    } else if (/opr\//i.test(ua)) {
      browser = "Opera";
    } else if (/msie|trident/i.test(ua)) {
      browser = "IE";
    } else {
      browser = "Unknown Browser";
    }

    // Detecting the platform
    let os;
    if (/android/i.test(ua)) {
      os = "Android";
    } else if (/iphone|ipad|ipod/i.test(ua)) {
      os = "iOS";
    } else if (/win/i.test(ua)) {
      os = "Windows";
    } else if (/mac/i.test(ua)) {
      os = "MacOS";
    } else if (/linux/i.test(ua)) {
      os = "Linux";
    } else {
      os = "Unknown OS";
    }

    return {
      full_user_agent: navigator.userAgent,
      simplified_user_agent: `${os} ${browser}`,
      loginDate: new Date().toISOString().slice(0, 10), // ISO 8601 format for YYYY-MM-DD
    };
  }

  const onSubmit = async (formData) => {
    const deviceDetails = getDeviceDetails();

    const loginPayload = {
      ...formData,
      device: deviceDetails,
    };
    console.log("loginPayload", deviceDetails);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginPayload),
    };

    try {
      const response = await fetch("/auth/login", requestOptions);

      const data = await response.json();

      if (response.status === 200) {
        login(data); // This will store the access_token and refresh_token

        localStorage.setItem("email", data.email);
        localStorage.setItem("username", data.username);
        const role = data.role;
        const organization = data.organization;

        setUser({
          role,
          organization,
        });
        // console.log("user data", user);
        setIsLoginSuccessful(true);
      } else {
        //console.log("In Login failed page");
        setErrorMessage(data.message || "Login failed");
        toast({
          title: "Login failed",
          description: "Your cridentials are invalid. Please enter again",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setErrorMessage("Failed to connect to the server");
    }
    reset();
  };

  useEffect(() => {
    if (user && isLoginSuccessful) {
      history.push("/home");
      setIsLoginSuccessful(false); // Reset the flag after redirecting
    }
  }, [user, isLoginSuccessful]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationTrigger, setAnimationTrigger] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        setAnimationTrigger((prev) => !prev); // Toggle the trigger
        return (prevIndex + 1) % content.length;
      });
    }, 3000); // Change content every 4 seconds
    return () => clearInterval(timer);
  }, []);

  return (
    <Flex h="100vh" overflow="hidden">
      {/* Left Side*/}

      <Box w="40%" bg="white" p={20} alignContent="center">
        <Container>
          <Stack spacing={4}>
            <Heading
              as="h1"
              size="lg"
              textAlign="left"
              mb={2}
              fontFamily="'Manrope', sans-serif"
              fontWeight="bold"
              color="#1A202C"
            >
              Login
            </Heading>
            <Text
              color="#3A4135"
              fontSize="md"
              fontFamily="'Manrope', sans-serif"
            >
              Welcome! Please fill in your username and password to sign in to
              your account.
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              {step === 1 && (
                <FormControl isInvalid={errors.email}>
                  {/* <FormLabel>Email</FormLabel> */}
                  <Input
                    id="email"
                    type="email"
                    placeholder="Type your email"
                    {...register("email", {
                      required: "Email is required",
                    })}
                    bg="blackAlpha.100"
                    borderRadius="none"
                    mt={4}
                  />
                  {/* <FormLabel>Password</FormLabel> */}
                  <Input
                    id="password"
                    type="password"
                    placeholder="Type your password"
                    {...register("password", {
                      required: "Password is required",
                    })}
                    bg="blackAlpha.100"
                    borderRadius="none"
                    mt={4}
                  />
                  <VStack
                    w="100%"
                    mt={2}
                    spacing={0}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    {/* <Text
                      mb={0}
                      fontSize="small"
                      fontFamily="'Manrope', sans-serif"
                      fontWeight="medium"
                      color="#3A4135"
                    >
                      Don't have an account?{" "}
                      <Link
                        fontFamily="'Manrope', sans-serif"
                        fontWeight="medium"
                        color="#004A45"
                        href="/signup"
                      >
                        Sign up
                      </Link>
                    </Text> */}

                    {/* <Link
                      href="/forgot_password"
                      fontFamily="'Manrope', sans-serif"
                      color="#3A4135"
                      fontSize="sm"
                    >
                      Forgot your password?
                    </Link> */}
                  </VStack>

                  <Button
                    mt={2}
                    colorScheme="green"
                    type="submit"
                    w="100%"
                    bg="#286328"
                    _hover="none"
                    color="white"
                    fontFamily="'Roboto', sans-serif"
                    fontWeight="medium"
                    borderRadius="full"
                  >
                    Login Now
                  </Button>
                  {/* <Text
                    mb={0}
                    mt={2}
                    fontSize="small"
                    fontFamily="'Manrope', sans-serif"
                    fontWeight="medium"
                    color="#3A4135"
                  >
                    Don't have an account?{" "}
                    <Link
                      fontFamily="'Manrope', sans-serif"
                      fontWeight="medium"
                      color="#004A45"
                      href="/signup"
                    >
                      Sign up
                    </Link>
                  </Text> */}
                </FormControl>
              )}
            </form>

            <Flex align="center">
              <Divider p={0} mb="0" mt="0" />
              <Text
                textStyle="sm"
                fontFamily="'Manrope', sans-serif"
                fontWeight="medium"
                color="#3A4135"
                mb="0"
                mt="0"
                mr="1"
                ml="1"
                textAlign="center"
              >
                or
              </Text>
              <Divider p={0} mb="0" mt="0" />
            </Flex>
            {/* <GoogleLoginButton /> */}
            {/* Social Login Buttons */}
            <Text
              textStyle="sm"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#3A4135"
              mb="0"
              mt="0"
              mr="1"
              ml="1"
              textAlign="center"
            >
              Follow us here
            </Text>
            <HStack spacing={2} mt={0} w="100%" justifyContent="center">
              <IconButton
                as={Link}
                href="https://mail.google.com/mail/?view=cm&fs=1&to=info@nomadtours.io"
                aria-label="contact us"
                icon={<EmailIcon />}
                isRound
                bg="blackAlpha.100"
                mt={2}
                target="_blank"
                rel="noopener noreferrer"
              />
              <IconButton
                aria-label="Instagram"
                icon={<FaInstagram />}
                isRound
                bg="blackAlpha.100"
                mt={2}
                as="a"
                href="https://www.instagram.com/nomadtours.io/"
                target="_blank"
                rel="noopener noreferrer"
              />
              <IconButton
                aria-label="Linktree"
                icon={
                  <LinktreeIcon style={{ width: "20px", height: "20px" }} />
                }
                isRound
                bg="blackAlpha.100"
                mt={2}
                as="a"
                href="https://linktr.ee/nomadtours"
                target="_blank"
                rel="noopener noreferrer"
              />
            </HStack>
          </Stack>
        </Container>

        <Box alignContent="center" justifyContent="center" p={5} pb={2} pt={10}>
          <HStack alignContent="center" justifyContent="center">
            <Link
              fontSize="12"
              href="/policies/terms-of-use"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#1A202C"
            >
              Terms of Use
            </Link>
            <Divider
              alignSelf="center"
              orientation="vertical"
              height="12px"
              borderColor="black"
              borderWidth="0.5px"
            />
            <Link
              fontSize="12"
              href="/policies/privacy-policy"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#1A202C"
            >
              Privacy Policy
            </Link>
          </HStack>
        </Box>
      </Box>

      {/* Right Side */}
      <Box
        w="60%"
        h="100vh" // Adjust height as needed
        bgImage={HeroImage} // Path to your image
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        display="flex"
        flexDirection="column"
        justifyContent="top" // Align content to the bottom
        alignItems="center" // Center content horizontally
        color="white"
        p={10}
      >
        <VStack
          w="auto"
          maxW="80%"
          mt={20}
          alignContent="center"
          textAlign="center"
          justifyContent="center"
          borderRadius="xl"
          // bg="white"
          bg="rgba(255, 255, 255, 0.4)"
          //  bg="rgba(255, 255, 255, 0.85)"
          p={4}
        >
          {/* 90% width to slightly narrow it */}
          <Heading
            color="#1A202C"
            fontSize="3xl" // Adjust font size as needed
            fontFamily="'ui', sans-serif"
            textAlign="left" // Left-align
            mb={0}
          >
            Get Ready for Your Travel Adventure!
          </Heading>
          <Text
            color="#1A202C"
            fontSize="lg" // Adjust font size as needed
            fontFamily="'ui', sans-serif"
            fontWeight="bold"
            textAlign="left" // Left-align text
            mb={0}
          >
            We’re putting the finishing touches on our AI Travel Planner.
            <br /> {/* Line break here */}
            Stay tuned for a quick launch!
          </Text>
        </VStack>
      </Box>
    </Flex>
  );
};

export default LoginPage;
