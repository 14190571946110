import React, { useState } from "react";
import {
  Box,
  Image,
  Text,
  Heading,
  Badge,
  IconButton,
  HStack,
  Button,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import Translate from "../../components/LanguageTranslation/Translate";

const ActivityCard = ({ activity }) => {
  // console.log("activity", activity);

  // Function to handle box click and open link
  const handleBoxClick = () => {
    window.open(activity.webURL, "_blank", "noopener,noreferrer");
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      cursor="pointer"
      onClick={handleBoxClick}
    >
      <Box position="relative">
        <Image
          src={activity.thumbnailHiResURL}
          alt={activity.title}
          width="100%"
          height="30vh"
        />
        <IconButton
          aria-label="Add to favorites"
          icon={<FaHeart />} // Conditional icon rendering
          size="md"
          bg="none"
          _hover={{ bg: "none" }}
          position="absolute"
          top="3px"
          right="3px"
          borderRadius="full"
        />
      </Box>
      <Box p="6">
        <Heading size="sm" mb="2" noOfLines="1">
          {activity.title}
        </Heading>
        {/* <Text fontSize="sm" color="gray.500" mb={2}>
          {activity.primaryDestinationName}, {activity.attractionCity}
        </Text> */}
        <Box d="flex" alignItems="baseline">
          <HStack spacing={2} mb={0}>
            {activity.rating ? (
              <>
                <StarIcon boxSize={3} color="#3A4135" mb={0} />
                <Text mb={0} fontSize="sm">
                  {activity.rating}
                </Text>
              </>
            ) : (
              <Text fontSize="sm" mb={0}>
                <Translate text="Rating not available" />
              </Text>
            )}
          </HStack>
          {/* Book Button */}
          <Button
            as="a"
            href={activity.webURL}
            target="_blank"
            rel="noopener noreferrer"
            mt={2}
            size="sm"
            bg="#286328"
            _hover="none"
            _active="none"
            textColor="white"
            width="full"
          >
            <Translate text="Book" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityCard;
