import React from "react";
import { Box, VStack, Text, Image } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import DOMPurify from "dompurify";
import { markdownToHtml, transformHtml } from "../../helpers/markdownHelpers";
import ItinerayCard from "./ItinerayCard";
import Activities from "./Activities";
import HotelsCard from "./HotelsCard";
import FlightCard from "./FlightCard";
import RVRentalCard from "./RVRentalCard";
import Translate from "../../components/LanguageTranslation/Translate";

// Define keyframes for the typing animation
const typingKeyframes = keyframes`
  0% { content: ''; }
  33% { content: '.'; }
  66% { content: '..'; }
  100% { content: '...'; }
`;

const MessageList = ({
  messages,
  handleSelectItem,
  isLoading,
  profilePicture,
}) => {
  // console.log("messages", messages);
  return (
    <VStack spacing={4} align="stretch" width="100%">
      {messages.length > 0 ? (
        messages.map((messageObj, index) => {
          // Combine tripPlan and viatorResponse into a single object
          const combinedData = {
            ...messageObj.tripPlan,
            viatorResponse: messageObj.viatorResponse,
          };

          return (
            <VStack
              key={index}
              spacing={2}
              width="100%"
              alignItems="flex-start"
            >
              {messageObj.user_message && (
                <Box
                  w="65%"
                  p={3}
                  borderRadius="xl"
                  alignSelf="flex-end"
                  // bg="#3A4135"
                  bg="#1A202C"
                >
                  <Text color="white" mb={0}>
                    {messageObj.user_message}
                  </Text>
                </Box>
              )}
              <Box w="100%" alignSelf="flex-start">
                <Box mb={2}>
                  {messageObj.tripPlan && (
                    <ItinerayCard
                      tripData={combinedData}
                      onSelectItem={handleSelectItem}
                    />
                  )}

                  {messageObj.viatorResponse &&
                    Array.isArray(messageObj.viatorResponse) &&
                    messageObj.viatorResponse.length > 0 &&
                    messageObj.viatorResponse.map((activitiesData, idx) => {
                      return (
                        <Activities
                          key={idx}
                          data={activitiesData.viator_response || [] }
                          location={activitiesData.location}
                        />
                      );
                    })}

                  {messageObj.homeToGoResponse &&
                    Array.isArray(messageObj.homeToGoResponse) &&
                    messageObj.homeToGoResponse.map((hotelData, idx) => (
                      <HotelsCard
                        key={idx}
                        hotels={hotelData.home_to_go_response || []}
                        location={hotelData.location}
                      />
                    ))}

                  {messageObj.flightInfo &&
                    Array.isArray(messageObj.flightInfo) &&
                    Array.isArray(messageObj.flightInfo[0]) && (
                      <FlightCard key={index} data={messageObj.flightInfo[0]} /> // Pass full array
                    )}

                  {messageObj.outdoorsyResponse &&
                    Array.isArray(messageObj.outdoorsyResponse) &&
                    messageObj.outdoorsyResponse.map((outdoorsyData, idx) => (
                      <RVRentalCard
                        key={idx}
                        RVs={outdoorsyData.outdoorsy_response.rentals || []} // Pass the rentals array
                        location={outdoorsyData.location}
                      />
                    ))}
                </Box>
                {messageObj.bot_message && (
                  <Box
                    w="65%"
                    h="auto"
                    p={3}
                    borderRadius="xl"
                    alignSelf="flex-start"
                    bg="#F6F6F6"
                  >
                    <Text
                      color="black"
                      mb={0}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          transformHtml(markdownToHtml(messageObj.bot_message)),
                          { ADD_ATTR: ["target"] }
                        ).replace(/<p>/g, '<p style="margin-bottom: 0;">'),
                      }}
                    />
                  </Box>
                )}
                {index === messages.length - 1 && !isLoading && (
                  <Image
                    src={profilePicture}
                    alt="Bot Profile"
                    boxSize="30px"
                    borderRadius="full"
                    border="2px solid"
                    borderColor="#1A202C"
                    ml={2}
                    mt={2}
                  />
                )}
              </Box>
            </VStack>
          );
        })
      ) : (
        <Text ml={2} color="gray.400">
          <Translate text="Start a conversation" />
        </Text>
      )}
      {isLoading && (
        <Box
          className="blink"
          w="100%"
          p={2}
          borderRadius="md"
          alignSelf="flex-start"
        >
          <Text color="gray.400" mb={0}>
            <Translate text="Ava is typing" />
            <Text
              as="span"
              _after={{
                content: `'.'`,
                animation: `${typingKeyframes} 1.5s infinite`,
              }}
            >
              ...
            </Text>
          </Text>
        </Box>
      )}
    </VStack>
  );
};

export default MessageList;
