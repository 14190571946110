import React, { useState } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  HStack,
  IconButton,
  Spacer,
  useColorMode,
  useToast,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import { FaTrash, FaClock } from "react-icons/fa";
import { CloseIcon, AddIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axiosInstance from "../../helpers/axiosInstance";
import GridIcon from "../../assets/icons/dot_grid_icon.svg";
import { useCurrency } from "../CurrencyExchange/CurrencyContext"; // Import Currency Context
import Translate from "../../components/LanguageTranslation/Translate";
import DatePicker from "react-datepicker";

const FlightsList = ({
  flights,
  setFlights,
  onFlightClick,
  tripId,
  setView,
}) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [hoveredItem, setHoveredItem] = useState(null);

  const { convertCurrency, currency } = useCurrency(); // Get currency conversion and selected currency

  // Function to handle drag end event
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(flights);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFlights(items);

    const payload = {
      flight_info: [items], // Maintain the nested array structure
    };

    try {
      await axiosInstance.put(`/bucket-list/${tripId}/update/flight`, payload);
    } catch (error) {
      console.error("Error updating flight order:", error);
      toast({
        title: "Error",
        description: "Unable to update flight order",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleFlightDelete = async (indexToDelete) => {
    const updatedFlights = flights.filter(
      (_, index) => index !== indexToDelete
    );
    const payload = {
      flight_info: updatedFlights.length > 0 ? [updatedFlights] : [[]],
    };

    try {
      await axiosInstance.put(`/bucket-list/${tripId}/update/flight`, payload);

      // If successful, update the state with the filtered flights
      setFlights(updatedFlights);
    } catch (error) {
      console.error("Error deleting flight:", error);

      // Show error message if the deletion fails
      toast({
        title: "Error",
        description: "Unable to delete flight",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCloseDetail = () => {
    setView(null);
  };

  const [currentStep, setCurrentStep] = useState(0);
  const [airline, setAirline] = useState("");
  const [originAirport, setOriginAirport] = useState("");
  const [originCity, setOriginCity] = useState("");
  const [destinationAirport, setDestinationAirport] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [price, setPrice] = useState("");
  const [departureDate, setDepartureDate] = useState(null);
  const [returnDate, setReturnDate] = useState(null);

  const handleNextStep = async () => {
    if (currentStep < 7) {
      setCurrentStep(currentStep + 1);
    } else {
      const newFlight = {
        flight_number: "",
        airline,
        origin_airport: originAirport,
        origin: originCity,
        destination_airport: destinationAirport,
        destination: destinationCity,
        price: parseFloat(price),
        departure_at: departureDate,
        return_at: returnDate,
        link: "",
        return_transfers: 0,
        duration: 0,
        duration_to: 0,
        duration_back: 0,
        transfers: 0,
      };

      try {
        const updatedFlights = [...flights, newFlight];
        const payload = {
          flight_info: [updatedFlights],
        };

        const response = await axiosInstance.put(
          `/bucket-list/${tripId}/update/flight`,
          payload
        );

        if (response.data.success) {
          setFlights(updatedFlights);
          toast({
            title: "New flight added successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setAirline("");
          setOriginAirport("");
          setOriginCity("");
          setDestinationAirport("");
          setDestinationCity("");
          setPrice("");
          setDepartureDate(null);
          setReturnDate(null);
          setCurrentStep(0);
        }
      } catch (error) {
        console.error("Error adding new flight:", error);
        toast({
          title: "Error adding new flight",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
      w="100%"
      h="100vh"
      overflowY="hidden"
    >
      <HStack justifyContent="space-between" bg="white">
        <Box
          flex="1"
          display="flex"
          justifyContent="left"
          p={4}
          pt={2}
          pb={2}
          pl={4}
          maxH="8vh"
          borderBottom="1px"
          borderLeft="1px"
          borderColor="blackAlpha.200"
        >
          <Text
            fontSize="md"
            fontWeight="bold"
            mt={1}
            pb={2}
            color="blackAlpha.700"
          >
            <Translate text="Update Flights" />
          </Text>
          <Spacer />
          <IconButton
            icon={<CloseIcon boxSize={2.5} />}
            onClick={handleCloseDetail}
            colorScheme="gray"
            size="sm"
            isRound={true}
            position="relative"
            zIndex="tooltip"
            bg="white"
            boxShadow="md"
          />
        </Box>
      </HStack>
      <Box h="89vh">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="flights">
            {(provided) => (
              <VStack
                spacing={4}
                h="100%"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
                bg="white"
                align="stretch"
                p={4}
                m={2}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {flights.map((flight, index) => {
                  // Convert flight price
                  const convertedPrice = flight.price
                    ? convertCurrency(flight.price, "USD") // Assuming flight prices are in USD
                    : null;

                  return (
                    <Draggable
                      key={`flight-${index}`}
                      draggableId={`flight-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          position="relative"
                          bg="white"
                          borderRadius="lg"
                          boxShadow="md"
                          border="1px solid"
                          borderColor="gray.200"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          onMouseEnter={() => setHoveredItem(index)}
                          onMouseLeave={() => setHoveredItem(null)}
                        >
                          <Box
                            position="absolute"
                            top="-10px"
                            left="-10px"
                            bg="black"
                            color="white"
                            borderRadius="full"
                            width="24px"
                            height="24px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            {index + 1}
                          </Box>
                          <Box
                            {...provided.dragHandleProps}
                            bg="gray.100"
                            alignContent="center"
                            visibility={
                              hoveredItem === index ? "visible" : "hidden"
                            }
                            h="100%"
                          >
                            <Image
                              src={GridIcon}
                              alt="Drag Handle"
                              boxSize="4"
                              opacity={hoveredItem === index ? 1 : 0.5}
                            />
                          </Box>
                          <VStack ml={1} w="full" p={4} pl={0} h="100%">
                            <HStack align="center" spacing={4} w="100%" mb={4}>
                              <VStack
                                align="flex-start"
                                spacing={1}
                                flex="1"
                                h="100%"
                              >
                                <Text fontSize="18px" fontWeight="bold" mb={0}>
                                  {flight.airline}
                                </Text>
                                <Text fontSize="14px" color="gray.600" mb={0}>
                                  {flight.origin_airport} ➔{" "}
                                  {flight.destination_airport}
                                </Text>
                                <Text fontSize="14px" color="gray.600" mb={0}>
                                  <Text
                                    as="span"
                                    fontWeight="bold"
                                    display="inline"
                                    mr={2}
                                  >
                                    <Translate text="Departure:" />
                                  </Text>
                                  <Translate text="From" /> {flight.origin}{" "}
                                  <Translate text="at" />{" "}
                                  {new Date(
                                    flight.departure_at
                                  ).toLocaleDateString()}
                                </Text>

                                <Text fontSize="14px" color="gray.600" mb={0}>
                                  <Text
                                    as="span"
                                    fontWeight="bold"
                                    display="inline"
                                    mr={2}
                                  >
                                    <Translate text="Return:" />
                                  </Text>
                                  <Translate text="From" /> {flight.destination}{" "}
                                  <Translate text="at" />{" "}
                                  {new Date(
                                    flight.return_at
                                  ).toLocaleDateString()}
                                </Text>
                              </VStack>
                              <Box h="100%" alignItems="top">
                                <Button
                                  as="a"
                                  href={flight.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  size="sm"
                                  bg="#286328"
                                  _hover="none"
                                  _active="none"
                                  textColor="white"
                                >
                                  <Translate text="Book" />
                                </Button>
                              </Box>
                            </HStack>
                            <HStack spacing={1} w="100%">
                              <FaClock color="gray.500" />
                              <Text
                                fontSize="sm"
                                color="gray.500"
                                ml={1}
                                mb={0}
                              >
                                {Math.floor(flight.duration_to / 60)}h{" "}
                                {flight.duration_to % 60}m
                              </Text>
                              <Text fontSize="sm" color="gray.500" mb={0}>
                                •
                              </Text>
                              <Text fontSize="sm" color="gray.500" mb={0}>
                                {flight.transfers > 0
                                  ? `${flight.transfers} stop`
                                  : "Non-stop"}
                              </Text>
                              <Spacer />
                              <Text
                                fontSize="md"
                                fontWeight="bold"
                                mb={0}
                                color="black"
                              >
                                {currency}{" "}
                                {convertedPrice ? convertedPrice : flight.price}
                              </Text>
                              <Spacer />
                              <IconButton
                                icon={<FaTrash />}
                                aria-label="Delete flight"
                                borderRadius="full"
                                size="xs"
                                visibility={
                                  hoveredItem === index ? "visible" : "hidden"
                                }
                                onClick={() => handleFlightDelete(index)} // Correct: Pass the index here
                              />
                            </HStack>
                          </VStack>
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
                <InputGroup>
                  {currentStep === 0 && (
                    <Input
                      placeholder="Enter Airline"
                      value={airline}
                      onChange={(e) => setAirline(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 1 && (
                    <Input
                      placeholder="Enter Origin Airport Code e.g., JFK"
                      value={originAirport}
                      onChange={(e) => setOriginAirport(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 2 && (
                    <Input
                      placeholder="Enter Origin City e.g NYC"
                      value={originCity}
                      onChange={(e) => setOriginCity(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 3 && (
                    <Input
                      placeholder="Enter Destination Airport Code e.g., LCY"
                      value={destinationAirport}
                      onChange={(e) => setDestinationAirport(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 4 && (
                    <Input
                      placeholder="Enter Destination City e.g LON"
                      value={destinationCity}
                      onChange={(e) => setDestinationCity(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 5 && (
                    <Input
                      placeholder="Enter Price"
                      type="number"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 6 && (
                    <DatePicker
                      selected={departureDate}
                      onChange={(date) => setDepartureDate(date)}
                      placeholderText="Select Departure Date"
                      className="date-picker"
                    />
                  )}
                  {currentStep === 7 && (
                    <DatePicker
                      selected={returnDate}
                      onChange={(date) => setReturnDate(date)}
                      placeholderText="Select Return Date"
                      className="date-picker"
                    />
                  )}
                  <InputRightElement>
                    <IconButton
                      onClick={handleNextStep}
                      bg="black"
                      color="white"
                      _hover="none"
                      size="sm"
                      icon={
                        currentStep < 7 ? <ArrowForwardIcon /> : <AddIcon />
                      }
                      aria-label={currentStep < 7 ? "Next Step" : "Add Flight"}
                    />
                  </InputRightElement>
                </InputGroup>
              </VStack>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};

export default FlightsList;
