import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  Progress,
  VStack,
  Divider,
  Input,
  Checkbox,
  IconButton,
  ButtonGroup,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { FaMinus, FaTrash, FaDownload } from "react-icons/fa";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import axiosInstance from "../../helpers/axiosInstance";
import { calculatePercentage } from "./utils";
import Translate from "../../components/LanguageTranslation/Translate";

const PackingListDetails = ({
  selectedCategory,
  selectedPackingListId,
  tripName,
  categoryItems,
  setCategoryItems,
  onUpdatePackingCategories,
  handleDeletePackingList, // Passed down to refresh the packing lists after deletion
}) => {
  const [newItem, setNewItem] = useState("");
  const [hoveredItem, setHoveredItem] = useState(null);
  const [selectedPackingListName, setSelectedPackingListName] = useState(""); // New state for packing list name
  const [tripCompanion, setTripCompanion] = useState("");
  const toast = useToast();

  const fetchPackingListById = async () => {
    if (selectedPackingListId) {
      try {
        const response = await axiosInstance.get(
          `/packing_lists/${selectedPackingListId}`
        );
        if (response.data && response.data.data) {
          const { packing_list_name, packing_list_items, trip_companion } =
            response.data.data;
          console.log("packing_list", trip_companion);
          setSelectedPackingListName(packing_list_name); // Set the packing list name
          setTripCompanion(trip_companion);
          const items = packing_list_items.map((item) => ({
            name: item.name,
            completed: item.completed || false, // Use completed from backend
          }));
          setCategoryItems({ [selectedCategory]: items });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to fetch packing list details.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    fetchPackingListById();
  }, [selectedPackingListId, selectedCategory, setCategoryItems, toast]);

  // Handle downloading the packing list
  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text(`Destination: ${tripName}`, 10, 10);

    const items =
      categoryItems[selectedCategory]?.map((item) => [item.name]) || [];

    doc.autoTable({
      startY: 20,
      head: [[selectedPackingListName]],
      body: items,
      theme: "grid",
    });

    doc.save(`${tripName}_${selectedPackingListName}.pdf`);
  };

  // Handle checkbox toggle to mark items as completed and update backend
  const handleCheckboxChange = async (itemName) => {
    const updatedItems = categoryItems[selectedCategory].map((item) =>
      item.name === itemName ? { ...item, completed: !item.completed } : item
    );

    setCategoryItems((prev) => ({ ...prev, [selectedCategory]: updatedItems }));

    const completedCount = updatedItems.filter((item) => item.completed).length;
    onUpdatePackingCategories(
      selectedCategory,
      completedCount,
      updatedItems.length
    );

    // Update the backend
    try {
      await axiosInstance.put(`/packing_lists/${selectedPackingListId}/items`, {
        packing_list_items: updatedItems,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update item status.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle adding a new item to the list
  const handleAddItem = async () => {
    if (!newItem) return;

    const updatedItems = [
      ...categoryItems[selectedCategory],
      { name: newItem, completed: false },
    ];
    setCategoryItems((prev) => ({ ...prev, [selectedCategory]: updatedItems }));

    try {
      const response = await axiosInstance.put(
        `/packing_lists/${selectedPackingListId}/items`,
        { packing_list_items: updatedItems }
      );

      if (response.data.success) {
        setNewItem(""); // Clear the input field
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update packing list.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRemoveItem = async (itemName) => {
    try {
      const response = await axiosInstance.put(
        `/packing_lists/${selectedPackingListId}/items`,
        {
          packing_list_items: categoryItems[selectedCategory]
            .filter((item) => item.name !== itemName)
            .map((item) => item),
        }
      );

      if (response.data.success) {
        fetchPackingListById(); // Refresh the list after item removal
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to remove item.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const calculateProgress = () => {
    if (
      !categoryItems[selectedCategory] ||
      categoryItems[selectedCategory].length === 0
    )
      return 0;
    const completedItems = categoryItems[selectedCategory].filter(
      (item) => item.completed
    ).length;
    return (completedItems / categoryItems[selectedCategory].length) * 100;
  };

  // Render nothing if no category is selected
  if (!selectedCategory) {
    return (
      <Box
        w="30%"
        p={5}
        pt={4}
        ml={0}
        bg="white"
        borderRadius="none"
        boxShadow="none"
      />
    );
  }

  return (
    <Box
      w="30%"
      p={5}
      pt={4}
      ml={0}
      bg="white"
      borderRadius="none"
      boxShadow="none"
      overflowY="auto"
      sx={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="md" mb={0}>
          {selectedCategory}
        </Heading>
        <ButtonGroup size="sm" spacing={2}>
          <IconButton
            icon={<FaDownload />}
            onClick={handleDownloadPDF}
            color="black"
            bg="none"
            aria-label="Download packing list"
            // variant="outline"
            size="xs"
          />
          <IconButton
            icon={<FaTrash />}
            onClick={handleDeletePackingList}
            colorScheme="red"
            aria-label="Delete packing list"
            variant="outline"
            size="xs"
          />
        </ButtonGroup>
      </Flex>

      <Text fontSize="sm" color="gray.500" mb={4}>
        {selectedPackingListName || "N/A"}{" "}
        <Text as="span" color="#286328">
          
          (<Translate text={tripCompanion.charAt(0).toUpperCase() + tripCompanion.slice(1)}/>)
        </Text>
      </Text>

      <Flex alignItems="center" mb={4}>
        <Text fontWeight="bold" mr={3} mb={0} color="#286328">
          {calculateProgress().toFixed(0)}%
        </Text>
        <Progress
          value={calculateProgress()}
          size="sm"
          colorScheme="green"
          flex="1"
          borderRadius="lg"
        />
      </Flex>

      <VStack align="start" spacing={3}>
        {categoryItems[selectedCategory]?.map((item) => (
          <Flex
            key={item.name}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            onMouseEnter={() => setHoveredItem(item.name)}
            onMouseLeave={() => setHoveredItem(null)}
            _hover={{ backgroundColor: "#FAFDFC" }}
            borderRadius="lg"
            cursor="pointer"
            p={1}
          >
            <Checkbox
              isChecked={item.completed}
              onChange={() => handleCheckboxChange(item.name)}
              colorScheme="green"
              sx={{
                "& > .chakra-checkbox__control": {
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                },
              }}
            >
              {/* Render the item name */}
              <Text
                as={item.completed ? "s" : "span"} // Strike-through if completed
                color={item.completed ? "gray.500" : "black"} // Faded color if completed
              >
                {item.name} {/* This is where you render the item name */}
              </Text>
            </Checkbox>

            {hoveredItem === item.name && (
              <IconButton
                icon={<FaMinus />}
                colorScheme="red"
                size="xs"
                variant="ghost"
                onClick={() => handleRemoveItem(item.name)}
                aria-label="Remove item"
                _hover={{ color: "red.600" }}
              />
            )}
          </Flex>
        ))}
        <Divider />
        <Input
          placeholder="Add item..."
          variant="filled"
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleAddItem()}
          bg="none"
          border="1px"
          borderColor="blackAlpha.200"
          borderRadius="xl"
          _hover="none"
        />
      </VStack>
    </Box>
  );
};

export default PackingListDetails;
