import React, { useState } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  HStack,
  IconButton,
  Spacer,
  useColorMode,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  useToast,
  Button,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { CloseIcon, AddIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axiosInstance from "../../helpers/axiosInstance"; // Import axios instance
import GridIcon from "../../assets/icons/dot_grid_icon.svg"; // Import the grid icon SVG
import { useCurrency } from "../CurrencyExchange/CurrencyContext"; // Import Currency Context
import Translate from "../../components/LanguageTranslation/Translate";

const HotelsList = ({
  hotels,
  setHotels,
  onHotelClick,
  tripId,
  location,
  setView,
}) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [hoveredItem, setHoveredItem] = useState(null);

  const { convertCurrency, currency } = useCurrency(); // Get the currency context

  // Function to handle drag end event
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(hotels);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setHotels(items); // Update state with reordered hotels

    // Construct the payload with updated hotel list
    const payload = {
      home_to_go_api_response: [
        {
          location: location,
          home_to_go_response: items,
        },
      ],
    };

    // Make PUT request to update the order in the backend
    try {
      await axiosInstance.put(`/bucket-list/${tripId}/update/stay`, payload);
    } catch (error) {
      console.error("Error updating hotel order:", error);
    }
  };

  const handleHotelDelete = async (hotelName) => {
    const updatedHotels = hotels.filter((hotel) => hotel.title !== hotelName);
    setHotels(updatedHotels); // Update state with filtered hotels

    // Construct the payload with updated hotel list
    const payload = {
      home_to_go_api_response: [
        {
          location,
          home_to_go_response: updatedHotels,
        },
      ],
    };

    // Make PUT request to update the list in the backend after deletion
    try {
      await axiosInstance.put(`/bucket-list/${tripId}/update/stay`, payload);
    } catch (error) {
      console.error("Error deleting hotel:", error);
    }
  };

  const handleCloseDetail = () => {
    setView(null);
  };

  // Function to add a new accommodation
  const [currentStep, setCurrentStep] = useState(0);
  const [title, setTitle] = useState(""); // Empty string for text input
  const [total, setTotal] = useState(null); // Null to indicate no initial value
  const [perNight, setPerNight] = useState(null); // Null for optional or unfilled number
  const [duration, setDuration] = useState(null); // Null for numeric input without default
  const [type, setType] = useState(""); // Empty string for text input

  const handleNextStep = async () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    } else {
      // Construct the new accommodation object with required structure
      const newAccommodation = {
        title,
        checkInOutTimes: [
          {
            dateTo: "", // Add default values or leave blank to update as necessary
            dateFrom: "",
            checkInTimeTo: "",
            checkInTimeFrom: "",
            checkOutTimeTo: "",
            checkOutTimeFrom: null,
            daysApplicable: null,
          },
        ],
        cancellationPolicyInfo: [
          {
            days: 0, // Default values or specify as needed
            refundPercent: 0,
          },
        ],
        rating: { stars: 0, value: 0, maxValue: 100 }, // Define default rating
        id: `custom-${Date.now()}`, // Unique ID for custom accommodations
        link: "",
        type,
        geoLocation: { lat: null, lon: null },
        price: {
          total: parseFloat(total) || 0,
          perNight: parseFloat(perNight) || 0,
          duration: parseInt(duration, 10) || 0,
          currency: "USD",
        },
        images: [
          {
            medium: "", // Default image values or placeholders if needed
            large: "",
          },
        ],
      };

      try {
        const updatedHotels = [...hotels, newAccommodation];
        const payload = {
          home_to_go_api_response: [
            {
              location: location,
              home_to_go_response: updatedHotels,
            },
          ],
        };

        const response = await axiosInstance.put(
          `/bucket-list/${tripId}/update/stay`,
          payload
        );

        if (response.data.success) {
          setHotels(updatedHotels);
          toast({
            title: "New accommodation added successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          // Reset form fields
          setTitle("");
          setTotal(0);
          setPerNight(0);
          setDuration(0);
          setType("");
          setCurrentStep(0);
        }
      } catch (error) {
        console.error("Error adding new accommodation:", error);
        toast({
          title: "Error adding new accommodation",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
      w="100%"
      h="100vh"
      overflowY="hidden"
    >
      <HStack justifyContent="space-between" bg="white">
        <Box
          flex="1"
          display="flex"
          justifyContent="left"
          p={4}
          pt={2}
          pb={2}
          pl={4}
          maxH="8vh"
          borderBottom="1px"
          borderLeft="1px"
          borderColor="blackAlpha.200"
        >
          <Text
            fontSize="md"
            fontWeight="bold"
            mt={1}
            pb={2}
            color="blackAlpha.700"
          >
            <Translate text="Update Accomodations" />
          </Text>
          <Spacer />
          <IconButton
            icon={<CloseIcon boxSize={2.5} />}
            onClick={handleCloseDetail}
            colorScheme="gray"
            size="sm"
            isRound={true}
            position="relative"
            zIndex="tooltip"
            bg="white"
            boxShadow="md"
          />
        </Box>
      </HStack>
      <Box h="89vh">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="hotels">
            {(provided) => (
              <VStack
                spacing={4}
                h="100%"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
                bg="white"
                align="stretch"
                p={4}
                m={2}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {hotels.map((hotel, index) => {
                  // Convert hotel price
                  const convertedPrice = hotel.price?.perNight
                    ? convertCurrency(
                        hotel.price.perNight,
                        hotel.price.currency
                      )
                    : null;

                  return (
                    <Draggable
                      key={hotel.id}
                      draggableId={hotel.id}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          position="relative"
                          bg="white"
                          borderRadius="lg"
                          boxShadow="md"
                          border="1px solid"
                          borderColor="gray.200"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          onMouseEnter={() => setHoveredItem(index)}
                          onMouseLeave={() => setHoveredItem(null)}
                        >
                          {/* Numbering circle */}
                          <Box
                            position="absolute"
                            top="-10px"
                            left="-10px"
                            bg="black"
                            color="white"
                            borderRadius="full"
                            width="24px"
                            height="24px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            {index + 1}
                          </Box>
                          <Box
                            {...provided.dragHandleProps}
                            bg="gray.100"
                            alignContent="center"
                            visibility={
                              hoveredItem === index ? "visible" : "hidden"
                            }
                            h="100%"
                          >
                            <Image
                              src={GridIcon}
                              alt="Drag Handle"
                              boxSize="4"
                              opacity={hoveredItem === index ? 1 : 0.5}
                            />
                          </Box>
                          <VStack ml={1} w="full" p={4} pl={0} h="100%">
                            <HStack align="center" spacing={4} w="100%">
                              <VStack
                                align="flex-start"
                                spacing={1}
                                flex="1"
                                h="100%"
                              >
                                <Text fontSize="18px" fontWeight="bold" mb={0}>
                                  {hotel.title}
                                </Text>
                                <Text
                                  fontSize="sm"
                                  fontWeight="bold"
                                  color="gray.500"
                                  // ml={1}
                                  mb={0}
                                >
                                  {hotel.type.replace("_", " ")}
                                </Text>
                                <Text fontSize="14px" color="gray.600" mb={0}>
                                  {currency}{" "}
                                  {convertedPrice
                                    ? convertedPrice
                                    : hotel.price?.perNight}{" "}
                                  per night -{" "}
                                  {hotel.rating?.value ? (
                                    `${hotel.rating.value} ★`
                                  ) : (
                                    <Translate text="No Rating" />
                                  )}{" "}
                                  (
                                  {hotel.reviews || (
                                    <Translate text="No reviews available" />
                                  )}
                                  )
                                </Text>
                              </VStack>
                              {hotel.images[0]?.medium &&(
                              <Image
                                src={
                                  `https:${hotel.images[0]?.medium}` ||
                                  "https://via.placeholder.com/120"
                                }
                                alt={hotel.title}
                                boxSize="120px"
                                borderRadius="lg"
                                objectFit="cover"
                              />
                              )}
                            </HStack>
                            <HStack spacing={1} w="100%">
                            {hotel.link &&(
                              <Button
                                as="a"
                                href={hotel.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="sm"
                                bg="#286328"
                                _hover="none"
                                _active="none"
                                textColor="white"
                                mt={2}
                              >
                                <Translate text="Book" />
                              </Button>
                            )}
                              <Spacer />
                              <IconButton
                                icon={<FaTrash />}
                                aria-label="Delete hotel"
                                borderRadius="full"
                                size="xs"
                                visibility={
                                  hoveredItem === index ? "visible" : "hidden"
                                }
                                onClick={() => handleHotelDelete(hotel.title)}
                              />
                            </HStack>
                          </VStack>
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
                <InputGroup>
                  {currentStep === 0 && (
                    <Input
                      placeholder="Enter Accommodation Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 1 && (
                    <Input
                      placeholder="Enter Total Price"
                      type="number"
                      value={total}
                      onChange={(e) => setTotal(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 2 && (
                    <Input
                      placeholder="Enter Price Per Night"
                      type="number"
                      value={perNight}
                      onChange={(e) => setPerNight(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 3 && (
                    <Input
                      placeholder="Enter Duration"
                      type="number"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  {currentStep === 4 && (
                    <Input
                      placeholder="Enter Type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      variant="outline"
                      bg="white"
                      w="full"
                      p={2}
                      borderRadius="lg"
                    />
                  )}
                  <InputRightElement>
                    <IconButton
                      onClick={handleNextStep}
                      bg="black"
                      color="white"
                      _hover="none"
                      size="sm"
                      icon={
                        currentStep < 4 ? <ArrowForwardIcon /> : <AddIcon />
                      }
                      aria-label={
                        currentStep < 4 ? "Next Step" : "Add Accommodation"
                      }
                    />
                  </InputRightElement>
                </InputGroup>
              </VStack>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};

export default HotelsList;
