import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  IconButton,
  VStack,
  Image,
  Button,
} from "@chakra-ui/react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import Translate from "../../components/LanguageTranslation/Translate";

const ItinerayCard = ({ tripData, onSelectItem }) => {
  // Check if the itinerary exists and has at least one day
  const firstDay = tripData?.itinerary?.[0];

  // Check if the first day's activity detail list exists and has at least one activity
  const firstActivity = firstDay?.activity_detail_list?.[0];

  // Initialize the favorite state for each item (assuming only one card displayed)
  const [isFavorited, setIsFavorited] = useState(false);

  const toggleFavorite = () => {
    setIsFavorited((prevIsFavorited) => !prevIsFavorited);
  };

  return (
    <Box
      mt={2}
      bg="gray.100"
      padding={2}
      borderRadius="xl"
      boxShadow="md"
      maxW="320px" // Set maximum width to match the provided image
      width="50%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Flex alignContent="top" justifyContent="space-between" width="100%">
        <Text fontWeight="bold" fontSize="md" ml={2} mt={0} mb={1}>
          
          <Translate text="Itinerary" />
        </Text>
      </Flex>
      <Box borderTopRadius="md" boxShadow="md" width="100%">
        <Image
          src={firstActivity?.thumbnailHiResURL}
          fallbackSrc="https://via.placeholder.com/300x200.png?text=No+Image"
          alt={firstActivity?.title || "No Title Available"}
          borderTopRadius="xl"
          objectFit="cover"
          width="100%"
          height="200px" // Adjust height to match the aspect ratio similar to the provided image
        />
      </Box>
      <VStack
        alignItems="center"
        width="100%"
        p={3}
        spacing={3}
        textAlign="left"
        bg="white"
        borderBottomRadius="xl"
      >
        <Text
          fontWeight="bold"
          fontSize="md"
          width="100%"
          noOfLines={2}
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          mb={0}
        >
          {tripData?.trip_name || "Untitled Trip"}
        </Text>
        <Button
          bg="#286328"
          _hover="none"
          _active="none"
          textColor="white"
          width="100%"
          size="sm"
          borderRadius="lg"
          onClick={() => onSelectItem(tripData)}
        >
          
          <Translate text="Open" />
        </Button>
      </VStack>
    </Box>
  );
};

export default ItinerayCard;
