import React from "react";
import {
  Box,
  Text,
  Icon,
  Button,
  HStack,
  Divider,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as ItineraryIcon } from "../../assets/icons/noun-travel-itinerary-4236219.svg";
import { useHistory } from "react-router-dom";
import Translate from "../../components/LanguageTranslation/Translate";

const NoOngoingTrip = () => {
  const { colorMode } = useColorMode();
  const history = useHistory();

  const redirectToChat = () => {
    history.push("/ava/chat"); // Replace '/chat' with the actual path to your chat page
  };

  const redirectToBucketList = () => {
    history.push("/bucketlists"); // Replace '/chat' with the actual path to your chat page
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      w="100%"
      h="100%"
      bg={colorMode === "dark" ? "gray.800" : "white"}
    >
      <Box textAlign="center" mb={4}>
        <Icon
          as={ItineraryIcon}
          boxSize={12}
          color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
        />
        <Text
          fontSize="2xl"
          fontWeight="bold"
          mt={2}
          color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
          mb={0}
        >
          
          <Translate text="No Ongoing Trip" />
        </Text>
        <Text
          fontSize="md"
          mt={2}
          color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
          mb={0}
        >
          
          <Translate text="You don't have any trips planned at the moment." />
        </Text>
        <Text
          fontSize="md"
          mt={2}
          color={colorMode === "dark" ? "#1A202C" : "#1A202C"}
          mb={0}
        >
          
          <Translate text="Start planning your next adventure or enable your saved trip!" />
        </Text>
        <HStack alignContent="center" justifyContent="center">
          <Button
            mt={6}
            bg="#286328"
            color="white"
            _hover="none"
            borderRadius="lg"
            onClick={redirectToChat}
          >
            
            <Translate text="Plan a trip" />
          </Button>
          <Divider
            orientation="vertical"
            h="40px"
            borderColor={colorMode === "dark" ? "black" : "black"}
            mt={6}
            mb={0}
          />
          <Button
            mt={6}
            bg="#286328"
            color="white"
            _hover="none"
            borderRadius="lg"
            onClick={redirectToBucketList}
          >
            
            <Translate text="Bucket list" />
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};

export default NoOngoingTrip;
