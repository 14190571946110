import React from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  HStack,
  Divider,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import TripDropdown from "../common/TripDropdown";
import SettingsMenu from "../common/SettingsMenu";
import { FiPieChart, FiCreditCard, FiTag } from "react-icons/fi"; // Added FiTag for categories
import Translate from "../../components/LanguageTranslation/Translate";

const BudgetMenu = ({
  selectedTrip,
  setSelectedTrip,
  tripName,
  setTripName,
  setView,
  onOpen,
  onOpenCategoryModal,
}) => {
  const bg = useColorModeValue("white", "gray.800");
  const buttonBg = useColorModeValue("#FFC107", "#FFC107");
  const buttonText = useColorModeValue("black", "black");

  return (
    <Box
      w="20%"
      display="flex"
      flexDirection="column"
      borderRight="1px solid"
      borderColor="blackAlpha.200"
      bg={bg}
      height="100vh"
      overflowY="auto"
    >
      {/* Budget Heading and Settings */}
      <HStack
        p={2}
        pl={4}
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        borderBottom="1px"
        borderColor="blackAlpha.100"
        height="8vh"
      >
        <Text fontSize="lg" fontWeight="bold" color="blackAlpha.800" mb={0}>
          <Translate text="Budget" />
        </Text>
        <SettingsMenu />
      </HStack>

      {/* Trip Dropdown */}
      <Box p={4} pt={0} pb={0}>
        <TripDropdown
          selectedTrip={selectedTrip}
          setSelectedTrip={setSelectedTrip}
          setTripName={setTripName}
          onTripSelect={setSelectedTrip}
        />
      </Box>

      {/* Options for Stats, Expenses, and Categories */}
      <VStack spacing={4} align="flex-start" p={4} pt={4}>
        <Divider m={0} p={0} />
        <HStack
          width="100%"
          // bg={useColorModeValue("yellow.100", "yellow.900")}
          bg="blackAlpha.50"
          p={2}
          pl={3}
          borderRadius="full"
          cursor="pointer"
          onClick={() => setView("stats")} // Switch to stats view
        >
          <Icon as={FiPieChart} boxSize={5} />
          <Text fontSize="md" fontWeight="medium" mb={0}>
            <Translate text="Stats" />
          </Text>
        </HStack>

        <HStack
          width="100%"
          bg="blackAlpha.50"
          p={2}
          pl={3}
          borderRadius="full"
          cursor="pointer"
          onClick={() => setView("expenses")} // Switch to expenses view
        >
          <Icon as={FiCreditCard} boxSize={5} />
          <Text fontSize="md" fontWeight="medium" mb={0}>
            <Translate text="Expenses" />
          </Text>
        </HStack>

        <HStack
          width="100%"
          bg="blackAlpha.50"
          p={2}
          pl={3}
          borderRadius="full"
          cursor="pointer"
          onClick={() => setView("categories")} // Switch to categories view
        >
          <Icon as={FiTag} boxSize={5} />
          <Text fontSize="md" fontWeight="medium" mb={0}>
            <Translate text="Categories" />
          </Text>
        </HStack>

        <Divider m={0} p={0} />
      </VStack>

      {/* Add Expense Button */}
      <Box mb={0} p={4} pt={0}>
        <Button
          width="100%"
          bg="#286328"
          color="white"
          _hover="none"
          borderRadius="full"
          leftIcon={<AddIcon />}
          onClick={onOpen}
          isDisabled={!selectedTrip} // Disable button if no trip is selected
        >
          <Translate text="Add Expense" />
        </Button>
      </Box>

      {/* Add Category Button */}
      <Box p={4} pb={0} pt={0}>
        <Button
          width="100%"
          bg="#286328"
          color="white"
          _hover="none"
          borderRadius="full"
          leftIcon={<AddIcon />}
          onClick={onOpenCategoryModal}
          isDisabled={!selectedTrip} // Disable button if no trip is selected
        >
          <Translate text="Add Category" />
        </Button>
      </Box>
    </Box>
  );
};

export default BudgetMenu;
