import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  Heading,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useToast,
  Button,
  HStack,
  Spacer,
  Link,
  IconButton,
} from "@chakra-ui/react";
import { CloseIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import CountryFlag from "react-country-flag";
import HotelsCard from "../chat/HotelsCard";
import Activities from "../chat/Activities"; // Ensure the correct import
import FlightCard from "../chat/FlightCard";
import RVRentalCard from "../chat/RVRentalCard";
import InfoCard from "./InfoCard";
import axiosInstance from "../../helpers/axiosInstance"; // Ensure axiosInstance is correctly imported
import { useCurrency } from "../CurrencyExchange/CurrencyContext"; // Import useCurrency hook
import countries from "i18n-iso-countries";
import eng from "i18n-iso-countries/langs/en.json";
import Translate from "../../components/LanguageTranslation/Translate";

// Register the English locale
countries.registerLocale(eng);

const ItineraryDetails = ({
  item,
  activities,
  hotels,
  flights,
  RVs,
  onActivityClick,
  onUpdateClick,
  onDayChange,
  selectedDayIndex,
  handleHotelUpdateClick,
  handleRVsUpdateClick,
  handleFlightUpdateClick,
  handleAllActivitiesUpdateClick,
  lastUpdatedDate,
  tripDetails,
  tripId,
}) => {
  // console.log("RVs",RVs);
  const tabListRef = useRef(null);
  const [itinerary, setItinerary] = useState([]);
  const toast = useToast();
  const { convertCurrency, currency } = useCurrency(); // Access convertCurrency and currency from context
  const [totalCost, setTotalCost] = useState(0);

  const tabListscrollLeft = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollBy({
        left: -300, // Adjust this value to control the scroll distance
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const tabListscrollRight = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollBy({
        left: 300, // Adjust this value to control the scroll distance
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  // Ensure tripDetails is not null and has a country property before accessing it
  const countryName = tripDetails?.country || "";
  const countryCode = countryName
    ? countries.getAlpha2Code(countryName, "en")
    : null;

  useEffect(() => {
    if (item && item.itinerary) {
      setItinerary(item.itinerary);
    }
  }, [item]);

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await axiosInstance.get(
          `/budget_planner/${tripId}/expenses`
        );
        const expensesData = response.data;

        if (Array.isArray(expensesData)) {
          // Calculate the total cost across all categories
          const total = expensesData.reduce((acc, category) => {
            if (category.expenses && Array.isArray(category.expenses)) {
              const categoryTotal = category.expenses.reduce(
                (catAcc, expense) => {
                  return catAcc + (expense.cost || 0); // Default to 0 if cost is undefined
                },
                0
              );
              return acc + categoryTotal;
            } else {
              console.warn("Expenses is not an array for category:", category);
            }
            return acc;
          }, 0);

          setTotalCost(total); // Set the total cost in the state
        } else {
          console.error("Response data is not an array");
        }
      } catch (error) {
        console.error("Error fetching expenses:", error);
      }
    };

    if (tripId) {
      fetchExpenses();
    }
  }, [tripId]);

  // Convert the total cost to the selected currency
  const convertedTotalCost = parseFloat(convertCurrency(totalCost, "USD")) || 0;

  if (!item) {
    return null; // Guard against null items
  }

  const { mainImage, travelDates, title, lastUpdated, description } =
    item || {};

  const handleUpdateClickInternal = () => {
    onUpdateClick(); // Trigger the parent's update function
  };

  const handleHotelUpdateClickInternal = () => {
    handleHotelUpdateClick(); // Trigger the parent's update function
  };

  const handleRVsUpdateClickInternal = () => {
    handleRVsUpdateClick(); // Trigger the parent's update function
  };

  const handleFlightUpdateClickInternal = () => {
    handleFlightUpdateClick(); // Trigger the parent's update function
  };

  const handleActivitiesUpdateClickInternal = () => {
    handleAllActivitiesUpdateClick(); // Trigger the parent's update function
  };

  const firstDayImage =
    itinerary[0]?.activity_detail_list?.[0]?.thumbnailHiResURL ||
    "https://via.placeholder.com/300x200.png?text=No+Image";

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const suffix =
      day === 1 || day === 21 || day === 31
        ? "st"
        : day === 2 || day === 22
        ? "nd"
        : day === 3 || day === 23
        ? "rd"
        : "th";

    return date
      .toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
      .replace(day.toString(), `${day}${suffix}`);
  };

  return (
    <Box
      right="0"
      top="0"
      h={{ base: "auto", md: "90vh" }}
      p={{ base: 2, md: 5 }}
      pt={{ base: 2, md: 2 }}
      bg="white"
      zIndex="modal"
      overflowY={{ base: "visible", md: "auto" }}
    >
      <VStack spacing={{ base: 4, md: 2 }} align="stretch" pt="2">
        <Image
          src={firstDayImage}
          fallbackSrc="https://via.placeholder.com/300x200.png?text=No+Image"
          alt={itinerary[0]?.location || "No Description Available"}
          borderRadius="md"
          boxSize="100%"
          objectFit="cover"
          mb={0}
          maxH={{ base: "200px", md: "300px" }}
        />
        <VStack
          spacing={4}
          alignItems="flex-start"
          justifyContent="flex-start"
          mt={{ base: 2, md: 5 }}
        >
          <Text fontSize={{ base: "xs", md: "sm" }} mb={0}>
            {tripDetails?.departure_date
              ? formatDate(tripDetails.departure_date)
              : "N/A"}{" "}
            to{" "}
            {tripDetails?.arrival_date
              ? formatDate(tripDetails.arrival_date)
              : "N/A"}
          </Text>

          <Heading size={{ base: "sm", md: "md" }} mb={0}>
            {item.trip_name || "No Title Available"}
            {countryCode ? (
              <CountryFlag
                countryCode={countryCode}
                svg
                style={{
                  width: "1.25em",
                  height: "1.25em",
                  marginLeft: "0.5em",
                  boxShadow:
                    "-4px 0 4px -2px rgba(0,0,0,0.1), 4px 0 4px -2px rgba(0,0,0,0.1)", // Shadows on left and right
                  backgroundColor: "#f0f0f0", // Light gray background
                  borderRadius: "4px", // Optional: rounded corners
                }}
              />
            ) : (
              <p></p>
            )}
          </Heading>
          {/* Display converted total cost */}
          <Link
            as={RouterLink}
            to="/budget"
            fontSize={{ base: "xs", md: "sm" }}
            mb={0}
          >
            {currency}{" "}
            {convertedTotalCost ? Math.round(convertedTotalCost) : "N/A"}
          </Link>

          <Text size={{ base: "sm", md: "md" }} mb={0}>
            {description || "No Description Available"}
          </Text>
          <Text fontSize={{ base: "xs", md: "sm" }} color="gray.500" mb={0}>
            <Translate text="Last updated:" />{" "}
            {lastUpdatedDate
              ? formatDate(lastUpdatedDate.split(" ")[0])
              : "N/A"}
          </Text>
        </VStack>
        <InfoCard
          country={tripDetails?.country}
          city={itinerary[selectedDayIndex]?.location}
        />
        <Text
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight="bold"
          mb={0}
          mt={0}
          p={2}
          pl={0}
        >
          <Translate text="Itinerary" />
        </Text>
        <Tabs
          variant="unstyled"
          onChange={(index) => onDayChange(index)}
          index={selectedDayIndex}
        >
          <Box position="relative" w="100%">
            <IconButton
              aria-label="Scroll Left"
              icon={<ChevronLeftIcon />}
              onClick={tabListscrollLeft}
              isRound={true}
              size="xs"
              position="absolute"
              top="40%"
              left="0px"
              transform="translateY(-50%)"
              backgroundColor="white"
              boxShadow="base"
              zIndex="overlay"
            />
            <TabList
              ref={tabListRef}
              overflowX="auto"
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
              whiteSpace="nowrap"
              pb={2}
            >
              {itinerary.map((day, index) => (
                <Tab
                  key={index}
                  _selected={{ bg: "white", border: "2px solid black" }}
                  _hover={{ bg: "none" }}
                  p={{ base: 1, md: 2 }}
                  pl={{ base: 2, md: 3 }}
                  pr={{ base: 2, md: 3 }}
                  border="2px solid"
                  borderColor="gray.200"
                  borderRadius="xl"
                  mr={{ base: 2, md: 6 }}
                  mb={4}
                  align="left"
                  flexShrink={0}
                >
                  <VStack alignItems="flex-start" justifyContent="flex-start">
                    <Heading
                      fontSize={{ base: "sm", md: "md" }}
                      fontWeight="bold"
                      mb={0}
                      fontFamily="Arial"
                    >
                      <Translate text="Day" /> {index + 1}
                    </Heading>
                    <Text
                      fontSize={{ base: "xs", md: "xs" }}
                      color="gray.500"
                      mb={0}
                      fontFamily="Arial"
                    >
                      {day.location}
                    </Text>
                  </VStack>
                </Tab>
              ))}
            </TabList>
            <IconButton
              aria-label="Scroll Right"
              icon={<ChevronRightIcon />}
              onClick={tabListscrollRight}
              isRound={true}
              size="xs"
              position="absolute"
              top="40%"
              right="0px"
              transform="translateY(-50%)"
              backgroundColor="white"
              boxShadow="base"
              zIndex="overlay"
            />
          </Box>
          <TabPanels>
            {itinerary.map((day, index) => (
              <TabPanel
                key={index}
                p={{ base: 3, md: 5 }}
                border="2px solid"
                borderColor="gray.200"
                borderRadius="xl"
              >
                <Heading
                  fontSize={{ base: "20px", md: "24px" }}
                  fontFamily="Arial"
                  mb={0}
                >
                  {day.current_day_name}
                </Heading>
                <VStack
                  mt={5}
                  spacing={4}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  {Object.keys(day.plan).map((timeOfDay, idx) => (
                    <VStack
                      key={idx}
                      spacing={2}
                      alignItems="flex-start"
                      justifyContent="flex-start"
                    >
                      <Heading
                        size={{ base: "sm", md: "xs" }}
                        fontFamily="Arial"
                        mb={0}
                      >
                        {timeOfDay.charAt(0).toUpperCase() + timeOfDay.slice(1)}
                      </Heading>
                      <Text fontFamily="Arial" mb={0}>
                        {day.plan[timeOfDay]}
                      </Text>
                    </VStack>
                  ))}
                </VStack>
                <VStack mt={5}>
                  <HStack w="full">
                    <Heading
                      mb={0}
                      fontSize={{ base: "sm", md: "md", lg: "lg" }}
                      textAlign="left"
                    >
                      <Translate text="Recommended Places" />
                    </Heading>
                    <Spacer />
                    <Button
                      aria-label="Update activities"
                      bg="#1A202C"
                      color="white"
                      _hover="none"
                      _active="none"
                      size={{ base: "xs", md: "xs", lg: "sm" }}
                      fontSize={{ base: "xs", md: "sm", lg: "sm" }}
                      onClick={handleUpdateClickInternal}
                    >
                      <Translate text="Pick Places" />
                    </Button>
                  </HStack>

                  <Activities
                    data={day.activity_detail_list}
                    onActivityClick={onActivityClick}
                  />
                </VStack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </VStack>
      <Box
        display="flex"
        align="center"
        justifyContent="space-between"
        mt={6}
        mb={0}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Heading mb={0} fontSize={{ base: "sm", md: "md", lg: "xl" }}>
          <Translate text="Recommended Accomodations" />
        </Heading>
        <Button
          aria-label="Update hotels"
          size={{ base: "xs", md: "sm", lg: "sm" }}
          fontSize={{ base: "xs", md: "sm", lg: "sm" }}
          bg="#1A202C"
          color="white"
          _hover="none"
          _active="none"
          mt={{ base: 4, md: 0 }}
          onClick={handleHotelUpdateClickInternal}
        >
          <Translate text="Pick Accomodations" />
        </Button>
      </Box>

      <HotelsCard hotels={hotels} onActivityClick={onActivityClick} />

      <Box
        display="flex"
        align="center"
        justifyContent="space-between"
        mt={6}
        mb={0}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Heading mb={4} fontSize={{ base: "sm", md: "md", lg: "xl" }}>
          <Translate text="Recommended Places" />
        </Heading>
        <Button
          aria-label="Update activities"
          size={{ base: "xs", md: "sm", lg: "sm" }}
          fontSize={{ base: "xs", md: "sm", lg: "sm" }}
          bg="#1A202C"
          color="white"
          _hover="none"
          _active="none"
          mt={{ base: 4, md: 0 }}
          onClick={handleActivitiesUpdateClickInternal}
        >
          <Translate text="Pick Places" />
        </Button>
      </Box>
      <Activities data={activities} onActivityClick={onActivityClick} />

      <Heading fontSize={{ base: "sm", md: "md", lg: "xl" }} mt={6}>
        <Translate text="Transportation" />
      </Heading>
      <Box
        display="flex"
        align="center"
        justifyContent="space-between"
        mt={4}
        mb={0}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Heading fontSize={{ base: "sm", md: "md", lg: "xl" }}>
          <Translate text="Recommended Flights" />
        </Heading>
        <Button
          aria-label="Update flight"
          bg="#1A202C"
          color="white"
          _hover="none"
          _active="none"
          size={{ base: "xs", md: "sm", lg: "sm" }}
          fontSize={{ base: "xs", md: "sm", lg: "sm" }}
          mt={{ base: 4, md: 0 }}
          onClick={handleFlightUpdateClickInternal}
        >
          <Translate text="Pick Flights" />
        </Button>
      </Box>
      <FlightCard data={flights} />

      <Box
        display="flex"
        align="center"
        justifyContent="space-between"
        mt={4}
        mb={0}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Heading fontSize={{ base: "sm", md: "md", lg: "xl" }}>
          <Translate text="Recommended RVs for Rental" />
        </Heading>
        <Button
          aria-label="Update activities"
          size={{ base: "xs", md: "sm", lg: "sm" }}
          fontSize={{ base: "xs", md: "sm", lg: "sm" }}
          bg="#1A202C"
          color="white"
          _hover="none"
          _active="none"
          mt={{ base: 4, md: 0 }}
          onClick={handleRVsUpdateClickInternal}
        >
          <Translate text="Pick RVs" />
        </Button>
      </Box>
      <RVRentalCard RVs={RVs} />
    </Box>
  );
};

export default ItineraryDetails;
