import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  Text,
  Input,
  Progress,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import axiosInstance from "../../helpers/axiosInstance"; // Adjust the path as needed
import Translate from "../../components/LanguageTranslation/Translate";

const BudgetManager = ({
  total,
  currency,
  convertCurrency,
  selectedTripId,
}) => {
  const [dailyAverage, setDailyAverage] = useState(null); // Daily spent value
  const [totalBudget, setTotalBudget] = useState(null); // Default total budget
  const [newTotalBudget, setNewTotalBudget] = useState(totalBudget);
  const [isEditing, setIsEditing] = useState(false);

  const [totalDailyBudget, setTotalDailyBudget] = useState(null); // Default daily budget
  const [newTotalDailyBudget, setNewTotalDailyBudget] =
    useState(totalDailyBudget);
  const [isDailyEditing, setDailyIsEditing] = useState(false);

  useEffect(() => {
    const fetchBudgetValues = async () => {
      try {
        const response = await axiosInstance.get(
          `/budget_planner/${selectedTripId}/get-budget-planner`
        );
        console.log("fetchBudgetValues", response.data);
        if (response.status === 200) {
          setTotalBudget(response.data.total_budget);
          setTotalDailyBudget(response.data.daily_budget);
        }
      } catch (error) {
        console.error("Error fetching budget values:", error);
      }
    };
    fetchBudgetValues();
  }, [selectedTripId]);

  // Fetch daily spent from the backend
  useEffect(() => {
    const fetchDailySpent = async () => {
      try {
        const response = await axiosInstance.get(
          `/budget_planner/${selectedTripId}/daily_spent`
        );
        if (response.status === 200) {
          // console.log("fetchDailySpent", response.data);
          setDailyAverage(response.data.total_cost); // Assuming API response includes daily_spent
        }
      } catch (error) {
        console.error("Error fetching daily spent:", error);
      }
    };

    if (selectedTripId) {
      fetchDailySpent(); // Fetch when component mounts
    }
  }, [selectedTripId]);

  const handleTotalBudgetChange = (e) => {
    setNewTotalBudget(e.target.value);
  };

  const saveTotalBudget = async () => {
    try {
      const response = await axiosInstance.post(
        `budget_planner/${selectedTripId}/total_budget`,
        {
          total_budget: newTotalBudget,
        }
      );
      if (response.status === 200 || response.status === 201) {
        setTotalBudget(newTotalBudget);
        setIsEditing(false); // Switch back to text display
      }
    } catch (error) {
      console.error("Error saving total budget:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      saveTotalBudget(); // Save on pressing "Enter"
    }
  };

  const handleTotalDailyBudgetChange = (e) => {
    setNewTotalDailyBudget(e.target.value);
  };

  const saveTotalDailyBudget = async () => {
    try {
      const response = await axiosInstance.post(
        `budget_planner/${selectedTripId}/daily_budget`,
        {
          daily_budget: newTotalDailyBudget,
        }
      );
      if (response.status === 200 || response.status === 201) {
        setTotalDailyBudget(newTotalDailyBudget);
        setDailyIsEditing(false); // Switch back to text display
      }
    } catch (error) {
      console.error("Error saving daily budget:", error);
    }
  };

  const handleKeyPressDaily = (e) => {
    if (e.key === "Enter") {
      saveTotalDailyBudget(); // Save on pressing "Enter"
    }
  };

  return (
    <HStack justify="space-between" w="100%" borderRadius="xl" bg="white">
      {/* Total Budget Section */}
      <Box textAlign="left" borderRadius="xl" bg="white" p={4}>
        <Text fontSize="sm" color="gray.500">
          <Translate text="TOTAL BUDGET" />
        </Text>
        <Flex alignItems="center">
          <Text fontSize="xl" fontWeight="bold" mb={0}>
            {currency}{" "}
            {convertCurrency(total, "USD") &&
            !isNaN(convertCurrency(total, "USD"))
              ? Number(convertCurrency(total, "USD")).toFixed(2)
              : "0.00"}
            /
          </Text>
          {!isEditing ? (
            <>
              <Text fontSize="xl" fontWeight="bold" mb={0}>
                {totalBudget ? (
                  `${currency} ${Number(
                    convertCurrency(totalBudget, "USD")
                  ).toFixed(2)}`
                ) : (
                  <Translate text="Enter Budget" />
                )}{" "}
              </Text>
              <IconButton
                icon={<EditIcon />}
                size="xs"
                bg="none"
                _hover="none"
                ml={2}
                onClick={() => setIsEditing(true)} // Switch to input field
                aria-label="Edit total budget"
              />
            </>
          ) : (
            <>
              <Input
                type="number"
                value={newTotalBudget}
                onChange={handleTotalBudgetChange}
                onKeyPress={handleKeyPress} // Save when pressing Enter
                width="120px"
                placeholder="Enter budget"
                size="sm"
                autoFocus
              />
              <Button
                ml={2}
                size="sm"
                colorScheme="blue"
                onClick={saveTotalBudget}
              >
                <Translate text="Save" />
              </Button>
            </>
          )}
        </Flex>
        <Progress
          value={totalBudget ? (total / totalBudget) * 100 : 0} // Avoid NaN when no totalBudget
          size="sm"
          colorScheme="red"
          mt={2}
        />
      </Box>

      {/* Daily Budget Section */}
      <Box textAlign="left" borderRadius="xl" bg="white" p={4}>
        <Text fontSize="sm" color="gray.500">
          <Translate text="DAILY AVERAGE" />
        </Text>
        <Flex alignItems="center">
          <Text fontSize="xl" fontWeight="bold" mb={0}>
            {currency}{" "}
            {convertCurrency(dailyAverage, "USD") &&
            !isNaN(convertCurrency(dailyAverage, "USD"))
              ? Number(convertCurrency(dailyAverage, "USD")).toFixed(2)
              : "0.00"}
            /
          </Text>
          {!isDailyEditing ? (
            <>
              <Text fontSize="xl" fontWeight="bold" mb={0}>
                {totalDailyBudget ? (
                  `${currency} ${Number(
                    convertCurrency(totalDailyBudget, "USD")
                  ).toFixed(2)}`
                ) : (
                  <Translate text="Enter Daily Budget" />
                )}{" "}
              </Text>
              <IconButton
                icon={<EditIcon />}
                size="xs"
                bg="none"
                _hover="none"
                ml={2}
                onClick={() => setDailyIsEditing(true)} // Switch to input field
                aria-label="Edit total daily budget"
              />
            </>
          ) : (
            <>
              <Input
                type="number"
                value={newTotalDailyBudget}
                onChange={handleTotalDailyBudgetChange}
                onKeyPress={handleKeyPressDaily} // Save when pressing Enter
                width="120px"
                placeholder="Enter Daily budget"
                size="sm"
                autoFocus
              />
              <Button
                ml={2}
                size="sm"
                colorScheme="blue"
                onClick={saveTotalDailyBudget}
              >
                <Translate text="Save" />
              </Button>
            </>
          )}
        </Flex>
        <Progress
          value={totalDailyBudget ? (dailyAverage / totalDailyBudget) * 100 : 0} // Avoid NaN when no totalDailyBudget
          size="sm"
          colorScheme="red"
        />
      </Box>
    </HStack>
  );
};

export default BudgetManager;
