// ChatHeader.js
import React, { useState } from "react";
import {
  Box,
  Text,
  Spacer,
  Button,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import SettingsMenu from "../common/SettingsMenu";
import axiosInstance from "../../helpers/axiosInstance";
import AuthModal from "../AuthModal"; // Import AuthModal
import Translate from "../../components/LanguageTranslation/Translate";

const ChatHeader = ({ conversationId }) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false); // State to track save operation
  const [isSignUpOpen, setSignUpOpen] = useState(false); // Add state for AuthModal

  const handleSaveChat = async () => {
    if (!conversationId) {
      toast({
        title: "Error",
        description: "No conversation to save.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsSaving(true); // Set loading state to true

    try {
      const response = await axiosInstance.post("/bucket-list/save", {
        conversation_id: conversationId,
      });

      if (response.data.success) {
        toast({
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Please complete your trip planning before saving.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setSignUpOpen(true); // Open AuthModal on 401 error
      } else {
        toast({
          title: "Error",
          description: error.response.data.message || "Error while saving the trip",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setIsSaving(false); // Reset loading state
    }
  };

  return (
    <>
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        maxH="8vh"
        borderBottom="1px"
        borderColor="blackAlpha.200"
      >
        <Box
          display="flex"
          alignItems="center"
          bg={colorMode === "dark" ? "gray.700" : "white"}
          width="100%"
          height="100%"
          p={4}
          boxSizing="border-box"
        >
          <Text
            fontSize="md"
            fontWeight="bold"
            mb={0}
            color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
          >
            
            <Translate text="New Chat" />
          </Text>
          <Spacer />
          <Button
            size="sm"
            bg="#286328"
            _hover="none"
            _active="none"
            textColor="white"
            borderRadius="lg"
            onClick={handleSaveChat}
            isLoading={isSaving} // Adds a spinner and disables the button when loading
            loadingText="Saving"
            spinner={<Spinner size="sm" />} // Custom spinner size
          >
            
            <Translate text="Save Trip" />
          </Button>
          <Box>
            <SettingsMenu />
          </Box>
        </Box>
      </Box>
      <AuthModal isOpen={isSignUpOpen} onClose={() => setSignUpOpen(false)} redirectPath="/ava/chat" />
    </>
  );
};

export default ChatHeader;
