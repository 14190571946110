import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  Text,
  IconButton,
  Spacer,
  VStack,
  Icon,
  Avatar,
  Button,
  Input,
  Flex,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import {
  FaTrash,
  FaMapMarkerAlt,
  FaHotel,
  FaCar,
  FaUtensils,
  FaShoppingBag,
  FaGlassCheers,
  FaGift,
  FaTshirt,
  FaCamera,
  FaTools,
  FaPlane,
} from "react-icons/fa"; // Importing all the icons at once
import axiosInstance from "../../helpers/axiosInstance";
import { useCurrency } from "../CurrencyExchange/CurrencyContext";
import BudgetManager from "./BudgetManager";
import moment from "moment"; // For date formatting and sorting
import Translate from "../../components/LanguageTranslation/Translate";

const categoryIcons = {
  Places: { icon: FaMapMarkerAlt, color: "#FF6384" }, // Pink
  Accommodations: { icon: FaHotel, color: "#36A2EB" }, // Blue
  Transportation: { icon: FaCar, color: "#FFCE56" }, // Yellow
  Food: { icon: FaUtensils, color: "#4CAF50" }, // Green
  Shopping: { icon: FaShoppingBag, color: "#FF9800" }, // Orange
  Entertainment: { icon: FaGlassCheers, color: "#FFC107" }, // Amber
  Gifts: { icon: FaGift, color: "#AB47BC" }, // Purple
  Clothing: { icon: FaTshirt, color: "#FF7043" }, // Deep Orange
  Photography: { icon: FaCamera, color: "#FF5722" }, // Orange Red
  Tools: { icon: FaTools, color: "#757575" }, // Grey
  Flights: { icon: FaPlane, color: "#29B6F6" }, // Light Blue
};

const ExpensesList = ({
  expenses,
  setExpenses,
  selectedTripId,
  onOpen,
  setNewExpenseName,
  setNewCost,
  setSelectedCategory,
  setSelectedDate,
  setSelectedLocation,
  setEditingExpenseIndex,
}) => {
  const { convertCurrency, currency } = useCurrency();

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await axiosInstance.get(
          `/budget_planner/${selectedTripId}/expenses`
        );
        console.log("fetchExpenses",response.data)
        setExpenses(response.data);
      } catch (error) {
        console.error("Error fetching expenses:", error);
      }
    };

    if (selectedTripId) {
      fetchExpenses();
    }
  }, [selectedTripId]);

  // Calculate total expenses
  const total = expenses.reduce((acc, categoryWithExpenses) => {
    return (
      acc +
      categoryWithExpenses.expenses.reduce(
        (categoryAcc, expense) => categoryAcc + parseFloat(expense.cost),
        0
      )
    );
  }, 0);

  // Helper function to group expenses by date
  const groupExpensesByDate = (expensesList) => {
    // console.log("expensesList", expensesList);
    return expensesList.reduce((groupedExpenses, categoryWithExpenses) => {
      categoryWithExpenses.expenses.forEach((expense) => {
        const expenseDate = moment(expense.expense_date).format("YYYY-MM-DD"); // Group by date
        if (!groupedExpenses[expenseDate]) {
          groupedExpenses[expenseDate] = [];
        }
        groupedExpenses[expenseDate].push({
          ...expense,
          category: categoryWithExpenses.category_name,
          editable: categoryWithExpenses.editable, // Include the editable field
        });
      });
      console.log("groupedExpenses", groupedExpenses);
      return groupedExpenses;
    }, {});
  };

  // Group expenses by date
  const groupedExpenses = groupExpensesByDate(expenses);

  // Sort dates in descending order (latest first)
  const sortedDates = Object.keys(groupedExpenses).sort(
    (a, b) => new Date(b) - new Date(a)
  );

  const openEditExpenseModal = (expenseIndex, expense, category) => {
    setNewExpenseName(expense.expense_name);
    setNewCost(expense.cost);
    setSelectedCategory(category); // Ensure this is properly set
    setSelectedDate(expense.expense_date);
    setSelectedLocation(expense.expense_location);
    setEditingExpenseIndex(expenseIndex);
    onOpen(); // Open the modal
  };

  const deleteExpense = async (expenseName, category) => {
    try {
      await axiosInstance.delete(
        `/budget_planner/${selectedTripId}/delete_expense`,
        {
          data: { category, expense_name: expenseName },
        }
      );
      const response = await axiosInstance.get(
        `/budget_planner/${selectedTripId}/expenses`
      );
      setExpenses(response.data);
    } catch (error) {
      console.error("Error deleting expense:", error);
    }
  };

  return (
    <Box
      flex="5"
      w="50%"
      display="flex"
      flexDirection="column"
      p={2}
      bg="#FAFDFC"
    >
      <BudgetManager
        total={total}
        currency={currency}
        convertCurrency={convertCurrency}
        selectedTripId={selectedTripId}
      />
      <Box
        mt={2}
        p={4}
        maxH="90vh"
        bg="white"
        borderRadius="xl"
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {sortedDates.map((date) => (
          <Box key={date}>
            {/* Date Header */}
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              {moment(date).format("dddd, Do MMMM YYYY")}{" "}
              {/* Format the date */}
            </Text>

            {/* Expenses under this date */}
            {groupedExpenses[date].map((expense, expIndex) => {
              const convertedCost = parseFloat(
                convertCurrency(expense.cost, "USD")
              );
              const categoryData = categoryIcons[expense.category] || {};

              return (
                <Box
                  key={expIndex}
                  p={4}
                  bg="white"
                  rounded="lg"
                  shadow="lg"
                  mb={6}
                >
                  <HStack justify="space-between" alignItems="center">
                    <HStack>
                      <Avatar
                        icon={<Icon as={categoryData.icon} />}
                        bg={categoryData.color}
                        size="sm"
                        mr={2}
                      />
                      <VStack spacing={0} align="start">
                        <Text fontWeight="bold" mb={0}>
                          <Translate text={expense.expense_name} />
                        </Text>
                        <Text fontSize="sm" color="gray.500" mb={0}>
                          <Translate text={expense.category} />
                        </Text>
                      </VStack>
                    </HStack>
                    <Spacer />
                    <Text fontWeight="bold" mr={2} mb={0}>
                      {currency} {convertedCost?.toFixed(2) ?? "0.00"}
                    </Text>
                    {expense.editable && (
                      <>
                        <IconButton
                          icon={<EditIcon />}
                          size="xs"
                          onClick={() =>
                            openEditExpenseModal(
                              expIndex,
                              expense,
                              expense.category
                            )
                          }
                        />
                        <IconButton
                          icon={<FaTrash />}
                          size="xs"
                          onClick={() =>
                            deleteExpense(
                              expense.expense_name,
                              expense.category
                            )
                          }
                        />
                      </>
                    )}
                  </HStack>
                </Box>
              );
            })}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ExpensesList;
