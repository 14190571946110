import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Text,
  Spacer,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../helpers/axiosInstance"; // Adjust import path for axios
import SettingsMenu from "../../components/common/SettingsMenu";

const Header = ({ saveSurvey }) => {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const toast = useToast();
  const [isPublished, setIsPublished] = useState(false); // Track survey publish status
  const [isLoading, setIsLoading] = useState(false); // Track loading state

  // Fetch the current survey status
  useEffect(() => {
    const fetchSurveyStatus = async () => {
      try {
        const response = await axiosInstance.get("/survey/fetch");
        if (response.status === 200 && response.data.survey) {
          setIsPublished(response.data.survey.publish);
        }
      } catch (error) {
        console.error("Error fetching survey status: ", error);
      }
    };

    fetchSurveyStatus();
  }, []);

  const handleButtonClick = () => {
    history.push("/feedback-response");
  };

  //   const togglePublishSurvey = async () => {
  //     setIsLoading(true); // Start loading
  //     try {
  //       const response = await axiosInstance.post("/survey/publish", {
  //         publish: !isPublished, // Toggle publish status
  //       });
  //       if (response.status === 200) {
  //         setIsPublished(!isPublished); // Update the local state
  //         toast({
  //           title: `Survey ${
  //             !isPublished ? "published" : "unpublished"
  //           } successfully!`,
  //           status: "success",
  //           duration: 3000,
  //           isClosable: true,
  //         });
  //       }
  //     } catch (error) {
  //       console.error(
  //         `Error ${isPublished ? "unpublishing" : "publishing"} survey: `,
  //         error
  //       );
  //       toast({
  //         title: "Error",
  //         description: `Failed to ${
  //           isPublished ? "unpublish" : "publish"
  //         } the survey.`,
  //         status: "error",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //     } finally {
  //       setIsLoading(false); // Stop loading
  //     }
  //   };

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      pb={0}
      maxH="8vh"
      borderBottom="1px"
      borderColor="blackAlpha.200"
      overflowY="hidden"
    >
      <Box
        display="flex"
        alignItems="center"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        width="100%"
        height="100%"
        p={4}
        boxSizing="border-box"
        overflowY="hidden"
      >
        <Text
          fontSize="md"
          fontWeight="bold"
          mb={0}
          mt={0}
          color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
        >
          Feedback
        </Text>
        <Spacer />
        {/* Save survey button */}
        <Button
          border="1px solid"
          bg="#286328"
          borderColor="#286328"
          color="white"
          _hover="none"
          onClick={saveSurvey}
          size="sm"
        >
          Save Survey
        </Button>
        {/* <Button
          bg={isPublished ? "red" : "#286328"} // Toggle color based on publish state
          color="white"
          _hover="none"
          size="sm"
          ml={2}
          onClick={togglePublishSurvey}
          isLoading={isLoading} // Show loading state during the API call
        >
          {isPublished ? "Unpublish" : "Publish"}
        </Button> */}
        <Button
          border="1px solid"
          bg="none"
          borderColor="#286328"
          color="black"
          _hover="none"
          size="sm"
          onClick={handleButtonClick}
          ml={2}
        >
          Response
        </Button>
        <SettingsMenu />
      </Box>
    </Box>
  );
};

export default Header;
