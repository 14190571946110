import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  HStack,
  Image,
  Spacer,
  Divider, // Import Divider for the separating line
} from "@chakra-ui/react";
import { ArrowBackIcon, CalendarIcon, TimeIcon } from "@chakra-ui/icons"; // Import Chakra UI icons
import { MdLocationOn,MdToday } from "react-icons/md"; // Import a React icon for the destination
import { FaWalking } from "react-icons/fa"; // Example walking icon for days
import { useParams, useHistory } from "react-router-dom"; // Import useParams and useHistory
import axiosInstance from "../../helpers/axiosInstance"; // Your axios instance setup
import SettingsMenu from "../common/SettingsMenu";
import Translate from "../../components/LanguageTranslation/Translate";

const BackToTrips = () => {
  const { tripId } = useParams(); // Get the tripId from the URL
  const history = useHistory(); // To handle back navigation
  const [journey, setJourney] = useState(null); // State to store fetched trip data
  const [tripDetails, setTripDetails] = useState(null); // State to store fetched trip details data

  // Function to handle back navigation
  const handleBackToJourneys = () => {
    history.goBack(); // Navigate back to the previous page
  };

  useEffect(() => {
    const fetchTripById = async () => {
      try {
        const response = await axiosInstance.get(`/bucket-list/${tripId}`);
        const trip = response.data.data.trip_plan; // Fetch the trip plan data
        const details = response.data.data.trip_details; // Fetch the trip details
        if (trip) {
          setJourney(trip); // Set the trip data in state
          setTripDetails(details); // Set the trip details data in state
        } else {
          console.log("No trip found with the given ID");
        }
      } catch (error) {
        console.error("Error fetching trip by ID:", error);
      }
    };

    fetchTripById();
  }, [tripId]);

  if (!journey || !tripDetails) {
    return <Text>Loading...</Text>; // Show a loading state if data is not fetched yet
  }

  // Function to format the departure date into month (as text) and year
  const formatMonthYear = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'long' }); // Get the month name
    const year = date.getFullYear(); // Get the year
    return { month, year };
  };

  // Extract month and year from departure date
  const { month, year } = formatMonthYear(tripDetails.departure_date);

  return (
    <Box w="full" h="100vh">
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        pb={1}
        h="8vh"
        borderBottom="1px"
        borderColor="blackAlpha.200"
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          height="100%"
          p={4}
          pt={2}
          pl={0}
          pb={2}
          boxSizing="border-box"
        >
          <Button
            leftIcon={<ArrowBackIcon />}
            onClick={handleBackToJourneys}
            mt={1}
            bg="transparent"
            _hover="none"
            _active="none"
          >
            
            <Translate text="Back" />
          </Button>

          <Spacer />
          <HStack spacing={4} mt={1}>
            <Button
              colorScheme="teal"
              size="sm"
              variant="outline"
              onClick={() => alert("Edit functionality coming soon!")}
            >
               <Translate text="Edit" />
            </Button>
            <Button
              colorScheme="red"
              size="sm"
              variant="outline"
              onClick={() => alert("Delete functionality coming soon!")}
            >
               <Translate text="Delete" />
            </Button>
            <SettingsMenu />
          </HStack>
        </Box>
      </Box>

      <Box p={3} pb={1} overflowY="auto" h="90vh" position="relative">
        {/* Image with overlay text */}
        <Box position="relative" h="70vh" w="100%" mb={0}>
          <Image
            src={
              journey.itinerary[0]?.activity_detail_list[0]?.thumbnailHiResURL ||
              "/placeholder-image.png"
            } // Fallback if image is unavailable
            alt={journey.trip_name}
            borderTopRadius="md"
            objectFit="cover"
            h="100%"
            w="100%"
            filter="blur(1px)"
          />

          {/* Overlay Box */}
          <Box
            position="absolute"
            top="0"
            left="0"
            p={5}
            bg="rgba(0, 0, 0, 0.5)" // Semi-transparent background
            color="white"
            borderTopRadius="md"
            maxW="50%"
            h="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="left"
            textAlign="left" // Center text horizontally
          >
            <Text fontSize="2xl" fontWeight="bold" mb={2}>
              {journey.trip_name} {/* Display trip name */}
            </Text>
            <Text fontSize="md">
              {journey.description} {/* Display trip description */}
            </Text>
          </Box>
        </Box>

        {/* Other Details */}
        <VStack
          align="start"
          spacing={4}
          bg="#262B2F" // Add semi-transparent dark background
          p={4} // Add padding to give space between text and background
          pl={8}
          pr={8}
          h="17vh"
          borderBottomRadius="md" // Add slight rounding to the edges
          color="white" // Set text color to white for contrast
        >
          <HStack spacing={10} w="full" justifyContent="space-between">
            {/* Centered Days Box */}
            <Box display="flex" flexDirection="column" alignItems="center">
              <MdToday  size={26} /> {/* Add icon above the value */}
              <Text fontWeight="bold" mt={2} mb={0}>{tripDetails.days[0]}</Text>
              <Text fontSize="sm" mb={0}><Translate text="Days" /></Text>
            </Box>
            <Divider orientation="vertical" height="50px" color="whiteAlpha.400" /> {/* Vertical Divider */}
            
            {/* Centered Month/Year Box */}
            <Box display="flex" flexDirection="column" alignItems="center">
              <CalendarIcon boxSize={6} /> {/* Add icon above the value */}
              <Text fontWeight="bold" mt={2} mb={1}>{year}</Text>
              <Text fontSize="sm" mb={0}>{month}</Text>
            </Box>
            <Divider orientation="vertical" height="50px" color="whiteAlpha.400" /> {/* Vertical Divider */}
            
            {/* Centered Departure Date Box */}
            <Box display="flex" flexDirection="column" alignItems="center">
              <TimeIcon boxSize={6} /> {/* Add icon above the value */}
              <Text fontWeight="bold" mt={2} mb={1}>{tripDetails.departure_date}</Text>
              <Text fontSize="sm" mb={0}><Translate text="Departure Date" /></Text>
            </Box>
            <Divider orientation="vertical" height="50px" color="whiteAlpha.400" /> {/* Vertical Divider */}
            
            {/* Centered Location Box */}
            <Box display="flex" flexDirection="column" alignItems="center">
              <MdLocationOn size={24} /> {/* Add icon above the value */}
              <Text fontWeight="bold" mt={2} mb={1}>{tripDetails.destination.join(', ')}</Text>
              <Text fontSize="sm" mb={0}><Translate text="Destination" /></Text>
            </Box>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};

export default BackToTrips;
