// DonationFailure.js
import React from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import banner from "../../assets/images/donation_banner.jpg";

const DonationFailure = () => (
  <Box
    h="100vh"
    w="100vw"
    bgImage={`url(${banner})`}
    bgSize="cover"
    bgPosition="center"
    display="flex"
    alignItems="center"
    justifyContent="center"
    p={6}
    color="white"
  >
    <Box
      bg="rgba(0, 0, 0, 0.7)"
      borderRadius="lg"
      p={10}
      maxW="500px"
      textAlign="center"
    >
      <VStack spacing={4}>
        <Heading size="xl" mb={4}>
          Donation Failed
        </Heading>
        <Text fontSize="lg">
          We couldn’t process your donation. Please try again, or contact support if you continue to have issues.
        </Text>
      </VStack>
    </Box>
  </Box>
);

export default DonationFailure;
