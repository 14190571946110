import React, { useState } from "react";
import { Box, VStack, Flex, Text, Image, IconButton } from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import Translate from "../../../components/LanguageTranslation/Translate";

const Posts = ({ posts, setPosts, colorMode }) => {
  const [current, setCurrent] = useState(0);
  const numVisibleCards = 4;
  const history = useHistory();

  const next = () => {
    if (hasNext) {
      setCurrent((prev) => prev + numVisibleCards);
    }
  };

  const prev = () => {
    if (hasPrev) {
      setCurrent((prev) => prev - numVisibleCards);
    }
  };

  const hasPrev = current > 0;
  const hasNext = current + numVisibleCards < posts.length;

  const handlePostClick = (postId) => {
    history.push(`/post/${postId}`);
  };

  return (
    <VStack flex={1} align="start" spacing={1} mb={6}>
      <Text fontSize="xl" fontWeight="bold" mb={0}>
         <Translate text="Trips" />
      </Text>
      {posts.length === 0 ? (
        // Display "Haven't posted yet" when no posts are available
        <Text fontSize="lg" color="gray.500" mt={4}>
           <Translate text="Haven't posted yet" />
        </Text>
      ) : (
        <Flex
          position="relative"
          alignItems="center"
          justifyContent="center"
          width="full"
        >
          {hasPrev && (
            <IconButton
              icon={<ArrowBackIcon />}
              isRound={true}
              size="lg"
              onClick={prev}
              position="absolute"
              left="10px"
              backgroundColor="white"
              boxShadow="base"
              zIndex="overlay"
            />
          )}
          <Flex overflow="hidden" width="full" p={2}>
            {posts.slice(current, current + numVisibleCards).map((post) => (
              <Box
                key={post.id}
                onClick={() => handlePostClick(post.id)}
                cursor="pointer"
                p={2}
                bg={colorMode === "dark" ? "gray.700" : "gray.100"}
                borderRadius="md"
                boxShadow="md"
                mr={4}
                _hover={{
                  bg: colorMode === "dark" ? "gray.600" : "gray.200",
                }}
                flex="0 0 auto"
                width="24%"
              >
                <Image
                  src={post.image}
                  alt={post.title}
                  borderRadius="md"
                  mb={3}
                  h="25vh"
                  w="100%"
                />
                <Box p={2}>
                  <Text
                    noOfLines={1}
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    fontWeight="bold"
                    fontSize="lg"
                    dangerouslySetInnerHTML={{ __html: post.title }}
                    mb={0}
                  />

                  <Text
                    noOfLines={2}
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    fontSize="sm"
                    dangerouslySetInnerHTML={{ __html: post.description }}
                    mb={2}
                  />
                  <Text fontSize="xs" color="gray.500" mb={0}>
                    <Translate text="Posted on:" />{" "}
                    {new Date(post.datePosted).toLocaleDateString()}
                  </Text>
                </Box>
              </Box>
            ))}
          </Flex>
          {hasNext && (
            <IconButton
              icon={<ArrowForwardIcon />}
              isRound={true}
              size="lg"
              onClick={next}
              position="absolute"
              right="10px"
              backgroundColor="white"
              boxShadow="base"
              zIndex="overlay"
            />
          )}
        </Flex>
      )}
    </VStack>
  );
};

export default Posts;
