import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Button,
  Input,
  Select,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { getIconForCategory, members } from "./utils";
import axiosInstance from "../../helpers/axiosInstance";
import Translate from "../../components/LanguageTranslation/Translate";

const PackingListModal = ({
  isModalOpen,
  closeModal,
  setCategoryItems,
  setPackingCategories,
  toast,
  selectedTrip,
  fetchPackingLists,
  setPackingLists,
}) => {
  const [listName, setListName] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [newItems, setNewItems] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [currentItem, setCurrentItem] = useState(""); // Input field for current item

  const handleSaveList = async () => {
    if (!listName || !newCategory || newItems.length === 0 || !selectedMember) {
      toast({
        title: "Error",
        description: "Please fill in all fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const payload = {
      packing_list_name: listName,
      trip_id: selectedTrip,
      trip_companion: selectedMember,
      packing_category: newCategory,
      packing_list_items: newItems.map((item) => ({
        name: item,
        completed: false,
      })),
    };

    try {
      const response = await axiosInstance.post("/packing_lists/", payload);
      if (response.data.success) {
        setCategoryItems((prev) => ({
          ...prev,
          [newCategory]: newItems.map((item) => ({
            name: item,
            completed: false,
          })),
        }));

        setPackingCategories((prev) => [
          ...prev,
          {
            name: newCategory,
            icon: getIconForCategory(newCategory),
            completed: 0,
            total: newItems.length,
          },
        ]);

        fetchPackingLists(setPackingLists, toast);

        toast({
          title: "Success",
          description: "Packing list created successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setListName("");
        setNewCategory("");
        setNewItems([]);
        setSelectedMember("");
        setCurrentItem(""); // Reset current item input field
        closeModal();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to create packing list.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddItem = () => {
    if (currentItem.trim() !== "") {
      setNewItems([...newItems, currentItem.trim()]);
      setCurrentItem(""); // Clear input after adding
    }
  };

  const handleRemoveItem = (itemToRemove) => {
    setNewItems(newItems.filter((item) => item !== itemToRemove));
  };

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent
        maxH="77vh"
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <ModalHeader>
          <Translate text="Add Packing List" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="start">
            <Input
              placeholder={Translate({
                text: "Enter New Category",
                as: "string",
              })}
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              size="sm"
              borderRadius="xl"
            />
            <Input
              placeholder={Translate({
                text: "List Name",
                as: "string",
              })}
              value={listName}
              onChange={(e) => setListName(e.target.value)}
              size="sm"
              borderRadius="xl"
            />
            <Select
              placeholder={Translate({
                text: "Select Companion",
                as: "string",
              })}
              onChange={(e) => setSelectedMember(e.target.value)}
              value={selectedMember}
              size="sm"
              borderRadius="xl"
            >
              {members.map((member) => (
                <option key={member.value} value={member.value}>
                  <Translate text={member.name} />
                </option>
              ))}
            </Select>

            <Input
              placeholder={Translate({
                text: "Add Item",
                as: "string",
              })}
              value={currentItem}
              onChange={(e) => setCurrentItem(e.target.value)}
              size="sm"
              borderRadius="xl"
              onKeyDown={(e) => e.key === "Enter" && handleAddItem()}
            />

            <Wrap>
              {newItems.map((item, index) => (
                <WrapItem key={index}>
                  <Tag
                    size="sm"
                    borderRadius="full"
                    variant="solid"
                    bg="#286328"
                    color="white"
                    key={item}
                  >
                    <TagLabel>{item}</TagLabel>
                    <TagCloseButton onClick={() => handleRemoveItem(item)} />
                  </Tag>
                </WrapItem>
              ))}
            </Wrap>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#286328"
            color="white"
            _hover="none"
            mr={3}
            onClick={handleSaveList}
            borderRadius="lg"
          >
            <Translate text="Save" />
          </Button>
          <Button onClick={closeModal} borderRadius="lg">
            {" "}
            <Translate text="Cancel" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PackingListModal;
