import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";  // Import Axios

// List of routes to be excluded from tracking
const excludedRoutes = [
  "/",
  "/settings/privacy",
  "/feedback-response",
  "/feedback",
  "/userform",
  "/newsletters",
  "/admin/all-blog",
  "/admin/blog/filters",
  "/admin/analytics",
  "/admin/blog/upload",
  "/filter",
  "/admin_panel",
  "/forgot_password",
  "/account",
  "/post/",
];

// Hook to track time spent on a page and visit counts
const usePageTracking = () => {
  const location = useLocation();
  const [startTime, setStartTime] = useState(null);
  const [visitCounts, setVisitCounts] = useState(() => {
    // Initialize visit counts from localStorage or empty object
    const storedCounts = localStorage.getItem("visitCounts");
    return storedCounts ? JSON.parse(storedCounts) : {};
  });

  useEffect(() => {
    const start = Date.now(); // Start time
    setStartTime(start);

    const currentPath = location.pathname;

    // Filter out excluded routes
    if (
      excludedRoutes.includes(currentPath) ||
      currentPath.startsWith("/backtotrips/") ||
      currentPath.startsWith("/itinerary_details/") ||
      currentPath.startsWith("/auth/reset_password/") ||
      currentPath.startsWith("/admin/update-blog/") ||
      currentPath.startsWith("/search-user/") 
      // ||
      // (currentPath.startsWith("/blog/") && currentPath.split("/").length > 2) // Exclude blog routes with slug
    ) {
      // console.log(`Excluded route: ${currentPath}`);
      return; // Skip tracking for excluded routes
    }

    // Update visit counts in local state and localStorage
    const updatedCounts = {
      ...visitCounts,
      [currentPath]: (visitCounts[currentPath] || 0) + 1,
    };
    setVisitCounts(updatedCounts);
    localStorage.setItem("visitCounts", JSON.stringify(updatedCounts));

    // When the user leaves the page (on unmount), calculate time spent
    return () => {
      const end = Date.now(); // End time
      const timeSpent = Math.floor((end - start) / 1000); // Time spent in seconds

      // console.log(`Time spent on ${currentPath}: ${timeSpent} seconds`);
      // console.log(`Total visits to ${currentPath}: ${updatedCounts[currentPath] || 0}`);

      // Send the time spent and visit data to the backend
      axios
        .post("/time_tracking/track", {
          page: currentPath,
          timeSpent: timeSpent,
          visits: updatedCounts[currentPath] || 1, // Send updated visit count
        })
        .then((response) => {
          // console.log("Time tracking data successfully sent to the backend.");
        })
        .catch((error) => {
          console.error("Error sending time tracking data:", error);
        });
    };
  }, [location]);

  return { visitCounts }; // Return visit counts for use elsewhere if needed
};

export default usePageTracking;
