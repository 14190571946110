import React, { useEffect, useState } from "react";
import { useTranslation } from "./TranslationContext";

const Translate = ({ text, as = "component" }) => {
  const { useTranslate } = useTranslation();
  const translatedText = useTranslate(text);

  if (as === "component") {
    return <>{translatedText}</>;
  }

  return translatedText;
};

export default Translate;
