import React, { useState } from "react";
import {
  Box,
  List,
  Image,
  Flex,
  IconButton,
  Avatar,
  Link as ChakraLink,
  Text,
  Divider,
  VStack,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Skeleton,
  useColorMode,
  useDisclosure,
  Spacer,
} from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { FiMessageSquare } from "react-icons/fi";
import { FaHeart, FaLocationArrow } from "react-icons/fa";
import { useAuth, logout } from "../auth";
import { useGoogleAuth } from "./common/google/GoogleAuthContext";
import { useUser } from "./userRole/UserContext";
import { useOTP } from "./AdminPanelAccess/OTPContext";
import SidebarItem from "./SidebarItem";
import logo from "../assets/images/logo1.png";
import openIcon from "../assets/icons/sidebar-open.svg";
import closeIcon from "../assets/icons/sidebar-close.svg";
import { FaSuitcase, FaWallet } from "react-icons/fa";
import { ReactComponent as HomeIcon } from "../assets/icons/home_house_icon.svg";
import { ReactComponent as ChatIcon } from "../assets/icons/chat_fill_message_communication_icon.svg";
import { ReactComponent as TravelTrackerIcon } from "../assets/icons/passport.svg";
import { ReactComponent as PackingListIcon } from "../assets/icons/fluent_clipboard_task_list_icon.svg";
import { ReactComponent as BudgetPlannerIcon } from "../assets/icons/budget.svg";
import { ReactComponent as ItineraryIcon } from "../assets/icons/noun-travel-itinerary-4236219.svg";
import { ReactComponent as DonateIcon } from "../assets/icons/donate.svg";
import { ReactComponent as DonateWhiteIcon } from "../assets/icons/donate-white.svg";
import AuthModal from "./AuthModal";
import Translate from "../components/LanguageTranslation/Translate";

function SideBar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const [logged] = useAuth();
  const { resetOTP } = useOTP();
  const { authState } = useGoogleAuth();
  const { user, loading } = useUser();
  const [redirectPath, setRedirectPath] = useState("/travel-planner");

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const isUserLoggedIn = logged || authState.isLoggedIn;

  const username = localStorage.getItem("username") || "";
  const email = localStorage.getItem("email") || "unknown@example.com";

  const logoutAndClear = () => {
    logout();
    const keysToRemove = [
      "username",
      "email",
      "perPage",
      "perPageFilter",
      "nPXg",
      "chat_messages",
      "conversation_id",
      "OTP_TOKEN,",
      "profilepic",
      "ongoingTrip",
      "selectedTab",
      "selectedTripPackingList",
      "selectedTripBudgetPlanner",
    ];
    keysToRemove.forEach((key) => localStorage.removeItem(key));
    resetOTP();
    history.push("/login");
  };

  const role = user ? user.role : null;

  let navLinks = [];

  const openAuthModal = (redirectPath) => {
    onOpen();
    setRedirectPath(redirectPath);
  };

  if (loading) {
    navLinks = [
      { to: "#", text: <Skeleton width="100px" height="20px" /> },
      { to: "#", text: <Skeleton width="100px" height="20px" /> },
      { to: "#", text: <Skeleton width="100px" height="20px" /> },
    ];
  } else if (isUserLoggedIn) {
    navLinks.push({
      to: "/travel-planner",
      text: "Ask Ava",
      icon: HomeIcon,
    });
    navLinks.push({ to: "/ava/chat", text: "Chat", icon: ChatIcon });
    navLinks.push({ to: "/bucketlists", text: "Bucketlist", icon: FaHeart });

    navLinks.push({
      to: "/itinerary",
      text: "Itinerary",
      icon: ItineraryIcon,
    });
    navLinks.push({
      to: "/budget",
      text: "Budget Planner",
      icon: FaWallet,
    });
    navLinks.push({
      to: "/packingLists",
      text: "Packing List",
      icon: FaSuitcase,
    });
    // navLinks.push({
    //   to: "/travel_tracker",
    //   text: "Travel Vibes",
    //   icon: TravelTrackerIcon,
    // });
    navLinks.push({
      to: "#",
      text: (
        <Box position="relative" display="inline-block" width="auto">
          <Text display="inline"><Translate text="Travel Vibes" /></Text>
          <Box
            position="absolute"
            top={-3} // Adjust to move "Beta" slightly above
            right={-10} // Adjust for horizontal position
            bg="blackAlpha.100" // Similar to the pink color in the image
            color="black"
            fontWeight="bold"
            fontSize="10px"
            px={2}
            py={0.5}
            borderRadius="lg"
            display="inline"
          >
            <Translate text="BETA" />
          </Box>
        </Box>
      ),
      icon: TravelTrackerIcon,
    });
  } else {
    navLinks.push({
      to: "/travel-planner",
      text: "Ask Ava",
      icon: HomeIcon,
    });
    navLinks.push({ to: "/ava/chat", text: "Chat", icon: ChatIcon });

    navLinks.push({
      to: "/bucketlists",
      text: "Bucketlist",
      icon: FaHeart,
      onClick: () => openAuthModal("/bucketlists"), // Open auth modal for non-authenticated users
    });
    navLinks.push({
      to: "/itinerary",
      text: "Itinerary",
      icon: ItineraryIcon,
      onClick: () => openAuthModal("/itinerary"), // Open auth modal for non-authenticated users
    });
    navLinks.push({
      to: "/budget",
      text: "Budget Planner",
      icon: BudgetPlannerIcon,
      onClick: () => openAuthModal("/budget"), // Open auth modal for non-authenticated users
    });
    navLinks.push({
      to: "/packingLists",
      text: "Packing List",
      icon: PackingListIcon,
      onClick: () => openAuthModal("/packingLists"), // Open auth modal for non-authenticated users
    });
    // navLinks.push({
    //   to: "/travel_tracker",
    //   text: "Travel Vibes",
    //   icon: TravelTrackerIcon,
    //   onClick: () => openAuthModal("/travel_tracker"), // Open auth modal for non-authenticated users
    // });
    navLinks.push({
      to: "#",
      text: (
        <Box
          position="relative"
          display="inline-block"
          width="auto"
          onClick={() => openAuthModal("/travel_tracker")} // Corrected onClick syntax
        >
          <Text display="inline"><Translate text="Travel Vibes" /></Text>
          <Box
            position="absolute"
            top={-3} // Adjust to move "Beta" slightly above
            right={-10} // Adjust for horizontal position
            bg="#1A202C" // Background color for the Beta tag
            color="white"
            fontSize="10px"
            px={2}
            py={0.5}
            borderRadius="lg"
            display="inline"
          >
            <Translate text="BETA" />
          </Box>
        </Box>
      ),
      icon: TravelTrackerIcon,
    });
  }

  const popoverLinks = isUserLoggedIn
    ? [
        ...(role === "Admin" || role === "User"
          ? [{ to: "/account", text: "Account" }]
          : []),
        ...(role === "Admin" ? [{ to: "/admin_panel", text: "Users" }] : []),
        ...(role === "Admin"
          ? [{ to: "/feedback", text: "Feedback Surveys" }]
          : []),
        ...(role === "Admin"
          ? [{ to: "/newsletters", text: "Newsletters" }]
          : []),
        ...(role === "Admin" ? [{ to: "/userform", text: "User Form" }] : []),
        ...(role === "Admin" ? [{ to: "/filter", text: "Users Filter" }] : []),
        ...(role === "Admin"
          ? [{ to: "/admin/blog/upload", text: "Upload Blogs" }]
          : []),
        ...(role === "Admin"
          ? [{ to: "/admin/analytics", text: "Analytics" }]
          : []),
      ]
    : [];

  return (
    <Box
      width={isSidebarOpen ? "17%" : "5%"}
      h="100vh"
      bg={colorMode === "dark" ? "gray.800" : "white"}
      color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
      borderRight="1px"
      borderColor="blackAlpha.200"
    >
      <Flex
        justifyContent={isSidebarOpen ? "space-between" : "center"}
        alignItems="center"
        p="4"
        borderBottom="1px"
        borderColor="blackAlpha.200"
        h="8vh"
      >
        {isSidebarOpen && (
          <Link to="/home">
            <Image src={logo} height="23px" />
          </Link>
        )}
        <IconButton
          icon={
            <Image src={isSidebarOpen ? closeIcon : openIcon} height="24px" />
          }
          onClick={toggleSidebar}
          bg="white"
          aria-label="Toggle Sidebar"
          size="sm"
          _hover={{ bg: "transparent" }}
        />
      </Flex>
      <Box
        h={
          isSidebarOpen
            ? isUserLoggedIn
              ? "70vh"
              : "75vh"
            : isUserLoggedIn
            ? "75vh"
            : "75vh"
        }
        pl={0}
        pt={isSidebarOpen ? 8 : 8}
      >
        <List spacing={isSidebarOpen ? "4" : "4"} pl={isSidebarOpen ? 0 : 0}>
          {navLinks.map((link, index) => (
            <SidebarItem
              key={index}
              icon={link.icon}
              to={link.to}
              label={link.text}
              isOpen={isSidebarOpen}
              ml={isSidebarOpen ? 5 : 3}
              onClick={link.onClick}
            />
          ))}
          {/* Add Donate link here, only visible when sidebar is closed */}
          {!isSidebarOpen && (
            <SidebarItem
              icon={DonateIcon}
              to="/donations"
              label="Donate"
              isOpen={isSidebarOpen}
              ml={3}
              onClick={isUserLoggedIn ? null : () => openAuthModal("/donations")}
            />
          )}
        </List>
      </Box>

      {isUserLoggedIn && (
        <Box
          p={isSidebarOpen ? 4 : 4}
          h="6vh"
          pl={isSidebarOpen ? 3 : 0}
          pt={isSidebarOpen ? 8 : 8}
        >
          {isSidebarOpen && (
            <Flex align="center" justifyContent="center" mb={4}>
              <Button
                leftIcon={<DonateWhiteIcon width="24px" height="24px" />}
                as={Link}
                to="/donations"
                bg="#286328"
                color="white"
                size="sm"
                _hover="none"
                width="80%"
              >
                <Translate text="Donate" />
              </Button>
            </Flex>
          )}
          <Flex align="center" justifyContent="center" p={2}>
            <Popover>
              <PopoverTrigger>
                <Flex alignItems="center" cursor="pointer">
                  <Avatar
                    name={username}
                    src="https://bit.ly/broken-link"
                    size="sm"
                    bg="#286328"
                    color="white"
                    pt="2px"
                  />
                  {isSidebarOpen && (
                    <Box ml={2}>
                      <Text fontWeight="bold" mb={0} ml={2}>
                        {username}
                      </Text>
                    </Box>
                  )}
                </Flex>
              </PopoverTrigger>
              <PopoverContent
                bg={colorMode === "light" ? "white" : "gray.700"}
                borderRadius="xl"
                borderColor={colorMode === "light" ? "gray.200" : "gray.600"}
                boxShadow="2xl"
                w="100%"
                height="auto"
                overflowY="hidden"
                color={colorMode === "light" ? "gray.800" : "gray.200"}
                ml={4}
              >
                <PopoverArrow />
                <Flex direction="column">
                  <Box mb={1} p={4} pt={2} pb={2}>
                    <Text fontSize="sm" color="gray.500" mb={0}>
                      {email}
                    </Text>
                  </Box>
                  <Divider mt={0} mb={0} />
                  <VStack align="left" spacing={1} mt={2}>
                    {popoverLinks.map((link, index) => (
                      <ChakraLink
                        as={Link}
                        key={index}
                        to={link.to}
                        p={1}
                        pl={4}
                        pr={4}
                        fontSize="sm"
                        fontWeight="normal"
                        w="100%"
                        _hover={{ bg: "blue.100", color: "blue.800" }}
                      >
                        {link.text}
                      </ChakraLink>
                    ))}
                    <Divider mt={0} mb={0} />
                    <Text
                      p={1}
                      pl={4}
                      pr={4}
                      w="100%"
                      fontSize="sm"
                      fontWeight="normal"
                      onClick={logoutAndClear}
                      cursor="pointer"
                      _hover={{ bg: "blue.100", color: "blue.800" }}
                    >
                      <Translate text="Logout" />
                    </Text>
                  </VStack>
                </Flex>
              </PopoverContent>
            </Popover>
          </Flex>
        </Box>
      )}
      {!isUserLoggedIn && (
        <Box
          p={isSidebarOpen ? 4 : 4}
          h="6vh"
          pl={isSidebarOpen ? 0 : 0}
          pt={isSidebarOpen ? 8 : 8}
        >
          <Flex align="center" p={4}>
            <Popover>
              <PopoverTrigger>
                <Flex alignItems="center" cursor="pointer">
                  <Avatar
                    // name={username}
                    src="https://bit.ly/broken-link"
                    size="sm"
                  />
                  {isSidebarOpen && (
                    <Box ml={2}>
                      <Text fontWeight="bold" mb={0} ml={2}>
                        <Translate text="Login" />
                      </Text>
                    </Box>
                  )}
                </Flex>
              </PopoverTrigger>
              <PopoverContent
                bg={colorMode === "light" ? "white" : "gray.700"}
                borderRadius="xl"
                borderColor={colorMode === "light" ? "gray.200" : "gray.600"}
                boxShadow="2xl"
                w="130%"
                height="auto"
                overflowY="hidden"
                color={colorMode === "light" ? "gray.800" : "gray.200"}
                ml={4}
              >
                <PopoverArrow />
                <Flex direction="column">
                  <Box mb={1} p={4} pt={2} pb={0}>
                    <Button
                      bg="#286328"
                      _hover="none"
                      textColor="white"
                      borderRadius="xl"
                      w="100%"
                      fontSize="sm"
                      fontWeight="normal"
                      onClick={() => openAuthModal("/travel-planner")}
                    >
                      <Translate text="Login" />
                    </Button>
                    <Divider mb={0} />
                  </Box>

                  <VStack align="left" spacing={1} mt={2}>
                    <Text
                      p={1}
                      pl={4}
                      pr={4}
                      w="100%"
                      fontSize="sm"
                      fontWeight="normal"
                      onClick={() => history.push("/policies/terms-of-use")}
                      cursor="pointer"
                      _hover={{ bg: "blue.100", color: "blue.800" }}
                    >
                      <Translate text="Terms of Services" />
                    </Text>
                  </VStack>
                </Flex>
              </PopoverContent>
            </Popover>
          </Flex>
          <AuthModal
            isOpen={isOpen}
            onClose={onClose}
            redirectPath={redirectPath}
          />
        </Box>
      )}
    </Box>
  );
}

export default SideBar;
