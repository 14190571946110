import React, { useState, useEffect } from "react";
import { Box, VStack, useToast, useDisclosure } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";
import Header from "./Header";
import CategoryAccordion from "./CategoryAccordion";
import CategoryModal from "./CategoryModal";

const BlogFilters = () => {
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryValues, setNewCategoryValues] = useState([]);
  const [newValue, setNewValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isNameEditable, setIsNameEditable] = useState(true);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get("/categories/");
      if (response.data.success) {
        const mappedCategories = response.data.data.map((category) => ({
          name: category.category_name,
          values: category.category_value_list,
        }));
        setCategories(mappedCategories);
      } else {
        throw new Error(response.data.message || "Failed to fetch categories");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const addOrEditCategory = async () => {
    if (newCategoryName.trim() === "") {
      toast({
        title: "Category Name Required",
        description: "Please enter a valid category name.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const payload = isEditing
      ? { values: newCategoryValues }
      : {
          category_name: newCategoryName,
          category_value_list: newCategoryValues,
        };

    try {
      const url = isEditing
        ? `/categories/${newCategoryName}/values`
        : "/categories/";

      const response = await axiosInstance.post(url, payload);

      if (response.data.success) {
        toast({
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        fetchCategories();

        resetModal();
        onClose();
      } else {
        throw new Error(
          response.data.message || "Failed to create or update category"
        );
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const deleteCategory = async (categoryName) => {
    try {
      const url = `/categories/${categoryName}`;
      const response = await axiosInstance.delete(url);

      if (response.data.success) {
        toast({
          title: "Deleted",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        fetchCategories();
      } else {
        throw new Error(response.data.message || "Failed to delete category");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const deleteNewValue = async (categoryIndex, value) => {
    const categoryName = categories[categoryIndex]?.name; // Get the correct category name

    if (!categoryName) {
      toast({
        title: "Error",
        description: "Invalid category selected.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const url = `/categories/${categoryName}/values`;
      const payload = { values: [value] };
      const response = await axiosInstance.delete(url, { data: payload });

      if (response.data.success) {
        toast({
          title: "Value Deleted",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        fetchCategories(); // Refetch categories to update UI
      } else {
        throw new Error(response.data.message || "Failed to delete value");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const resetModal = () => {
    setNewCategoryName("");
    setNewCategoryValues([]);
    setIsEditing(false);
    setEditingIndex(null);
  };

  const handleOpenModal = () => {
    resetModal();
    onOpen();
  };

  const handleEditCategory = (e, index) => {
    e.stopPropagation();
    const categoryToEdit = categories[index];
    setNewCategoryName(categoryToEdit.name);
    setNewCategoryValues(categoryToEdit.values);
    setIsEditing(true);
    setIsNameEditable(false); // Disable editing of category name when editing values
    setEditingIndex(index);
    onOpen();
  };

  return (
    <Box minH="100vh" bg="#F5F7F9">
      <Header handleOpenModal={handleOpenModal} />
      <VStack
        align="start"
        spacing={6}
        m={4}
        bg="white"
        border="1px"
        h="87vh"
        borderColor="blackAlpha.200"
        borderRadius="md"
        boxShadow="md"
        overflowY="auto"
      >
        <CategoryAccordion
          categories={categories}
          handleEditCategory={handleEditCategory}
          deleteCategory={(index) => deleteCategory(categories[index].name)}
          deleteNewValue={(categoryName, value) =>
            deleteNewValue(categoryName, value)
          }
        />
      </VStack>
      <CategoryModal
        isOpen={isOpen}
        onClose={onClose}
        isEditing={isEditing}
        newCategoryName={newCategoryName}
        setNewCategoryName={setNewCategoryName}
        newValue={newValue}
        setNewValue={setNewValue}
        newCategoryValues={newCategoryValues}
        handleValueKeyPress={(e) => {
          if (e.key === "Enter") {
            setNewCategoryValues([...newCategoryValues, newValue]);
            setNewValue("");
            e.preventDefault();
          }
        }}
        addValueToNewCategory={() => {
          if (newValue.trim() !== "") {
            setNewCategoryValues([...newCategoryValues, newValue]);
            setNewValue("");
          }
        }}
        addOrEditCategory={addOrEditCategory}
        isNameEditable={isNameEditable} // Pass the isNameEditable state
      />
    </Box>
  );
};

export default BlogFilters;
