import axiosInstance from "../../helpers/axiosInstance"; // Your axios instance

export const followUser = async (socialId) => {
  try {
    const response = await axiosInstance.post(`/social_media/follow/${socialId}`);
    if (response.data.success) {
      return true; // Follow was successful
    } else {
      throw new Error(response.data.message); // Handle the error message
    }
  } catch (error) {
    console.error("Failed to follow user:", error);
    return false; // Follow failed
  }
};


export const unfollowUser = async (socialId) => {
  try {
    const response = await axiosInstance.delete(`/social_media/follow/${socialId}`);
    if (response.data.success) {
      return true; // Follow was successful
    } else {
      throw new Error(response.data.message); // Handle the error message
    }
  } catch (error) {
    console.error("Failed to follow user:", error);
    return false; // Follow failed
  }
};
