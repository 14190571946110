import React from "react";
import { Box, Flex, Text, Link, Button, useBreakpointValue } from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";

const ContactUs = () => {
  // Use breakpoints to adjust font sizes and button sizes
  const fontSize = useBreakpointValue({ base: "md", md: "lg", lg: "xl" });
  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });
  const boxPadding = useBreakpointValue({ base: 4, md: 8 });

  return (
    <Box 
      p={boxPadding} 
      textAlign="center" 
      bg="white" 
      borderRadius="md" 
      boxShadow="lg"
      maxW={{ base: "90%", md: "80%", lg: "600px" }}
      mx="auto"
      mt={12}
    >
      <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold" mb={4}>
        Get in Touch
      </Text>
      <Text fontSize={fontSize} mb={6}>
        Have any questions or need further information? Feel free to reach out to us via email.
      </Text>
      <Flex justifyContent="center" alignItems="center">
        <Button
          as={Link}
          href="mailto:lancewood.info@gmail.com"
          bg="#286328"
          color="white"
          _hover="none"
          _active="none"
          size={buttonSize}
          leftIcon={<EmailIcon />}
        >
          Email Me
        </Button>
      </Flex>
    </Box>
  );
};

export default ContactUs;
