import React from "react";
import {
  Box,
  Image,
  Text,
  Heading,
  Badge,
  IconButton,
  HStack,
  Button,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import Translate from "../../components/LanguageTranslation/Translate";
import { FaHeart } from "react-icons/fa";
import { useCurrency } from "../CurrencyExchange/CurrencyContext";

const HotelCard = ({ hotel }) => {
  const { convertCurrency, currency } = useCurrency();
  // console.log("HotelCard", hotel);

  const convertedPrice = convertCurrency(
    hotel.price.perNight,
    hotel.price.currency
  );

  // Function to handle box click and open link
  const handleBoxClick = () => {
    window.open(hotel.link, "_blank", "noopener,noreferrer");
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      cursor="pointer"
      onClick={handleBoxClick}
    >
      <Box position="relative">
        <Image
          src={
            hotel.images && hotel.images[0]?.large
              ? `https:${hotel.images[0].large}`
              : "https://via.placeholder.com/300x200.png?text=No+Image"
          }
          alt={hotel.title || "Hotel Image"}
          width="100%"
          height="30vh"
          objectFit="cover"
        />

        <IconButton
          aria-label="Add to favorites"
          icon={<FaHeart />}
          size="md"
          bg="none"
          _hover={{ bg: "none" }}
          position="absolute"
          top="3px"
          right="3px"
          borderRadius="full"
          color="black"
        />
      </Box>
      <Box p="6">
        <Heading size="sm" mb="2" noOfLines="1">
          {hotel.title || "Hotel Title"}
        </Heading>
        <HStack mb="2">
          <Box display="flex" alignItems="center">
            {hotel.rating?.value ? (
              <HStack spacing={1}>
                <StarIcon boxSize={3} color="#3A4135" mb={0} />
                <Text color="#1A202C" fontWeight="bold" fontSize="sm" mb={0}>
                  {(hotel.rating.value / 10).toFixed(1)}
                </Text>
                <Text fontSize="xs" color="gray.500" mb={0}>
                  ({hotel.rating.value} reviews)
                </Text>
              </HStack>
            ) : (
              <Text color="gray.500" fontSize="sm" mb={0}>
                <Translate text="No reviews available" />
              </Text>
            )}
          </Box>
          <Text mb={0}>,</Text>
          <HStack spacing={1} alignItems="flex-start">
            <Text fontWeight="bold" fontSize="sm" color="gray.800" mb={0}>
              {currency} {convertedPrice.toLocaleString()}{" "}
            </Text>
            <Text fontSize="sm" color="gray.800" mb={0}>
              <Translate text="night" />
            </Text>
          </HStack>
        </HStack>
        {/* Book Button */}
        <Button
          as="a"
          href={hotel.link}
          target="_blank"
          rel="noopener noreferrer"
          mt={2}
          size="sm"
          bg="#286328"
          _hover="none"
          _active="none"
          textColor="white"
          width="full"
        >
          <Translate text="Book" />
        </Button>
      </Box>
    </Box>
  );
};

export default HotelCard;
