import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  IconButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  useDisclosure,
  Text,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import CountryFlag from "react-country-flag";
import { useCurrency } from "../CurrencyExchange/CurrencyContext";
import { useTranslation } from "../LanguageTranslation/TranslationContext";
import Translate from "../LanguageTranslation/Translate";

// Mapping language codes to country codes for flags
const languageToCountryMap = {
  en: "US",
  "en-GB": "GB",
  cs: "CZ",
  da: "DK",
  nl: "NL",
  fi: "FI",
  fr: "FR",
  de: "DE",
  it: "IT",
  is: "IS",
  no: "NO",
  es: "ES",
  sv: "SE",
  pt: "PT",
  zh: "CN",
  ja: "JP",
  ko: "KR",
  id: "ID",
  ms: "MY",
  tl: "PH",
  vi: "VN",
  th: "TH",
};

const availableLanguages = [
  { code: "en", label: "English (US)" },
  { code: "cs", label: "Czech" },
  { code: "da", label: "Danish" },
  { code: "nl", label: "Dutch" },
  { code: "en-GB", label: "English (UK)" },
  { code: "fi", label: "Finnish" },
  { code: "fr", label: "French" },
  { code: "de", label: "German" },
  { code: "it", label: "Italian" },
  { code: "is", label: "Icelandic" },
  { code: "no", label: "Norwegian" },
  { code: "es", label: "Spanish" },
  { code: "sv", label: "Swedish" },
  { code: "pt", label: "Portuguese" },
  { code: "zh", label: "Chinese (Simplified)" },
  { code: "ja", label: "Japanese" },
  { code: "ko", label: "Korean" },
  { code: "id", label: "Bahasa Indonesia" },
  { code: "ms", label: "Bahasa Malaysia" },
  { code: "tl", label: "Filipino" },
  { code: "vi", label: "Vietnamese" },
  { code: "th", label: "Thai" },
];

const currencyToCountryMap = {
  EUR: "NL", // Netherlands as an example for EUR
  USD: "US",
  GBP: "GB",
};

const SettingsMenu = ({ languageCodeColor = "black" }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currency, setCurrency } = useCurrency();
  const { language, setLanguage } = useTranslation();
  const [countryCode, setCountryCode] = useState("US");
  const [languageCode, setLanguageCode] = useState("EN");

  useEffect(() => {
    const storedCurrency = localStorage.getItem("selectedCurrency");
    if (storedCurrency) {
      setCurrency(storedCurrency);
      updateCountryCode(storedCurrency);
    }
  }, [setCurrency]);

  const updateCountryCode = (selectedCurrency) => {
    const country = currencyToCountryMap[selectedCurrency] || "US";
    setCountryCode(country);
    setLanguageCode(selectedCurrency === "EUR" ? "NL" : "EN");
  };

  const handleCurrencyChange = (event) => {
    const selectedCurrency = event.target.value;
    setCurrency(selectedCurrency);
    localStorage.setItem("selectedCurrency", selectedCurrency);
    updateCountryCode(selectedCurrency);
  };

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    setLanguageCode(selectedLanguage.toUpperCase());
  };

  return (
    <Box>
      <Flex align="center" onClick={onOpen} cursor="pointer">
        <IconButton
          icon={
            <CountryFlag
              countryCode={countryCode}
              svg
              style={{
                width: "1.5em",
                height: "auto",
                marginLeft: "0.25em",
                boxShadow:
                  "-4px 0 4px -2px rgba(0,0,0,0.1), 4px 0 4px -2px rgba(0,0,0,0.1)",
                backgroundColor: "#f0f0f0",
                borderRadius: "4px",
              }}
            />
          }
          aria-label="Open settings"
          bg="none"
          _hover="none"
          _active="none"
        />
        <Text fontWeight="bold" fontSize="sm" color={languageCodeColor} mb={0}>
          {languageCode}
        </Text>
        <ChevronDownIcon fontSize="xl" color={languageCodeColor} ml={0} />
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Translate text="Select Preferences" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Language Selector */}
            <Box mb={4}>
              <Menu w="100%">
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  width="100%"
                  textAlign="left"
                  px={4}
                  py={2}
                  borderWidth="1px"
                  borderRadius="md"
                  borderColor="gray.200"
                  bg="white"
                  _hover={{ bg: "gray.100" }}
                  _expanded={{ bg: "gray.100" }}
                  _focus={{ boxShadow: "outline" }}
                  fontWeight="normal"
                >
                  {availableLanguages.find((lang) => lang.code === language)
                    ?.label || "Select Language"}
                </MenuButton>
                <MenuList maxHeight="40vh" overflowY="auto">
                  {availableLanguages.map(({ code, label }) => (
                    <MenuItem
                      key={code}
                      onClick={() => handleLanguageChange(code)}
                    >
                      <CountryFlag
                        countryCode={languageToCountryMap[code]}
                        svg
                        style={{
                          width: "1em",
                          height: "1em",
                          marginRight: "0.5em",
                        }}
                      />
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Box>
            {/* Currency Selector */}
            <Box mb={4}>
              <Select value={currency} onChange={handleCurrencyChange}>
                <option value="EUR">EUR - Euro (€)</option>
                <option value="USD">USD - US Dollar ($)</option>
                <option value="GBP">GBP - British Pound (£)</option>
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button bg="black" color="white" _hover="none" onClick={onClose}>
              <Translate text="Close" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SettingsMenu;
