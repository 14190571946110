import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Icon,
  Flex,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FaShoppingBag,
  FaHotel,
  FaCar,
  FaUtensils,
  FaPlane,
  FaTshirt,
  FaGift,
  FaGlassCheers,
  FaTools,
  FaMapMarkerAlt,
  FaCamera,
  FaTrash,
  FaEdit,
} from "react-icons/fa";
import RightHeader from "./RightHeader";
import axiosInstance from "../../helpers/axiosInstance";
import Translate from "../../components/LanguageTranslation/Translate";

const categoryIcons = {
  Places: { icon: FaMapMarkerAlt, color: "#FF6384" },
  Accommodations: { icon: FaHotel, color: "#36A2EB" },
  Transportation: { icon: FaCar, color: "#FFCE56" },
  Food: { icon: FaUtensils, color: "#4CAF50" },
  Shopping: { icon: FaShoppingBag, color: "#FF9800" },
  Entertainment: { icon: FaGlassCheers, color: "#FFC107" },
  Gifts: { icon: FaGift, color: "#AB47BC" },
  Clothing: { icon: FaTshirt, color: "#FF7043" },
  Photography: { icon: FaCamera, color: "#FF5722" },
  Tools: { icon: FaTools, color: "#757575" },
  Flights: { icon: FaPlane, color: "#29B6F6" },
};

const CategoryList = ({ categories, setCategories, selectedTripId }) => {
  const [newCategoryName, setNewCategoryName] = useState("");
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get(
          `/budget_planner/${selectedTripId}/categories`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    if (selectedTripId) {
      fetchCategories();
    }
  }, [selectedTripId]);

  const deleteCategory = async (categoryName) => {
    try {
      await axiosInstance.delete(
        `/budget_planner/${selectedTripId}/delete_category`,
        { data: { name: categoryName } }
      );
      const response = await axiosInstance.get(
        `/budget_planner/${selectedTripId}/categories`
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const openEditModal = (category) => {
    setCategoryToEdit(category);
    setNewCategoryName(category.name);
    onOpen();
  };

  const handleEditSubmit = async () => {
    if (!categoryToEdit) return;

    try {
      await axiosInstance.put(
        `/budget_planner/${selectedTripId}/update_category`,
        { old_name: categoryToEdit.name, new_name: newCategoryName }
      );
      const response = await axiosInstance.get(
        `/budget_planner/${selectedTripId}/categories`
      );
      setCategories(response.data);
      onClose();
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  return (
    <Box
      flex="3"
      w="50%"
      h="100vh"
      backgroundPosition="center"
      display="flex"
      flexDirection="column"
      boxLeftShadow="xl"
      borderRight="1px"
      borderColor="gray.200"
    >
      <RightHeader />
      <VStack
        align="start"
        spacing={3}
        p={4}
        w="100%"
        h="85vh"
        overflowY="auto"
        mt={3}
      >
        {categories.map((category, index) => (
          <Flex
            key={index}
            w="100%"
            justify="space-between"
            align="center"
            borderBottom="1px"
            borderColor="blackAlpha.100"
            pb={2}
          >
            <HStack spacing={3}>
              <Box
                w="40px"
                h="40px"
                bg={categoryIcons[category.name]?.color || "#FF9800"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="full"
              >
                <Icon
                  as={categoryIcons[category.name]?.icon || FaShoppingBag}
                  color="white"
                  boxSize={5}
                />
              </Box>
              <Text fontSize="md" fontWeight="bold" ml={2} mb={0}>
                <Translate text={category.name} />
              </Text>
            </HStack>
            <HStack>
              {category.editable && (
                <>
                  <IconButton
                    icon={<FaEdit />}
                    bg="none"
                    color="black"
                    size="xs"
                    _hover="none"
                    onClick={() => openEditModal(category)}
                  />
                  <IconButton
                    icon={<FaTrash />}
                    bg="none"
                    color="black"
                    size="xs"
                    _hover="none"
                    onClick={() => deleteCategory(category.name)}
                  />
                </>
              )}
            </HStack>
          </Flex>
        ))}
      </VStack>

      {/* Edit Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Enter new category name"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              bg="#286328"
              color="white"
              _hover="none"
              mr={3}
              onClick={handleEditSubmit}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CategoryList;
