import React from "react";
import {
  Box,
  IconButton,
  Text,
  Spacer,
  Button,
  HStack,
  useColorMode,
} from "@chakra-ui/react";
import { ArrowBackIcon, AddIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import SettingsMenu from "../../components/common/SettingsMenu";

const Header = ({ handleOpenModal }) => {
  const history = useHistory();
  const { colorMode } = useColorMode();

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      maxH="8vh"
      borderBottom="1px"
      borderColor="blackAlpha.200"
      overflowY="hidden"
        bg="white"
    >
      <Box
        display="flex"
        alignItems="center"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        width="100%"
        height="100%"
        p={4}
        pt={2}
        pl={0}
        pb={2}
        boxSizing="border-box"
        overflowY="hidden"
      >
        <IconButton
          icon={<ArrowBackIcon />}
          variant="ghost"
          _hover="none"
          onClick={() => history.goBack()}
        />
        <Text
          fontSize="md"
          fontWeight="bold"
          mb={0}
          mt={0}
          color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
        >
          Filters
        </Text>
        <Spacer />
        <Button
          mt={0}
          ml={3}
          onClick={handleOpenModal}
          size="sm"
          bg="#286328"
          _hover="none"
          textColor="white"
          borderRadius="xl"
          leftIcon={<AddIcon />}
        >
          Add Category
        </Button>
        <SettingsMenu />
      </Box>
    </Box>
  );
};

export default Header;
