import React, { useState, useEffect } from "react";
import { Box, VStack, Flex, Text, Image, IconButton } from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../helpers/axiosInstance";
import Translate from "../../components/LanguageTranslation/Translate";

const TripsSections = ({ colorMode, socialId }) => {
  const [ongoingTrip, setOngoingTrip] = useState(null);
  const [completedTrips, setCompletedTrips] = useState([]);
  const [currentCompleted, setCurrentCompleted] = useState(0);
  const numVisibleCards = 4;
  const history = useHistory();

  // Fetch trips based on socialId or default bucket-list
  const fetchTrips = async () => {
    try {
      const endpoint = socialId
        ? `/social_media/get-trips-by-social-id/${socialId}`
        : "/bucket-list/";

      const response = await axiosInstance.get(endpoint);

      // Check if the response data contains 'trip_list' (for trips by social ID)
      const trips = response.data.data.trip_list || response.data.data;
      console.log("trips", trips);

      // Find the ongoing trip
      const ongoing = trips.find((trip) => trip.is_on_going);

      // Find completed trips
      const completed = trips.filter((trip) => trip.is_trip_completed);

      // Set the ongoing trip and completed trips
      setOngoingTrip(ongoing || null); // If no ongoing trip, set to null
      setCompletedTrips(completed);
    } catch (error) {
      console.error("Error fetching trip data:", error);
    }
  };

  useEffect(() => {
    fetchTrips(); // Fetch trips on mount or when socialId changes
  }, [socialId]);

  const redirectToBackToTrips = (tripId) => {
    history.push(`/backtotrips/${tripId}`);
  };

  const nextCompleted = () => {
    setCurrentCompleted(
      (prev) => (prev + numVisibleCards) % completedTrips.length
    );
  };

  const prevCompleted = () => {
    setCurrentCompleted(
      (prev) =>
        (prev - numVisibleCards + completedTrips.length) % completedTrips.length
    );
  };

  const hasPrevCompleted = currentCompleted > 0;
  const hasNextCompleted =
    currentCompleted + numVisibleCards < completedTrips.length;

  return (
    <Box mb={10}>
      {/* Your Trips Section */}
      <VStack flex={1} align="start" spacing={1}>
        <Text fontSize="xl" fontWeight="bold" mb={0}>
          {socialId ?  <Translate text="User's Trips"/> : <Translate text="Your Trips" />}
        </Text>
        <Flex alignItems="center" justifyContent="left" width="full" mt={4}>
          {hasPrevCompleted && (
            <IconButton
              icon={<ArrowBackIcon />}
              isRound={true}
              size="lg"
              onClick={prevCompleted}
              position="absolute"
              left="10px"
              backgroundColor="white"
              boxShadow="base"
              zIndex="overlay"
            />
          )}
          <Flex overflowX="auto" width="full" p={1}>
            {completedTrips
              .slice(currentCompleted, currentCompleted + numVisibleCards)
              .map((trip) => (
                <Box
                  key={trip.id}
                  cursor="pointer"
                  width="250px"
                  p={2}
                  mr={2}
                  bg={colorMode === "dark" ? "gray.700" : "gray.100"}
                  borderRadius="md"
                  boxShadow="md"
                  onClick={() => redirectToBackToTrips(trip._id)}
                >
                  <Image
                    src={
                      trip.trip_plan?.itinerary[0]?.activity_detail_list[0]
                        ?.thumbnailHiResURL || "/placeholder-image.png"
                    }
                    alt={trip.trip_plan?.trip_name || "Completed Trip"}
                    borderRadius="md"
                    mb={3}
                    h="25vh"
                    w="100%"
                  />
                  <Text fontWeight="bold" fontSize="lg" noOfLines={1} mb={2}>
                    {trip.trip_plan?.trip_name || "Unnamed Trip"}
                  </Text>
                  <Text fontSize="sm" noOfLines={2} mb={1}>
                    {trip.trip_plan?.description || ""}
                  </Text>
                </Box>
              ))}
          </Flex>
          {hasNextCompleted && (
            <IconButton
              icon={<ArrowForwardIcon />}
              isRound={true}
              size="lg"
              onClick={nextCompleted}
              position="absolute"
              right="10px"
              backgroundColor="white"
              boxShadow="base"
              zIndex="overlay"
            />
          )}
        </Flex>
      </VStack>

      {/* Ongoing Trip Section */}
      <VStack flex={1} align="start" spacing={1} mt={4}>
        <Text fontSize="xl" fontWeight="bold" mb={0}>
           <Translate text="Ongoing Trip" />
        </Text>
        <Flex alignItems="center" justifyContent="left" width="full" mt={4}>
          {ongoingTrip ? (
            <Box
              cursor="pointer"
              width="250px"
              p={2}
              bg={colorMode === "dark" ? "gray.700" : "gray.100"}
              borderRadius="md"
              boxShadow="md"
              onClick={() => redirectToBackToTrips(ongoingTrip._id)}
            >
              <Image
                src={
                  ongoingTrip.trip_plan?.itinerary[0]?.activity_detail_list[0]
                    ?.thumbnailHiResURL || "/placeholder-image.png"
                }
                alt={ongoingTrip.trip_plan?.trip_name || "Ongoing Trip"}
                borderRadius="md"
                mb={3}
                h="25vh"
                w="100%"
              />
              <Text fontWeight="bold" fontSize="lg" noOfLines={1} mb={2}>
                {ongoingTrip.trip_plan?.trip_name || "Unnamed Ongoing Trip"}
              </Text>
              <Text fontSize="sm" noOfLines={2} mb={1}>
                {ongoingTrip.trip_plan?.description || ""}
              </Text>
            </Box>
          ) : (
            <Text fontSize="md" fontWeight="normal">
              <Translate text="No ongoing trip" />
            </Text>
          )}
        </Flex>
      </VStack>
    </Box>
  );
};

export default TripsSections;
