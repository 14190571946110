import React from "react";
import { Box, Heading, useColorMode } from "@chakra-ui/react";
import Translate from "../../components/LanguageTranslation/Translate";

const CreatorsBusinessBanner = ({heading, banner}) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      bgImage={`url(${banner})`} // Set the background image
      bgSize="cover"
      bgPosition="center"
      w="100%"
      h="60vh"
      pb={{ base: 4, md: 0 }} 
      pt={{ base: 4, md: 0 }}
      px={{ base: 4, md: 12 }}
      mb={{ base: 4, md:12}}
      display="flex"
      justifyContent="center" // Center content vertically
      alignItems="center" // Center content horizontally
      fontFamily="Poppins, sans-serif"
      color={colorMode === "light" ? "black" : "white"}
    >
      <Box textAlign="center">
        <Heading
          as="h1"
          fontSize={{ base: "3xl", md: "6xl" }}
          color="white"
          textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
        >
          
          <Translate text={heading} />
        </Heading>
      </Box>
    </Box>
  );
};

export default CreatorsBusinessBanner;
