import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  useColorMode,
  Spacer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  TableContainer,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { AiOutlineFileExcel } from "react-icons/ai"; // Excel icon for download
import SettingsMenu from "../common/SettingsMenu";
import axiosInstance from "../../helpers/axiosInstance";

const UserForm = () => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch user form data on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get("/userform/");
        setUserData(response.data.user_submissions);
        setIsLoading(false);
      } catch (error) {
        toast({
          title: "Error fetching user data.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [toast]);

  // Handle file download
  const handleDownload = async () => {
    try {
      const response = await axiosInstance.get("/userform/download", {
        responseType: "blob", // Important for handling files
      });

      const filename = response.headers["content-disposition"]
        ?.split("filename=")[1]
        ?.replace(/['"]/g, ""); // Extract the filename from the headers

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Use the filename from response
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Clean up after download

      toast({
        title: "Download Successful",
        description: `File "${filename}" has been downloaded.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error downloading file:", error);
      toast({
        title: "Error",
        description: "An error occurred while downloading the file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box h="100vh" bg="white">
      {/* Header Section */}
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        maxH="8vh"
        borderBottom="1px"
        borderColor="blackAlpha.200"
        overflowY="hidden"
      >
        <Box
          display="flex"
          alignItems="center"
          bg={colorMode === "dark" ? "gray.700" : "white"}
          width="100%"
          height="100%"
          p={4}
          pt={2}
          pb={2}
          boxSizing="border-box"
          overflowY="hidden"
        >
          <Text
            fontSize="md"
            fontWeight="bold"
            mb={0}
            mt={0}
            color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
          >
            User Data
          </Text>
          <Spacer />
          <IconButton
            aria-label="Download Excel"
            icon={<AiOutlineFileExcel />}
            color="green"
            bg="none"
            _hover="none"
            size="lg"
            onClick={handleDownload}
            isLoading={isLoading}
          />
          <SettingsMenu />
        </Box>
      </Box>

      {/* Table Section with Scroll */}
      <Box p={4} h="90vh" overflowY="auto">
        {isLoading ? (
          <Spinner size="lg" />
        ) : (
          <Box
            maxHeight="80vh" // Set the max height for the table area
            overflow="auto" // Enable vertical scrolling if the content exceeds the max height
            // w="80vw"
            w="auto"
          >
            <TableContainer>
              <Table variant="simple" border="1px" borderColor="gray.200">
                <Thead>
                  <Tr fontSize="sm">
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Phone No.</Th>
                    <Th>Creator Type</Th>
                    <Th>
                      Website or <br></br>Instagram Link
                    </Th>

                    <Th>Type</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {userData.length > 0 ? (
                    userData.map((user, index) => (
                      <Tr key={index} fontSize="sm">
                        <Td>{user.name}</Td>
                        <Td>{user.email}</Td>
                        <Td>{user.phone}</Td>
                        <Td>{user.creator}</Td>
                        <Td>{user.websiteOrInstagram}</Td>

                        <Td>{user.type}</Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={3}>No data available</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserForm;
