import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Tooltip,
  Icon,
  Button,
  Text,
  Input,
  VStack,
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Select,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorMode,
  Divider,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";
import { AddIcon } from "@chakra-ui/icons";
import usePersistedState from "../usePersistedState";
import SettingsMenu from "../common/SettingsMenu";

function AdminPanel() {
  const [users, setUsers] = useState([]);
  const roles = ["Admin", "User"];
  const [emailToDelete, setEmailToDelete] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { colorMode } = useColorMode();
  const handleOpen = () => {
    setIsOpen(true);
  };

  const [errors, setErrors] = useState({}); // For form validation errors
  // Validate form data
  const validate = (data) => {
    const errors = {};
    if (!data.username) errors.username = "Username is required";
    if (!data.email) errors.email = "Email is required";
    if (!data.password) errors.password = "Password is required";
    if (!data.role) errors.role = "Role is required";
    return errors;
  };
  const initialState = {
    username: "",
    email: "",
    password: "",
    role: "",
  };

  const [newUserData, setNewUserData] = useState(initialState);
  const toast = useToast();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = usePersistedState("perPage", 5); // Default to 10 messages per page
  const [totalPages, setTotalPages] = useState(0);

  const [isRoleChangeAlertOpen, setIsRoleChangeAlertOpen] = useState(false);
  const [isDeleteUserAlertOpen, setDeleteUserAlertOpen] = useState(false);
  const [userToChange, setUserToChange] = useState({ email: "", role: "" });
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const fetchUsers = () => {
    const requestData = {
      page: currentPage, // Add page number to the request
      per_page: perPage, // Number of items per page
    };

    axiosInstance
      .post(`/auth/admin/get_users`, requestData)
      .then((response) => {
        console.log("API Response:", response.data.users_info);
        if (response.data && response.data.users_info.users) {
          setUsers(response.data.users_info.users);
          if (response.data && response.data.users_info) {
            setTotalPages(response.data.users_info.total_pages);
            if (currentPage > totalPages) {
              setCurrentPage(Math.max(totalPages, 1));
            }
          }
        } else {
          console.error("Unexpected API response format");

          toast({
            title: "Error",
            description: "An Error Occurred.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  useEffect(() => {
    // Only fetch messages if perPage is a valid number greater than 0
    if (perPage > 0) {
      fetchUsers();
    }
  }, [currentPage, perPage]); // Include currentPage here

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow the field to be empty
    setPerPage(value === "" ? "" : Math.max(1, parseInt(value, 10)));
  };

  const handleInputBlur = () => {
    // When input loses focus, enforce minimum and correct invalid entries
    setPerPage((prev) =>
      prev === "" || isNaN(prev) ? 1 : Math.max(1, parseInt(prev, 10))
    );
  };

  function getDeviceDetails() {
    const ua = navigator.userAgent;

    // Detecting the browser
    let browser;
    if (/chrome|chromium|crios/i.test(ua)) {
      browser = "Chrome";
    } else if (/firefox|fxios/i.test(ua)) {
      browser = "Firefox";
    } else if (/safari/i.test(ua)) {
      browser = "Safari";
    } else if (/edg/i.test(ua)) {
      browser = "Edge";
    } else if (/opr\//i.test(ua)) {
      browser = "Opera";
    } else if (/msie|trident/i.test(ua)) {
      browser = "IE";
    } else {
      browser = "Unknown Browser";
    }

    // Detecting the platform
    let os;
    if (/android/i.test(ua)) {
      os = "Android";
    } else if (/iphone|ipad|ipod/i.test(ua)) {
      os = "iOS";
    } else if (/win/i.test(ua)) {
      os = "Windows";
    } else if (/mac/i.test(ua)) {
      os = "MacOS";
    } else if (/linux/i.test(ua)) {
      os = "Linux";
    } else {
      os = "Unknown OS";
    }

    return {
      full_user_agent: navigator.userAgent,
      simplified_user_agent: `${os} ${browser}`,
      registration_date: new Date().toISOString().slice(0, 10),
    };
  }

  const addUser = () => {
    //console.log("newUserData", newUserData);
    const deviceDetails = getDeviceDetails();

    const signinPayload = {
      ...newUserData,
      device: deviceDetails,
    };

    axiosInstance
      .post("/auth/signup", signinPayload)
      .then((response) => {
        //console.log("newUserData response", response);
        if (response.status === 200) {
          toast({
            title: "Status",
            description: "User Created Successesfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: "An Error Occurred.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }

        setNewUserData(initialState); // Reset the state
        setIsOpen(false); // Close the modal
        fetchUsers(); // Refresh the user list after adding
      })
      .catch((error) => {
        //console.log(error);
        if (error.response && error.response.status === 400) {
          toast({
            title: "User Creation Failed",
            description: "User already exists.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  const changeUserRole = (email, role) => {
    //console.log("Changing user role function");
    const payload = {
      email: email,
      role: role,
    };
    //console.log("payload:", payload);
    axiosInstance
      .put("/auth/admin/change_role", payload)
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: "Status",
            description: "Role changed successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: "An Error Occurred.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        // Refresh the user list
        fetchUsers();
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const deleteUser = () => {
    axiosInstance
      .delete(
        `/auth/admin/delete_user?email=${encodeURIComponent(emailToDelete)}`
      )
      .then((response) => {
        // Refresh the user list
        fetchUsers();
        if (response.status === 200) {
          toast({
            title: "Status",
            description: "User Deleted Successesfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  // Handle form submission
  const handleSubmit = () => {
    // Check if the password is at least 8 characters.
    if (newUserData.password.length < 8) {
      setPasswordError("Password should be at least 8 characters.");

      // Show the error toast
      toast({
        title: "Error",
        description: "Password should be at least 8 characters.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Stop further execution
    }
    const validationErrors = validate(newUserData);
    if (Object.keys(validationErrors).length === 0) {
      // No errors, you can now submit data
      //console.log(newUserData);
      addUser();
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <Box  h="100vh">
     <Box
          flex="1"
          display="flex"
          flexDirection="column"
          maxH="8vh"
          borderBottom="1px"
          borderColor="blackAlpha.200"
          overflowY="hidden"
        >
          <Box
            display="flex"
            alignItems="center"
            bg={colorMode === "dark" ? "gray.700" : "white"}
            width="100%"
            height="100%"
            p={4}
            pt={2}
            pb={2}
            boxSizing="border-box"
            overflowY="hidden"
          >
            <Text
              fontSize="md"
              fontWeight="bold"
              mb={0}
              // mt={2}
              color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
            >
              Admin Panel
            </Text>
            <Spacer />
            <Button
              bg="#286328"
              _hover="none"
              textColor="white"
              borderRadius="xl"
              size="sm"
              leftIcon={<AddIcon />}
              onClick={() => setShowAddUserModal(true)}
            >
              Add User
            </Button>
            <SettingsMenu />
          </Box>
        </Box>
      <Box w="100%">
        {/* <Divider m="0" /> */}
        <Box h="77.5%" overflowX="auto" scroll="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>USERNAME</Th>
                <Th>EMAIL</Th>
                <Th>ROLE</Th>
                <Th>ACTIONS</Th>
                <Th>CHANGE ROLE</Th>
              </Tr>
            </Thead>
            <Tbody>
              :
              {Array.isArray(users) &&
                users.slice(0, perPage).map((user, index) => (
                  <Tr key={index}>
                    <Td>{user.username}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.role}</Td>
                    <Td>
                      <Button
                        colorScheme="red"
                        bgColor="red.500"
                        textColor="white"
                        size="sm"
                        onClick={() => {
                          setEmailToDelete(user.email);
                          setDeleteUserAlertOpen(true);
                        }}
                      >
                        Delete
                      </Button>
                    </Td>
                    <AlertDialog
                      isOpen={isDeleteUserAlertOpen}
                      onClose={() => setDeleteUserAlertOpen(false)}
                    >
                      <AlertDialogOverlay>
                        <AlertDialogContent>
                          <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete User
                          </AlertDialogHeader>

                          <AlertDialogBody>
                            Are you sure you want to delete the user?
                          </AlertDialogBody>

                          <AlertDialogFooter>
                            <Button
                              variant="outline"
                              onClick={() => setDeleteUserAlertOpen(false)}
                            >
                              No
                            </Button>
                            <Button
                              colorScheme="red"
                              bgColor="red.500"
                              textColor="white"
                              onClick={() => {
                                deleteUser();
                                setDeleteUserAlertOpen(false);
                              }}
                              ml={3}
                            >
                              Yes
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialogOverlay>
                    </AlertDialog>
                    <Td>
                      <Select
                        value={user.role}
                        onChange={(e) => {
                          const updatedRole = e.target.value;
                          setUserToChange({
                            email: user.email,
                            role: updatedRole,
                          });
                          setIsRoleChangeAlertOpen(true);
                        }}
                      >
                        <option value="" disabled>
                          Select a role
                        </option>
                        {roles.map((role, idx) => (
                          <option key={idx} value={role}>
                            {role}
                          </option>
                        ))}
                      </Select>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
            <Tfoot ml={0}></Tfoot>
          </Table>
        </Box>
        <Box mt={2} mb={2} ml={4} display="flex" justifyContent="left">
          <HStack spacing={4} mt={2} mb={2} align="center">
            <Button
              size="sm"
              onClick={handlePreviousClick}
              disabled={currentPage <= 1}
            >
              Previous
            </Button>
            <Text pt={3} size="sm">
              {currentPage} of {totalPages}
            </Text>
            <Button
              size="sm"
              onClick={handleNextClick}
              disabled={currentPage >= totalPages}
            >
              Next
            </Button>
            <Box>
              <FormControl>
                <HStack>
                  <FormLabel htmlFor="per-page" pt={2} size="sm">
                    Per Page:
                  </FormLabel>
                  <Input
                    size="sm"
                    id="per-page"
                    type="number"
                    value={perPage}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    min={1}
                    max={100}
                    width="100px"
                  />
                </HStack>
              </FormControl>
            </Box>
          </HStack>
        </Box>

        <AlertDialog
          isOpen={isRoleChangeAlertOpen}
          onClose={() => setIsRoleChangeAlertOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Change Role
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to change the role?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setIsRoleChangeAlertOpen(false)}
                >
                  No
                </Button>
                <Button
                  colorScheme="red"
                  bgColor="red.500"
                  textColor="white"
                  onClick={() => {
                    changeUserRole(userToChange.email, userToChange.role);
                    setIsRoleChangeAlertOpen(false);
                  }}
                  ml={3}
                >
                  Yes
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
      <Modal
        isOpen={showAddUserModal}
        onClose={() => setShowAddUserModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={errors.username}>
              <FormLabel htmlFor="text">Username</FormLabel>
              <Input
                id="text"
                type="text"
                placeholder="Your username"
                maxLength={25}
                value={newUserData.username}
                onChange={(e) =>
                  setNewUserData({
                    ...newUserData,
                    username: e.target.value,
                  })
                }
              />
              <FormErrorMessage>{errors.username}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.email} mt={4}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="Your email"
                maxLength={80}
                value={newUserData.email}
                onChange={(e) =>
                  setNewUserData({ ...newUserData, email: e.target.value })
                }
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!passwordError} mt={4}>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                id="password"
                type="password"
                placeholder="Your password"
                value={newUserData.password}
                onChange={(e) => {
                  const passwordValue = e.target.value;
                  if (passwordValue.length < 8) {
                    setPasswordError(
                      "Password should be at least 8 characters."
                    );
                    setIsPasswordValid(false);
                  } else {
                    setPasswordError("");
                    setIsPasswordValid(true);
                  }
                  setNewUserData({
                    ...newUserData,
                    password: passwordValue,
                  });
                }}
              />
              <FormErrorMessage>{passwordError}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.role} mt={4}>
              <FormLabel>Role</FormLabel>
              <Select
                value={newUserData.role}
                onChange={(e) =>
                  setNewUserData({ ...newUserData, role: e.target.value })
                }
              >
                <option value="" disabled>
                  Select a role
                </option>
                {roles.map((role, idx) => (
                  <option key={idx} value={role}>
                    {role}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.role}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              bgColor="blue.500"
              textColor="white"
              onClick={handleSubmit}
              isDisabled={!isPasswordValid}
            >
              Add User
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default AdminPanel;
