import React, { useState } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  Button,
  HStack,
  Icon,
  IconButton,
  Flex,
  useColorMode,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon, StarIcon } from "@chakra-ui/icons";
import { FaHeart, FaRegHeart, FaCaravan } from "react-icons/fa"; // Import FaCaravan
import axiosInstance from "../../helpers/axiosInstance";
import Translate from "../../components/LanguageTranslation/Translate";
import { useCurrency } from "../CurrencyExchange/CurrencyContext";

const RVRentalCard = ({ RVs, location }) => {
  // console.log("RVRentalCard", RVs);
  const { convertCurrency, currency } = useCurrency();
  const { colorMode } = useColorMode();
  const [current, setCurrent] = useState(0);
  const [likedActivities, setLikedActivities] = useState({});

  // Check if RVs is an array and has items, or set it to an empty array
  const validRVs = Array.isArray(RVs) ? RVs : [];

  if (validRVs.length === 0) {
    return (
      <Text mt={2}>
        <Translate text="No RVs available" />
      </Text>
    );
  }

  const numVisibleCards = 2;
  const numTotalCards = RVs.length;

  const next = () => {
    setCurrent((prev) => (prev + numVisibleCards) % numTotalCards);
  };

  const prev = () => {
    setCurrent(
      (prev) => (prev - numVisibleCards + numTotalCards) % numTotalCards
    );
  };

  const hasPrev = current > 0;
  const hasNext = current + numVisibleCards < numTotalCards;

  const sendLikedActivity = async (rv, isLiked) => {
    const payload = {
      outdoorsy_api_response: [
        {
          location: location,
          outdoorsy_response: [rv], // Send only the current liked/unliked activity
        },
      ],
      conversation_type: "RVs",
    };
    try {
      await axiosInstance.post(
        "/bucket-list/save-third-party-service",
        payload
      );
      // console.log(`Activity ${isLiked ? "liked" : "unliked"}:`, rv);
    } catch (error) {
      console.error("Error saving liked activity:", error);
    }
  };

  const toggleLike = (index, rv) => {
    const updatedLikes = { ...likedActivities };
    const isLiked = !updatedLikes[index];

    if (isLiked) {
      updatedLikes[index] = rv; // Add activity if liked
    } else {
      delete updatedLikes[index]; // Remove activity if unliked
    }

    setLikedActivities(updatedLikes);

    // Send only the current activity to backend
    sendLikedActivity(rv, isLiked);
  };

  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      padding={0}
      mt={3}
      width="100%"
    >
      {hasPrev && (
        <IconButton
          icon={<ArrowBackIcon />}
          isRound={true}
          size="md"
          onClick={prev}
          position="absolute"
          left="10px"
          backgroundColor="white"
          boxShadow="base"
          zIndex="overlay"
        />
      )}
      <HStack
        overflow="hidden"
        width="full"
        h="auto"
        spacing={3}
        justifyContent="space-between"
      >
        {RVs?.slice(current, current + numVisibleCards).map((rv, index) => {
          const convertedPrice = convertCurrency(rv?.price_per_day, "USD");
          const actualIndex = current + index;
          const isLiked = likedActivities[actualIndex] !== undefined;
          return (
            <Box
              key={index}
              bg="gray.100"
              padding={2}
              borderRadius="xl"
              width="50%"
              display="flex"
              flexDirection="column"
              alignItems="flex-start" // Align content to the left
            >
              <HStack spacing={2} mb={1} align="left">
                {" "}
                {/* Align HStack content to the left */}
                <Icon
                  as={FaCaravan}
                  color="#286328"
                  boxSize={4}
                  alignSelf="center"
                />{" "}
                {/* FaCaravan icon */}
                <Text
                  fontWeight="bold"
                  fontSize="sm"
                  mb={0}
                  noOfLines={1}
                  sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Translate text="RVs Rental" />
                </Text>
              </HStack>
              <Box
                borderTopRadius="md"
                boxShadow="md"
                width="100%"
                position="relative"
              >
                <Image
                  src={
                    rv?.image_url ||
                    "https://via.placeholder.com/300x200.png?text=No+Image"
                  }
                  fallbackSrc="https://via.placeholder.com/300x200.png?text=No+Image"
                  alt={rv?.vehicle_name || "RV"}
                  borderTopRadius="xl"
                  objectFit="cover"
                  width="100%"
                  height="200px"
                />
                <IconButton
                  aria-label="Add to favorites"
                  icon={isLiked ? <FaHeart /> : <FaRegHeart />}
                  size="md"
                  color={isLiked ? "red.500" : "black"}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleLike(actualIndex, rv);
                  }}
                  position="absolute"
                  top="0px"
                  right="0px"
                  borderRadius="full"
                  bg="none"
                  _hover={{ bg: "none" }}
                />
              </Box>
              <VStack
                alignItems="flex-start"
                width="100%"
                p={3}
                spacing={2}
                textAlign="left"
                bg="white"
                borderBottomRadius="xl"
              >
                <Text
                  fontWeight="bold"
                  fontSize="md"
                  noOfLines={1}
                  sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  mb={0}
                >
                  {rv?.vehicle_name}
                </Text>
                {rv?.rating ? (
                  <HStack spacing={1}>
                    <StarIcon boxSize={3} color="#3A4135" />
                    <Text fontWeight="bold" fontSize="sm" mb={0}>
                      {rv?.rating.toFixed(1)}
                    </Text>
                    <Text fontSize="xs" color="gray.500" mb={0}>
                      ({rv?.reviews} reviews)
                    </Text>
                  </HStack>
                ) : (
                  <Text color="gray.500" fontSize="sm" mb={0}>
                    <Translate text="No reviews available" />
                  </Text>
                )}
                <HStack spacing={1} alignItems="flex-start">
                  <Text fontWeight="bold" fontSize="sm" color="gray.800" mb={0}>
                    {/* ${rv?.price_per_day.toLocaleString()} */}
                    {currency} {convertedPrice}
                  </Text>
                  <Text fontSize="sm" color="gray.800" mb={0}>
                    / <Translate text="day" />
                  </Text>
                </HStack>
                <Button
                  as="a"
                  href={rv?.booking_url}
                  target="_blank"
                  mt={2}
                  size="sm"
                  bg="#286328"
                  _hover="none"
                  _active="none"
                  textColor="white"
                  width="full"
                >
                  <Translate text=" Book Now" />
                </Button>
              </VStack>
            </Box>
          );
        })}
      </HStack>
      {hasNext && (
        <IconButton
          icon={<ArrowForwardIcon />}
          isRound={true}
          size="md"
          onClick={next}
          position="absolute"
          right="10px"
          backgroundColor="white"
          boxShadow="base"
          zIndex="overlay"
        />
      )}
    </Flex>
  );
};

export default RVRentalCard;
