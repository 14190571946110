import React, { createContext, useContext, useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

const OTPContext = createContext();

export const useOTP = () => useContext(OTPContext);

export const OTPProvider = ({ children }) => {
  const [isOTPVerified, setIsOTPVerified] = useState(false);

  useEffect(() => {
    const verifyToken = () => {
      const token = localStorage.getItem('OTP_TOKEN');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decodedToken.exp > currentTime) {
            setIsOTPVerified(true);
          } else {
            localStorage.removeItem('OTP_TOKEN');
            setIsOTPVerified(false);
          }
        } catch (error) {
          console.error('Error decoding OTP token:', error);
          setIsOTPVerified(false);
        }
      }
    };
    verifyToken();
  }, []);

  const verifyOTP = (token) => {
    setIsOTPVerified(true);
    localStorage.setItem('OTP_TOKEN', token);
  };

  const resetOTP = () => {
    setIsOTPVerified(false);
    localStorage.removeItem('OTP_TOKEN');
  };

  return (
    <OTPContext.Provider value={{ isOTPVerified, verifyOTP, resetOTP }}>
      {children}
    </OTPContext.Provider>
  );
};
