import React, { useState, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  useToast,
  VStack,
  Box,
} from "@chakra-ui/react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage"; // Utility function for cropping the image
import "./main.css"; // Add necessary styles for the cropper
import axiosInstance from "../../helpers/axiosInstance"; 
import Translate from "../../components/LanguageTranslation/Translate";

const CoverProfile = ({ isOpen, onClose, onUploadSuccess }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const toast = useToast();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      toast({
        title: "No file selected",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      // Crop the selected image
      const croppedImageBlob = await getCroppedImg(
        URL.createObjectURL(selectedFile),
        croppedAreaPixels
      );

      // Convert the blob to a file for upload
      const croppedImageFile = new File([croppedImageBlob], selectedFile.name, {
        type: selectedFile.type,
      });

      const formData = new FormData();
      formData.append("file", croppedImageFile);

      const response = await axiosInstance.post("/social_media/update-cover-photo", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        toast({
          title: "Cover image uploaded successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onUploadSuccess();
        onClose();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      toast({
        title: "Error uploading cover image",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader><Translate text="Upload Cover Image" /></ModalHeader>
        <ModalCloseButton />
        <ModalBody h="60vh">
          <VStack h="55vh" alignItems="center" justifyContent="center">
            {!selectedFile && (
              <Button onClick={() => document.getElementById("file-upload").click()}>
                <Translate text="Choose Image" />
              </Button>
            )}
            <Input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              display="none"
              id="file-upload"
            />
            {selectedFile && (
              <div className="crop-container">
                <Cropper
                  image={URL.createObjectURL(selectedFile)}
                  crop={crop}
                  zoom={zoom}
                  aspect={16 / 9} // Change aspect ratio as needed
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                  h="50vh"
                />
              </div>
            )}
            <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
              {selectedFile && (
                <Button onClick={() => document.getElementById("file-upload").click()}>
                  <Translate text="Choose Image" />
                </Button>
              )}
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          {selectedFile && (
            <Button bg="#286328" color="white" _hover="none" onClick={handleUpload}>
              <Translate text="Upload" />
            </Button>
          )}
          <Button variant="ghost" onClick={onClose}>
            <Translate text="Cancel" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CoverProfile;
