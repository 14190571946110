import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  IconButton,
  Spacer,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useColorMode,
  useDisclosure,
  useClipboard,
} from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";
import { useHistory } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download_2_fill_icon.svg";
import SettingsMenu from "../common/SettingsMenu";
import Translate from "../../components/LanguageTranslation/Translate";

const ItineraryHeader = ({ tripId, handleToggleOngoingTrip }) => {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const { isOpen, onOpen: openModal, onClose } = useDisclosure();
  const [shareUrl, setShareUrl] = useState("");
  const { hasCopied, onCopy } = useClipboard(shareUrl);

  // State for tracking the completion status of the trip
  const [isCompleted, setIsCompleted] = useState(false);

  // Function to handle sharing
  const handleShare = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL; // Use the base URL from .env file
    const generatedUrl = `${baseUrl}/itinerary_details/${tripId}`;
    setShareUrl(generatedUrl); // Set the share URL in state
    openModal(); // Open the modal
  };

  // Function to handle the trip completion toggle
  const handleToggleCompletion = async (event) => {
    const newCompletionStatus = !isCompleted; // Toggle the current status
    setIsCompleted(newCompletionStatus); // Update the local state

    try {
      const response = await axiosInstance.put(
        `/bucket-list/${tripId}/update/completion`,
        { is_completed: newCompletionStatus } // Pass the new completion status
      );

      if (response.data.success) {
        // After successfully marking the trip as completed, update the ongoing trip status
        handleToggleOngoingTrip(false);
      }
    } catch (error) {
      console.error("Error updating trip completion:", error);
      // Revert the local state if there is an error
      setIsCompleted(!newCompletionStatus);
    }
  };

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      pb={0}
      maxH="8vh"
      borderBottom="1px"
      borderColor="blackAlpha.200"
      overflowY="hidden"
    >
      <Box
        display="flex"
        alignItems="center"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        width="100%"
        height="100%"
        p={4}
        // pt={0}
        // pb={0}
        boxSizing="border-box"
        overflowY="hidden"
      >
        <Text
          fontSize="md"
          fontWeight="bold"
          mb={0}
          mt={0}
          color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
        >
          <Translate text="Itinerary" />
        </Text>
        <Spacer />
        <Box
          display="flex"
          alignItems="center"
          onClick={(e) => e.stopPropagation()}
          mr={2}
        >
          <Text mb={0} mr={2} fontSize="sm">
            <Translate text="Mark Completed" />
          </Text>
          <Switch
            size="sm"
            isChecked={isCompleted} // Bind to state
            onChange={handleToggleCompletion} // Trigger toggle function on change
          />
        </Box>
        <IconButton
          size="sm"
          icon={<Box as={DownloadIcon} boxSize="5" />}
          aria-label="Download"
          bg="none"
          _hover={{ bg: "none" }}
        />
        <IconButton
          size="sm"
          icon={<FaShareAlt />}
          onClick={handleShare} // Call the new share function to open modal
          aria-label="Share"
          bg="none"
          _hover="none"
        />
        <SettingsMenu />
      </Box>
      {/* Modal for sharing the link */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {" "}
            <Translate text="Share Profile" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              {" "}
              <Translate text="Here is the shareable link for the profile:" />
            </Text>
            <Box
              bg={colorMode === "light" ? "gray.100" : "gray.700"}
              p={3}
              borderRadius="md"
              wordBreak="break-all"
            >
              {shareUrl}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onCopy}
              _hover="none"
              bg="black"
              color="white"
              mr={3}
            >
              <Translate text={hasCopied ? "Copied" : "Copy Link"} />
            </Button>
            <Button variant="ghost" onClick={onClose}>
              <Translate text="Close" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ItineraryHeader;
