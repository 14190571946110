import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Flex,
  Button,
  IconButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Slider as ChakraSlider,
  SliderThumb,
  SliderTrack,
  SliderFilledTrack,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { FaPlay, FaPause } from "react-icons/fa";
import demoVideo from "../assets/videos/demo.mp4";
import Translate from "../components/LanguageTranslation/Translate";

const VideoSection = () => {
  const history = useHistory();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isHovered, setIsHovered] = useState(false); // Track hover state
  const videoRef = useRef(null);

  // Handle play/pause toggle
  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Handle progress bar update
  const handleProgress = () => {
    const progress =
      (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setProgress(progress);
  };

  // Handle seeking using the progress bar
  const handleSeek = (value) => {
    const newTime = (value / 100) * videoRef.current.duration;
    videoRef.current.currentTime = newTime;
    setProgress(value);
  };

  // Update the progress as the video plays
  useEffect(() => {
    const video = videoRef.current;
    video.addEventListener("timeupdate", handleProgress);
    return () => {
      video.removeEventListener("timeupdate", handleProgress);
    };
  }, []);

  const handleLinkClick = (message) => {
    localStorage.removeItem("conversation_id"); // Clear any existing conversation
    history.push(`/ava/chat?message=${encodeURIComponent(message)}`); // Redirect to the chat system with the clicked text
  };

  // Handle navigating to the blog and scrolling to the top
  // const handleNavigateToBlog = () => {
  //   history.push("/blogs");
  //   window.scrollTo(0, 0); // Ensure page scrolls to the top
  // };

  return (
    <Box ml={20} mr={20}>
      {/* Header Section */}
      <Flex
        direction={{ base: "column", md: "row" }} // Change direction for smaller screens
        justifyContent="space-between"
        alignItems="flex-start" // Align contents to the top left
        p={8}
        pt={20}
        pb={20}
        pr={{ base: 8, md: 2, lg: "4" }}
        bg="white"
        maxW={{ base: "100%", md: "100%" }}
      >
        {/* Video Section on Left */}
        <Box
          flex="1"
          position="relative"
          maxW={{ base: "100%", md: "45%", lg: "45%" }}
          h="auto"
          borderRadius="30px"
          boxShadow="md"
          overflow="hidden"
          onMouseEnter={() => setIsHovered(true)} // Track hover enter
          onMouseLeave={() => setIsHovered(false)} // Track hover leave
          mr={{ base: 0, md: 4 }} // Margin right for spacing
          textAlign="left" // Align text to the left
        >
          {/* Video */}
          <video
            ref={videoRef}
            src={demoVideo}
            style={{
              objectFit: "cover", // Ensure the video covers the entire box
              width: "100%",
              height: "100%", // Make it fill the container
              borderRadius: "0", // Remove any rounding
            }}
            onClick={handlePlayPause} // Play/pause by clicking the video
          />

          {/* Black shadow layer when video is not playing */}
          {!isPlaying && (
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              bg="rgba(0, 0, 0, 0.4)" // Semi-transparent black shadow
              zIndex="1" // Layer it above the video
              pointerEvents="none" // Prevent interfering with play/pause clicks
            />
          )}

          {/* Play Button - Always visible */}
          {!isPlaying && (
            <IconButton
              icon={<FaPlay />}
              onClick={handlePlayPause}
              position="absolute"
              top="50%" // Center the button vertically
              left="50%" // Center the button horizontally
              transform="translate(-50%, -50%)" // Adjust to perfectly center the button
              zIndex="2"
              bgGradient="linear(to-r, #286328, #4CAF50)" // Apply a gradient background
              borderRadius="full"
              color="white"
              size="lg" // Adjust size to make button larger
              _hover="none"
            />
          )}

          {/* Pause Button - Visible only when video is playing and hovered */}
          {isPlaying && isHovered && (
            <IconButton
              icon={<FaPause />}
              onClick={handlePlayPause}
              position="absolute"
              top="50%" // Center the button vertically
              left="50%" // Center the button horizontally
              transform="translate(-50%, -50%)" // Adjust to perfectly center the button
              zIndex="2"
              bgGradient="linear(to-r, #286328, #4CAF50)" // Apply a gradient background
              borderRadius="full"
              color="white"
              size="lg" // Adjust size to make button larger
              _hover="none"
            />
          )}

          {/* Conditionally render Progress Bar over the video - Show on hover */}
          {isHovered && (
            <Box
              position="absolute"
              bottom="0" // Align the slider to the bottom edge of the video
              left="0"
              right="0"
              zIndex="2" // Ensure it's on top of the video
              paddingX={4}
              bg="rgba(0, 0, 0, 0.3)" // Semi-transparent background to make it more visible
            >
              <ChakraSlider
                aria-label="progress-bar"
                value={progress}
                onChange={handleSeek}
                colorScheme="green"
                height="10px" // Make the slider thin
              >
                <SliderTrack bg="gray.600">
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb boxSize={3} />
              </ChakraSlider>
            </Box>
          )}
        </Box>

        {/* Text Section on Right with Tabs */}
        <Box
          flex="1"
          ml={{ base: 0, md: 10 }} // Margin left for spacing
          maxW={{ base: "100%", md: "50%", lg: "55%" }}
          textAlign="left" // Align text to the left
          justifyContent="flex-start"
        >
          <Heading
            as="h1"
            size={{ base: "xl", md: "lg", lg: "1xl" }}
            fontFamily="Poppins, sans-serif"
            textAlign="left" // Align heading to the left
          >
            <Translate text="Explore the Outdoors" />
          </Heading>

          {/* Tabs Section with custom styling */}
          <Tabs variant="unstyled" mt={4} minW="68%" maxW="90%">
            <TabList>
              <Tab
                _selected={{
                  color: "#286328",
                  fontWeight: "bold",
                  borderBottom: "3px solid #286328",
                }}
                _focus={{ boxShadow: "none" }}
                color="gray.400"
                fontWeight="bold"
                fontSize="sm" // Decreased font size for tabs
                textAlign="left" // Align tab text to the left
                borderBottom="2px solid #e5e5e5"
              >
                <Translate text="ABOUT US" />
              </Tab>
              <Tab
                _selected={{
                  color: "#286328",
                  fontWeight: "bold",
                  borderBottom: "3px solid #286328",
                }}
                _focus={{ boxShadow: "none" }}
                color="gray.400"
                fontWeight="bold"
                fontSize="sm" // Decreased font size for tabs
                textAlign="left" // Align tab text to the left
                borderBottom="2px solid #e5e5e5"
              >
                <Translate text="WHY CHOOSE US" />
              </Tab>
              <Tab
                _selected={{
                  color: "#286328",
                  fontWeight: "bold",
                  borderBottom: "3px solid #286328",
                }}
                _focus={{ boxShadow: "none" }}
                color="gray.400"
                fontWeight="bold"
                fontSize="sm" // Decreased font size for tabs
                textAlign="left" // Align tab text to the left
                borderBottom="2px solid #e5e5e5"
              >
                <Translate text="OUR MISSION" />
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Text mt={2} textAlign="left" mb={0}>
                  <Text as="span" fontWeight="bold">
                    Nomad Tours
                  </Text>{" "}
                  <Translate
                    text="creates personalized travel plans in seconds, tailored to your
                  outdoor lifestyle. Stress-free planning and epic adventures
                  await!"
                  />
                </Text>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Text mt={2} textAlign="left" mb={0}>
                    <Text as="span" fontWeight="bold">
                      Nomad Tours{" "}
                    </Text>

                    <Translate
                      text="uses the latest AI to create personalized travel plans in
                    seconds. You’ll get helpful tools like a budget planner and
                    packing list to make your trip even easier."
                    />
                  </Text>
                  <Text mb={0}>
                    <Translate
                      text="We also connect you with other travelers and businesses for
                    real tips and advice. Enjoy smart tech and a great travel
                    community, all in one place."
                    />
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Text mt={2} textAlign="left" mb={0}>
                    <Text as="span" fontWeight="bold">
                      Nomad Tours'{" "}
                    </Text>

                    <Translate
                      text="mission is to inspire everyone to connect with nature and
                    explore the amazing places around us."
                    />
                  </Text>
                  <Text mb={0}>
                    <Translate
                      text="We aim to create unforgettable experiences that not only
                    lead to lasting memories, but also boost your health and
                    mental well-being. By protecting our green planet, we
                    promote a sustainable future for all."
                    />
                  </Text>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Flex mt={0} ml={4} justifyContent="flex-start">
            <Button
              bg="black"
              color="white"
              _hover="none"
              as={RouterLink}
              to="/travel-planner"
              size={{ base: "sm", md: "md", lg: "md" }}
              mr={4}
            >
              <Translate text="Get started" />
            </Button>
            <Button
              variant="outline"
              borderColor="black"
              _hover="none"
              size={{ base: "sm", md: "md", lg: "md" }}
              onClick={() => handleLinkClick("Find Lodging")} // Call the function to navigate and scroll
            >
              <Translate text="Find Lodging" />
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default VideoSection;
